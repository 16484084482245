module Nickel {

    export class Component {

        /**
         * The config JSON data associated with this component.
         */
        public data:any;

        /**
         * The jquery object container for this view.
         */
        public container:JQuery;

        /**
         * All of this views content DOM elements.
         */
        public content:JQuery;

        /**
         * The Class that created this instance.
         */
        public delegate:any;

        /**
         * Whether of not this instance is visible or not.
         */
        public onStage:boolean = false;

        /**
         * Timeout used when showing and hiding this view.
         */
        public displayTimeout:number;

        /**
         * The delay for setting display:none on this view after the showContent class is removed.
         */
        public hideDelay:any = 0;

        /**
         * The delay for adding the showContent class to this view once display:block has been set.
         */
        public showDelay:number = 25;

        /**
         * Rivets.js instance for binding this.data to this.content
         */
        public rivets:any;

        /**
         * The object containing all event listeners added to this specific component
         */
        private listeners:any = {};

        /**
         * The class to use for showing this component
         */
        public displayClass:string = "block";

        /**
         * A unique id for this class, used binding / removing global event listenrs
         */
        public guid:string = Utils.generateUUID();

        /**
         * Stores the global vars
         * @param container    A jQuery object containing the parent div for this view.
         * @param data            The config JSON data associated with this component.
         * @param delegate        The Class that instantiated this view.
         */
        constructor(container:JQuery, data:any, delegate:any) {

            this.container = container;
            this.delegate = delegate;
            this.data = data;
        }

        /**
         * Sets this.content with a JQuery object pased in (usually cloned from Main.templates), binds this.data to
         * this.content using rivets.
         */
        public viewLoaded(v:JQuery):void {

            this.content = v;
            this.rivets = rivets.bind(this.content, {
                "data": this.data
            })
            if (this.container) {
                this.container.append(this.content);
            }
        }

        /**
         * Adds an event listener for an event dispatched from this specific instance.
         * @evt        Event string we want to add an event listener for.
         * @callback    The function we want to call if that event is dispatched.
         */
        public on(evt:string, callback:any) {

            if (!this.listeners[evt]) {
                this.listeners[evt] = [];
            }

            if (this.listeners[evt].indexOf(callback) == -1) {
                this.listeners[evt].push(callback);
            }
        }

        /**
         * Removes an event listener for an event dispatched from this specific instance.
         * @evt        Event string we want to remove an event listener for.
         * @callback    The function we want to remove from the listeners array for that event.
         */
        public off(evt:string, callback:any) {

            var i = this.listeners[evt].indexOf(callback)
            if (i > -1) {
                this.listeners[evt].splice(i, 1);
                if (this.listeners[evt].length == 0) {
                    this.listeners[evt] = null;
                }
            }
        }

        /**
         * Dispatches an event to any Classes listening for it from this instance.
         * @evt        The event we want to dispatch.
         * @callback    The data we want to pass back to the event listener function.
         */
        public dispatch(evt:string, data:any = null) {

            var listeners = this.listeners[evt];
            if (listeners) {
                for (var i = 0; i < listeners.length; i++) {
                    listeners[i](data);
                }
            }
        }

        /**
         * Binds all of the event listeners for this component.
         */
        public bindEvents() {

        }

        /**
         * Unbinds all of the Event listeners for this component.
         */
        public unbindEvents() {

        }

        /**
         * Adds display block to this.content, and calls showContent
         */
        public showMe():void {

            var style = (this.displayClass == "visible") ? "visibility" : "display";
            this.content.css(style, this.displayClass);
            this.onStage = true;

            clearTimeout(this.displayTimeout);
            this.displayTimeout = setTimeout(()=> {
                this.showContent();
            }, this.showDelay);
        }

        /**
         * Adds display none to this.content, calls hideContent
         */
        public hideMe():void {

            this.hideContent();
            this.onStage = false;

            var style = (this.displayClass == "visible") ? "visibility" : "display";
            var hide = (style == "display") ? "none" : "hidden";

            clearTimeout(this.displayTimeout);
            this.displayTimeout = setTimeout(()=> {
                this.content.css(style, hide);
            }, this.hideDelay);
        }

        /**
         * Adds the showContent class and removes the hideContent class from this component.
         */
        public showContent():void {
            if (this.content) {
                this.content.removeClass('hideContent');
                this.content.addClass('showContent');
            }
        }

        /**
         * Adds the hideContent class and removes the showContent class from this component.
         */
        public hideContent():void {
            if (this.content) {
                this.content.removeClass('showContent');
                this.content.addClass('hideContent');
            }
        }

        /**
         * Kills this component, removes the event listeners, removes this.content, and set's itself to null;
         */
        public killMe() {

            this.unbindEvents();
            if (this.content) {
                this.content.remove();
                this.content = null;
            }
        }
    }
}
