module Nickel {

    export class DecisionOption extends Level {

        public sceneDropdown:any;

        constructor(container, data, index, delegate) {

            super(container, data, index, delegate);

            this.data = data;
            this.viewLoaded(Main.templates.find('.decision').clone());
            this.container.append(this.content);
            this.sceneDropdown = this.content.find('.sceneSelect');
            this.sceneDropdown.bind('change', $.proxy(this.dropdownChanged, this));

        }

        private dropdownChanged() {

            var id = this.sceneDropdown.find('option:selected').attr('data-id');
            this.data.value = id;
        }

        public addScenes(scenes) {

            for (var key in scenes) {
                if (scenes.hasOwnProperty(key)) {
                    var scene = scenes[key];
                    var option = $('<option data-id = "' + scene.id + '">' + scene.id + '</option>');
                    this.sceneDropdown.append(option);
                }
            }

            if (this.data.value) {
                if (scenes[this.data.value]) {
                    this.sceneDropdown.val(scenes[this.data.value].id);
                } else {
                    this.data.value = "";
                }
            }
        }

        public killMe() {

            super.killMe();

            this.sceneDropdown.unbind('change');
        }
    }
}
