class AjaxUrlProvider {
    public static localApiHost: string = 'api'; // no trailing slash
    private static restApiBaseUrl: string = null;
    private static legacyApiBaseUrl: string = null;

    public static getRestApiBaseUrl() {
        if (!AjaxUrlProvider.restApiBaseUrl) {
            var baseUrl: string = 'https://';
            var domain: string = document.domain;
            var domainPieces: Array<string> = domain.split('.');
            if (domain.indexOf('imposium') === -1) {
                baseUrl += AjaxUrlProvider.localApiHost;
            } else if (domainPieces[0] === 'cms') {
                baseUrl += domain.replace('cms', 'api');
            } else if (domainPieces[0] === 'hub') {
                baseUrl += domain.replace('hub', 'api');
            } else {
                console.warn('Unsupported API hostname');
            }

            AjaxUrlProvider.restApiBaseUrl = baseUrl;
        }

        return AjaxUrlProvider.restApiBaseUrl;
    }

    public static getLegacyApiBaseUrl() {
        if (!AjaxUrlProvider.legacyApiBaseUrl) {
            var baseUrl: string = '';
            var domain: string = document.domain;
            var domainPieces: Array<string> = domain.split('.');
            if (domain.indexOf('imposium') === -1) {
                baseUrl += 'http://localhost'
            } else if (domainPieces[0] === 'cms') {
                baseUrl += 'https://' + domain;
            } else if (domainPieces[0] === 'hub') {
                baseUrl += 'https://' + domain.replace('hub', 'cms');
            } else {
                console.warn('Unsupported legacy API hostname');
            }

            AjaxUrlProvider.legacyApiBaseUrl = baseUrl;
        }

        return AjaxUrlProvider.legacyApiBaseUrl;
    }
}

class AjaxRequest {
    public static organizationId: string = null;

    public apiVersion: string = null;
    public url: string = null;
    public data: any = {};
    public successCallback: any = null;
    public errorCallback: any = null;
    public loader: JQuery = null;
    public org: string = null;

    public constructor(data: any = {}, success: any = null, error: any = null, loader: JQuery = null, url: string = '/api') {
        this.url = (url.indexOf('http') == 0) ? url : AjaxUrlProvider.getLegacyApiBaseUrl() + url;
        this.data = data;
        this.successCallback = success;
        this.errorCallback = error;
        this.loader = loader;
        this.org = AjaxRequest.organizationId;
    }
}

class HMacAjaxRequest extends AjaxRequest {
    public static accessKeyId: string = null;
    public static secretAccessKey: string = null;

    public route: string;
    public sig: string;
    public key: string;
    public secret: string;

    public constructor(route: string, data: any = null, success: any = null, error: any = null, loader: any = null) {
        super(data, success, error, loader);

        this.secret = HMacAjaxRequest.secretAccessKey;
        this.key = HMacAjaxRequest.accessKeyId;
        this.route = route;
        this.url = AjaxUrlProvider.getRestApiBaseUrl() + this.route;

        route = route.split(/[?#]/)[0];

        if (data) {
            if (typeof data === 'string') {
                route += data;
            } else {
                route += JSON.stringify(data);
            }
        }

        this.sig = this.getSignature(route);
    }

    private getSignature(message) {

        var sig = CryptoJS.HmacSHA1(message, this.secret);
        var base64 = sig.toString(CryptoJS.enc.Hex);
        return base64;
    }
}

class JWTAjaxRequest extends AjaxRequest {
    public static idToken: string = null;
    public authToken: string = null;

    public constructor(route: string, data: any = null, success: any = null, error: any = null, loader: any = null) {
        super(data, success, error, loader);

        this.authToken = JWTAjaxRequest.idToken;
        this.url = AjaxUrlProvider.getRestApiBaseUrl() + route;
    }
}

class Ajax {
    static oldestApiVersion: string = "0.0.1";
    static latestApiVersion: string = "2.0.0";
    static formSubmit(form: any, onComplete: any = null, loader: JQuery = null, onProgress: any = null) {
        if (!form.data('ajaxified')) {
            form.data('ajaxified', '1');
            form.ajaxForm({
                complete: function (xhr) {
                    if (loader) {
                        loader.hide();
                    }

                    if (onComplete) {
                        var responseObj = eval("(" + xhr.responseText + ")");

                        onComplete(responseObj);
                    }
                },
                uploadProgress: function (evt, pos, total, perc) {
                    if (onProgress) {
                        onProgress(evt, pos, total, perc);
                    }
                }
            });
        }
        if (loader) {
            loader.show();
        }
        form.submit();
        return form;
    }

    static get(request: any) {
        if (request.data) {
            return Ajax.request('GET', request, "application/x-www-form-urlencoded; charset=utf-8");
        } else {
            return Ajax.request('GET', request);
        }
    }

    static post(request: any) {
        return Ajax.request('POST', request);
    }

    static request(method: string, request: any, contentType: any = "application/json; charset=utf-8", additionalHeaders: any = null) {
        if (request.loader) {
            request.loader.show();
        }

        var requestData: JQueryAjaxSettings = {
            type: method,
            url: request.url,
            contentType: contentType,
            success: function (response) {
                if (request.loader) {
                    request.loader.hide();
                }

                if (request.successCallback) {
                    request.successCallback(response);
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {

                if (request.loader) {
                    request.loader.hide();
                }
                if (request.authToken && jqXHR.status === 403) {
                    Nickel.Auth.logout();
                } else if (request.errorCallback) {
                    request.errorCallback({jqXHR: jqXHR, textStatus: textStatus, errorThrown: errorThrown});
                }
            }
        };

        var isJsonRequest: boolean = contentType && contentType.indexOf('application/json') != -1;

        if (isJsonRequest) {
            requestData.dataType = 'json';
        } else if (contentType === false) {
            requestData.processData = false;
        }

        if (additionalHeaders) {
            requestData.headers = additionalHeaders;
        }

        if (request.data) {
            requestData.data = (isJsonRequest) ? JSON.stringify(request.data) : request.data;
        }

        if (request.sig) {
            requestData.beforeSend = function (r) {
                r.setRequestHeader("X-Imposium-Access-Key", request.key);
                r.setRequestHeader("X-Imposium-Signature", request.sig);
                if (request.apiVersion) {
                    r.setRequestHeader("X-Imposium-Api-Version", request.apiVersion);
                }
                if (request.org) {
                    r.setRequestHeader("X-Imposium-Account-Id", request.org);
                }
            }
        }

        if (request.authToken) {
            requestData.beforeSend = function (r) {
                r.setRequestHeader("Authorization", "Bearer " + request.authToken);
                if (request.apiVersion) {
                    r.setRequestHeader("X-Imposium-Api-Version", request.apiVersion);
                }
                if (request.org) {
                    r.setRequestHeader("X-Imposium-Account-Id", request.org);
                }
            }
        }

        return $.ajax(requestData);
    }
}
