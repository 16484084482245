module Nickel {

    export class PaginatedView extends View {

        /**
         * The current page we're on
         */
        public page:number = 1;

        /**
         * The total number of pages in this data set
         */
        public pages:number = 1;

        /**
         * The total number of experiences in this data set
         */
        public total:number = 1;

        /**
         * JQuery objects for the main buttons on the view
         */
        private btnNextPage:JQuery;
        private btnPrevPage:JQuery;
        private btnFirstPage:JQuery;
        private btnLastPage:JQuery;
        private btnRefresh:JQuery;

        /**
         * Stores the global vars, and adds the state change listener
         * @param container    A jQuery object containing the parent div for this view.
         * @param id            The unique ID associated with this view, used to determine if this view should be
         *     visible or not by listening to the browser state.
         * @param displayText    The text to show in the header for this view.
         */
        constructor(container, id, displayText) {

            super(container, id, displayText);

            this.btnNextPage = this.container.find('.btnNextPage').bind('click', $.proxy(this.nextPageClicked, this));
            this.btnPrevPage = this.container.find('.btnPrevPage').bind('click', $.proxy(this.prevPageClicked, this));
            this.btnFirstPage = this.container.find('.btnFirstPage').bind('click', $.proxy(this.firstPageClicked, this));
            this.btnLastPage = this.container.find('.btnLastPage').bind('click', $.proxy(this.lastPageClicked, this));
            this.btnRefresh = this.container.find('.btnRefresh').bind('click', $.proxy(this.reloadPaginatedData, this));
        }

        /**
         * Makes sure that the pagination buttons are enabled / disabled appropriately.
         */
        public checkPaginationButtons():void {

            if (this.page <= 1) {
                this.btnPrevPage.addClass('disabled');
                this.btnFirstPage.addClass('disabled');
            } else {
                this.btnPrevPage.removeClass('disabled');
                this.btnFirstPage.removeClass('disabled');
            }

            if (this.page == this.pages) {
                this.btnNextPage.addClass('disabled');
                this.btnLastPage.addClass('disabled');
            } else {
                this.btnNextPage.removeClass('disabled');
                this.btnLastPage.removeClass('disabled');
            }

            if (this.pages == 0) {
                this.container.find('.paginationPiece').hide();
            } else {
                this.container.find('.paginationPiece').show();
            }
        }

        /**
         * Moves this.page variable ahead and gets some new experiences.
         * @param e    Event passed in from the button click.
         */
        private nextPageClicked(e:Event):void {

            this.page++;
            this.reloadPaginatedData();
        }

        /**
         * Moves this.page variable back and gets some new experiences.
         * @param e    Event passed in from the button click.
         */
        private prevPageClicked(e:Event):void {

            this.page--;
            this.reloadPaginatedData();
        }

        /**
         * Sets this.page to 1 and gets the experiences.
         * @param e    Event passed in from the button click.
         */
        private firstPageClicked(e:Event):void {

            this.page = 1;
            this.reloadPaginatedData();
        }

        /**
         * Sets this.page to the last page and gets the experiences.
         * @param e    Event passed in from the button click.
         */
        private lastPageClicked(e:Event):void {

            this.page = this.pages;
            this.reloadPaginatedData();
        }

        /**
         * Checks pagination buttons and reloads paginated data.
         */
        public reloadPaginatedData() {
            this.checkPaginationButtons();
            this.getPaginatedData();
        }

        /**
         * Reaches out to server with this.page.
         * Callback with response from server must set this.pages and this.total
         */
        public getPaginatedData() {
            // Override me
            // TODO: make abstract by upgrading Typescript
        }
    }
}
