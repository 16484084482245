module Nickel {

    export class DataView extends PaginatedView {

        /**
         * The current story we're viewing batches for
         */
        private storyId: string;

        /**
         * The label of the current story we're viewing batches for
         */
        private storyLabel: string = "";

        /**
         * The current batch we're modifying
         */
        private batchId: string;

        /**
         * The label of the current batch we're viewing
         */
        private batchLabel: string = "";

        /**
         * An array holding all BatchRowItem classes
         */
        private batchRows: any = [];

        /**
         * An array holding all BatchColumn classes
         */
        private batchColumns: any = [];

        /**
         * A JSON-encoded string representing the last loaded batch column result.
         * Used to only reload columns if changes have occurred to the column structure.
         */
        private batchColumnResult: string = "";

        /**
         * If this view has been initilized yet
         */
        private initialized: boolean = false;

        /**
         * Rivets.js instance binding this classes Global variables to the view
         */
        public rivets: any;

        /**
         * Dropdown containing a link for each story.
         */
        private storyDropdown: Nickel.StoryPicker;

        /**
         * Dropdown containing a link for each batch.
         */
        private batchDropdown: Nickel.BatchPicker;

        /**
         * The number of rows to render. Will be 0 unless a batch is currently running.
         * The default value of 1 is overwritten as soon as data is returned. This hides the Render button until we
         * have data.
         */
        private rowsToRender: number = 1;

        /**
         * JQuery objects for the main buttons on the view
         */
        private loader: JQuery;
        private spreadsheetUpload: JQuery;

        /**
         * Export CSV loader.
         */
        private exportLadda: any;

        /**
         * Stores the global vars, and adds the state change listener
         * @param container    A jQuery object containing the parent div for this view.
         * @param id            The unique ID associated with this view, used to determine if this view should be
         *     visible or not by listening to the browser state.
         * @param displayText    The text to show in the header for this view.
         */
        constructor(container, id, displayText) {

            super(container, id, displayText);

            this.storyDropdown = new Nickel.StoryPicker(this.container.find('.storyDropdownContainer'), null, this);
            this.batchDropdown = new Nickel.BatchPicker(this.container.find('.batchDropdownContainer'), null, this);

            this.loader = this.container.find('.batchLoader');
            this.exportLadda = Ladda.create(this.container.find('.btnExportBatch').get(0));

            this.container.find('[data-toggle="tooltip"]').tooltip();
        }

        /**
         * Binds this view to the DOM, populates the dynamic header buttons, binds all of the event listeners, and
         * get's the first batch of experiences.
         */
        private init(): void {

            this.initialized = true;

            this.rivets = rivets.bind(this.container, {
                "controller": this
            });

            this.storyDropdown.on(StoryPicker.STORY_CLICKED, $.proxy(this.storyClicked, this));
            this.batchDropdown.on(BatchPicker.BATCH_CLICKED, $.proxy(this.batchClicked, this));

            this.container.find('.btnNewBatch').bind('click', $.proxy(this.createNewBatch, this));
            this.container.find('.btnImportBatchData').bind('click', $.proxy(this.triggerBatchDataImport, this));
            this.container.find('.btnDeleteBatch').bind('click', $.proxy(this.deleteBatch, this));
            this.container.find('.btnEditColumns').bind('click', $.proxy(this.editColumns, this));
            this.container.find('.btnCloseColumnEditor').bind('click', $.proxy(this.hideColumnEditor, this));
            this.container.find('.btnExportBatch').bind('click', $.proxy(this.exportBatch, this));
            this.container.find('.btnRenderBatch').bind('click', $.proxy(this.renderBatch, this));
            this.container.find('.btnCancelBatch').bind('click', $.proxy(this.cancelBatch, this));
            this.container.find('.btnSaveColumns').bind('click', $.proxy(this.saveColumns, this));
            this.container.find('.btnAddColumn').bind('click', $.proxy(this.addColumn, this));

            this.spreadsheetUpload = this.container.find('.spreadsheetUpload');
            this.spreadsheetUpload.bind('click', function (e) { $(this).prop("value", ""); e.stopPropagation(); });
            this.spreadsheetUpload.bind('change', $.proxy(this.importBatchData, this));
        }

        /**
         * Decide which story and batch to load and display.
         */
        public stateChanged() {

            super.stateChanged();

            if (this.initialized) {
                var urlStrings = History['getState']().url.split("?")[0].split("/");

                var storyId = urlStrings[4];
                var batchId = urlStrings[5];

                this.setState(storyId, batchId);
            }
        }

        /**
         * Reloads data from the db and sets current state based on story id / batch id specified.
         * This should only be called from stateChanged. Nowhere else.
         * @param storyId
         * @param batchId
         */
        private setState(storyId: string = null, batchId: string = null) {
            if (storyId) {
                Ajax.get(new JWTAjaxRequest('/story/' + storyId + '/batches', null, $.proxy(function (response) {
                    this.storyId = response.story_id;
                    this.storyLabel = response.story_name;
                    Main.setHeaderStory({id: this.storyId, name: this.storyLabel});

                    var batches: Array<Object> = response.batches;
                    this.batchDropdown.populateBatchDropdown(batches, this.storyId);

                    if (batchId) {
                        for (var i = 0; i < batches.length; i++) {
                            if (batches[i]['id'] === batchId) {
                                this.batchId = batchId;
                                this.batchLabel = batches[i]['name'];
                                break;
                            }
                        }
                        this.page = 1;
                        this.reloadPaginatedData();
                    }
                }, this)));
            } else {
                Main.setDefaultHeaderStory();
            }
        }

        /**
         * Clears current story & batch state.
         */
        private clearState() {
            this.storyId = null;
            this.clearBatchState();
        }

        /**
         * Clears current batch state.
         */
        private clearBatchState() {
            this.batchId = null;
            this.batchLabel = "";
            this.batchColumnResult = '';
            this.clearColumns();
        }

        /**
         * Handles story being clicked from Story dropdown.
         * @param story
         */
        public storyClicked(story) {
            if (story.id !== this.storyId) {
                this.clearState();

                Utils.pushState('/data/' + story.id);
            }
        }

        /**
         * Handles batch being clicked from Batch dropdown.
         * @param batch
         */
        private batchClicked(batch) {
            if (batch.id !== this.batchId) {
                this.clearBatchState();

                Utils.pushState('/data/' + batch.story_id + '/' + batch.id);
            }
        }

        /**
         * Adds a batch entry under the current story (without data).
         */
        private createNewBatch() {
            var name: string = prompt('Please provide a name for the new batch.');
            if (name) {
                Ajax.post(new JWTAjaxRequest('/batch', {
                    'story_id': this.storyId,
                    'name': name,
                }, $.proxy(function (batch) {
                    this.batchClicked(batch);
                }, this)));
            }
        }

        /**
         * Reaches out to server to get batch data.
         */
        public getPaginatedData() {
            this.clearExperiences();

            if (!this.batchId) {
                return;
            }

            var filters: Array<string> = [];
            for (var i = 0; i < this.batchColumns.length; i++) {
                if (this.batchColumns[i].hasFilter()) {
                    filters.push(this.batchColumns[i].getFilter());
                }
            }

            Ajax.get(new JWTAjaxRequest(
                '/batch/' + this.batchId + '?' + $.param({'page': this.page, 'filters': filters}),
                null,
                $.proxy(this.gotBatch, this),
                null,
                this.loader
            ));
        }

        /**
         * Loads batch data into display.
         */
        public gotBatch(result) {
            this.pages = result.total_pages;
            this.total = result.row_count;
            this.rowsToRender = result.rows_to_render;

            // Add columns
            this.setColumns(result.columns);

            // Add rows
            this.addExperiences(result.data);

            // Size dynamic columns evenly
            var totalColumns = this.container.find('.columnHolder .col.col-auto').length;
            var percent: number = (totalColumns > 0) ? (100 / totalColumns) : 0;
            this.container.find('.col-auto').not('.col-status').css('width', percent + '%');

            this.checkPaginationButtons();
        }

        /**
         * Creates new BatchColumn classes and adds them to the this.batchColumns object.
         * @param columnData
         */
        private setColumns(columnData: any[]): void {

            // Check to see if columns have changed - return immediately if not, cache and continue if so
            var encodedResult: string = JSON.stringify(columnData);
            if (encodedResult === this.batchColumnResult) {
                return;
            }

            this.batchColumnResult = encodedResult;

            // Wipe existing columns / column holder
            this.clearColumns();

            // Add columns
            var columnHolder: JQuery = this.container.find('.columnHolder');

            for (var index in columnData) {
                var col = new Nickel.BatchColumn(columnHolder, parseInt(index), columnData[index], this);
                this.batchColumns.push(col);
            }

            columnHolder.append('<div class = "col col-auto">Moderation Status</div>'); // Add status
        }

        /**
         * Creates new BatchRowItem classes and adds them to the this.batchRows object.
         * @param rowData
         */
        private addExperiences(rowData: any[]): void {

            var batchHolder: JQuery = this.container.find('.batchHolder');

            for (var i = 0; i < rowData.length; i++) {
                var exp = new Nickel.BatchRowItem(batchHolder, rowData[i], this);
                this.batchRows.push(exp);
            }
        }

        /**
         * Removes and kills all of the existing BatchRowItem classes.
         */
        private clearExperiences(): void {

            for (var i = 0; i < this.batchRows.length; i++) {
                var exp = this.batchRows[i];
                exp.killMe();
                exp = null;
            }
            this.batchRows = [];
        }

        /**
         * Removes and kills all of the existing BatchColumn classes.
         */
        private clearColumns(): void {

            for (var i = 0; i < this.batchColumns.length; i++) {
                var col = this.batchColumns[i];
                col.killMe();
                col = null;
            }
            this.batchColumns = [];

            var columnHolder: JQuery = this.container.find('.columnHolder');
            columnHolder.empty();
        }

        /**
         * Brings up the interface to associate EGC/UGC with certain columns.
         */
        private editColumns() {
            if (this.batchColumns.length <= 0) {
                alert('Please import a CSV file before modifying EGC/UGC column associations.');
                return;
            }

            Ajax.get(new JWTAjaxRequest('/story/' + this.storyId, null, $.proxy(this.showColumnEditor, this)));
        }

        /**
         * Show column editor.
         */
        private showColumnEditor(storyData) {

            // Bind association select handler
            this.container.find('.columns ul[data-column] select.columnTypeSelect').each(function () {
                $(this).unbind('change').bind('change', $.proxy(DataView.configureAssociationDropdown, $(this).parent().parent(), storyData)).change();
            });
            this.container.find('.columns ul[data-column] select.associationSelect').unbind('change').bind('change', $.proxy(this.saveAssociationDetail, this));

            // Populate latest detail value on each column (because selection options are dynamic)
            for (var i = 0; i < this.batchColumns.length; i++) {
                var column: BatchColumn = this.batchColumns[i];
                this.container.find('.columns ul[data-column=' + column.getColumnIndex() + '] select.associationSelect').val(column.data.detail).change();
            }

            // Show the view
            this.container.find('.columnEditor').addClass('active');
        }

        /**
         * Hide column editor.
         */
        private hideColumnEditor() {

            // Hide the view
            this.container.find('.columnEditor').removeClass('active');

            // Force column refresh in case of cancelled changes
            this.batchColumnResult = '';

            // Reload data so user can see column changes
            this.reloadPaginatedData();
        }

        /**
         * Exports batch data with added EGC columns to a CSV file.
         */
        private exportBatch(e) {
            this.exportLadda.start();

            Ajax.get(new JWTAjaxRequest('/batch/' + this.batchId + '/export', null, $.proxy(function (response) {
                if (response.id) {
                    this.downloadBatchExport(response.id);
                } else {
                    Ajax.post(new JWTAjaxRequest('/batch/' + this.batchId + '/export', null, $.proxy(this.batchDataExportQueued, this, this.batchId)));
                }
            }, this)));
        }

        /**
         * Asks for confirmation and triggers the batch render if confirmed.
         */
        private renderBatch() {
            Ajax.post(new JWTAjaxRequest('/batch/' + this.batchId + '/prepare', null, $.proxy(function (response) {
                var triggered: boolean = false;
                /*var avgRenderTime: number = response.average_render_time;
                if (!avgRenderTime) {
                    alert('Please render at least 1 video before attempting to run the batch.')
                } else */if (!response.rows_to_render || response.rows_to_render <= 0) {
                    alert('Nothing to render.');
                } else {
                    var confirmation = confirm(DataView.getBatchConfirmationMsg(response.rows_to_render));
                    if (confirmation) {
                        this.triggerBatchRender();
                        triggered = true;
                    }
                }

                if (!triggered) {
                    Ajax.post(new JWTAjaxRequest('/batch/' + this.batchId + '/cancel'));
                }

            }, this), null, this.loader));
        }

        /**
         * Starts the batch render.
         */
        private triggerBatchRender() {
            Ajax.post(new JWTAjaxRequest('/job/render-batch', {
                'batch_id': this.batchId,
            }, $.proxy(this.reloadPaginatedData, this)));
        }

        /**
         * Cancels a batch by setting the # of rows to render to 0.
         */
        private cancelBatch() {
            var confirmation = confirm('Are you sure? This will cancel the current batch and any partially collected EGC data will be left in its current state.');
            if (confirmation) {
                Ajax.post(new JWTAjaxRequest('/batch/' + this.batchId + '/cancel', null, $.proxy(this.reloadPaginatedData, this)));
            }
        }

        /**
         * Returns the final warning message before triggering the batch to render.
         * @param numJobs
         * @returns {string}
         */
        private static getBatchConfirmationMsg(numJobs) {
            var confirmMsg: string = 'Found ' + numJobs + ' ';
            confirmMsg += (numJobs > 1) ? 'jobs' : 'job';
            confirmMsg += ' to render. \n\n';
            confirmMsg += 'Press OK to run the batch.';
            return confirmMsg;
        }

        /**
         * Configures an association dropdown based on the currently selected column type. Context is a column <ul>.
         */
        private static configureAssociationDropdown(storyData) {
            var inventory, videoScene;
            for (var actId in storyData['acts']) {
                if (storyData['acts'].hasOwnProperty(actId)) {
                    inventory = storyData['acts'][actId]['inventory'];
                    for (var sceneId in storyData['acts'][actId]['scenes']) {
                        if (storyData['acts'][actId]['scenes'].hasOwnProperty(sceneId) &&
                            (storyData['acts'][actId]['scenes'][sceneId]['type'].indexOf('Video') != -1 || storyData['acts'][actId]['scenes'][sceneId]['type'].indexOf('Composition') != -1)) {
                            videoScene = storyData['acts'][actId]['scenes'][sceneId];
                            break;
                        }
                    }
                    break;
                }
            }

            var associationFields: JQuery = $(this).find('.association');
            var associationSelect: JQuery = associationFields.find('select.associationSelect');
            associationSelect.empty();

            var columnType: string = $(this).find('select.columnTypeSelect').val();
            switch (columnType) {
                case 'UGC':
                    associationFields.show();

                    associationSelect.append(new Option('Composition ID', 'composition_id'));
                    associationSelect.append(new Option('Composition Tag', 'composition_tag'));

                    if (inventory) {
                        for (var inventoryId in inventory) {
                            if (inventory.hasOwnProperty(inventoryId)) {
                                var inventoryItem = inventory[inventoryId];
                                associationSelect.append(new Option(inventoryItem.label || inventoryItem.name, inventoryId));
                            }
                        }
                    }

                    break;
                case 'EGC':
                    associationFields.show();

                    associationSelect.append(new Option('Experience ID', 'id'));
                    associationSelect.append(new Option('Story ID', 'story_id'));

                    if (videoScene) {
                        var encodingOptions = videoScene['sceneData']['encodingSettings'];
                        var increments = [];
                        var formatNames = [];
                        var formatKeys = [];
                        for (var i = 0; i < encodingOptions.length; i++) {
                            if (encodingOptions[i].extension && encodingOptions[i].height) {
                                var formatName = encodingOptions[i].name;
                                var formatKey = encodingOptions[i].extension + '_' + encodingOptions[i].height;

                                if (typeof increments[formatKey] === 'undefined') {
                                    increments[formatKey] = 0;
                                }
                                increments[formatKey]++;

                                formatNames.push(formatName);

                                if (formatKeys.indexOf(formatKey) <= -1) {
                                    formatKeys.push(formatKey);
                                } else {
                                    formatKeys.push(formatKey + '_' + increments[formatKey]);
                                }
                            }
                        }

                        for (var i = 0; i < formatKeys.length; i++) {
                            associationSelect.append(new Option(formatNames[i], 'video_url_' + formatKeys[i]));
                        }

                        if (videoScene['exportAsPlaylist']) {
                            associationSelect.append(new Option('M3U8', 'video_url_m3u8'));
                        }
                        if (videoScene['sceneData']['savePosterFrame']) {
                            associationSelect.append(new Option('Poster Frame', 'poster'));
                        }
                        if (videoScene['sceneData']['saveSnapshotFrame']) {
                            associationSelect.append(new Option('Snapshot Frame', 'snapshot'));
                        }
                        if (videoScene['sceneData']['saveSocialFrame']) {
                            associationSelect.append(new Option('Social Frame', 'social'));
                        }
                    }

                    break;
                default:
                    associationFields.hide();
                    break;
            }

            // Trigger association select change to save type/detail parameters.
            associationSelect.change();
        }

        /**
         * Binds dropdowns to the type/detail parameter of a column.
         * @param e The click event.
         */
        private saveAssociationDetail(e) {
            var detailDropdown: JQuery = $(e.currentTarget);
            var typeDropdown: JQuery = detailDropdown.parent().parent().find('select.columnTypeSelect');
            var columnIndex: number = detailDropdown.parent().parent().data('column');
            this.batchColumns[columnIndex].data.type = typeDropdown.val();
            this.batchColumns[columnIndex].data.detail = detailDropdown.val();
        }

        /**
         * Deletes the current batch.
         */
        private deleteBatch() {
            var confirmation = confirm("Delete " + this.batchLabel + "? This will permanently remove the batch along with all of it's data.");
            if (confirmation) {
                Ajax.request('DELETE', new JWTAjaxRequest('/batch/' + this.batchId, null, $.proxy(function (response) {
                    this.clearBatchState();
                    Utils.pushState('/data/' + this.storyId);
                }, this)));
            }
        }

        /**
         * Triggers file upload.
         * @param e
         */
        private triggerBatchDataImport(e) {
            this.spreadsheetUpload.click();
        }

        /**
         * Imports chosen file into current batch's data.
         */
        private importBatchData(e) {
            var input = <HTMLInputElement> this.spreadsheetUpload[0];
            var file = input.files[0];
            if (file) {
                var myFormData = new FormData();
                myFormData.append('csv', file);

                this.loader.show();

                $.ajax({
                    url: AjaxUrlProvider.getRestApiBaseUrl() + '/batch/' + this.batchId + '/data/from-csv',
                    type: 'POST',
                    processData: false,
                    contentType: false,
                    dataType: 'json',
                    data: myFormData,
                    headers: {
                        'Authorization': 'Bearer ' + JWTAjaxRequest.idToken
                    },
                    success: $.proxy(this.batchDataImportQueued, this)
                });
            }
        }

        /**
         * Callback for when a spreadsheet has been queued to be imported.
         * @param response
         */
        private batchDataImportQueued(response) {
            if (response['job_id'] && response['job_name']) {
                JobHandler.pollFor(response['job_id'], response['job_name'], $.proxy(function () {
                    this.loader.hide();
                    this.reloadPaginatedData();
                }, this), $.proxy(function () {
                    this.loader.hide();
                    alert('Failed to import CSV data - check the Console logs at the bottom of the Stories tab for more information');
                }, this));
            } else {
                this.loader.hide();
                alert('Unable to create job to import CSV data');
            }
        }

        /**
         * Callback for when a batch has been queued to be exported.
         * @param batchId
         * @param response
         */
        private batchDataExportQueued(batchId, response) {
            if (response['job_id'] && response['job_name']) {
                JobHandler.pollFor(response['job_id'], response['job_name'], $.proxy(function () {
                    Ajax.get(new JWTAjaxRequest('/batch/' + this.batchId + '/export', null, $.proxy(function (response) {
                        if (response.id) {
                            this.downloadBatchExport(response.id);
                        } else {
                            this.exportLadda.stop();
                            alert('Failed to export CSV data');
                        }
                    }, this)));
                }, this), $.proxy(function () {
                    this.exportLadda.stop();
                    alert('Failed to export CSV data - check the Console logs at the bottom of the Stories tab for more information');
                }, this));
            } else {
                this.exportLadda.stop();
                alert('Unable to create job to export CSV data');
            }
        }

        /**
         * Downloads a batch export.
         * @param exportId
         */
        private downloadBatchExport(exportId) {
            const anchor = this.container.find('a.btnExportBatchLink');

            $.ajax({
                url: AjaxUrlProvider.getRestApiBaseUrl() + '/batch/' + this.batchId + '/export/' + exportId + '?preserve=true',
                type: 'GET',
                dataType: 'binary',
                headers: {
                    'Authorization': 'Bearer ' + JWTAjaxRequest.idToken
                },
                processData: false,
                success: $.proxy(function (blob) {
                    let windowUrl = window['URL'] || window['webkitURL'];
                    let url = windowUrl.createObjectURL(blob);
                    anchor.prop('href', url);
                    anchor.prop('download', this.batchLabel + '.csv');
                    anchor.get(0).click();
                    windowUrl.revokeObjectURL(url);

                    this.exportLadda.stop();
                }, this),
                error: $.proxy(function (jqXHR, textStatus, errorThrown) {
                    this.exportLadda.stop();
                }, this)
            });
        }

        /**
         * Save latest columns to the db.
         */
        private saveColumns() {
            var columns: Array<Object> = [];
            for (var i = 0; i < this.batchColumns.length; i++) {
                columns.push(this.batchColumns[i].data);
            }

            Ajax.request('PUT', new JWTAjaxRequest('/batch/' + this.batchId + '/columns', columns, $.proxy(this.hideColumnEditor, this)));
        }

        /**
         * Add a new column.
         */
        private addColumn() {
            var newColumnIndex: number = this.batchColumns.length;
            var column = new Nickel.BatchColumn(this.container.find('.columnHolder'), newColumnIndex, {
                'name': '',
                'type': 'EGC',
                'detail': 'id'
            }, this);
            this.batchColumns.push(column);
            Ajax.get(new JWTAjaxRequest('/story/' + this.storyId, null, $.proxy(function (storyData) {
                var newColumn = this.container.find('.columns ul[data-column=' + newColumnIndex + ']');
                newColumn.find('select.columnTypeSelect').bind('change', $.proxy(DataView.configureAssociationDropdown, newColumn, storyData)).change();
                newColumn.find('select.associationSelect').bind('change', $.proxy(this.saveAssociationDetail, this));
            }, this)));
        }

        /**
         * Shows the data view.
         * @param subSection
         */
        public showMe(subSection) {

            super.showMe(subSection);

            if (!this.initialized) {
                this.init();
            }
        }
    }
}
