module Nickel {
    export class JobLogFinder {

        /**
         * Called to locate a job log based on the job ID and populate it somewhere via a callback.
         * @param jobId An Imposium job ID.
         * @param callback The callback function that is called with the log HTML passed in.
         * @param delay How long, in milliseconds, to wait before making the request.
         * @param recursivelyRetry Whether or not to recursively retry if a 404 is received.
         */
        static findLogHtml(jobId: string, callback: Function, delay: number = 0, recursivelyRetry: boolean = false) {
            Ajax.get(new JWTAjaxRequest('/job/' + jobId + '/log-url', null, function (response) {
                if (response && response.signed_url) {
                    JobLogFinder.loadLog(response.signed_url, callback, delay, recursivelyRetry);
                } else {
                    console.error('Could not retrieve log URL of job ID ' + jobId);
                }
            }, function (xhr) {
                console.error('The request to retrieve the log URL of job ID ' + jobId + ' failed with a status of ' + xhr.jqXHR.status + ' ' + xhr.jqXHR.statusText);
            }));
        }

        /**
         * Converts the log URL contents (ANSI) to HTMl and passes it to the callback.
         * @param url
         * @param callback
         * @param delay
         * @param recursivelyRetry
         */
        private static loadLog(url:string, callback:Function, delay:number, recursivelyRetry:boolean) {
            setTimeout(function () {
                $.get(url, function (ansi) {
                    callback(ansi_up.ansi_to_html(ansi));
                }).fail(function (e) {
                    if (e.status == 404 && recursivelyRetry) {
                        JobLogFinder.loadLog(url, callback, delay, recursivelyRetry);
                    } else {
                        console.error('Could not load job log. A ' + e.status + ' status code was returned when requesting this URL: ' + url);
                    }
                });
            }, delay);
        }
    }
}
