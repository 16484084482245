module Nickel {

    export class MTQuadVO{
        public coords:string = null;
    }

    export class MTQuadInput extends Level {

        public perFrame:number = 8;
        public btnConvertCoords:any;
        private textArea:any;
        private scaleInput:JQuery;
        private scaleContainer:JQuery;

        constructor(container, data, index, delegate) {

            super(container, data, index, delegate);

            if (data) {
                this.data = data;
            } else {
                this.data = new MTQuadVO();
            }

            this.showInPopup = false;

            this.viewLoaded(Main.templates.find('.inputMTQuad').clone());
            this.container.append(this.content);

            this.textArea = this.content.find('textarea');

            this.addConversionButton();
            this.addScaleInput();
            this.checkTextArea();
            this.addFrameCounter();
        }

        public addScaleInput(){
            
            this.scaleContainer = $('<div class="coords-scale">scale:</div>');
            this.scaleInput = $('<input type = "number" min = "0" step = "any">');
            this.scaleContainer.append(this.scaleInput);
            this.content.append(this.scaleContainer);
        }

        public addFrameCounter() {

            this.content.append('<div class="coords-counter">frames: <span>0</span></div>');
            this.textArea.bind('keyup', $.proxy(this.updateCoordsFrameCount, this));
            this.updateCoordsFrameCount({'target': this.textArea[0]});
        }

        public addConversionButton() {

            this.btnConvertCoords = $('<button class = "btn btn-primary btn-xs btnConvertCoords" disabled>Format Corner Pin Data</button>');
            this.content.append(this.btnConvertCoords);
            this.btnConvertCoords.bind('click', ()=>this.convertCornerPinData());
            this.textArea.bind('input propertychange"', ()=>this.checkTextArea());
        }

        public checkTextArea() {

            var val = this.textArea.val();
            if (val.indexOf('Adobe After Effects') != -1 && val.indexOf('Keyframe Data') != -1) {
                this.btnConvertCoords.removeAttr('disabled');
                this.scaleContainer.css('display', 'inline-block');
            } else {
                this.btnConvertCoords.attr('disabled', 'disabled');
                this.scaleContainer.css('display', 'none');
                this.scaleInput.val('');
            }
            this.updateCoordsFrameCount({'target': this.textArea[0]});
        }

        private convertCornerPinData() {

            var data = this.textArea.val();
            if (data == undefined || data.trim() == '') {

                alert('Invalid data');
                return;
            }

            if(data.indexOf('ADBE Corner Pin') != -1){
                 this.convertMocha();   
            }else if(data.indexOf('Effects Corner Pin') != -1){
                this.convertAECorner();
            }else{
                this.convertAE();
            }
        }

        private convertAE(){
            
            var data = this.textArea.val();            
            var points = '';
            var transformScale = Number(this.scaleInput.val());
            if(transformScale == 0) transformScale = 1;

            //dimensions
            var width = Number(StringUtils.between(data, 'Source Width', 'Source Height'));
            var height = Number(StringUtils.between(data, 'Source Height', 'Source Pixel'));

            //scale
            var scale = StringUtils.between(data,'Scale', 'Transform');
            var scaleLine = scale.split('\n')[2];
            var scaleArr = scaleLine.match(/\S+/g);
            var scaleX = Number(scaleArr[1]) / 100;
            var scaleY = Number(scaleArr[2]) / 100;
                        
            //anchor
            var anchor = StringUtils.between(data, 'Anchor Point', 'End');
            var anchorLine = anchor.split('\n')[2];
            var anchorArr = anchorLine.match(/\S+/g);
            var anchorX = Number(anchorArr[1]);
            var anchorY = Number(anchorArr[2]);

            //position
            var position = StringUtils.between(data, 'Position', 'Transform');
            var positionLines = position.split('\n');
            positionLines.shift();
            positionLines.shift();
            positionLines.pop();
            positionLines.pop();

            //loop through all of the points and create the coords for that point
            var realWidth = width * scaleX * transformScale;
            var realHeight = height * scaleY * transformScale;
            var realAnchorX = anchorX * scaleX * transformScale;
            var realAnchorY = anchorY * scaleY * transformScale;

            for(var i = 0; i<positionLines.length; i++){

                var line = positionLines[i];
                var arr = line.match(/\S+/g);
                var x = (Number(arr[1]) * transformScale ) - realAnchorX;
                var y = (Number(arr[2]) * transformScale) - realAnchorY;
                
                //get corner pins from x,y
                var tlX = x;
                var tlY = y;
                var trX = x + realWidth;
                var trY = y;
                var brX = x + realWidth;
                var brY = y + realHeight;
                var blX = x;
                var blY = y + realHeight;

                //build out the coords string
                var pt = tlX.toFixed(2) + ',' + tlY.toFixed(2) + ',' + trX.toFixed(2) + ',' + trY.toFixed(2) + ',' + brX.toFixed(2) + ',' + brY.toFixed(2) + ',' + blX.toFixed(2) + ',' + blY.toFixed(2);
                if(i!=positionLines.length-1){
                    pt+=',';
                }
                points+=pt;
            }

            this.data.coords = points;
            
            this.checkTextArea();
        }

        private convertMocha(){

            var data = this.textArea.val();
            var pinData = [];
            var transformScale = Number(this.scaleInput.val());
            if(transformScale == 0) transformScale = 1;

            pinData.push(StringUtils.between(data, 'Pin-0001', 'Effects'));
            pinData.push(StringUtils.between(data, 'Pin-0002', 'Effects'));
            pinData.push(StringUtils.between(data, 'Pin-0004', 'End'));
            pinData.push(StringUtils.between(data, 'Pin-0003', 'Effects'));

            for (var i = 0; i < 4; i++) {
                pinData[i] = pinData[i].split('\n');
                pinData[i].shift();
                pinData[i].shift();
                pinData[i].pop();
                pinData[i].pop();
            }

            var lines = data.split('\n')
            var sourceWidth, sourceHeight, fps, allObjArray = [];
            var coords = '';
            for (var index = 0; index < pinData[0].length; index++) {

                var currLine = lines[index];
                if (StringUtils.contains('Source Width', currLine)) {
                    sourceWidth = Number(StringUtils.afterLast(currLine, 'Source Width')).toString();

                }
                if (StringUtils.contains('Source Height', currLine)) {
                    sourceHeight = Number(StringUtils.afterLast(currLine, 'Source Height')).toString();

                }
                if (StringUtils.contains('Units Per Second', currLine)) {
                    fps = Number(StringUtils.afterLast(currLine, 'Units Per Second')).toString();

                }
                for (var i = 0; i < 4; i++) {
                    var arr = pinData[i][index].match(/\S+/g);
                    coords += Number(Number(arr[1]) * transformScale).toFixed(2);
                    coords += ",";
                    coords += Number(Number(arr[2]) * transformScale).toFixed(2);
                    coords += ",";
                }
            };

            //trim off the last comma
            coords = coords.slice(0, -1);

            //overwrite the existing value
            this.data.coords = coords;

            //this will disable the button if the coords converted successfully
            this.checkTextArea();
        }

        private convertAECorner(){

            var data = this.textArea.val();
            var pinData = [];
            var transformScale = Number(this.scaleInput.val());
            if(transformScale == 0) transformScale = 1;

            pinData.push(StringUtils.between(data, 'Left #2', 'Effects'));
            pinData.push(StringUtils.between(data, 'Right #3', 'Effects'));
            pinData.push(StringUtils.between(data, 'Right #5', 'End'));
            pinData.push(StringUtils.between(data, 'Left #4', 'Effects'));

            for (var i = 0; i < 4; i++) {
                pinData[i] = pinData[i].split('\n');
                pinData[i].shift();
                pinData[i].shift();
                pinData[i].pop();
                pinData[i].pop();
            }

            var lines = data.split('\n')
            var sourceWidth, sourceHeight, fps, allObjArray = [];
            var coords = '';
            for (var index = 0; index < pinData[0].length; index++) {

                var currLine = lines[index];
                if (StringUtils.contains('Source Width', currLine)) {
                    sourceWidth = Number(StringUtils.afterLast(currLine, 'Source Width')).toString();

                }
                if (StringUtils.contains('Source Height', currLine)) {
                    sourceHeight = Number(StringUtils.afterLast(currLine, 'Source Height')).toString();

                }
                if (StringUtils.contains('Units Per Second', currLine)) {
                    fps = Number(StringUtils.afterLast(currLine, 'Units Per Second')).toString();

                }
                for (var i = 0; i < 4; i++) {
                    var arr = pinData[i][index].match(/\S+/g);
                    coords += Number(Number(arr[1]) * transformScale).toFixed(2);
                    coords += ",";
                    coords += Number(Number(arr[2]) * transformScale).toFixed(2);
                    coords += ",";
                }
            };

            //trim off the last comma
            coords = coords.slice(0, -1);

            //overwrite the existing value
            this.data.coords = coords;

            //this will disable the button if the coords converted successfully
            this.checkTextArea();
        }

        public updateCoordsFrameCount(e) {
            var textField = $(e.target);
            var counterDiv = this.content.find('.coords-counter');
            var textVal = textField.val();
            if (textVal) {
                textVal = textVal.trim();
                if (textVal.substr(textVal.length - 1) == ',') {
                    textVal = textVal.substring(0, textVal.length - 1);
                }
                var commaCountMatches = textVal.match(/,/g);
                var commaCount = (!commaCountMatches) ? 0 : commaCountMatches.length + 1;
                var frameCount = commaCount / this.perFrame;
                counterDiv.find('span').html(frameCount);
                if (frameCount % 1 === 0) {
                    counterDiv.removeClass('bad');
                } else {
                    counterDiv.addClass('bad');
                }
            }
        }
    }


    export class MTRectVO{
        public coords:string = null;
    }

    export class MTRectInput extends Level {

        public perFrame:number = 2;
        private textArea:any;

        constructor(container, data, index, delegate) {

            super(container, data, index, delegate);

            if (data) {
                this.data = data;
            } else {
                this.data = new MTRectVO();
            }

            this.showInPopup = false;

            this.viewLoaded(Main.templates.find('.inputMTRect').clone());
            this.container.append(this.content);
            this.textArea = this.content.find('textarea');
            this.addFrameCounter();
        }

        public addFrameCounter() {

            this.content.append('<div class="coords-counter">frames: <span>0</span></div>');
            this.textArea.bind('keyup', $.proxy(this.updateCoordsFrameCount, this));
            this.updateCoordsFrameCount({'target': this.textArea[0]});
        }

        public updateCoordsFrameCount(e) {
            var textField = $(e.target);
            var counterDiv = this.content.find('.coords-counter');
            var textVal = textField.val();
            if (textVal) {
                textVal = textVal.trim();
                if (textVal.substr(textVal.length - 1) == ',') {
                    textVal = textVal.substring(0, textVal.length - 1);
                }
                var commaCountMatches = textVal.match(/,/g);
                var commaCount = (!commaCountMatches) ? 0 : commaCountMatches.length + 1;
                var frameCount = commaCount / this.perFrame;
                counterDiv.find('span').html(frameCount);
                if (frameCount % 1 === 0) {
                    counterDiv.removeClass('bad');
                } else {
                    counterDiv.addClass('bad');
                }
            }
        }
    }

    export class StaticRectVO{
        public x:number = null;
        public y:number = null;
        public width:number = null;
        public height:number = null;
    }
    
    export class StaticRectInput extends Level {

        constructor(container, data, index, delegate) {

            super(container, data, index, delegate);

            if (data) {
                this.data = data;
            } else {
                this.data = new StaticRectVO();
            }

            this.showInPopup = false;

            this.viewLoaded(Main.templates.find('.inputStaticRect').clone());
            this.container.append(this.content);
        }
    }

    export class StaticQuadVO{
        public top_left:any = {
            "x":null,
            "y":null
        };
        public top_right:any = {
            "x":null,
            "y":null
        };
        public bottom_left:any = {
            "x":null,
            "y":null
        };
        public bottom_right:any = {
            "x":null,
            "y":null
        };
    }

    export class StaticQuadInput extends Level {

        constructor(container, data, index, delegate) {

            super(container, data, index, delegate);

            if (data) {
                this.data = data;
            } else {
                this.data = new StaticQuadVO();
            }

            this.showInPopup = false;

            this.viewLoaded(Main.templates.find('.inputStaticQuad').clone());
            this.container.append(this.content);
        }
    }
}
