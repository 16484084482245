module Nickel {

    export class TextScene01VO extends SceneVO {

        public type: any = "TextScene01";
        public sceneData: any = {
            message: "",
            delay: null,
            phoneNumber: ""
        };
    }

    export class TextScene01 extends Scene {

        public label: string = "Text Message Scene";
        public nodeStyle: any = {
            "bg": {
                "fill": 0x62e5a9,
                "stroke": 0x929292
            },
            "hover": {
                "fill": 0x46de86,
                "stroke": 0x929292
            },
            "highlight": {
                "fill": 0x22b567,
                "stroke": 0x307c64
            }
        };

        constructor(container, data, index, delegate) {

            super(container, data, index, delegate);

            if (data) {
                this.data = data;
            } else {
                this.data = new TextScene01VO();
            }

            this.viewLoaded(Main.templates.find('.textscene01').clone());
        }
    }
}
