/*
 * Copyright © 2018. Nickel Media Inc.
 *
 */
module Nickel {

    export class AssetProvider {

        private static assets: any;
        private static callbacks: any = [];
        private static requesting: boolean = false;

        public static refreshAssets(callback) {
            AssetProvider.assets = null;
            AssetProvider.getAssets(callback);
        }

        public static getAssets(callback) {
            if (AssetProvider.assets == null) {
                AssetProvider.callbacks.push(callback);
                if (!AssetProvider.requesting) {
                    AssetProvider.requesting = true;
                    Ajax.get(new JWTAjaxRequest('/footage?order=name&items_per_page=1000000', null, function (data) {
                        let assets = {};
                        data.footage.forEach((item, index) => {
                            if (!assets[item['type']]) {
                                assets[item['type']] = {};
                            }
                            assets[item['type']][item['id']] = item['name'];
                        });
                        AssetProvider.assets = assets;
                        AssetProvider.requesting = false;

                        // Fire any queued callbacks
                        let i = AssetProvider.callbacks.length;
                        while (i--) {
                            AssetProvider.callbacks.splice(i, 1)[0](AssetProvider.assets);
                        }
                    }, function () {
                        AssetProvider.requesting = false;
                    }));
                }
            } else {
                callback(AssetProvider.assets)
            }
        }
    }
}
