module Nickel {

    export class YoutubeLogin {
        public button:any;
        public authenticated = false;
        public callback:any;
        public interval;

        constructor(button, callback) {
            this.button = button;
            this.callback = callback;

            window.addEventListener("message", $.proxy(function (ev) {
                if (ev.data.message === "handleYoutubeLogin") {
                    // noinspection JSPotentiallyInvalidUsageOfClassThis
                    clearInterval(this.interval);
                    // noinspection JSPotentiallyInvalidUsageOfClassThis
                    this.handleYoutubeLogin(ev.data);
                    ev.source.close();
                }
            }, this));
        }

        public login() {
            let url = AjaxUrlProvider.getLegacyApiBaseUrl() + '/auth.youtube.php';
            let child = Utils.openWindow(url, 500, 600);

            this.interval = setInterval($.proxy(function() {
                if (child.closed) {
                    // noinspection JSPotentiallyInvalidUsageOfClassThis
                    clearInterval(this.interval);
                    return;
                }
                child.postMessage({message: "requestLogin"}, "*")
            }, this), 500);
        }

        public handleYoutubeLogin(response) {
            if (this.callback) {
                this.callback(response);
            }
        }
    }
}
