module Nickel {
    export class InfoBox extends Component {
        constructor(container, data, delegate) {
            super(container, data, delegate);

            this.viewLoaded(Main.templates.find('.infoBox').clone());
        }

        public viewLoaded(v) {
            super.viewLoaded(v);

        }

        public updateMe(data) {
            this.data = data;
            this.content.html(data.name);
        }

        public setPosition(position) {
            this.content.css('left', position.left).css('top', position.top);
        }
    }
}
