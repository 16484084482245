module Nickel {

    export class ListInterface extends Component {

        public listData:any;
        public activeItems:any = {};
        public storyNode:any;

        constructor(container, data, delegate) {

            super(container, data, delegate)

            this.viewLoaded(Main.templates.find('.list_interface').clone());

            $(window).bind('resize', $.proxy(this.resize, this));
            this.resize();

        }

        public setData(data) {

            this.listData = data;
            this.listData.active = true;

            var found = this.dig(Main.activeNode);
            this.storyNode = this.addItem(data);

            if (!found) {
                EventBus.dispatch(Level.SHOW_DETAILS, {id: this.listData.id});
            }
        }

        public hideSelected(type) {

            var t = (type == "inventory") ? "scene" : type;
            if (this.activeItems[t]) {
                this.activeItems[t].removeChildren();
            }
        }

        public showChildren(parent, data) {

            var type = (data.type == "inventory") ? "scene" : data.type;
            this.activeItems[type] = parent;

            if (data.children) {
                if (data.children.length > 0) {
                    this.content.find("." + data.children[0].type).empty();
                    for (var i = 0; i < data.children.length; i++) {
                        var item = this.addItem(data.children[i]);
                        parent.children.push(item);
                    }
                }
            }
        }

        public addItem(itemData) {

            itemData.cls = 'item-' + itemData.type;
            itemData.numChildren = (itemData.children) ? itemData.children.length : 0;

            var cont = this.content.find("." + itemData.type);
            var item = new Nickel.ListItem(cont, itemData, this);

            return item;
        }

        public itemClicked() {

        }

        public resize() {

            this.content.find('.col').css('height', <number>this.container.height() - 200);
        }

        public killMe() {
            this.content.remove();
            this.content = null;

            $(window).unbind('resize', $.proxy(this.resize, this));
        }

        //digs through the data object looking for an id, if it finds it, shows all of the parents and that object.
        // TODO, make this not suck so bad
        public dig(id) {

            if (this.listData.children) {
                for (var s = 0; s < this.listData.children.length; s++) {
                    var act = this.listData.children[s];
                    if (act.id == id) {
                        act.click = true;
                        return true;
                    } else {
                        if (act.children) {
                            for (var a = 0; a < act.children.length; a++) {
                                var scene = act.children[a];
                                if (scene.id == id) {
                                    act.active = true;
                                    scene.click = true;
                                    return true;
                                } else {
                                    if (scene.children) {

                                        for (var c = 0; c < scene.children.length; c++) {
                                            var cut = scene.children[c];
                                            if (cut.id == id) {
                                                act.active = true;
                                                scene.active = true;
                                                cut.click = true;
                                                return true;
                                            } else {
                                                if (cut.children) {
                                                    for (var o = 0; o < cut.children.length; o++) {
                                                        var overlay = cut.children[o];
                                                        if (overlay.id == id) {
                                                            act.active = true;
                                                            scene.active = true;
                                                            cut.active = true;
                                                            overlay.click = true;
                                                            return true;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            return false;
        }
    }

    export class ListItem extends Component {

        public children:any = [];

        constructor(container, data, delegate) {

            super(container, data, delegate);

            this.viewLoaded(Main.templates.find('.list_item').clone());
            this.content.bind('click', $.proxy(this.itemClicked, this));

            if (this.data.numChildren == 0) {
                this.content.find('.num').hide();
            }

            if (this.data.disabled) {
                this.content.addClass('disabled');
            }

            if (this.data.active) {

                delete this.data.active;
                this.selectNode();

            } else {
                if (this.data.click) {

                    this.itemClicked();
                    delete this.data.click;
                }
            }
        }

        private selectNode() {

            this.delegate.hideSelected(this.data.type);
            this.delegate.showChildren(this, this.data);
            this.content.parent().find('.selected').removeClass('selected');
            this.content.addClass('selected');
        }

        public itemClicked() {

            this.selectNode();
            EventBus.dispatch(Level.SHOW_DETAILS, {id: this.data.id});
        }

        public killMe() {
            this.removeChildren();
            this.content.unbind('click', $.proxy(this.itemClicked, this));
            this.content.remove();
            this.content = null;
        }

        public removeChildren() {

            for (var i = 0; i < this.children.length; i++) {
                this.children[i].killMe();
            }
            this.children = [];
        }

    }
}
