module Nickel {

    export class VO {

        public id:string;
        public dateCreated:number;

        constructor() {

            this.id = Utils.generateUUID();
            this.dateCreated = new Date().getTime() / 1000;
        }
    }

    export class Level {

        //globals
        public data:any;
        public container:any;
        public content:any;
        public rivets:any;
        public index:number;
        public delegate:any;
        public onStage:boolean = true;
        //details stuff
        public detailsOpen:boolean = false;
        private details:any;
        private displayTimeout:any;
        public firstSave:boolean = false;

        //child stuff
        public childToDelete:any;
        public children:any = [];
        public activeChild:any;

        //buttons
        private btnHide:any;
        private btnEdit:any;
        private btnDuplicate:any;
        private btnSave:any;
        private btnRemove:any;
        public label:string = "";

        public showInPopup:boolean = true;

        public static SHOW_DETAILS:string = "showdetails";

        private zero:ZeroClipboard;
        private btnCopy:JQuery;
        public guid:string = Utils.generateUUID();

        constructor(container, data, index, delegate) {

            this.container = container;
            this.index = index;
            this.delegate = delegate;
        }

        /**
         * Creates the rivets data binding and binds all Level generic events.
         * @param v A JQuery object containing this level's content selector.
         */
        public viewLoaded(v) {

            this.content = v;
            //this.container.append(this.content);

            this.rivets = rivets.bind(this.content, {
                "data": this.data,
                "controller": this
            });

            this.addCopyButton();

            if (this.showInPopup) {
                EventBus.addEventListener(Level.SHOW_DETAILS, $.proxy(this.showDetails, this), this);
            }

            $(window).bind("resize", $.proxy(this.resize, this));
            //this.resize(null);

        }

        private addCopyButton() {

            var cont = this.content.find('label:contains(ID)').parent().find('.data');
            this.btnCopy = $('<div class = "btnCopyToClipboard"><i class = "fa fa-clipboard"></i></div>');
            cont.append(this.btnCopy);
            this.zero = new ZeroClipboard(this.btnCopy[0]);

            this.btnCopy.on('mousedown', () => this.btnCopy.addClass('copied'));

            this.zero.on('copy', event => {
                var input = $(event.target).parent().find('input');
                event.clipboardData.setData('text/plain', input.val().trim());
            });

            this.zero.on('aftercopy', () => this.btnCopy.removeClass('copied'));
        }

        /**
         * @param d A data object
         */
        public showDetails(d) {

            if (d.id == this.data.id) {
                EventBus.dispatch(RightInterface.SHOW_IN_INTERFACE, {view: this, type: this.label});
                this.showMe();
            }
        }

        /**
         * Show the level
         */
        public showMe() {

            this.onStage = true;

            if (this.showInPopup) {
                EventBus.dispatch(NodeTree.SELECT_NODE, {id: this.data.id});
                Main.activeNode = this.data.id;
                Main.saveState();
            }
        }

        /**
         * Hide the level.
         */
        public hideMe() {
            this.onStage = false;
        }

        /**
         * Removes the child from the child array and calls kill on it. Each individual level takes care of removing it
         * from the db.
         */
        public deleteChild() {

            //remove the TS controller for the act
            var child = this.children.splice(this.childToDelete, 1)[0];
            child.killMe();
            this.activeChild = null;
            child = null;

            this.showMe();
        }

        /**
         * Hides the currently shown child, and shows the new one.
         * @param index
         */
        public showChild(index) {

            if (this.activeChild) {
                this.activeChild.hideMe();
            }

            this.activeChild = this.children[index];

            if (this.activeChild) {
                this.activeChild.showDetails({id: this.activeChild.data.id});
            }
        }

        /**
         * Calls the parent and tells it to duplicate this level.
         */
        public duplicateMe() {
            if (this.delegate && typeof this.delegate.duplicateChildItem == 'function') {
                this.delegate.duplicateChildItem(this);
            }
        }

        /**
         * Sets the parent's childToDelete as this level's index.
         */
        public removeMe() {
            this.delegate.childToDelete = this.index;
            this.delegate.deleteChild();
            this.delegate.saveData();
        }

        /**
         * Removes this classes frontend from the UI.
         */
        public killMe() {

            this.rivets.unbind();
            $(window).unbind("resize", $.proxy(this.resize, this));
            this.content.remove();
            this.content = null;
        }

        /**
         * Reloads the story data and updates the interface with reloaded data.
         */
        public reloadMe(callback = null):void {
            Ajax.get(new JWTAjaxRequest('/story/' + Main.storyId, null, $.proxy(function (story) {
                this.onDataReload(story);
                if (callback) {
                    callback();
                }
            }, this)));
        }

        /**
         * Specifies where the story's data can be found on this level.
         * @param storyData An object containing the story JSON structure.
         */
        public onDataReload(storyData) {
            console.log('onDataReload is not overridden correctly');

            //TODO: make this abstract when upgraded to Typescript 1.6
        }

        //OVERRIDE ME
        public addChild(data, index) {

        }

        //OVERRIDE ME
        public saveData() {

        }

        //OVERRIDE ME
        public dataSaved(r) {

        }

        //OVERRIDE ME
        public levelRemoved(r) {

        }

        //OVERRIDE ME
        public resize() {
            var o = this.content.find('.scrollable').offset();
            if (o) {
                var t = o.top;
                var h = $(window).height() - t - 10;
                this.content.find('.scrollable').css('height', h);
            }
        }
    }
}
