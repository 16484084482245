module Nickel {

    /**
     * Value Object for defining the structure of the top level Act JSON data.
     */
    export class ActVO extends VO {
        public name:string = "New Act";
        public label:string = "New Act";
        public description:string = "";
        public isPremium:boolean = false;
        public inventory:any = {};
        public scenes:any = {};
        public order:number = null;
        public displayImage:any = null;
        public comingSoon:any = false;
        public minimumAppVersion:any = "1.0";
        public usesFacebook:boolean = false;
        public usesTwitter:boolean = false;
    }

    /**
     * Act Node, containing scene and inventory information.
     */
    export class Act extends Level {

        /**
         * The JSON data from the DB associated with this Story.
         */
        public data:ActVO;

        /**
         * Array containing all child Inventory classes.
         */
        public inventory:Nickel.InventoryItem[] = [];

        /**
         * Object containing all child Scene classes. Stored in key / value pairs, indexed by the Scene ID.
         */
        public children:any = {};

        /**
         * JQuery class contaninig a list item for this act in the re-order list.
         */
        public actOrderListItem:JQuery;

        /**
         * Label for this type of node used in the CMS.
         */
        public label:string = "Act";

        /**
         * If true, show the video player in the right interface when this view is visible.
         */
        public videoPlayer:boolean = false;

        /**
         * Stores the global vars, loads this Node's DOM view, and creates an new Value Object if needed.
         * @param container        A jQuery object containing the parent div for this view.
         * @param data          The JSON data unique to this node.
         * @param index        The index of this node in the delegate's child array (only applicable if stored in an
         *     array and not an object).
         * @param delegate        The Class that created this instance.
         */
        constructor(container, data, index, delegate) {

            super(container, data, index, delegate);

            if (data) {
                this.data = data;
            } else {
                this.data = new ActVO();
                this.firstSave = true;
            }

            //load this views markup
            this.viewLoaded(Main.templates.find(".act").clone());
        }

        /**
         * Binds this.data to the view using rivets. Creates all of the child Scenes and Inventory Items.
         * @param v        A jQuery object to act as this node's view and to bind it's data to.
         */
        public viewLoaded(v:JQuery):void {

            super.viewLoaded(v);

            //loop through and create the acts
            var count = 0;
            for (var key in this.data.scenes) {
                this.addChild(this.data.scenes[key], count);
                count++;
            }

            //loop through and create the inventory objects
            count = 0;
            for (var iKey in this.data.inventory) {
                this.addInventoryItem(this.data.inventory[iKey], count);
            }

            //add to the act order list
            this.actOrderListItem = $("<li data-id = '" + this.data.id + "' class = 'list-group-item'>" + this.data.name + "</li>")
            this.delegate.content.find(".actOrderList").append(this.actOrderListItem);

            this.bindEvents();
        }

        /**
         * Binds all of the event listeners for this Class.
         */
        private bindEvents():void {

            this.content.find('.addSceneDropdown li a').bind('click', $.proxy(this.addSceneBtnClicked, this));
            this.content.find(".btnAddInventory").bind('click', $.proxy(this.newInventory, this));
            this.content.find(".btnExportAct").bind('click', $.proxy(this.exportMe, this));
            this.content.find(".btnImportScene").bind('click', $.proxy(this.importScene, this));
            $("#sceneImportFile").bind('change', $.proxy(this.getSceneData, this));

            //image upload stuff
            this.content.find('.upload-act-image').bind('change', $.proxy(this.uploadImage, this));
            this.content.find('.btnUploadActImage').bind('click', $.proxy(this.uploadImageClicked, this));
        }

        /**
         * Exports this classes this.data obeject to a .json file that the user downloads.
         */
        public exportMe():void {

            //save the story
            this.saveData();

            var actId: string = this.data.id;
            var filename: string = this.data.name + '.json';
            Ajax.get(new JWTAjaxRequest('/story/' + this.delegate.data.id, null, function (storyData) {
                var actData: Object = storyData['acts'][actId];
                saveAs(new Blob([JSON.stringify(actData)], {type: "text/plain;charset=utf-8"}), filename);
            }));
        }

        /**
         * Calls the click event on a hidden button to trigger the import scene form.
         */
        public importScene(e):void {
            $("#sceneImportFile").trigger('click');
        }

        /**
         * Creates a FileReader instance to parse the incoming JSON file.
         * @param e    Event passed in from the click.
         */
        public getSceneData(e:any):void {
            if (e.target.files[0]) {
                var r = new FileReader();
                r.onload = $.proxy(this.saveImportedScene, this);
                r.readAsText(e.target.files[0]);
            } else {
                console.log("Failed to load file!");
            }
        }

        /**
         * Saves the act that has just been imported. Re-binds the import event listener.
         * @param e    Event passed in from the click.
         */
        public saveImportedScene(e:any):void {
            // Need to replace and re-bind the change event to allow multiple imports!
            $("#sceneImportFile").replaceWith('<input id = "sceneImportFile" type="file" style="display:none;">');
            $("#sceneImportFile").bind('change', $.proxy(this.getSceneData, this));

            // Once we've reset for the next import, save the actual act data
            var scene = this.addChild(JSON.parse(e.target.result), 0);
            scene.saveData();
            this.showChild(scene.data.id);
        }

        /**
         * Uploads an image to the backend using a hidden form.
         * @param e    Event passed in from the click.
         */
        private uploadImage(e:Event):void {

            var form = $(e.target).parent().parent();
            form.attr('action', AjaxUrlProvider.getLegacyApiBaseUrl() + '/api');

            // Get story ID so upload is sent to the right place
            var storyIdInput = form.find('input[name=story_id]');
            storyIdInput.val(this.delegate.data.id);

            Ajax.formSubmit(form, $.proxy(function (response) {

                this.data.displayImage = response.bind.info;
                this.saveData();

            }, this));
        }

        /**
         * Triggers a click on a hidden button to upload an image.
         * @param e    Event passed in from the click.
         */
        public uploadImageClicked(e:Event):void {
            e.preventDefault();
            this.content.find('.upload-act-image').trigger('click');
        }

        /**
         * Creates a new Inventory Item and shows it. Saves the Story.
         */
        public newInventory():void {

            //TODO figure out why this bullshit is nessassary. Empty object from VO is being converted to []. GW
            if (this.data.inventory instanceof Array) {
                this.data.inventory = {};
            }

            var item = this.addInventoryItem(null, this.inventory.length);
            this.data.inventory[item.data.id] = item.data;
            item.showMe();
            this.saveData();
        }

        /**
         * Creates a new Inventory Item adds it to the inventory array. returns the item.
         * @param data        The data associated with this new item.
         * @param index    The index of this item in the inventory array.
         * @returns            The inventory item just created.
         */
        public addInventoryItem(data:any, index:number) {

            var item = new Nickel.InventoryItem(this.content.find(".inventoryHolder"), data, index, this);
            this.inventory.push(item);
            return item;
        }

        /**
         * Removes an inventory item from the database, and from the UI.
         * @param item        The inventory item we want to remove.
         */
        public removeInventoryItem(item:Nickel.InventoryItem):void {

            delete this.data.inventory[item.data.id];
            item.killMe();
        }

        /**
         * Receives click event from the Add Scene dropdown.
         * @param e        Event passed in from the click.
         */
        public addSceneBtnClicked(e:Event):void {

            //add the scene
            var type = $(e.currentTarget).data('id');
            this.addScene(type);
        }

        /**
         * Creates a new scene, adds its data to the global data structure, shows that new scene. Saves to the Database.
         * @param type The class name of the type of scene to be created.
         * @param data Initial data to initialize the scene with. Set to <null> to use default value object.
         */
        private addScene(type:string, data:any = null):void {
            var scene = this.addChild(data, this.children.length, type);

            //TODO figure out why this bullshit is nessassary. Empty object from VO is being converted to []. GW
            if (this.data.scenes instanceof Array) {
                this.data.scenes = {};
            }

            this.data.scenes[scene.data.id] = scene.data;

            //show the scene
            this.showChild(scene.data.id);

            //save
            this.saveData();
        }

        /**
         * Duplicates an existing scene, changes all cut and overlay IDs, shows that new scene. Saves to the Database.
         * @param scene The scene object to duplicate.
         */
        public duplicateChildItem(scene:any):void {
            var data = $.extend(true, {}, scene.data);
            data.id = Utils.generateUUID();

            // change out cut ids
            if (data.sceneData.cuts && data.sceneData.cuts.length > 0) {
                for (var cutIndex in data.sceneData.cuts) {
                    data.sceneData.cuts[cutIndex].id = Utils.generateUUID();

                    // change out overlay ids
                    if (data.sceneData.cuts[cutIndex].overlays && data.sceneData.cuts[cutIndex].overlays.length > 0) {
                        for (var overlayIndex in data.sceneData.cuts[cutIndex].overlays) {
                            data.sceneData.cuts[cutIndex].overlays[overlayIndex].id = Utils.generateUUID();
                        }
                    }
                }
            }

            this.addScene(data.type, data);
        }

        /**
         * Creates a new scene, adds it's data to the global data structure, shows that new scene. Saves to the
         * Database.
         * @param data        The JSON data associated with this new Scene.
         * @param index    The index of this new Scene in the children array.
         * @param type        The type of scene we want to create.
         * @returns        The newly created Scene.
         */
        public addChild(data:any, index:number, type?:string):any {

            var t = (type) ? type : data.type;
            var scene;
            scene = new Nickel[t](this.content.find('.sceneHolder'), data, index, this);
            this.children[scene.data.id] = scene;
            return scene;

        }

        /**
         * Show's this Act. sets the actID and actTitle.
         */
        public showMe():void {

            super.showMe();

            Main.actId = this.data.id;
            Main.actTitle = this.data.name;
        }

        /**
         * Hides this act. Hides the active child as well.
         */
        public hideMe():void {

            super.hideMe();

            if (this.activeChild) {
                this.activeChild.hideMe();
            }
        }

        /**
         * Builds this Acts inventory data object. and saves the global data object to the Database.
         */
        public saveData():void {

            EventBus.dispatch(Story.SAVE_STORY);
        }

        /**
         * Tells this Act's delegate to remove it.
         */
        public removeMe():void {
            this.delegate.childToDelete = this.data.id;
            this.delegate.deleteChild();
        }

        /**
         * Removes and completely kills a child Scene. Saves the Story to the database.
         */
        public deleteChild():void {

            //kill the view
            var child = this.children[this.childToDelete];
            child.killMe();
            child = null;
            this.activeChild = null;

            //remove the data
            delete this.children[this.childToDelete];
            delete this.data.scenes[this.childToDelete];

            //save
            this.showMe();
            this.saveData();
        }

        /**
         * Completely removes this Act from the DOM.
         */
        public killMe() {

            super.killMe();

            this.actOrderListItem.remove();
        }

        /**
         * Specifies where the story's data can be found on this level.
         * @param storyData An object containing the story JSON structure.
         */
        public onDataReload(storyData):void {
            $.extend(true, this.data, storyData['acts'][this.data.id]);
        }
    }
}
