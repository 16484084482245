module Nickel {

    /**
     * Value Object for defining the data structure specific to VideoScene01
     */
    export class VideoScene01VO extends SceneVO {

        public sceneDataFilter:string = '';
        public sceneData:any = {
            cuts: [],
            videoFile: {},
            encodingSettings: [
                {
                    "name": "MP4 H.264 1920x1080",
                    "video": "-c:v libx264 -preset fast -profile:v high -level 5.1 -crf 22 -pix_fmt yuv420p",
                    "audio": "-c:a aac -b:a 192k -strict -2",
                    "width": "1920",
                    "height": "1080",
                    "extension": "mp4"
                },
                {
                    "name": "MP4 H.264 1280x720",
                    "video": "-c:v libx264 -preset fast -profile:v high -level 4.1 -crf 22 -pix_fmt yuv420p",
                    "audio": "-c:a aac -b:a 192k -strict -2",
                    "width": "1280",
                    "height": "720",
                    "extension": "mp4"
                },
                {
                    "name": "MP4 H.264 854x480",
                    "video": "-c:v libx264 -preset fast -profile:v high -level 4.1 -crf 22 -pix_fmt yuv420p",
                    "audio": "-c:a aac -b:a 192k -strict -2",
                    "width": "854",
                    "height": "480",
                    "extension": "mp4"
                }
            ],
            savePosterFrame: false,
            posterFrame: 0,
            saveSnapshotFrame: false,
            snapshotFrame: 0,
            saveSocialFrame: false,
            socialFrame: 0,
            limitProcessConcurrency: true,
            processConcurrency: 20
        };
        public type:string = "VideoScene01";
        public exportAsPlaylist:boolean = false;
        public postProcessingScript:string = '';
    }

    /**
     * Linear Video Scene class. Contains all logic for creating Cuts, uploading a base video.
     */
    export class VideoScene01 extends Scene {

        /**
         * Array containing child Cut classes.
         */
        public children:Nickel.Cut[] = [];

        /**
         * Jquery object of upload progress bar container.
         */
        private progress:JQuery;

        /**
         * Jquery object of upload progress bar div.
         */
        private progressBar:JQuery;

        /**
         * SceneRecutter class that gets the backent to re-make cuts and save them to the DB.
         */
        private recutter:Nickel.SceneRecutter;

        /**
         * CutPicker class to select and  set start and end frames.
         */
        private cutPicker:Nickel.CutPicker;

        /**
         * The cut currently being edited in the Cut Picker.
         */
        private cutBeingEdited:Nickel.Cut;

        /**
         * Label for this type of node used in the CMS.
         */
        public label:string = "Linear Video Scene";

        /**
         * If true, show the video player in the right interface when this view is visible.
         */
        public videoPlayer:boolean = true;

        /**
         * Stores the global vars, loads this Node's DOM view, and creates an new Value Object if needed. Instantiates
         * this Levels components.
         * @param container        A jQuery object containing the parent div for this view.
         * @param data          The JSON data unique to this node.
         * @param index        The index of this node in the delegate's child array (only applicable if stored in an
         *     array and not an object).
         * @param delegate        The Class that created this instance.
         */
        constructor(container, data, index, delegate) {

            super(container, data, index, delegate);

            if (data) {
                this.data = data;
            } else {
                this.data = new VideoScene01VO();
                this.firstSave = true;
            }

            this.viewLoaded(Main.templates.find(".videoscene01").clone());
            this.updateEncodingSettings();
            this.recutter = new SceneRecutter(this.content.find('.sceneOptions'), {}, this);
        }

        /**
         * Binds this.data to the view using rivets. Sets the progress and progress bar.
         * @param v        A jQuery object to act as this node's view and to bind it's data to.
         */
        public viewLoaded(v:JQuery):void {

            super.viewLoaded(v);

            this.progress = this.content.find('.progress');
            this.progressBar = this.content.find('.uploadProgress');

            this.content.find('.cutList').sortable({
                "stop": $.proxy(this.cutOrderListChange, this)
            });

            this.bindEvents();
            this.addExistingCuts();

            this.content.find('[data-toggle="tooltip"]').tooltip();
        }

        /**
         * Binds all of the event listeners for this Class.
         */
        public bindEvents():void {

            this.content.find('.upload-main-video').bind('change', $.proxy(this.prepareVideoUpload, this));
            this.content.find('.btnUploadVideo').bind('click', $.proxy(this.uploadVideoClicked, this));
            this.content.find('.btnAddCut').bind('click', $.proxy(this.addCutBtnClicked, this));
            this.content.find(".btnGenerate").bind('click', $.proxy(this.generatePreview, this));
            this.content.find(".btnSelectAudio").bind('click', $.proxy(this.selectAudioClicked, this));
            this.content.find(".btnAddOutput").bind('click', $.proxy(this.addEncodingOutput, this));
            this.content.find(".btnAddOutputFromPreset").bind('click', $.proxy(this.addEncodingOutputFromPreset, this));
            this.content.find('.btnSelectSocialScript').bind('click', $.proxy(this.selectSocialScriptClicked, this));
            this.content.find('.btnSelectDataFilter').bind('click', $.proxy(this.selectDataFilterClicked, this));
            this.content.find('.btnSelectPostProcessingScript').bind('click', $.proxy(this.selectPostProcessingScriptClicked, this));
            this.content.find('.btnAutoCut').bind('click', $.proxy(this.triggerAutoCut, this));
        }

        public triggerAutoCut() {
            if (this.data.sceneData.videoFile && this.data.sceneData.videoFile.url) {
                var ladda = Ladda.create(this.content.find('.btnAutoCut')[0]);
                ladda.start();
                JobHandler.runJob('autoCutScene', {
                    'story_id': this.delegate.delegate.data.id,
                    'act_id': this.delegate.data.id,
                    'scene_id': this.data.id
                }, $.proxy(function () {
                    ladda.stop();
                    this.reloadMe();
                }, this), $.proxy(function () {
                    ladda.stop();
                }, this));
            } else {
                alert('Please upload a base video before creating cuts');
            }
        }

        /**
         * Called when the Select Script button is pressed.
         * @param e The click event.
         */
        public selectDataFilterClicked(e) {
            S3FilePicker.getInstance().open($(e.target).parent().parent().find('input').val(), $.proxy(function (fileKey) {
                this.data.sceneDataFilter = fileKey.trim();
                this.saveData();
            }, this));
        }

        /**
         * Called when the Select Script button is pressed.
         * @param e The click event.
         */
        public selectSocialScriptClicked(e) {
            S3FilePicker.getInstance().open($(e.target).parent().parent().find('input').val(), $.proxy(function (fileKey) {
                this.data.sceneData.socialScript = fileKey.trim();
                this.saveData();
            }, this));
        }

        /**
         * Called when the Select Script button is pressed.
         * @param e The click event.
         */
        public selectPostProcessingScriptClicked(e) {
            S3FilePicker.getInstance().open($(e.target).parent().parent().find('input').val(), $.proxy(function (fileKey) {
                this.data.postProcessingScript = fileKey.trim();
                this.saveData();
            }, this));
        }

        public selectAudioClicked(e) {
            var textarea = $(e.target).parent().parent().find('input');
            console.log(textarea);
            S3FilePicker.getInstance().open(textarea.val(), $.proxy(this.saveSelectedAudio, this));
        }

        public saveSelectedAudio(fileKey) {
            this.data.sceneData.customAudioScript = fileKey;
            this.saveData();
        }

        public cutOrderListChange(e) {

            //update the acts order and save the info to the database
            var _this = this;
            var orderedCuts = [];
            this.content.find('.cutList li').each(function (i) {
                var cut = $.grep(_this.data.sceneData.cuts, $.proxy(function (e) {
                    return e.id == this.id;
                }, this))[0];
                orderedCuts.push(cut);
            });
            for (var j in orderedCuts) {
                this.children[this.children.length - 1].removeMe();
            }
            for (var i = 0; i < orderedCuts.length; i++) {
                var newCut = this.addChild(orderedCuts[i], this.children.length);
                this.data.sceneData.cuts.push(newCut.data);
            }

            this.saveData();
        }

        public generatePreview() {
            if (!(this.data.sceneData.videoFile && this.data.sceneData.videoFile.url)) {
                alert('Please upload a base video before previewing');
                return;
            }

            var storyId = this.delegate.delegate.data.id;
            var actId = this.delegate.data.id;

            window.location.href = '/preview/' + storyId + '/' + actId;
        }

        public uploadVideoClicked(e) {

            e.preventDefault();

            this.content.find('.upload-main-video').trigger('click');
        }

        public deleteChild() {

            super.deleteChild();

            var child = this.data.sceneData.cuts.splice(this.childToDelete, 1)[0];
            child = null;

            this.saveData();
        }

        public addExistingCuts() {

            for (var i = 0; i < this.data.sceneData.cuts.length; i++) {

                this.addChild(this.data.sceneData.cuts[i], this.children.length);
            }

            // this.showChild(0);
            // $( ".cutList li:nth-child(1)" ).addClass('list-group-item-info');
        }

        private editCut(cutData) {
            var imageBased = (this.data.sceneData.videoFile.frames) ? true : false;
            if (!this.cutPicker) {
                this.cutPicker = new Nickel.CutPicker($("#storyView"), cutData, this);
                this.cutPicker.editing = true;
                this.cutPicker.loadVideo(this.data.sceneData.videoFile, imageBased);
            } else {
                this.cutPicker.setData(cutData);
                this.cutPicker.editing = true;
                this.cutPicker.showMe();
            }
            this.cutPicker.useImageBasedSeek(imageBased);
        }

        private openCutPicker() {
            if (this.data.sceneData.videoFile) {
                if (!this.cutPicker) {
                    this.cutPicker = new Nickel.CutPicker($("#storyView"), null, this);
                    this.cutPicker.loadVideo(this.data.sceneData.videoFile);
                } else {
                    console.log('Cut picker problems');
                }
            } else {
                alert('Please upload a base video');
            }
        }

        private saveCut(vo, encode = true) {

            var cut = this.cutBeingEdited;
            cut.pickerVO = vo;
            cut.data.startFrame = vo.inFrame;
            cut.data.endFrame = vo.outFrame;
            this.saveData();
            cut.updateCut();
        }

        public showMe() {

            super.showMe();

            if (this.data.sceneData.videoFile && this.data.sceneData.videoFile.url) {
                EventBus.dispatch(VideoPlayer.LOAD_VIDEO, {src: this.data.sceneData.videoFile.url});
            }
        }

        private prepareVideoUpload(e) {
            var form = $(e.target).parent().parent();
            var file = <HTMLInputElement> form.find('input[type=file]')[0];
            var fileData = file.files[0];
            if (fileData) {
                EventBus.dispatch(VideoPlayer.CLEAR_VIDEO);
                EventBus.dispatch(Debug.LOG, "Preparing video upload");

                Ajax.get(new JWTAjaxRequest('/story/' + this.delegate.delegate.data.id + '/upload-url', {
                    'filename': fileData.name,
                    'content_type': fileData.type
                }, $.proxy(this.uploadVideo, this, fileData), function (e) {
                    console.error("Error getting base video upload URL");
                    console.log(e)
                }));
            }
        }

        private uploadVideo(fileData, signedUrlResponse) {
            EventBus.dispatch(Debug.LOG, "Uploading video");

            this.progressBar.css('width', '0%');
            this.progress.show();

            var params:any = {
                url: signedUrlResponse.signed_url,
                type: 'PUT',
                data: fileData,
                cache: false,
                contentType: false,
                processData: false,
                headers: {
                    'Content-Type': fileData.type
                },
                xhr: $.proxy(function () {

                    var xhr = new XMLHttpRequest();
                    xhr.upload.addEventListener("progress", $.proxy(function (evt) {
                        this.progressBar.css('width', ((evt.loaded / evt.total) * 100 + "%"));
                    }, this), false);

                    return xhr;

                }, this),
                success: $.proxy(function (response) {

                    JobHandler.runJob('processBaseVideo', {
                        'story_id': this.delegate.delegate.data.id,
                        'file_key': signedUrlResponse.file_key
                    }, $.proxy(function (jobId, jobName, output) {
                        // noinspection JSPotentiallyInvalidUsageOfClassThis
                        this.progress.hide();

                        if (output && output.url) {
                            this.data.sceneData.videoFile = output;

                            this.saveData();
                            EventBus.dispatch(VideoPlayer.LOAD_VIDEO, {src: this.data.sceneData.videoFile.url});
                        }

                    }, this), $.proxy(function () {
                        // noinspection JSPotentiallyInvalidUsageOfClassThis
                        this.progress.hide();

                    }, this));

                }, this),
                error: $.proxy(function (response) {

                    this.progress.hide();
                    EventBus.dispatch(Debug.ERROR, "Error uploading video to S3");
                    console.log(response);

                }, this)
            };

            $.ajax(params);
        }

        public addChild(data, index) {

            var cut = new Nickel.Cut(this.content.find('.cutDetails'), data, index, this);
            this.children.push(cut);
            return cut;
        }

        public addCutBtnClicked() {
            if (this.data.sceneData.videoFile && this.data.sceneData.videoFile.url) {
                this.addCut();
            } else {
                alert('Please upload a base video before creating a cut');
            }
        }

        private addCut(data = null) {
            var cut = this.addChild(data, this.children.length);
            this.data.sceneData.cuts.push(cut.data);
            this.showChild(cut.index);

            this.saveData();
        }

        private addExistingCut(data = null) {
            var cut = this.addChild(data, this.children.length);
            this.showChild(cut.index);
        }

        public duplicateChildItem(cut) {
            var data = $.extend(true, {}, cut.data);
            data.id = Utils.generateUUID();
            if (data.overlays && data.overlays.length > 0) {
                for (var index in data.overlays) {
                    data.overlays[index].id = Utils.generateUUID();
                }
            }
            this.addCut(data);
        }

        private updateProgress(data) {
            this.content.find('.progress-msg').text(data.msg);
        }

        public static validatePublishAction(data) {
            if (!(data.sceneData.videoFile && data.sceneData.videoFile.url)) {
                alert('Video scene ' + data.name + ' does not have a base video. Please upload a base video before publishing.');
                return false;
            }
            return true;
        }

        /*
         * Specifies where the story's data can be found on this level.
         * @param storyData An object containing the story JSON structure.
         */
        public onDataReload(storyData):void {
            var existingIds = [];
            for (var i = 0; i < this.data.sceneData.cuts.length; i++) {
                var cut = this.data.sceneData.cuts[i];
                existingIds.push(cut.id);
            }

            $.extend(true, this.data, storyData['acts'][this.delegate.data.id]['scenes'][this.data.id]);

            for (var i = 0; i < this.data.sceneData.cuts.length; i++) {
                var cut = this.data.sceneData.cuts[i];
                if (existingIds.indexOf(cut.id) == -1) {
                    this.addExistingCut(cut);
                }
            }

            this.saveData();
        }

        /**
         * Called every time the encoding setting list is updated.
         */
        private updateEncodingSettings() {

            // refresh delete click event so old entries are unbound and new entries are bound
            this.content.find('.btnDeleteOutput').unbind('click').bind('click', $.proxy(this.removeEncodingOutput, this));

            // refresh toggle click event so old entries are unbound and new entries are bound
            this.content.find('.outputFormat .outputLabel').unbind('click').bind('click', function (e) {
                VideoScene01.toggleEncodingOutputDetails($(e.currentTarget).parent().parent().find('.outputDetails'));
            });
        }

        /**
         * Toggles the display details of an encoding setting.
         * @param detailsArea
         */
        private static toggleEncodingOutputDetails(detailsArea:JQuery) {
            if (detailsArea.hasClass('active')) {
                detailsArea.removeClass('active');
            } else {
                detailsArea.addClass('active');
            }
        }

        /**
         * Removes an encoding setting from the list.
         * @param e The click event.
         */
        private removeEncodingOutput(e) {
            var index:number = $(e.currentTarget).parent().parent().parent().index();
            this.data.sceneData.encodingSettings.splice(index, 1);
            this.updateEncodingSettings();
        }

        /**
         * Adds an encoding setting to the list.
         */
        private addEncodingOutput() {
            var name:string = prompt('Please provide a name for the new output format \n\nExample: "ProRes422 HQ 1920x1080 Stereo"');
            if (name) {
                var myWidth = "";
                var myHeight = "";
                if (this.data.sceneData.videoFile && this.data.sceneData.videoFile.width && this.data.sceneData.videoFile.height) {
                    myWidth = "" + this.data.sceneData.videoFile.width;
                    myHeight = "" + this.data.sceneData.videoFile.height;
                }

                this.data.sceneData.encodingSettings.push({
                    "name": name,
                    "video": "",
                    "audio": "",
                    "width": myWidth,
                    "height": myHeight,
                    "extension": "mp4"
                });

                this.updateEncodingSettings();

                var detailsArea:JQuery = this.content.find('.outputFormat .outputDetails').last();
                VideoScene01.toggleEncodingOutputDetails(detailsArea);
                detailsArea.find('input[type=text]').first().focus();
            }
        }

        /**
         * Adds an encoding setting from the preset dropdown to the list.
         * @param e The click event.
         */
        private addEncodingOutputFromPreset(e) {
            var dropdown:JQuery = $(e.currentTarget).parent().find('select');
            var option:JQuery = dropdown.find(':selected');

            this.data.sceneData.encodingSettings.push({
                "name": option.data('name'),
                "video": option.data('video'),
                "audio": option.data('audio'),
                "width": option.data('width'),
                "height": option.data('height'),
                "extension": option.data('extension')
            });

            this.updateEncodingSettings();
        }
    }
}
