module Nickel {

    export class DynamicPanel {

        /**
         * X positioning of the draggable handle. possible values: left, right, center.
         */
        public handleX:string = 'left';

        /**
         * X positioning of the draggable handle. possible values: top, bottom, center.
         */
        public handleY:string = "center";

        /**
         * Determines the direction of the lines on the handle.
         */
        public handleStyle:string = "vertical";

        /**
         * Whether or not you can change the width of the element.
         */
        public expandWidth:boolean = true;

        /**
         * Whether or not you can change the height of the element.
         */
        public expandHeight:boolean = true;

        /**
         * Minimum width (px) of the element.
         */
        private minWidth:number;

        /**
         * Minimum height (px) of the element.
         */
        private minHeight:number;

        /**
         * Minimum height (px) of the element.
         */
        private maxWidth:number;

        /**
         * Maximum height (px) of the element.
         */
        private maxHeight:number;

        /**
         * The width of the element when the dymamic panel is instantiated.
         */
        public initialWidth:number;

        /**
         * The height of the element when the dymamic panel is instantiated.
         */
        public initialHeight:number;

        /**
         * The current width of the element.
         */
        public currentWidth:number;

        /**
         * The current height of the element.
         */
        public currentHeight:number;

        /**
         * The initial X value when a drag is started.
         */
        private initialX:number;

        /**
         * The initial Y value when a drag is started.
         */
        private initialY:number;

        /**
         * The DOM element we're making dynamic.
         */
        public element:JQuery;

        /**
         * The handle element added to this.element for grabbing.
         */
        private handle:JQuery;

        /**
         * Event listeners.
         */
        private listeners:any = {};

        /**
         * Dispatched everytime the element resizes.
         */
        public static RESIZE:string = "resizepanel";

        /**
         * Set the defauly config values, binds all event listeners, and adds the handle to the element.
         * @element     The DOM element we want to turn dynamic.
         * @config      JSON config values passed in. Will override any global variables named the same in this class.
         */
        constructor(element:JQuery, config:any) {

            this.element = element;

            this.element.addClass('dynamicPanel');

            //loop through the config and override the defaults
            this.setDefaults(config);

            this.addHandle();

            this.bindEvents();
        }

        /**
         * Adds the handle to the stage so the user can manipulate the size of the dynamic panel.
         */
        private addHandle() {

            this.handle = $('<div class = "dynamicHandle"><i class = "fa fa-navicon"></i></div>');
            this.handle.addClass('x-' + this.handleX);
            this.handle.addClass('y-' + this.handleY);
            this.handle.addClass(this.handleStyle);
            this.element.append(this.handle);
        }

        /**
         * Loops through the keys in the config object, and applies them to the global variables of this class.
         */
        private setDefaults(config) {

            for (var key in config) {
                if (config.hasOwnProperty(key)) {
                    this[key] = config[key];
                }
            }

            this.expandWidth = (this.handleX == "center") ? false : true;
            this.expandHeight = (this.handleY == "center") ? false : true;

            this.initialWidth = this.currentWidth = this.element.width();
            this.initialHeight = this.currentHeight = this.element.height();
        }

        /**
         * Called when the user clicks on the handle. Sets the initial pan values, and adds the other event listeners.
         */
        private handleDown(e) {

            e.preventDefault();
            e.stopPropagation();

            this.initialX = e.clientX;
            this.initialY = e.clientY;

            $(window).on('mousemove', $.proxy(this.handleMove, this));
            $(window).on('mouseup', $.proxy(this.handleUp, this));
        }

        /**
         * Calculates the new width / height of the element, and calls setSize.
         */
        private handleMove(e) {

            e.preventDefault();
            e.stopPropagation();

            var offsetX = this.initialX - e.clientX;
            var offsetY = this.initialY - e.clientY;

            if (this.expandWidth) {
                this.currentWidth = this.initialWidth + offsetX;
            }
            if (this.maxWidth && this.currentWidth > this.maxWidth) {
                this.currentWidth = this.maxWidth;
            }
            if (this.minWidth && this.currentWidth < this.minWidth) {
                this.currentWidth = this.minWidth;
            }

            if (this.expandHeight) {
                this.currentHeight = this.initialHeight + offsetY;
            }
            if (this.maxHeight && this.currentHeight > this.maxHeight) {
                this.currentHeight = this.maxHeight;
            }
            if (this.minHeight && this.currentHeight < this.minHeight) {
                this.currentHeight = this.minHeight;
            }

            this.setSize();
        }

        /**
         * Resets the initialWidth and height of the element. Removes the up and move event listeners.
         */
        private handleUp(e) {

            e.preventDefault();
            e.stopPropagation();

            this.initialWidth = this.currentWidth;
            this.initialHeight = this.currentHeight;

            $(window).off('mouseup', $.proxy(this.handleUp, this));
            $(window).off('mousemove', $.proxy(this.handleMove, this));
        }

        /**
         * Applies currentWidth and currentHeight to the this.element.
         */
        private setSize() {

            if (this.expandWidth) {
                this.element.css('width', this.currentWidth);
            }
            if (this.expandHeight) {
                this.element.css('height', this.currentHeight);
            }

            this.dispatch(DynamicPanel.RESIZE);
        }

        /**
         * Binds all event listeners for this class.
         */
        private bindEvents() {

            this.handle.on('mousedown', $.proxy(this.handleDown, this));
        }

        /**
         * Adds an event listener for an event dispatched from this specific instance.
         * @evt         Event string we want to add an event listener for.
         * @callback    The function we want to call if that event is dispatched.
         */
        public on(evt:string, callback:any) {

            if (!this.listeners[evt]) {
                this.listeners[evt] = [];
            }

            if (this.listeners[evt].indexOf(callback) == -1) {
                this.listeners[evt].push(callback);
            }
        }

        /**
         * Removes an event listener for an event dispatched from this specific instance.
         * @evt         Event string we want to remove an event listener for.
         * @callback    The function we want to remove from the listeners array for that event.
         */
        public off(evt:string, callback:any) {

            var i = this.listeners[evt].indexOf(callback)
            if (i > -1) {
                this.listeners[evt].splice(i, 1);
                if (this.listeners[evt].length == 0) {
                    this.listeners[evt] = null;
                }
            }
        }

        /**
         * Dispatches an event to any Classes listening for it from this instance.
         * @evt         The event we want to dispatch.
         * @callback    The data we want to pass back to the event listener function.
         */
        public dispatch(evt:string, data:any = null) {

            var listeners = this.listeners[evt];
            if (listeners) {
                for (var i = 0; i < listeners.length; i++) {
                    listeners[i](data);
                }
            }
        }
    }
}
