module Nickel {

    /**
     * Component for displaying a single user experience data, and providing a UI to manipulate it.
     */
    export class BatchRowItem extends Component {

        /**
         * Set to true if this item is clicked, set to false once another item is clicked.
         */
        private active: boolean = false;

        /**
         * Dispatched when the user clicks on this specific batch row.
         */
        public static CLICKED:string = "batchrowitemclicked";

        /**
         * Stores the global vars, loads the template, populates the dynamic buttons, and binds the event listeners.
         * @param container        A jQuery object containing the parent div for this view.
         * @param data          The JSON data unique to this component.
         * @param delegate        The Class that created this instance.
         */
        constructor(container: JQuery, data: any, delegate: DataView) {

            super(container, data, delegate);

            if (data && data['values']) {
                data['displayValues'] = [];
                for (var i = 0; i < data['values'].length; i++) {
                    data['displayValues'][i] = BatchRowItem.filterDisplayValue(data['values'][i]);
                }
            }

            this.data = data;
            this.viewLoaded(Main.templates.find('.batchRowItem').clone());
            this.bindEvents();
        }

        /**
         * Returns the display value for an item.
         * @param originalValue
         */
        public static filterDisplayValue(originalValue) {
            if (originalValue.substr(0, 7) === 'http://' || originalValue.substr(0, 8) === 'https://') {
                return '<a href="' + originalValue + '" target="_blank">' + originalValue + '</a>';
            }

            return originalValue;
        }

        /**
         * Binds all of the event listeners for this view.
         */
        public bindEvents(): void {

            this.content.bind('click', $.proxy(this.selectItem, this));
        }

        /**
         * Unbinds all of the event listeners for this view.
         */
        public unbindEvents(): void {

            this.content.unbind('click', $.proxy(this.selectItem, this));
        }

        /**
         * Called if the user clicks on this item in the list.
         */
        private selectItem(): void {

            EventBus.dispatch(BatchRowItem.CLICKED, this);
        }

        /**
         * Completely removes the view from the DOM, clears all event listeners and kills rivets.
         */
        public killMe(): void {

            clearTimeout(this.displayTimeout);

            this.unbindEvents();
            this.rivets.unbind();
            this.content.remove();
            this.content = null;
        }
    }
}
