module Nickel {

    /**
     * Parent class for all URL triggered dynamic views.
     */
    export class View {

        /**
         * The unique ID associated with this view, this matched against the browser state to see if this view should
         * be visible.
         */
        public id:string;

        /**
         * The text to show in the header for this view.
         */
        public displayText:string;

        /**
         * The jquery object container for this view.
         */
        public container:JQuery;

        /**
         * Whether or not this view is currently visible or not.
         */
        public onStage:boolean = false;

        /**
         * The active sub section for this view, set by the updateMe function.
         */
        public subSection:string = null;

        /**
         * The delay for setting this view to display:none once the showContent class has been removed.
         */
        public hideDelay:number = 0;

        /**
         * The button created to show / hide this view in the header
         */
        public trigger:any;

        /**
         * The global timeout set when showing / hiding this view
         */
        public displayTimeout:number;

        /**
         * Stores the global vars, and adds the state change listener
         * @param container     A jQuery object containing the parent div for this view.
         * @param id            The unique ID associated with this view, used to determine if this view should be
         *     visible or not by listening to the browser state.
         * @param displayText   The text to show in the header for this view.
         */
        constructor(container:any, id:string, displayText:string) {

            //set the variables passed in from the parent object
            this.container = $("#" + container);
            this.id = id;
            this.displayText = displayText;

            //if this view has a unique id, add a url change listener
            if (id != null) {
                History['Adapter'].bind(window, 'statechange', $.proxy(this.stateChanged, this));
            }
        }

        /**
         * Called if this views button in the header is clicked.
         */
        public triggerClicked(e):void {

            e.preventDefault();
            Utils.pushState('/' + this.id);
        }

        /**
         * Triggered anytime the browser URL state changes, determines if this view should be visible or not.
         */
        public stateChanged():void {

            //get the state
            var showView = false;
            var urlStrings = History['getState']().url.split("?")[0].split("/");
            var stateString = urlStrings[3];
            var subString = (urlStrings[4]) ? ( urlStrings[4].length > 0) ? urlStrings[4] : null : null;

            //if we are just updating the sub class
            if (stateString == this.id && this.onStage && this.subSection != subString) {
                this.updateMe(subString);

                //check to see if we need to hide this class
            } else {
                if (stateString != this.id && this.onStage) {
                    this.hideMe();
                    this.subSection = null;

                    //check to see if we need to show the class
                } else {
                    if (stateString == this.id && !this.onStage) {

                        this.showMe(subString);
                    }
                }
            }
        }

        /**
         * Called when the stateChanged function sees that this view should be visible, and isn't already.
         * @param subState      Name of the sub view passed in from stateChanged.
         */
        public showMe(subState:string):void {

            if (this.subSection != subState) {
                this.updateMe(subState);
            }

            this.container.css('display', 'block');
            this.onStage = true;

            clearTimeout(this.displayTimeout);
            this.displayTimeout = setTimeout(()=> {
                this.showPageContent();
            }, 30);

            if (this.trigger) {
                this.trigger.addClass('active');
            }
        }


        /**
         * Called when the stateChanged function sees that this view shouldn't be visible, and is.
         */
        public hideMe():void {

            this.hidePageContent();

            clearTimeout(this.displayTimeout);
            this.displayTimeout = setTimeout(()=> {
                this.onStage = false;
                this.container.css('display', 'none');
            }, this.hideDelay);

            if (this.trigger) {
                this.trigger.removeClass('active');
            }
        }

        /**
         * Called when the sub section of this view changes.
         * @param subState      The subState passed in from stateChanged.
         */
        public updateMe(subState:string):void {

            this.subSection = subState;
        }

        /**
         * Toggles the show and hide css classes to show the content
         */
        public showPageContent():void {

            this.container.removeClass('hideContent');
            this.container.addClass('showContent');
        }

        /**
         * Toggles the show and hide css classes to show the content
         */
        public hidePageContent():void {

            this.container.removeClass('showContent');
            this.container.addClass('hideContent');
        }

    }
}
