/*
 * Copyright © 2018. Nickel Media Inc.
 *
 */
var Nickel;
(function (Nickel) {
    var Auth = (function () {
        function Auth() {
        }
        Auth.factory = function () {
            return new auth0.WebAuth({
                domain: 'imposium.auth0.com',
                clientID: 'FoP5tjbiy76e8XGK7NDAGagIEC07bfPX',
                responseType: 'id_token',
                scope: 'openid',
                redirectUri: window.location.protocol + '//' + window.location.host + '/redirect'
            });
        };
        Auth.login = function (hash, callback) {
            var auth = Auth.factory();
            if (!Auth.isAuthenticated()) {
                if (hash) {
                    auth.parseHash({ hash: hash }, function (err, authResult) {
                        if (authResult && authResult.idToken) {
                            Auth.setSession(authResult, callback);
                        }
                        else if (err) {
                            console.error(err);
                        }
                    });
                }
                else {
                    auth.checkSession({}, function (err, authResult) {
                        if (authResult && authResult.idToken) {
                            Auth.setSession(authResult, callback);
                        }
                        else {
                            localStorage.setItem('request_path', window.location.pathname);
                            auth.authorize();
                        }
                    });
                }
            }
            else {
                var session = Auth.getSession();
                var sharedSession = Auth.getSharedSession();
                if (sharedSession && sharedSession.sub && sharedSession.sub === session.sub &&
                    sharedSession.stories_hash === Auth.generateHash(session.access_data)) {
                    callback(session.token, session.access_data);
                }
                else {
                    auth.checkSession({}, function (err, authResult) {
                        if (authResult && authResult.idToken) {
                            Auth.setSession(authResult, callback);
                        }
                        else {
                            Auth.logout();
                        }
                    });
                }
            }
        };
        Auth.logout = function () {
            var auth = Auth.factory();
            // Remove tokens and expiry time from localStorage
            localStorage.removeItem('id_token');
            localStorage.removeItem('sub');
            localStorage.removeItem('access_data');
            localStorage.removeItem('expires_at');
            localStorage.removeItem('request_path');
            var sharedSession = Auth.getSharedSession();
            if (sharedSession && sharedSession.sub) {
                // Remove shared session cookie
                Auth.updateSharedSessionCookie('', -1);
                // noinspection TypeScriptValidateJSTypes
                auth.logout({ "returnTo": window.location.protocol + '//' + window.location.host + '/redirect' });
            }
            else {
                auth.authorize();
            }
            return false;
        };
        Auth.setSession = function (authResult, callback) {
            if (!authResult.idToken || !authResult.idTokenPayload['sub'] || !authResult.idTokenPayload['exp']) {
                console.error('Unable to configure session from auth result');
                callback(null, null);
            }
            JWTAjaxRequest.idToken = authResult.idToken;
            Ajax.get(new JWTAjaxRequest('/access', null, function (accessData) {
                var idToken = authResult.idToken;
                var sub = authResult.idTokenPayload['sub'];
                localStorage.setItem('id_token', idToken);
                localStorage.setItem('sub', sub);
                localStorage.setItem('access_data', JSON.stringify(accessData));
                localStorage.setItem('expires_at', JSON.stringify(authResult.idTokenPayload['exp'] * 1000));
                Auth.updateSharedSession({ sub: sub, stories_hash: Auth.generateHash(accessData) });
                callback(idToken, accessData);
            }, function (xhr) {
                console.error('The request to retrieve service access failed with a status of ' + xhr.jqXHR.status + ' ' + xhr.jqXHR.statusText);
                callback(null, null);
            }));
        };
        Auth.getSession = function () {
            return { "token": localStorage.getItem('id_token'), "sub": localStorage.getItem('sub'), "access_data": JSON.parse(localStorage.getItem('access_data')) };
        };
        Auth.getSharedSession = function () {
            var value = Utils.getCookie(Auth.sharedSessionCookie);
            if (value) {
                return JSON.parse(decodeURIComponent(value));
            }
            return null;
        };
        Auth.updateSharedSession = function (data) {
            var currentData = Auth.getSharedSession();
            if (!currentData) {
                currentData = {};
            }
            for (var key in data) {
                if (data.hasOwnProperty(key)) {
                    currentData[key] = data[key];
                }
            }
            var expiresAt = JSON.parse(localStorage.getItem('expires_at'));
            var expirySeconds = (expiresAt) ? (expiresAt - new Date().getTime()) / 1000 : -1;
            Auth.updateSharedSessionCookie(encodeURIComponent(JSON.stringify(currentData)), expirySeconds);
        };
        Auth.updateSharedSessionCookie = function (value, expirySeconds) {
            var domainParts = window.location.host.split('.').reverse();
            var domain = (domainParts.length > 2) ? '.' + domainParts[1] + '.' + domainParts[0].split(':')[0] : null;
            Utils.setCookie(Auth.sharedSessionCookie, value, expirySeconds, domain);
        };
        Auth.isAuthenticated = function () {
            // Check whether the current time is past the expiry time
            var expiresAt = JSON.parse(localStorage.getItem('expires_at'));
            return new Date().getTime() < expiresAt;
        };
        Auth.generateHash = function (accessData) {
            var s = accessData.organizations.reduce(function (p, c) { return (p.concat(c.stories.reduce(function (p, c) { return (p += "" + c.id + c.name); }, ''))); }, '');
            var hash = 0;
            if (s.length === 0) {
                return hash;
            }
            for (var i = 0; i < s.length; i++) {
                hash = ((hash << 5) - hash) + s.charCodeAt(i);
                hash |= 0;
            }
            return hash;
        };
        Auth.getPreviousRequestPath = function () {
            var prevRequestPath = localStorage.getItem('request_path');
            localStorage.removeItem('request_path');
            return prevRequestPath;
        };
        Auth.canAccess = function (route, services) {
            var accessUrl = window.location.host + route;
            if (services) {
                for (var i = 0; i < services.length; i++) {
                    if (services[i]['url']) {
                        if (accessUrl == services[i]['url'].replace('http://', '').replace('https://', '').replace('//', '')) {
                            return true;
                        }
                    }
                }
            }
            return false;
        };
        Auth.canAccessByName = function (name, services) {
            if (services) {
                for (var i = 0; i < services.length; i++) {
                    if (services[i]['name'] && services[i]['name'] === name) {
                        return true;
                    }
                }
            }
            return false;
        };
        Auth.sharedSessionCookie = 'auth_state';
        return Auth;
    })();
    Nickel.Auth = Auth;
})(Nickel || (Nickel = {}));
/**
 * Helper functions available to all other Classes.
 */
var Utils = (function () {
    function Utils() {
    }
    /* --------------------------------------------------------*/
    /* --------------------- MISC HELPERS ---------------------*/
    /* --------------------------------------------------------*/
    /**
     * Resizes a rectangle to fit into another rectangle using different positioning and scale properties to crop /
     * position.
     * @data    The data object containig all of the paraeters for the calculation.
     */
    Utils.fitToContainer = function (data) {
        var newH, newW, top, left;
        var aspectRatio = data.contentWidth / data.contentHeight;
        //scale
        if (data.scaleMode == "proportionalInside") {
            newW = data.containerWidth;
            newH = newW / aspectRatio;
            if (newH > data.containerHeight) {
                newH = data.containerHeight;
                newW = newH * aspectRatio;
            }
        }
        else {
            if (data.scaleMode == "proportionalOutside") {
                newW = data.containerWidth;
                newH = newW / aspectRatio;
                if (newH < data.containerHeight) {
                    newH = data.containerHeight;
                    newW = newH * aspectRatio;
                }
            }
            else {
                if (data.scaleMode == "none" || !data.scaleMode) {
                    newW = data.contentWidth;
                    newH = data.contentHeight;
                }
            }
        }
        if (data.maxWidth) {
            if (newW > data.maxWidth) {
                newW = data.maxWidth;
                newH = newW / aspectRatio;
            }
        }
        if (data.maxHeight) {
            if (newH > data.maxHeight) {
                newH = data.maxHeight;
                newW = newH * aspectRatio;
            }
        }
        //fit
        left = (data.hAlign == "left") ? 0 : (data.hAlign == "right") ? -(newW - data.containerWidth) : (data.containerWidth - newW) / 2;
        top = (data.vAlign == "top") ? 0 : (data.vAlign == "bottom") ? -(newH - data.containerHeight) : (data.containerHeight - newH) / 2;
        return {
            'width': newW,
            'height': newH,
            'top': top,
            'left': left
        };
    };
    /**
     * Generates a UUID and returns it.
     */
    Utils.generateUUID = function () {
        var d = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
        });
        return uuid;
    };
    /**
     * Generates a unique id, based off of the langth passed in.
     * @numChars    How may characters we want the (psuedo) unique ID to contain.
     */
    Utils.generateID = function (numChars) {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < numChars; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        return text;
    };
    /**
     * Pushes a browser state
     * @state   The state we want to push.
     */
    Utils.pushState = function (state) {
        History['pushState']({ state: 1 }, document.title, state + window.location.search);
    };
    /**
     * Opens a popup window centered in the screen
     */
    Utils.openWindow = function (url, width, height) {
        var windowSize = {
            'width': width,
            'height': height,
            'left': (screen.width / 2) - (width / 2),
            'top': (screen.height / 2) - (height / 2 + 100)
        };
        var windowFeatures = "width=" + windowSize.width + ",height=" + windowSize.height + ",status,resizable,scrollbars,modal,alwaysRaised";
        windowFeatures += ",left=" + windowSize.left + ",top=" + windowSize.top + "screenX=" + windowSize.left + ",screenY=" + windowSize.top;
        return window.open(url, '' + new Date().getTime() + '', windowFeatures);
    };
    /**
     * Parse a query string variable from the current URL.
     * @param variable
     */
    Utils.getQueryStringVariable = function (variable) {
        if (window.location.search) {
            var query = window.location.search.substring(1);
            var vars = query.split('&');
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split('=');
                if (decodeURIComponent(pair[0]) == variable) {
                    return decodeURIComponent(pair[1]);
                }
            }
        }
        return null;
    };
    /* --------------------------------------------------------*/
    /* ------------------------ STRING ------------------------*/
    /* --------------------------------------------------------*/
    /**
     * Capitalises the first letter of a string.
     * @str     The string you want to capitalise.
     */
    Utils.capitalize = function (str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    /**
     * Returns a formatted string for displaying the file size, from bytes.
     * @bytes       The filesize, in bytes.
     */
    Utils.formatBytes = function (bytes) {
        if (bytes >= 1000000000) {
            bytes = (bytes / 1000000000).toFixed(2) + ' GB';
        }
        else {
            if (bytes >= 1000000) {
                bytes = (bytes / 1000000).toFixed(2) + ' MB';
            }
            else {
                if (bytes >= 1000) {
                    bytes = (bytes / 1000).toFixed(2) + ' KB';
                }
                else {
                    if (bytes > 1) {
                        bytes = bytes + ' bytes';
                    }
                    else {
                        if (bytes == 1) {
                            bytes = bytes + ' byte';
                        }
                        else {
                            bytes = '0 byte';
                        }
                    }
                }
            }
        }
        return bytes;
    };
    /* --------------------------------------------------------*/
    /* ------------------------ OBJECT ------------------------*/
    /* --------------------------------------------------------*/
    /**
     * Counts the key/value pairs in an object.
     * @obj     The object you want to get a "length" from.
     */
    Utils.objSize = function (obj) {
        var size = 0, key;
        for (key in obj) {
            if (obj.hasOwnProperty(key)) {
                size++;
            }
        }
        return size;
    };
    /* --------------------------------------------------------*/
    /* ----------------------- NUMBERS ------------------------*/
    /* --------------------------------------------------------*/
    /**
     * Detects if a value is numberic or not.
     * @n   The value we want to check.
     */
    Utils.isNumeric = function (n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    };
    /**
     * Detects if a number is odd or event
     * @num     The value we want to check.
     */
    Utils.isOdd = function (num) {
        return (num % 2) == 16;
    };
    /**
     * Contain a number to a min and max.
     */
    Utils.clamp = function (min, max, val) {
        if (val < min) {
            return min;
        }
        if (val > max) {
            return max;
        }
        return val;
    };
    /* --------------------------------------------------------*/
    /* ------------------------- MATH -------------------------*/
    /* --------------------------------------------------------*/
    /**
     * Convert degrees to radians.
     */
    Utils.degreesToRadians = function (degrees) {
        return degrees * Math.PI / 180;
    };
    /**
     * Convert radians to degrees
     */
    Utils.radiansToDegrees = function (radians) {
        return radians * 180 / Math.PI;
    };
    /**
     * Calculates the distance between two points in 2D space.
     */
    Utils.lineDistance = function (point1, point2) {
        var xs = 0;
        var ys = 0;
        xs = point2.x - point1.x;
        xs = xs * xs;
        ys = point2.y - point1.y;
        ys = ys * ys;
        return Math.sqrt(xs + ys);
    };
    /**
     * Calculates the angle in degrees between two points
     */
    Utils.calcAngle = function (p1, p2) {
        var calcAngle = Math.atan2(p1.x - p2.x, p1.y - p2.y) * (180 / Math.PI);
        if (calcAngle < 0) {
            calcAngle = Math.abs(calcAngle);
        }
        else {
            calcAngle = 360 - calcAngle;
        }
        return calcAngle;
    };
    /**
     * Returns a random number between 2 numbers
     */
    Utils.randomFromInterval = function (from, to) {
        return Math.floor(Math.random() * (to - from + 1) + from);
    };
    /* --------------------------------------------------------*/
    /* ------------------------- ARRAY ------------------------*/
    /* --------------------------------------------------------*/
    /**
     * Switches the position of two array elements.
     * @array       The array containing both elements.
     * @a           The index of the first element.
     * @b           The index of the second element.
     * @return      The array with the elements switched.
     */
    Utils.swapArrayElements = function (array, a, b) {
        var temp = array[a];
        array[a] = array[b];
        array[b] = temp;
        return array;
    };
    /**
     * Removes one of more elements from an array.
     * @array       The array containing all of the elements.
     * @from        The first element we want to remove (and the last, if @to isn't set).
     * @to          The index of the last element we want to remove.
     * @return      The original array with the elements removed.
     */
    Utils.removeFromArray = function (array, from, to) {
        var rest = array.slice((to || from) + 1 || array.length);
        array.length = from < 0 ? array.length + from : from;
        return array.push.apply(array, rest);
    };
    /**
     * Randomly shuffles the contents of an array.
     * @array       The array containing all of the elements.
     */
    Utils.shuffleArray = function (array) {
        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    };
    /* --------------------------------------------------------*/
    /* -------------------------- JSON ------------------------*/
    /* --------------------------------------------------------*/
    /**
     * Checks if a string is valid json.
     * @str     The string you want to check.
     */
    Utils.isValidJSON = function (str) {
        try {
            if (str.charAt(0) == "{") {
                JSON.parse(str);
            }
            else {
                return false;
            }
        }
        catch (e) {
            return false;
        }
        return true;
    };
    /**
     * Formats a JSON sting with line breaks for displaying pretty JSON.
     * @str     The string you want to format.
     */
    Utils.formatJSONString = function (str) {
        var jsonObj = JSON.parse(str);
        var jsonPretty = JSON.stringify(jsonObj, null, '\t');
        return jsonPretty;
    };
    /* --------------------------------------------------------*/
    /* ----------------- BROWSER / OS DETECTION ---------------*/
    /* --------------------------------------------------------*/
    /**
     * Detects the operating system on a mobile device, returns and os and the version.
     */
    Utils.detectMobileOS = function () {
        var mobileOS;
        var mobileOSver;
        var ua = navigator.userAgent;
        var uaindex;
        // determine OS
        if (ua.match(/iPad/i) || ua.match(/iPhone/i)) {
            mobileOS = 'iOS';
            uaindex = ua.indexOf('OS ');
        }
        else {
            if (ua.match(/Android/i)) {
                mobileOS = 'Android';
                uaindex = ua.indexOf('Android ');
            }
            else {
                mobileOS = 'unknown';
            }
        }
        // determine version
        if (mobileOS === 'iOS' && uaindex > -1) {
            mobileOSver = ua.substr(uaindex + 3, 3).replace('_', '.');
        }
        else {
            if (mobileOS === 'Android' && uaindex > -1) {
                mobileOSver = ua.substr(uaindex + 8, 3);
            }
            else {
                mobileOSver = 'unknown';
            }
        }
        var num = Number(mobileOSver);
        return { "os": mobileOS, "ver": num };
    };
    /* --------------------------------------------------------*/
    /* -------------------- FEATURE DETECTION -----------------*/
    /* --------------------------------------------------------*/
    /**
     * Detects if the current browser can play the mp4 video format or now.
     */
    Utils.canPlayMP4 = function () {
        var canPlay = false;
        var v = document.createElement('video');
        if (v.canPlayType && v.canPlayType('video/mp4').replace(/no/, '')) {
            canPlay = true;
        }
        return canPlay;
    };
    /* --------------------------------------------------------*/
    /* ----------------------- DATE / TIME --------------------*/
    /* --------------------------------------------------------*/
    Utils.formatDate = function (date, format) {
        var dateStr = date.toString();
        var dateArr = dateStr.split(" ");
        var tz = dateArr[dateArr.length - 1];
        if (tz === "Time)") {
            var matches = /\(([^)]+)\)$/.exec(dateStr);
            if (matches) {
                tz = '(' + matches[1].match(/\b(\w)/g).join('') + ')';
            }
        }
        return date.toString(format).replace(' 0:', ' 12:') + " " + tz; // Fix date.js bug to show 12:00am instead of 0:00am
    };
    /* --------------------------------------------------------*/
    /* ------------------------ COOKIES -----------------------*/
    /* --------------------------------------------------------*/
    /**
     *Sets a cookie to the document object
     * @c_name      The name for the new cookie.
     * @value       The data to set on this cookie.
     * @exseconds   How many seconds before this cookie expires.
     * @domain      Optional domain to place the cookie on.
     */
    Utils.setCookie = function (c_name, value, exseconds, domain) {
        var exdate = new Date();
        exdate.setTime(exdate.getTime() + (exseconds * 1000));
        var c_value = value +
            ((exseconds == null) ? "" : "; expires=" + exdate.toUTCString()) +
            ((!domain) ? "" : "; domain=" + domain) +
            ";path=/";
        document.cookie = c_name + "=" + c_value;
    };
    /**
     * Retrives a cookie from the document object using the cookies name
     * @c_name      Label of the cookie you want to retrieve.
     */
    Utils.getCookie = function (c_name) {
        var c_value = document.cookie;
        var c_start = c_value.indexOf(" " + c_name + "=");
        if (c_start == -1) {
            c_start = c_value.indexOf(c_name + "=");
        }
        if (c_start == -1) {
            c_value = null;
        }
        else {
            c_start = c_value.indexOf("=", c_start) + 1;
            var c_end = c_value.indexOf(";", c_start);
            if (c_end == -1) {
                c_end = c_value.length;
            }
            c_value = c_value.substring(c_start, c_end);
        }
        return c_value;
    };
    /* --------------------------------------------------------*/
    /* ---------------------- VALIDATION ----------------------*/
    /* --------------------------------------------------------*/
    /**
     * Validates an email address.
     */
    Utils.realEmail = function (addressToTest) {
        var regPattern = /^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
        return regPattern.test(addressToTest);
    };
    return Utils;
})();
var StoryUtils = (function () {
    function StoryUtils() {
    }
    StoryUtils.getFirstCutTime = function (story, sceneId) {
        var time = 0;
        var scene = StoryUtils.getScene(story, sceneId);
        if (scene) {
            var cuts = scene.sceneData.cuts;
            var first = cuts[0];
            if (first) {
                time = first.startFrame / scene.sceneData.videoFile.rate;
            }
        }
        return time;
    };
    StoryUtils.getScene = function (story, sceneId, params) {
        var r = null;
        if (story.acts) {
            for (var key in story.acts) {
                if (story.acts.hasOwnProperty(key)) {
                    var act = story.acts[key];
                    if (act.scenes) {
                        for (var s in act.scenes) {
                            if (act.scenes.hasOwnProperty(s)) {
                                var scene = act.scenes[s];
                                if (scene.id == sceneId) {
                                    r = scene;
                                    return r;
                                }
                            }
                        }
                    }
                }
            }
        }
        return r;
    };
    StoryUtils.getFirstSceneOfType = function (story, type) {
        var r = null;
        if (story.acts) {
            for (var key in story.acts) {
                if (story.acts.hasOwnProperty(key)) {
                    var act = story.acts[key];
                    if (act.scenes) {
                        for (var s in act.scenes) {
                            if (act.scenes.hasOwnProperty(s)) {
                                var scene = act.scenes[s];
                                if (scene.type === type) {
                                    r = scene.id;
                                    return r;
                                }
                            }
                        }
                    }
                }
            }
        }
        return r;
    };
    StoryUtils.getAct = function (story, actId, params) {
        var act = null;
        if (story.acts) {
            if (story.acts.hasOwnProperty(actId)) {
                act = story.acts[actId];
                return act;
            }
        }
        return act;
    };
    StoryUtils.getInventory = function (story, invId) {
        var r = null;
        //run through all of the acts, and their scenes, and look for the label matching this ID
        if (story.acts) {
            for (var key in story.acts) {
                if (story.acts.hasOwnProperty(key)) {
                    var act = story.acts[key];
                    if (act.inventory) {
                        for (var s in act.inventory) {
                            if (act.inventory.hasOwnProperty(s)) {
                                var inv = act.inventory[s];
                                if (inv.id == invId) {
                                    r = inv;
                                    return r;
                                }
                            }
                        }
                    }
                }
            }
        }
        return r;
    };
    return StoryUtils;
})();
var StringUtils = (function () {
    function StringUtils() {
    }
    StringUtils.between = function (p_string, p_start, p_end) {
        var str = '';
        if (p_string == null) {
            return str;
        }
        var startIdx = p_string.indexOf(p_start);
        if (startIdx != -1) {
            startIdx += p_start.length;
            var endIdx = p_string.indexOf(p_end, startIdx);
            if (endIdx != -1) {
                str = p_string.substr(startIdx, endIdx - startIdx);
            }
        }
        return str;
    };
    StringUtils.contains = function (needle, haystack) {
        return haystack && (haystack.indexOf(needle) != -1);
    };
    StringUtils.afterLast = function (p_string, p_char) {
        if (p_string == null) {
            return '';
        }
        ;
        var idx = p_string.lastIndexOf(p_char);
        if (idx == -1) {
            return '';
        }
        idx += p_char.length;
        return p_string.substr(idx);
    };
    StringUtils.toHHMMSS = function (secondsInput) {
        var sec_num = parseInt(secondsInput, 10); // don't forget the second param
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);
        if (hours < 10) {
            hours = "0" + hours;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        if (seconds < 10) {
            seconds = "0" + seconds;
        }
        return hours + ':' + minutes + ':' + seconds;
    };
    return StringUtils;
})();
var Nickel;
(function (Nickel) {
    /**
     * Parent class for all URL triggered dynamic views.
     */
    var View = (function () {
        /**
         * Stores the global vars, and adds the state change listener
         * @param container     A jQuery object containing the parent div for this view.
         * @param id            The unique ID associated with this view, used to determine if this view should be
         *     visible or not by listening to the browser state.
         * @param displayText   The text to show in the header for this view.
         */
        function View(container, id, displayText) {
            /**
             * Whether or not this view is currently visible or not.
             */
            this.onStage = false;
            /**
             * The active sub section for this view, set by the updateMe function.
             */
            this.subSection = null;
            /**
             * The delay for setting this view to display:none once the showContent class has been removed.
             */
            this.hideDelay = 0;
            //set the variables passed in from the parent object
            this.container = $("#" + container);
            this.id = id;
            this.displayText = displayText;
            //if this view has a unique id, add a url change listener
            if (id != null) {
                History['Adapter'].bind(window, 'statechange', $.proxy(this.stateChanged, this));
            }
        }
        /**
         * Called if this views button in the header is clicked.
         */
        View.prototype.triggerClicked = function (e) {
            e.preventDefault();
            Utils.pushState('/' + this.id);
        };
        /**
         * Triggered anytime the browser URL state changes, determines if this view should be visible or not.
         */
        View.prototype.stateChanged = function () {
            //get the state
            var showView = false;
            var urlStrings = History['getState']().url.split("?")[0].split("/");
            var stateString = urlStrings[3];
            var subString = (urlStrings[4]) ? (urlStrings[4].length > 0) ? urlStrings[4] : null : null;
            //if we are just updating the sub class
            if (stateString == this.id && this.onStage && this.subSection != subString) {
                this.updateMe(subString);
            }
            else {
                if (stateString != this.id && this.onStage) {
                    this.hideMe();
                    this.subSection = null;
                }
                else {
                    if (stateString == this.id && !this.onStage) {
                        this.showMe(subString);
                    }
                }
            }
        };
        /**
         * Called when the stateChanged function sees that this view should be visible, and isn't already.
         * @param subState      Name of the sub view passed in from stateChanged.
         */
        View.prototype.showMe = function (subState) {
            var _this = this;
            if (this.subSection != subState) {
                this.updateMe(subState);
            }
            this.container.css('display', 'block');
            this.onStage = true;
            clearTimeout(this.displayTimeout);
            this.displayTimeout = setTimeout(function () {
                _this.showPageContent();
            }, 30);
            if (this.trigger) {
                this.trigger.addClass('active');
            }
        };
        /**
         * Called when the stateChanged function sees that this view shouldn't be visible, and is.
         */
        View.prototype.hideMe = function () {
            var _this = this;
            this.hidePageContent();
            clearTimeout(this.displayTimeout);
            this.displayTimeout = setTimeout(function () {
                _this.onStage = false;
                _this.container.css('display', 'none');
            }, this.hideDelay);
            if (this.trigger) {
                this.trigger.removeClass('active');
            }
        };
        /**
         * Called when the sub section of this view changes.
         * @param subState      The subState passed in from stateChanged.
         */
        View.prototype.updateMe = function (subState) {
            this.subSection = subState;
        };
        /**
         * Toggles the show and hide css classes to show the content
         */
        View.prototype.showPageContent = function () {
            this.container.removeClass('hideContent');
            this.container.addClass('showContent');
        };
        /**
         * Toggles the show and hide css classes to show the content
         */
        View.prototype.hidePageContent = function () {
            this.container.removeClass('showContent');
            this.container.addClass('hideContent');
        };
        return View;
    })();
    Nickel.View = View;
})(Nickel || (Nickel = {}));
var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    __.prototype = b.prototype;
    d.prototype = new __();
};
var Nickel;
(function (Nickel) {
    var PaginatedView = (function (_super) {
        __extends(PaginatedView, _super);
        /**
         * Stores the global vars, and adds the state change listener
         * @param container    A jQuery object containing the parent div for this view.
         * @param id            The unique ID associated with this view, used to determine if this view should be
         *     visible or not by listening to the browser state.
         * @param displayText    The text to show in the header for this view.
         */
        function PaginatedView(container, id, displayText) {
            _super.call(this, container, id, displayText);
            /**
             * The current page we're on
             */
            this.page = 1;
            /**
             * The total number of pages in this data set
             */
            this.pages = 1;
            /**
             * The total number of experiences in this data set
             */
            this.total = 1;
            this.btnNextPage = this.container.find('.btnNextPage').bind('click', $.proxy(this.nextPageClicked, this));
            this.btnPrevPage = this.container.find('.btnPrevPage').bind('click', $.proxy(this.prevPageClicked, this));
            this.btnFirstPage = this.container.find('.btnFirstPage').bind('click', $.proxy(this.firstPageClicked, this));
            this.btnLastPage = this.container.find('.btnLastPage').bind('click', $.proxy(this.lastPageClicked, this));
            this.btnRefresh = this.container.find('.btnRefresh').bind('click', $.proxy(this.reloadPaginatedData, this));
        }
        /**
         * Makes sure that the pagination buttons are enabled / disabled appropriately.
         */
        PaginatedView.prototype.checkPaginationButtons = function () {
            if (this.page <= 1) {
                this.btnPrevPage.addClass('disabled');
                this.btnFirstPage.addClass('disabled');
            }
            else {
                this.btnPrevPage.removeClass('disabled');
                this.btnFirstPage.removeClass('disabled');
            }
            if (this.page == this.pages) {
                this.btnNextPage.addClass('disabled');
                this.btnLastPage.addClass('disabled');
            }
            else {
                this.btnNextPage.removeClass('disabled');
                this.btnLastPage.removeClass('disabled');
            }
            if (this.pages == 0) {
                this.container.find('.paginationPiece').hide();
            }
            else {
                this.container.find('.paginationPiece').show();
            }
        };
        /**
         * Moves this.page variable ahead and gets some new experiences.
         * @param e    Event passed in from the button click.
         */
        PaginatedView.prototype.nextPageClicked = function (e) {
            this.page++;
            this.reloadPaginatedData();
        };
        /**
         * Moves this.page variable back and gets some new experiences.
         * @param e    Event passed in from the button click.
         */
        PaginatedView.prototype.prevPageClicked = function (e) {
            this.page--;
            this.reloadPaginatedData();
        };
        /**
         * Sets this.page to 1 and gets the experiences.
         * @param e    Event passed in from the button click.
         */
        PaginatedView.prototype.firstPageClicked = function (e) {
            this.page = 1;
            this.reloadPaginatedData();
        };
        /**
         * Sets this.page to the last page and gets the experiences.
         * @param e    Event passed in from the button click.
         */
        PaginatedView.prototype.lastPageClicked = function (e) {
            this.page = this.pages;
            this.reloadPaginatedData();
        };
        /**
         * Checks pagination buttons and reloads paginated data.
         */
        PaginatedView.prototype.reloadPaginatedData = function () {
            this.checkPaginationButtons();
            this.getPaginatedData();
        };
        /**
         * Reaches out to server with this.page.
         * Callback with response from server must set this.pages and this.total
         */
        PaginatedView.prototype.getPaginatedData = function () {
            // Override me
            // TODO: make abstract by upgrading Typescript
        };
        return PaginatedView;
    })(Nickel.View);
    Nickel.PaginatedView = PaginatedView;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    var Component = (function () {
        /**
         * Stores the global vars
         * @param container    A jQuery object containing the parent div for this view.
         * @param data            The config JSON data associated with this component.
         * @param delegate        The Class that instantiated this view.
         */
        function Component(container, data, delegate) {
            /**
             * Whether of not this instance is visible or not.
             */
            this.onStage = false;
            /**
             * The delay for setting display:none on this view after the showContent class is removed.
             */
            this.hideDelay = 0;
            /**
             * The delay for adding the showContent class to this view once display:block has been set.
             */
            this.showDelay = 25;
            /**
             * The object containing all event listeners added to this specific component
             */
            this.listeners = {};
            /**
             * The class to use for showing this component
             */
            this.displayClass = "block";
            /**
             * A unique id for this class, used binding / removing global event listenrs
             */
            this.guid = Utils.generateUUID();
            this.container = container;
            this.delegate = delegate;
            this.data = data;
        }
        /**
         * Sets this.content with a JQuery object pased in (usually cloned from Main.templates), binds this.data to
         * this.content using rivets.
         */
        Component.prototype.viewLoaded = function (v) {
            this.content = v;
            this.rivets = rivets.bind(this.content, {
                "data": this.data
            });
            if (this.container) {
                this.container.append(this.content);
            }
        };
        /**
         * Adds an event listener for an event dispatched from this specific instance.
         * @evt        Event string we want to add an event listener for.
         * @callback    The function we want to call if that event is dispatched.
         */
        Component.prototype.on = function (evt, callback) {
            if (!this.listeners[evt]) {
                this.listeners[evt] = [];
            }
            if (this.listeners[evt].indexOf(callback) == -1) {
                this.listeners[evt].push(callback);
            }
        };
        /**
         * Removes an event listener for an event dispatched from this specific instance.
         * @evt        Event string we want to remove an event listener for.
         * @callback    The function we want to remove from the listeners array for that event.
         */
        Component.prototype.off = function (evt, callback) {
            var i = this.listeners[evt].indexOf(callback);
            if (i > -1) {
                this.listeners[evt].splice(i, 1);
                if (this.listeners[evt].length == 0) {
                    this.listeners[evt] = null;
                }
            }
        };
        /**
         * Dispatches an event to any Classes listening for it from this instance.
         * @evt        The event we want to dispatch.
         * @callback    The data we want to pass back to the event listener function.
         */
        Component.prototype.dispatch = function (evt, data) {
            if (data === void 0) { data = null; }
            var listeners = this.listeners[evt];
            if (listeners) {
                for (var i = 0; i < listeners.length; i++) {
                    listeners[i](data);
                }
            }
        };
        /**
         * Binds all of the event listeners for this component.
         */
        Component.prototype.bindEvents = function () {
        };
        /**
         * Unbinds all of the Event listeners for this component.
         */
        Component.prototype.unbindEvents = function () {
        };
        /**
         * Adds display block to this.content, and calls showContent
         */
        Component.prototype.showMe = function () {
            var _this = this;
            var style = (this.displayClass == "visible") ? "visibility" : "display";
            this.content.css(style, this.displayClass);
            this.onStage = true;
            clearTimeout(this.displayTimeout);
            this.displayTimeout = setTimeout(function () {
                _this.showContent();
            }, this.showDelay);
        };
        /**
         * Adds display none to this.content, calls hideContent
         */
        Component.prototype.hideMe = function () {
            var _this = this;
            this.hideContent();
            this.onStage = false;
            var style = (this.displayClass == "visible") ? "visibility" : "display";
            var hide = (style == "display") ? "none" : "hidden";
            clearTimeout(this.displayTimeout);
            this.displayTimeout = setTimeout(function () {
                _this.content.css(style, hide);
            }, this.hideDelay);
        };
        /**
         * Adds the showContent class and removes the hideContent class from this component.
         */
        Component.prototype.showContent = function () {
            if (this.content) {
                this.content.removeClass('hideContent');
                this.content.addClass('showContent');
            }
        };
        /**
         * Adds the hideContent class and removes the showContent class from this component.
         */
        Component.prototype.hideContent = function () {
            if (this.content) {
                this.content.removeClass('showContent');
                this.content.addClass('hideContent');
            }
        };
        /**
         * Kills this component, removes the event listeners, removes this.content, and set's itself to null;
         */
        Component.prototype.killMe = function () {
            this.unbindEvents();
            if (this.content) {
                this.content.remove();
                this.content = null;
            }
        };
        return Component;
    })();
    Nickel.Component = Component;
})(Nickel || (Nickel = {}));
var AjaxUrlProvider = (function () {
    function AjaxUrlProvider() {
    }
    AjaxUrlProvider.getRestApiBaseUrl = function () {
        if (!AjaxUrlProvider.restApiBaseUrl) {
            var baseUrl = 'https://';
            var domain = document.domain;
            var domainPieces = domain.split('.');
            if (domain.indexOf('imposium') === -1) {
                baseUrl += AjaxUrlProvider.localApiHost;
            }
            else if (domainPieces[0] === 'cms') {
                baseUrl += domain.replace('cms', 'api');
            }
            else if (domainPieces[0] === 'hub') {
                baseUrl += domain.replace('hub', 'api');
            }
            else {
                console.warn('Unsupported API hostname');
            }
            AjaxUrlProvider.restApiBaseUrl = baseUrl;
        }
        return AjaxUrlProvider.restApiBaseUrl;
    };
    AjaxUrlProvider.getLegacyApiBaseUrl = function () {
        if (!AjaxUrlProvider.legacyApiBaseUrl) {
            var baseUrl = '';
            var domain = document.domain;
            var domainPieces = domain.split('.');
            if (domain.indexOf('imposium') === -1) {
                baseUrl += 'http://localhost';
            }
            else if (domainPieces[0] === 'cms') {
                baseUrl += 'https://' + domain;
            }
            else if (domainPieces[0] === 'hub') {
                baseUrl += 'https://' + domain.replace('hub', 'cms');
            }
            else {
                console.warn('Unsupported legacy API hostname');
            }
            AjaxUrlProvider.legacyApiBaseUrl = baseUrl;
        }
        return AjaxUrlProvider.legacyApiBaseUrl;
    };
    AjaxUrlProvider.localApiHost = 'api'; // no trailing slash
    AjaxUrlProvider.restApiBaseUrl = null;
    AjaxUrlProvider.legacyApiBaseUrl = null;
    return AjaxUrlProvider;
})();
var AjaxRequest = (function () {
    function AjaxRequest(data, success, error, loader, url) {
        if (data === void 0) { data = {}; }
        if (success === void 0) { success = null; }
        if (error === void 0) { error = null; }
        if (loader === void 0) { loader = null; }
        if (url === void 0) { url = '/api'; }
        this.apiVersion = null;
        this.url = null;
        this.data = {};
        this.successCallback = null;
        this.errorCallback = null;
        this.loader = null;
        this.org = null;
        this.url = (url.indexOf('http') == 0) ? url : AjaxUrlProvider.getLegacyApiBaseUrl() + url;
        this.data = data;
        this.successCallback = success;
        this.errorCallback = error;
        this.loader = loader;
        this.org = AjaxRequest.organizationId;
    }
    AjaxRequest.organizationId = null;
    return AjaxRequest;
})();
var HMacAjaxRequest = (function (_super) {
    __extends(HMacAjaxRequest, _super);
    function HMacAjaxRequest(route, data, success, error, loader) {
        if (data === void 0) { data = null; }
        if (success === void 0) { success = null; }
        if (error === void 0) { error = null; }
        if (loader === void 0) { loader = null; }
        _super.call(this, data, success, error, loader);
        this.secret = HMacAjaxRequest.secretAccessKey;
        this.key = HMacAjaxRequest.accessKeyId;
        this.route = route;
        this.url = AjaxUrlProvider.getRestApiBaseUrl() + this.route;
        route = route.split(/[?#]/)[0];
        if (data) {
            if (typeof data === 'string') {
                route += data;
            }
            else {
                route += JSON.stringify(data);
            }
        }
        this.sig = this.getSignature(route);
    }
    HMacAjaxRequest.prototype.getSignature = function (message) {
        var sig = CryptoJS.HmacSHA1(message, this.secret);
        var base64 = sig.toString(CryptoJS.enc.Hex);
        return base64;
    };
    HMacAjaxRequest.accessKeyId = null;
    HMacAjaxRequest.secretAccessKey = null;
    return HMacAjaxRequest;
})(AjaxRequest);
var JWTAjaxRequest = (function (_super) {
    __extends(JWTAjaxRequest, _super);
    function JWTAjaxRequest(route, data, success, error, loader) {
        if (data === void 0) { data = null; }
        if (success === void 0) { success = null; }
        if (error === void 0) { error = null; }
        if (loader === void 0) { loader = null; }
        _super.call(this, data, success, error, loader);
        this.authToken = null;
        this.authToken = JWTAjaxRequest.idToken;
        this.url = AjaxUrlProvider.getRestApiBaseUrl() + route;
    }
    JWTAjaxRequest.idToken = null;
    return JWTAjaxRequest;
})(AjaxRequest);
var Ajax = (function () {
    function Ajax() {
    }
    Ajax.formSubmit = function (form, onComplete, loader, onProgress) {
        if (onComplete === void 0) { onComplete = null; }
        if (loader === void 0) { loader = null; }
        if (onProgress === void 0) { onProgress = null; }
        if (!form.data('ajaxified')) {
            form.data('ajaxified', '1');
            form.ajaxForm({
                complete: function (xhr) {
                    if (loader) {
                        loader.hide();
                    }
                    if (onComplete) {
                        var responseObj = eval("(" + xhr.responseText + ")");
                        onComplete(responseObj);
                    }
                },
                uploadProgress: function (evt, pos, total, perc) {
                    if (onProgress) {
                        onProgress(evt, pos, total, perc);
                    }
                }
            });
        }
        if (loader) {
            loader.show();
        }
        form.submit();
        return form;
    };
    Ajax.get = function (request) {
        if (request.data) {
            return Ajax.request('GET', request, "application/x-www-form-urlencoded; charset=utf-8");
        }
        else {
            return Ajax.request('GET', request);
        }
    };
    Ajax.post = function (request) {
        return Ajax.request('POST', request);
    };
    Ajax.request = function (method, request, contentType, additionalHeaders) {
        if (contentType === void 0) { contentType = "application/json; charset=utf-8"; }
        if (additionalHeaders === void 0) { additionalHeaders = null; }
        if (request.loader) {
            request.loader.show();
        }
        var requestData = {
            type: method,
            url: request.url,
            contentType: contentType,
            success: function (response) {
                if (request.loader) {
                    request.loader.hide();
                }
                if (request.successCallback) {
                    request.successCallback(response);
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                if (request.loader) {
                    request.loader.hide();
                }
                if (request.authToken && jqXHR.status === 403) {
                    Nickel.Auth.logout();
                }
                else if (request.errorCallback) {
                    request.errorCallback({ jqXHR: jqXHR, textStatus: textStatus, errorThrown: errorThrown });
                }
            }
        };
        var isJsonRequest = contentType && contentType.indexOf('application/json') != -1;
        if (isJsonRequest) {
            requestData.dataType = 'json';
        }
        else if (contentType === false) {
            requestData.processData = false;
        }
        if (additionalHeaders) {
            requestData.headers = additionalHeaders;
        }
        if (request.data) {
            requestData.data = (isJsonRequest) ? JSON.stringify(request.data) : request.data;
        }
        if (request.sig) {
            requestData.beforeSend = function (r) {
                r.setRequestHeader("X-Imposium-Access-Key", request.key);
                r.setRequestHeader("X-Imposium-Signature", request.sig);
                if (request.apiVersion) {
                    r.setRequestHeader("X-Imposium-Api-Version", request.apiVersion);
                }
                if (request.org) {
                    r.setRequestHeader("X-Imposium-Account-Id", request.org);
                }
            };
        }
        if (request.authToken) {
            requestData.beforeSend = function (r) {
                r.setRequestHeader("Authorization", "Bearer " + request.authToken);
                if (request.apiVersion) {
                    r.setRequestHeader("X-Imposium-Api-Version", request.apiVersion);
                }
                if (request.org) {
                    r.setRequestHeader("X-Imposium-Account-Id", request.org);
                }
            };
        }
        return $.ajax(requestData);
    };
    Ajax.oldestApiVersion = "0.0.1";
    Ajax.latestApiVersion = "2.0.0";
    return Ajax;
})();
var Loader = (function () {
    function Loader(view) {
        this.view = view;
        this.init();
    }
    Loader.prototype.init = function () {
        this.hide();
    };
    Loader.prototype.show = function () {
        this.view.removeClass('hidden');
    };
    Loader.prototype.hide = function () {
        this.view.addClass('hidden');
    };
    return Loader;
})();
/**
 * Global event listener class. Used for passing global between views and components.
 */
var EventBus = (function () {
    function EventBus() {
    }
    /**
     * Adds an event listener for a global event.
     * @evt         Event string we're listening for
     * @callback    The callback function to call if the event is dispatched.
     */
    EventBus.addEventListener = function (evt, callback, caller) {
        if (!EventBus.listeners[evt]) {
            EventBus.listeners[evt] = {};
        }
        EventBus.listeners[evt][caller.guid] = callback;
    };
    /**
     * Removes a specific event listener for a global event.
     * @evt         Event string we're removing
     * @callback    The callback function we want to remove.
     */
    EventBus.removeEventListener = function (evt, callback, caller) {
        var listeners = EventBus.listeners[evt];
        var callerListener = listeners[caller.guid];
        delete listeners[caller.guid];
    };
    /**
     * Adds an event listener for a global event.
     * @evt         The event we want to dispatch.
     * @callback    The data we want to pass into the callback function for that event.
     */
    EventBus.dispatchEvent = function (evt, data) {
        if (data === void 0) { data = null; }
        var listeners = EventBus.listeners[evt];
        for (var key in listeners) {
            if (listeners.hasOwnProperty(key)) {
                listeners[key](data);
            }
        }
    };
    /**
     * Adds an event listener for a global event.
     * @evt         The event we want to dispatch.
     * @callback    The data we want to pass into the callback function for that event.
     */
    EventBus.dispatch = function (evt, data) {
        if (data === void 0) { data = null; }
        var listeners = EventBus.listeners[evt];
        for (var key in listeners) {
            if (listeners.hasOwnProperty(key)) {
                listeners[key](data);
            }
        }
    };
    /**
     * Object containing all event listeners, with the key being the event string, and the value being an array of listener functions.
     */
    EventBus.listeners = {};
    return EventBus;
})();
var Nickel;
(function (Nickel) {
    var VO = (function () {
        function VO() {
            this.id = Utils.generateUUID();
            this.dateCreated = new Date().getTime() / 1000;
        }
        return VO;
    })();
    Nickel.VO = VO;
    var Level = (function () {
        function Level(container, data, index, delegate) {
            this.onStage = true;
            //details stuff
            this.detailsOpen = false;
            this.firstSave = false;
            this.children = [];
            this.label = "";
            this.showInPopup = true;
            this.guid = Utils.generateUUID();
            this.container = container;
            this.index = index;
            this.delegate = delegate;
        }
        /**
         * Creates the rivets data binding and binds all Level generic events.
         * @param v A JQuery object containing this level's content selector.
         */
        Level.prototype.viewLoaded = function (v) {
            this.content = v;
            //this.container.append(this.content);
            this.rivets = rivets.bind(this.content, {
                "data": this.data,
                "controller": this
            });
            this.addCopyButton();
            if (this.showInPopup) {
                EventBus.addEventListener(Level.SHOW_DETAILS, $.proxy(this.showDetails, this), this);
            }
            $(window).bind("resize", $.proxy(this.resize, this));
            //this.resize(null);
        };
        Level.prototype.addCopyButton = function () {
            var _this = this;
            var cont = this.content.find('label:contains(ID)').parent().find('.data');
            this.btnCopy = $('<div class = "btnCopyToClipboard"><i class = "fa fa-clipboard"></i></div>');
            cont.append(this.btnCopy);
            this.zero = new ZeroClipboard(this.btnCopy[0]);
            this.btnCopy.on('mousedown', function () { return _this.btnCopy.addClass('copied'); });
            this.zero.on('copy', function (event) {
                var input = $(event.target).parent().find('input');
                event.clipboardData.setData('text/plain', input.val().trim());
            });
            this.zero.on('aftercopy', function () { return _this.btnCopy.removeClass('copied'); });
        };
        /**
         * @param d A data object
         */
        Level.prototype.showDetails = function (d) {
            if (d.id == this.data.id) {
                EventBus.dispatch(Nickel.RightInterface.SHOW_IN_INTERFACE, { view: this, type: this.label });
                this.showMe();
            }
        };
        /**
         * Show the level
         */
        Level.prototype.showMe = function () {
            this.onStage = true;
            if (this.showInPopup) {
                EventBus.dispatch(Nickel.NodeTree.SELECT_NODE, { id: this.data.id });
                Main.activeNode = this.data.id;
                Main.saveState();
            }
        };
        /**
         * Hide the level.
         */
        Level.prototype.hideMe = function () {
            this.onStage = false;
        };
        /**
         * Removes the child from the child array and calls kill on it. Each individual level takes care of removing it
         * from the db.
         */
        Level.prototype.deleteChild = function () {
            //remove the TS controller for the act
            var child = this.children.splice(this.childToDelete, 1)[0];
            child.killMe();
            this.activeChild = null;
            child = null;
            this.showMe();
        };
        /**
         * Hides the currently shown child, and shows the new one.
         * @param index
         */
        Level.prototype.showChild = function (index) {
            if (this.activeChild) {
                this.activeChild.hideMe();
            }
            this.activeChild = this.children[index];
            if (this.activeChild) {
                this.activeChild.showDetails({ id: this.activeChild.data.id });
            }
        };
        /**
         * Calls the parent and tells it to duplicate this level.
         */
        Level.prototype.duplicateMe = function () {
            if (this.delegate && typeof this.delegate.duplicateChildItem == 'function') {
                this.delegate.duplicateChildItem(this);
            }
        };
        /**
         * Sets the parent's childToDelete as this level's index.
         */
        Level.prototype.removeMe = function () {
            this.delegate.childToDelete = this.index;
            this.delegate.deleteChild();
            this.delegate.saveData();
        };
        /**
         * Removes this classes frontend from the UI.
         */
        Level.prototype.killMe = function () {
            this.rivets.unbind();
            $(window).unbind("resize", $.proxy(this.resize, this));
            this.content.remove();
            this.content = null;
        };
        /**
         * Reloads the story data and updates the interface with reloaded data.
         */
        Level.prototype.reloadMe = function (callback) {
            if (callback === void 0) { callback = null; }
            Ajax.get(new JWTAjaxRequest('/story/' + Main.storyId, null, $.proxy(function (story) {
                this.onDataReload(story);
                if (callback) {
                    callback();
                }
            }, this)));
        };
        /**
         * Specifies where the story's data can be found on this level.
         * @param storyData An object containing the story JSON structure.
         */
        Level.prototype.onDataReload = function (storyData) {
            console.log('onDataReload is not overridden correctly');
            //TODO: make this abstract when upgraded to Typescript 1.6
        };
        //OVERRIDE ME
        Level.prototype.addChild = function (data, index) {
        };
        //OVERRIDE ME
        Level.prototype.saveData = function () {
        };
        //OVERRIDE ME
        Level.prototype.dataSaved = function (r) {
        };
        //OVERRIDE ME
        Level.prototype.levelRemoved = function (r) {
        };
        //OVERRIDE ME
        Level.prototype.resize = function () {
            var o = this.content.find('.scrollable').offset();
            if (o) {
                var t = o.top;
                var h = $(window).height() - t - 10;
                this.content.find('.scrollable').css('height', h);
            }
        };
        Level.SHOW_DETAILS = "showdetails";
        return Level;
    })();
    Nickel.Level = Level;
})(Nickel || (Nickel = {}));
var Stopwatch = (function () {
    function Stopwatch(container) {
        this.options = {
            delay: 100
        };
        this.container = container;
        this.time = this.container.find('.time');
        // initialize
        this.reset();
    }
    Stopwatch.prototype.start = function (offset) {
        if (offset === void 0) { offset = 0; }
        if (!this.interval) {
            this.clock = 0;
            this.render();
            this.offset = Date.now() - (offset * 1000);
            this.interval = setInterval($.proxy(this.update, this), this.options.delay);
        }
    };
    Stopwatch.prototype.stop = function () {
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        }
    };
    Stopwatch.prototype.reset = function () {
        this.clock = 0;
        // this.render();
    };
    Stopwatch.prototype.update = function () {
        this.clock += this.delta();
        this.render();
    };
    Stopwatch.prototype.render = function () {
        this.curTime = this.clock / 1000;
        this.curTime = this.curTime.toFixed(2);
        this.time.val(this.curTime);
    };
    Stopwatch.prototype.delta = function () {
        var now = Date.now(), d = now - this.offset;
        this.offset = now;
        return d;
    };
    return Stopwatch;
})();
var Nickel;
(function (Nickel) {
    var StoryPicker = (function (_super) {
        __extends(StoryPicker, _super);
        function StoryPicker(content, data, delegate) {
            _super.call(this, content, data, delegate);
            this.viewLoaded(Main.templates.find('.storyPicker').clone());
            EventBus.addEventListener(StoryPicker.UPDATE_STORIES, $.proxy(this.getStoryData, this), this);
            EventBus.addEventListener(StoryPicker.STORIES_LOADED, $.proxy(this.populateStoryDropdown, this), this);
            this.btnDropdown = this.content.find('.btnStoryDropdown').bind('click', $.proxy(this.dropdownClicked, this));
            this.storyDropdown = this.content.find('.dropdown');
            this.content.on('click', '.dropdown .btnStory', $.proxy(this.storyButtonClick, this));
            if (!StoryPicker.stories) {
                this.getStoryData();
            }
            else {
                this.populateStoryDropdown(StoryPicker.stories);
            }
        }
        /**
         * Hit the backend for all story data
         */
        StoryPicker.prototype.getStoryData = function () {
            if (!StoryPicker.storiesLoading) {
                StoryPicker.storiesLoading = true;
                Ajax.get(new JWTAjaxRequest('/stories', null, $.proxy(function (data) {
                    StoryPicker.storiesLoading = false;
                    this.gotStories(data.stories);
                }, this), $.proxy(function (e) {
                    StoryPicker.storiesLoading = false;
                    console.error("Error getting the stories");
                    console.log(e);
                }, this)));
            }
        };
        /**
         * Sets the static story data and populates the dropdown
         */
        StoryPicker.prototype.gotStories = function (stories) {
            StoryPicker.stories = stories;
            EventBus.dispatch(StoryPicker.STORIES_LOADED, StoryPicker.stories);
        };
        /**
         * Toggles the visibility of the story dropdown.
         */
        StoryPicker.prototype.dropdownClicked = function () {
            if (this.btnDropdown.hasClass('active')) {
                this.btnDropdown.removeClass('active');
                this.storyDropdown.hide();
            }
            else {
                this.btnDropdown.addClass('active');
                this.storyDropdown.show();
            }
        };
        /**
         * Populates the story dropdown for this view.
         */
        StoryPicker.prototype.populateStoryDropdown = function (storyData) {
            storyData.sort(function (a, b) {
                if (a.name < b.name)
                    return -1;
                if (a.name > b.name)
                    return 1;
                return 0;
            });
            this.clearStoryDropdown();
            for (var i = 0; i < storyData.length; i++) {
                var btn = $('<a class = "btnStory" data-id = "' + storyData[i].id + '">' + storyData[i].name + '</a>');
                this.storyDropdown.append(btn);
            }
        };
        /**
         * Removes all of the items from the dropdown
         */
        StoryPicker.prototype.clearStoryDropdown = function () {
            this.storyDropdown.empty();
        };
        /**
         * Sets the story based off of whatever story button the user clicked in the story dropdown.
         */
        StoryPicker.prototype.storyButtonClick = function (evt) {
            this.dropdownClicked();
            var id = $(evt.target).attr('data-id');
            var result = $.grep(StoryPicker.stories, function (e) { return e.id == id; });
            if (result[0]) {
                this.dispatch(StoryPicker.STORY_CLICKED, result[0]);
            }
        };
        StoryPicker.stories = null;
        StoryPicker.storiesLoading = false;
        StoryPicker.STORIES_LOADED = "storiesloaded";
        StoryPicker.STORY_CLICKED = "storypicked";
        StoryPicker.UPDATE_STORIES = "updatestories";
        return StoryPicker;
    })(Nickel.Component);
    Nickel.StoryPicker = StoryPicker;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    var ActPicker = (function (_super) {
        __extends(ActPicker, _super);
        function ActPicker(content, data, delegate) {
            _super.call(this, content, data, delegate);
            this.viewLoaded(Main.templates.find('.actPicker').clone());
            this.btnDropdown = this.content.find('.btnActDropdown').bind('click', $.proxy(this.dropdownClicked, this));
            this.actDropdown = this.content.find('.dropdown');
            this.content.on('click', '.dropdown .btnAct', $.proxy(this.actButtonClick, this));
        }
        /**
         * Toggles the visibility of the story dropdown.
         */
        ActPicker.prototype.dropdownClicked = function () {
            if (this.btnDropdown.hasClass('active')) {
                this.btnDropdown.removeClass('active');
                this.actDropdown.hide();
            }
            else {
                this.btnDropdown.addClass('active');
                this.actDropdown.show();
            }
        };
        /**
         * Populates the story dropdown for this view.
         */
        ActPicker.prototype.populateActDropdown = function (storyData) {
            this.actData = storyData.acts;
            this.clearActDropdown();
            var acts = storyData.acts;
            for (var key in acts) {
                if (acts.hasOwnProperty(key)) {
                    var act = acts[key];
                    var btn = $('<a class = "btnAct" data-id = "' + act.id + '">' + act.name + '</a>');
                    this.actDropdown.append(btn);
                }
            }
        };
        /**
         * Removes all of the items from the dropdown
         */
        ActPicker.prototype.clearActDropdown = function () {
            this.actDropdown.empty();
        };
        /**
         * Sets the story based off of whatever story button the user clicked in the story dropdown.
         */
        ActPicker.prototype.actButtonClick = function (evt) {
            this.dropdownClicked();
            var id = $(evt.target).attr('data-id');
            var result = this.actData[id];
            if (result) {
                this.dispatch(ActPicker.ACT_CLICKED, result);
            }
        };
        ActPicker.ACT_CLICKED = "actpicked";
        return ActPicker;
    })(Nickel.Component);
    Nickel.ActPicker = ActPicker;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    var BatchPicker = (function (_super) {
        __extends(BatchPicker, _super);
        function BatchPicker(content, data, delegate) {
            _super.call(this, content, data, delegate);
            this.viewLoaded(Main.templates.find('.batchPicker').clone());
            this.btnDropdown = this.content.find('.btnBatchDropdown').bind('click', $.proxy(this.dropdownClicked, this));
            this.batchDropdown = this.content.find('.dropdown');
            this.content.on('click', '.dropdown .btnBatch', $.proxy(this.batchButtonClick, this));
        }
        /**
         * Toggles the visibility of the batch dropdown.
         */
        BatchPicker.prototype.dropdownClicked = function () {
            if (this.btnDropdown.hasClass('active')) {
                this.btnDropdown.removeClass('active');
                this.batchDropdown.hide();
            }
            else {
                this.btnDropdown.addClass('active');
                this.batchDropdown.show();
            }
        };
        /**
         * Populates the batch dropdown for this view.
         */
        BatchPicker.prototype.populateBatchDropdown = function (batches, storyId) {
            this.batchData = {};
            this.batchDropdown.empty();
            batches.sort(function (a, b) {
                if (a.name < b.name)
                    return -1;
                if (a.name > b.name)
                    return 1;
                return 0;
            });
            for (var key in batches) {
                if (batches.hasOwnProperty(key)) {
                    var batch = batches[key];
                    batch['story_id'] = storyId;
                    this.batchData[batch.id] = batch;
                    var btn = $('<a class = "btnBatch" data-id = "' + batch.id + '">' + batch.name + '</a>');
                    this.batchDropdown.append(btn);
                }
            }
        };
        /**
         * Sets the batch based off of whatever batch button the user clicked in the batch dropdown.
         */
        BatchPicker.prototype.batchButtonClick = function (evt) {
            this.dropdownClicked();
            var id = $(evt.target).attr('data-id');
            var result = this.batchData[id];
            if (result) {
                this.dispatch(BatchPicker.BATCH_CLICKED, result);
            }
        };
        BatchPicker.BATCH_CLICKED = "batchpicked";
        return BatchPicker;
    })(Nickel.Component);
    Nickel.BatchPicker = BatchPicker;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    var DynamicPanel = (function () {
        /**
         * Set the defauly config values, binds all event listeners, and adds the handle to the element.
         * @element     The DOM element we want to turn dynamic.
         * @config      JSON config values passed in. Will override any global variables named the same in this class.
         */
        function DynamicPanel(element, config) {
            /**
             * X positioning of the draggable handle. possible values: left, right, center.
             */
            this.handleX = 'left';
            /**
             * X positioning of the draggable handle. possible values: top, bottom, center.
             */
            this.handleY = "center";
            /**
             * Determines the direction of the lines on the handle.
             */
            this.handleStyle = "vertical";
            /**
             * Whether or not you can change the width of the element.
             */
            this.expandWidth = true;
            /**
             * Whether or not you can change the height of the element.
             */
            this.expandHeight = true;
            /**
             * Event listeners.
             */
            this.listeners = {};
            this.element = element;
            this.element.addClass('dynamicPanel');
            //loop through the config and override the defaults
            this.setDefaults(config);
            this.addHandle();
            this.bindEvents();
        }
        /**
         * Adds the handle to the stage so the user can manipulate the size of the dynamic panel.
         */
        DynamicPanel.prototype.addHandle = function () {
            this.handle = $('<div class = "dynamicHandle"><i class = "fa fa-navicon"></i></div>');
            this.handle.addClass('x-' + this.handleX);
            this.handle.addClass('y-' + this.handleY);
            this.handle.addClass(this.handleStyle);
            this.element.append(this.handle);
        };
        /**
         * Loops through the keys in the config object, and applies them to the global variables of this class.
         */
        DynamicPanel.prototype.setDefaults = function (config) {
            for (var key in config) {
                if (config.hasOwnProperty(key)) {
                    this[key] = config[key];
                }
            }
            this.expandWidth = (this.handleX == "center") ? false : true;
            this.expandHeight = (this.handleY == "center") ? false : true;
            this.initialWidth = this.currentWidth = this.element.width();
            this.initialHeight = this.currentHeight = this.element.height();
        };
        /**
         * Called when the user clicks on the handle. Sets the initial pan values, and adds the other event listeners.
         */
        DynamicPanel.prototype.handleDown = function (e) {
            e.preventDefault();
            e.stopPropagation();
            this.initialX = e.clientX;
            this.initialY = e.clientY;
            $(window).on('mousemove', $.proxy(this.handleMove, this));
            $(window).on('mouseup', $.proxy(this.handleUp, this));
        };
        /**
         * Calculates the new width / height of the element, and calls setSize.
         */
        DynamicPanel.prototype.handleMove = function (e) {
            e.preventDefault();
            e.stopPropagation();
            var offsetX = this.initialX - e.clientX;
            var offsetY = this.initialY - e.clientY;
            if (this.expandWidth) {
                this.currentWidth = this.initialWidth + offsetX;
            }
            if (this.maxWidth && this.currentWidth > this.maxWidth) {
                this.currentWidth = this.maxWidth;
            }
            if (this.minWidth && this.currentWidth < this.minWidth) {
                this.currentWidth = this.minWidth;
            }
            if (this.expandHeight) {
                this.currentHeight = this.initialHeight + offsetY;
            }
            if (this.maxHeight && this.currentHeight > this.maxHeight) {
                this.currentHeight = this.maxHeight;
            }
            if (this.minHeight && this.currentHeight < this.minHeight) {
                this.currentHeight = this.minHeight;
            }
            this.setSize();
        };
        /**
         * Resets the initialWidth and height of the element. Removes the up and move event listeners.
         */
        DynamicPanel.prototype.handleUp = function (e) {
            e.preventDefault();
            e.stopPropagation();
            this.initialWidth = this.currentWidth;
            this.initialHeight = this.currentHeight;
            $(window).off('mouseup', $.proxy(this.handleUp, this));
            $(window).off('mousemove', $.proxy(this.handleMove, this));
        };
        /**
         * Applies currentWidth and currentHeight to the this.element.
         */
        DynamicPanel.prototype.setSize = function () {
            if (this.expandWidth) {
                this.element.css('width', this.currentWidth);
            }
            if (this.expandHeight) {
                this.element.css('height', this.currentHeight);
            }
            this.dispatch(DynamicPanel.RESIZE);
        };
        /**
         * Binds all event listeners for this class.
         */
        DynamicPanel.prototype.bindEvents = function () {
            this.handle.on('mousedown', $.proxy(this.handleDown, this));
        };
        /**
         * Adds an event listener for an event dispatched from this specific instance.
         * @evt         Event string we want to add an event listener for.
         * @callback    The function we want to call if that event is dispatched.
         */
        DynamicPanel.prototype.on = function (evt, callback) {
            if (!this.listeners[evt]) {
                this.listeners[evt] = [];
            }
            if (this.listeners[evt].indexOf(callback) == -1) {
                this.listeners[evt].push(callback);
            }
        };
        /**
         * Removes an event listener for an event dispatched from this specific instance.
         * @evt         Event string we want to remove an event listener for.
         * @callback    The function we want to remove from the listeners array for that event.
         */
        DynamicPanel.prototype.off = function (evt, callback) {
            var i = this.listeners[evt].indexOf(callback);
            if (i > -1) {
                this.listeners[evt].splice(i, 1);
                if (this.listeners[evt].length == 0) {
                    this.listeners[evt] = null;
                }
            }
        };
        /**
         * Dispatches an event to any Classes listening for it from this instance.
         * @evt         The event we want to dispatch.
         * @callback    The data we want to pass back to the event listener function.
         */
        DynamicPanel.prototype.dispatch = function (evt, data) {
            if (data === void 0) { data = null; }
            var listeners = this.listeners[evt];
            if (listeners) {
                for (var i = 0; i < listeners.length; i++) {
                    listeners[i](data);
                }
            }
        };
        /**
         * Dispatched everytime the element resizes.
         */
        DynamicPanel.RESIZE = "resizepanel";
        return DynamicPanel;
    })();
    Nickel.DynamicPanel = DynamicPanel;
})(Nickel || (Nickel = {}));
/*
 * Copyright © 2018. Nickel Media Inc.
 *
 */
var Nickel;
(function (Nickel) {
    var CloudRegionProvider = (function () {
        function CloudRegionProvider() {
        }
        CloudRegionProvider.getRegions = function (callback) {
            callback(CloudRegionProvider.regions);
        };
        CloudRegionProvider.regions = [
            'us-east-1',
            'us-east-2',
            'us-west-1',
            'us-west-2',
            'ca-central-1',
            'eu-west-1',
            'eu-west-2',
            'eu-west-3',
            'eu-central-1',
            'ap-south-1',
            'ap-northeast-1',
            'ap-northeast-2',
            'ap-southeast-1',
            'ap-southeast-2',
            'sa-east-1'
        ];
        return CloudRegionProvider;
    })();
    Nickel.CloudRegionProvider = CloudRegionProvider;
})(Nickel || (Nickel = {}));
/*
 * Copyright © 2018. Nickel Media Inc.
 *
 */
var Nickel;
(function (Nickel) {
    var AssetProvider = (function () {
        function AssetProvider() {
        }
        AssetProvider.refreshAssets = function (callback) {
            AssetProvider.assets = null;
            AssetProvider.getAssets(callback);
        };
        AssetProvider.getAssets = function (callback) {
            if (AssetProvider.assets == null) {
                AssetProvider.callbacks.push(callback);
                if (!AssetProvider.requesting) {
                    AssetProvider.requesting = true;
                    Ajax.get(new JWTAjaxRequest('/footage?order=name&items_per_page=1000000', null, function (data) {
                        var assets = {};
                        data.footage.forEach(function (item, index) {
                            if (!assets[item['type']]) {
                                assets[item['type']] = {};
                            }
                            assets[item['type']][item['id']] = item['name'];
                        });
                        AssetProvider.assets = assets;
                        AssetProvider.requesting = false;
                        // Fire any queued callbacks
                        var i = AssetProvider.callbacks.length;
                        while (i--) {
                            AssetProvider.callbacks.splice(i, 1)[0](AssetProvider.assets);
                        }
                    }, function () {
                        AssetProvider.requesting = false;
                    }));
                }
            }
            else {
                callback(AssetProvider.assets);
            }
        };
        AssetProvider.callbacks = [];
        AssetProvider.requesting = false;
        return AssetProvider;
    })();
    Nickel.AssetProvider = AssetProvider;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    /**
     * Page for building, modifying and storing story JSON data.
     */
    var StoriesView = (function (_super) {
        __extends(StoriesView, _super);
        /**
         * Stores the global vars, and adds the state change listener
         * @param container    A jQuery object containing the parent div for this view.
         * @param id            The unique ID associated with this view, used to determine if this view should be
         *     visible or not by listening to the browser state.
         * @param displayText    The text to show in the header for this view.
         */
        function StoriesView(container, id, displayText) {
            _super.call(this, container, id, displayText);
            this.initialized = false;
            /**
             * An object contaning all of the Story objects, stored as key value pairs with the story ID.
             */
            this.children = {};
            /**
             * An Array holding all of the items in the story dropdown.
             */
            this.storyItems = [];
            this.bindEvents();
            this.title = this.container.find('.storyTitle');
            this.publisher = new Nickel.StoryPublisher(this.container.find('.publish'), {}, this);
            this.debug = new Nickel.Debug(this.container.find('.nodeHolder'), {}, this);
            this.debug.bindGlobalEvents();
        }
        /**
         * Binds all of the event listeners for this view
         */
        StoriesView.prototype.bindEvents = function () {
            $("#btnAddStory").bind("click", $.proxy(this.addStoryClicked, this));
            $("#btnImportStory").bind('click', $.proxy(this.importStory, this));
            $("#storyImportFile").bind('change', $.proxy(this.getStoryData, this));
            $(".btnInterfaceType").bind('click', $.proxy(this.interfaceTypeChange, this));
            $(".btnSaveActiveStory").bind('click', $.proxy(this.saveActiveStory, this));
            $(".btnRefreshStory").bind('click', $.proxy(this.refreshStory, this));
            EventBus.addEventListener(StoriesView.UPDATE_NODES, $.proxy(this.updateNodes, this), this);
            EventBus.addEventListener(Nickel.RightInterface.RESIZE_INTERFACE, $.proxy(this.resize, this), this);
            $(window).bind('resize', $.proxy(this.resize, this));
            this.resize();
        };
        /**
         * Hits the database to get the data for all of the user's stories.
         */
        StoriesView.prototype.init = function () {
            this.initialized = true;
            this.setDefaultInterfaceType();
            this.storyDropdown = new Nickel.StoryPicker(this.container.find('.dropdownContainer'), null, this);
            this.storyDropdown.on(Nickel.StoryPicker.STORY_CLICKED, $.proxy(this.storyClicked, this));
            this.container.find('[data-toggle="tooltip"]').tooltip();
            var request = new JWTAjaxRequest('/stories', null, $.proxy(this.gotData, this));
            request.apiVersion = Ajax.latestApiVersion;
            Ajax.get(request);
        };
        /**
         * Adds the selected class to the active interface type in the story header (node or list interface).
         */
        StoriesView.prototype.setDefaultInterfaceType = function () {
            $(".btnInterfaceType." + Main.interfaceType).addClass('selected');
        };
        /**
         * Adds the selected class to the active interface type in the story header (node or list interface).
         * @param e    Event Passed in from the click.
         */
        StoriesView.prototype.interfaceTypeChange = function (e) {
            $(".btnInterfaceType.selected").removeClass('selected');
            $(e.currentTarget).addClass('selected');
            Main.interfaceType = $(e.currentTarget).attr('data-type');
            Main.saveState();
            this.updateNodes();
        };
        /**
         * Saves the currently visible story.
         */
        StoriesView.prototype.saveActiveStory = function () {
            if (this.activeChild) {
                this.activeChild.saveData();
            }
        };
        /**
         * Updates the node (or list) interface to display the most recent data model.
         */
        StoriesView.prototype.updateNodes = function () {
            if (this.storyInterface) {
                this.storyInterface.killMe();
                this.storyInterface = null;
            }
            if (Main.interfaceType == "list") {
                this.storyInterface = new Nickel.ListInterface(this.container.find('.nodeHolder'), null, this);
            }
            else {
                if (Main.interfaceType == "nodes") {
                    this.storyInterface = new Nickel.NodeTree(this.container.find('.nodeHolder'), null, this);
                }
            }
            if (this.activeChild) {
                var data = this.getNodeData(this.activeChild.data);
                this.storyInterface.setData(data);
            }
        };
        /**
         * Shows the view, if it hasn't been initialized, calls init
         * @param subSection        The sub view to show.
         */
        StoriesView.prototype.showMe = function (subSection) {
            _super.prototype.showMe.call(this, subSection);
            if (!this.initialized) {
                this.init();
            }
        };
        /**
         * Makes a new story, saves it, and shows it.
         * @param e    Event passed in from the click.
         */
        StoriesView.prototype.addStoryClicked = function (e) {
            e.preventDefault();
            var story = this.addChild(null, 0);
            story.saveData();
            this.expData.push(story.data);
            this.showChild(story.data.id, true);
        };
        /**
         * Triggers the click event on a hidden form to import a JSON file.
         */
        StoriesView.prototype.importStory = function () {
            $("#storyImportFile").trigger('click');
        };
        /**
         * Refreshes active story data and assets
         */
        StoriesView.prototype.refreshStory = function (e) {
            if (this.activeChild) {
                var icon = $(e.currentTarget).find('i');
                icon.addClass('fa-spin');
                EventBus.dispatch(Nickel.Debug.LOG, "Refresh Story and Assets");
                this.activeChild.reloadMe(function () {
                    Nickel.AssetProvider.refreshAssets(function () {
                        icon.removeClass('fa-spin');
                        EventBus.dispatch(StoriesView.UPDATE_NODES);
                        EventBus.dispatch(Nickel.Debug.SUCCESS, "Story and Assets Refreshed Successfully");
                    });
                });
            }
        };
        /**
         * Reads the story data from an Imported JSON fiie.
         * @param e    Event passed in from the input
         */
        StoriesView.prototype.getStoryData = function (e) {
            if (e.target.files[0]) {
                var r = new FileReader();
                r.onload = $.proxy(this.saveImportedStory, this);
                r.readAsText(e.target.files[0]);
            }
            else {
                console.log("Failed to load file!");
            }
        };
        /**
         * Reads the story data from an Imported JSON fiie.
         * @param e    Event passed in from the input
         */
        StoriesView.prototype.saveImportedStory = function (e) {
            // Need to replace and re-bind the change event to allow multiple imports!
            $("#storyImportFile").replaceWith('<input id = "storyImportFile" type="file" style="display:none;">')
                .bind('change', $.proxy(this.getStoryData, this));
            // Once we've reset for the next import, save the actual story data
            var story = this.addChild(JSON.parse(e.target.result), 0);
            if (story.data.id !== this.activeChild.data.id) {
                story.firstSave = true;
            }
            story.saveData();
            this.showChild(story.data.id);
        };
        /**
         * Creates a new Story object, and adds it to the children array
         * @param data        The data passed in from the database for this story, if null, the story will create a new
         *     StoryVO.
         * @param index        The index of this story in the children array.
         * @returns        Returns the new Story object.
         */
        StoriesView.prototype.addChild = function (data, index) {
            var story = new Nickel.Story(this.container.find("#storyHolder"), data, index, this);
            this.children[story.data.id] = story;
            return story;
        };
        /**
         * Creates the right interface, and adds all of the stories with the data from the DB. Either shows the first
         * child, or the child saved in the cookie.
         * @param d        The data passed back from the ajax request.
         */
        StoriesView.prototype.gotData = function (d) {
            this.expData = d.stories;
            this.rightInterface = new Nickel.RightInterface(this.container, { "label": "", type: "" }, this);
            var sharedSession = Nickel.Auth.getSharedSession();
            var index;
            var found = false;
            for (var i = 0; i < this.expData.length; i++) {
                var data = this.expData[i];
                if (sharedSession && sharedSession.story_id && sharedSession.story_id == data.id) {
                    index = i;
                    found = true;
                }
            }
            if (!found) {
                index = 0;
            }
            this.addChild($.extend({}, this.expData[index]), index);
            if (sharedSession && sharedSession.story_id && this.children[sharedSession.story_id]) {
                this.showChild(sharedSession.story_id, false);
            }
            else {
                for (var first in this.children)
                    break;
                this.showChild(first, false);
            }
        };
        /**
         * Loops through a story object and extracts the nessassary data for display on node or list interface.
         * @param data    The raw story JSON data.
         * @returns    Returns an object, containing multiple levels of child object, each with a label, ID, children,
         *     and type.
         */
        StoriesView.prototype.getNodeData = function (data) {
            //story
            var root = {};
            root.name = data.label || data.name;
            root.id = data.id;
            root.children = [];
            root.type = "story";
            //act
            for (var aid in data.acts) {
                var actData = data.acts[aid];
                if (actData) {
                    var act = {};
                    act.id = actData.id;
                    act.name = actData.label || actData.name;
                    act.children = [];
                    act.type = "act";
                    //scene
                    for (var sid in actData.scenes) {
                        var sceneData = actData.scenes[sid];
                        if (sceneData) {
                            var scene = {};
                            scene.name = sceneData.label || sceneData.name || 'Scene';
                            scene.id = sceneData.id;
                            scene.children = [];
                            scene.type = "scene";
                            //cut
                            if (sceneData.sceneData) {
                                if (sceneData.sceneData.cuts) {
                                    for (var c = 0; c < sceneData.sceneData.cuts.length; c++) {
                                        var cutData = sceneData.sceneData.cuts[c];
                                        if (cutData && cutData.name != "Generated") {
                                            var cut = {};
                                            cut.name = cutData.label || cutData.name;
                                            cut.id = cutData.id;
                                            cut.type = "cut";
                                            cut.children = [];
                                            //overlay
                                            for (var o = 0; o < cutData.overlays.length; o++) {
                                                var overlayData = cutData.overlays[o];
                                                if (overlayData) {
                                                    var overlay = {};
                                                    overlay.name = Utils.capitalize(overlayData.type);
                                                    overlay.id = overlayData.id;
                                                    overlay.type = "overlay";
                                                    overlay.enabled = overlayData.enabled;
                                                    cut.children.push(overlay);
                                                }
                                            }
                                            scene.children.push(cut);
                                        }
                                    }
                                }
                            }
                            act.children.push(scene);
                        }
                    }
                    //inventory
                    for (var id in actData.inventory) {
                        var inventoryData = actData.inventory[id];
                        var inventory = {};
                        inventory.id = inventoryData.id;
                        inventory.name = inventoryData.label || inventoryData.name;
                        inventory.type = "inventory";
                        act.children.push(inventory);
                    }
                    root.children.push(act);
                }
            }
            return root;
        };
        /**
         * Called when the active item in the story dropdown changes. Shows the new story.
         */
        StoriesView.prototype.storyClicked = function (storyData) {
            if ((this.activeChild && this.activeChild.data.id == storyData.id) || !this.expData) {
                return; // Skip if already displaying the specified story or data not loaded
            }
            var id = storyData.id;
            if (!this.children[id]) {
                for (var i = 0; i < this.expData.length; i++) {
                    var data = this.expData[i];
                    if (data.id == id) {
                        this.addChild($.extend({}, this.expData[i]), i);
                    }
                }
            }
            this.showChild(id, true);
        };
        /**
         * Removed a Story object, and calls the killMe function on it, which removes it from the database.
         */
        StoriesView.prototype.deleteChild = function () {
            //remove the TS controller for the act
            var child = this.children[this.childToDelete];
            //remove the data
            for (var i = 0; i < this.expData.length; i++) {
                var d = this.expData[i];
                if (d.id == this.childToDelete) {
                    Utils.removeFromArray(this.expData, i);
                    break;
                }
            }
            child.deleteMe();
            this.activeChild = null;
            child = null;
            this.updateNodes();
            this.rightInterface.clear();
        };
        /**
         * Shows a specific story, based off of the ID passed in. Updates the node interface to display this new story.
         * Saves the state of the editor.
         * @param id        The unique ID of the story you want to show.
         * @param force        Whether or not to set the active node as this story.
         */
        StoriesView.prototype.showChild = function (id, force) {
            if (force === void 0) { force = false; }
            if (this.activeChild) {
                var oldId = this.activeChild.data.id;
                this.activeChild.killMe();
                this.children[oldId] = null;
            }
            this.activeChild = this.children[id];
            if (this.activeChild) {
                if (force) {
                    Main.activeNode = this.activeChild.data.id;
                }
                Main.storyId = this.activeChild.data.id;
                Main.storyTitle = this.activeChild.data.name;
                Main.s3ResourcesBucket = this.activeChild.resourcesBucket;
                this.updateNodes();
                Main.saveState();
                this.publisher.updateStatus();
                this.title.html("Story: " + this.activeChild.data.name);
                Main.setHeaderStory(this.activeChild.data);
            }
        };
        /**
         * Resizes the story view to fill the entire screen.
         */
        StoriesView.prototype.resize = function () {
            $("#storyView").css('height', window.innerHeight - 52);
            this.container.find('.nodeHolder').css('width', window.innerWidth - Nickel.RightInterface.WIDTH);
            this.container.find('.storyHeader').css('width', window.innerWidth - Nickel.RightInterface.WIDTH);
            if (this.storyInterface) {
                this.storyInterface.resize();
            }
        };
        /**
         * Static String used in the EventBus
         */
        StoriesView.UPDATE_NODES = "updatenodes";
        return StoriesView;
    })(Nickel.View);
    Nickel.StoriesView = StoriesView;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    /**
     * Value Object for defining the structure of the top level Story JSON data.
     */
    var StoryVO = (function (_super) {
        __extends(StoryVO, _super);
        function StoryVO() {
            _super.apply(this, arguments);
            this.label = "New Story";
            this.name = "New Story";
            this.status = "active";
            this.moderationType = "post";
            this.moderationApprovedScene = "";
            this.moderationRejectedScene = "";
            this.metadata = {};
            this.description = "";
            this.acts = {};
            this.displayImage = null;
            this.displayVideoUrls = {};
            this.disabled = false;
            this.discardUgc = false;
            this.useAsTemplate = false;
            this.gaTrackingId = "";
            this.resourcesDataImage = "";
            this.jobAllocationType = "";
        }
        return StoryVO;
    })(Nickel.VO);
    Nickel.StoryVO = StoryVO;
    /**
     * Top Level Node, containing all global information for the story.
     */
    var Story = (function (_super) {
        __extends(Story, _super);
        /**
         * Stores the global vars, loads this Node's DOM view, and creates an new Value Object if needed.
         * @param container        A jQuery object containing the parent div for this view.
         * @param data          The JSON data unique to this node.
         * @param index        The index of this node in the delegate's child array (only applicable if stored in an
         *     array and not an object).
         * @param delegate        The Class that created this instance.
         */
        function Story(container, data, index, delegate) {
            _super.call(this, container, data, index, delegate);
            /**
             * The max character length of a story's JSON data that can be saved to the database.
             * @type {number}
             */
            this.maxJsonLength = 16777215;
            /**
             * An object containing all of the Act child classes. Stored as key/value pairs indexed by the Acts ID.
             */
            this.children = {};
            /**
             * The whitelisted URL's for this story
             */
            this.whitelistedUrls = "*";
            /**
             * The Label for this kind of node to display in the CMS.
             */
            this.label = "Story";
            /**
             * If true, show the video player in the right interface when this view is visible.
             */
            this.videoPlayer = false;
            if (data) {
                this.data = data;
            }
            else {
                this.data = new StoryVO();
                this.firstSave = true;
            }
            //if there is no moderationType set, set as "post"
            if (!this.data.moderationType) {
                this.data.moderationType = "post";
            }
            //set the uploads and resources as globals and remove them from the data to prevent it being saved back
            // into the db.
            if (this.data["resourcesBucket"]) {
                this.resourcesBucket = this.data["resourcesBucket"];
            }
            else {
                this.resourcesBucket = Story.DEFAULT_RESOURCES_BUCKET;
            }
            if (this.data["uploadsBucket"]) {
                this.uploadsBucket = this.data["uploadsBucket"];
            }
            else {
                this.uploadsBucket = Story.DEFAULT_UPLOADS_BUCKET;
            }
            if (this.data["renderedBucket"]) {
                this.renderedBucket = this.data["renderedBucket"];
            }
            else {
                this.renderedBucket = Story.DEFAULT_RENDERED_BUCKET;
            }
            if (this.data["whitelistedUrls"]) {
                this.whitelistedUrls = this.data["whitelistedUrls"];
            }
            delete this.data["whitelistedUrls"];
            delete this.data["resourcesBucket"];
            delete this.data["uploadsBucket"];
            delete this.data["renderedBucket"];
            this.viewLoaded(Main.templates.find(".story").clone());
        }
        /**
         * Binds this.data to the view using rivets. Creates all of the child Acts.
         * @param v        A jQuery object to act as this node's view and to bind its data to.
         */
        Story.prototype.viewLoaded = function (v) {
            _super.prototype.viewLoaded.call(this, v);
            //loop through and create the acts
            var holder = [];
            for (var key in this.data.acts) {
                holder.push($.extend({}, this.data.acts[key]));
            }
            //order the data objects based off of order
            holder.sort(function (a, b) {
                return a.order - b.order;
            });
            //add the children
            for (var i = 0; i < holder.length; i++) {
                this.addChild(this.data.acts[holder[i].id], i);
            }
            var targ = (this.data.moderationType == 'post') ? 'middle' : (this.data.moderationType == 'bypass') ? 'right' : 'left';
            this.content.find('.toggle .' + targ).addClass('selected');
            this.handleModerationSceneDisplay();
            //Bind all of the event listeners for this view
            this.bindEvents();
        };
        /**
         * Binds all of the event listeners for this Class.
         */
        Story.prototype.bindEvents = function () {
            this.content.find(".btnCreateUploadsBucket").bind('click', $.proxy(this.createUploadsBucket, this));
            this.content.find(".btnCreateRenderedBucket").bind('click', $.proxy(this.createRenderedBucket, this));
            this.content.find(".btnCreateResourcesBucket").bind('click', $.proxy(this.createResourcesBucket, this));
            this.content.find(".btnAdd").bind('click', $.proxy(this.addActBtnClicked, this));
            this.content.find(".btnExportStory").bind('click', $.proxy(this.exportMe, this));
            this.content.find('.upload-story-image').bind('change', $.proxy(this.uploadImage, this));
            this.content.find('.btnUploadStoryImage').bind('click', $.proxy(this.uploadImageClicked, this));
            this.content.find('.toggle .option').bind('click', $.proxy(this.moderationToggleClicked, this));
            this.actOrderlist = this.content.find(".actOrderList").sortable({
                "stop": $.proxy(this.actOrderListChange, this)
            });
            EventBus.addEventListener(Story.SAVE_STORY, $.proxy(this.saveData, this), this);
        };
        /**
         * Binds all of the event listeners for this Class.
         */
        Story.prototype.unbindEvents = function () {
            this.content.find(".btnCreateUploadsBucket").unbind('click', $.proxy(this.createUploadsBucket, this));
            this.content.find(".btnCreateRenderedBucket").unbind('click', $.proxy(this.createRenderedBucket, this));
            this.content.find(".btnCreateResourcesBucket").unbind('click', $.proxy(this.createResourcesBucket, this));
            this.content.find(".btnAdd").unbind('click', $.proxy(this.addActBtnClicked, this));
            this.content.find(".btnExportStory").unbind('click', $.proxy(this.exportMe, this));
            this.content.find('.upload-story-image').unbind('change', $.proxy(this.uploadImage, this));
            this.content.find('.btnUploadStoryImage').unbind('click', $.proxy(this.uploadImageClicked, this));
            this.content.find('.toggle .option').unbind('click', $.proxy(this.moderationToggleClicked, this));
            EventBus.removeEventListener(Story.SAVE_STORY, $.proxy(this.saveData, this), this);
        };
        /**
         * Create an S3 bucket for use on the story if it doesn't already exist.
         * @param e    Event passed in from the click.
         * @param createDistribution    Whether or not to create a CloudFront distribution.
         * @param readOnly    Whether or not to grant write permissions on the bucket.
         */
        Story.prototype.createBucket = function (e, createDistribution, readOnly) {
            var button = $(e.currentTarget);
            var bucketInput = button.parent().find('input');
            var bucketName = bucketInput.val().toLowerCase().trim();
            bucketInput.val(bucketName); // force lowercase or else bucket won't be created successfully
            if (bucketName == '') {
                EventBus.dispatch(Nickel.Debug.ERROR, {
                    text: 'S3 Bucket Check Error',
                    error: { status: "Message: ", statusText: 'Bucket Name Cannot Be Empty' }
                });
                return;
            }
            var spinner = Ladda.create(button[0]);
            spinner.start();
            S3FilePicker.bucketExists(bucketName, function (exists) {
                if (!exists) {
                    Nickel.CloudRegionProvider.getRegions(function (regions) {
                        var inputOptions = [];
                        for (var i = 0; i < regions.length; i++) {
                            inputOptions.push({ 'text': regions[i], 'value': regions[i] });
                        }
                        bootbox.prompt({
                            title: 'S3 Bucket "' + bucketName + '" does not exist. Create it?',
                            inputType: 'select',
                            inputOptions: inputOptions,
                            container: '#main-container',
                            callback: function (region) {
                                if (region) {
                                    S3FilePicker.createS3Bucket(bucketName, region, createDistribution, readOnly, function () {
                                        spinner.stop();
                                    });
                                }
                                else {
                                    EventBus.dispatch(Nickel.Debug.SUCCESS, 'S3 Bucket "' + bucketName + '" Creation Cancelled');
                                    spinner.stop();
                                }
                            }
                        });
                    });
                }
                else {
                    EventBus.dispatch(Nickel.Debug.SUCCESS, 'S3 Bucket "' + bucketName + '" Exists');
                    spinner.stop();
                }
            }, function () {
                spinner.stop();
            });
        };
        /**
         * Create an S3 bucket to use for the "uploads" bucket.
         * @param e    Event passed in from the click.
         */
        Story.prototype.createUploadsBucket = function (e) {
            this.createBucket(e, false, false);
        };
        /**
         * Create an S3 bucket to use for the "rendered" bucket.
         * @param e    Event passed in from the click.
         */
        Story.prototype.createRenderedBucket = function (e) {
            this.createBucket(e, true, false);
        };
        /**
         * Create an S3 bucket to use for the "resources" bucket.
         * @param e    Event passed in from the click.
         */
        Story.prototype.createResourcesBucket = function (e) {
            this.createBucket(e, false, true);
        };
        /**
         * Toggles the active moderation type
         * @param e    Event passed in from the click.
         */
        Story.prototype.moderationToggleClicked = function (e) {
            var targ = $(e.currentTarget);
            targ.parent().find('.selected').removeClass('selected');
            targ.addClass('selected');
            this.data.moderationType = targ.attr('data-type');
            this.handleModerationSceneDisplay();
        };
        Story.prototype.handleModerationSceneDisplay = function () {
            if (this.data.moderationType == 'post' || this.data.moderationType == 'bypass') {
                this.content.find('.mod-callback-pre').hide();
            }
            else {
                this.content.find('.mod-callback-pre').show();
            }
        };
        /**
         * Exports this classes this.data obeject to a .json file that the user downloads.
         */
        Story.prototype.exportMe = function () {
            //save the story
            this.saveData();
            var filename = this.data.name + '.json';
            Ajax.get(new JWTAjaxRequest('/story/' + this.data.id, null, function (storyData) {
                saveAs(new Blob([JSON.stringify(storyData)], { type: "text/plain;charset=utf-8" }), filename);
            }));
        };
        /**
         * Creates a FileReader instance to parse the incoming JSON file.
         * @param e    Event passed in from the click.
         */
        Story.prototype.getActData = function (e) {
            if (e.target.files[0]) {
                var r = new FileReader();
                r.onload = $.proxy(this.saveImportedAct, this);
                r.readAsText(e.target.files[0]);
            }
            else {
                console.error("Failed to load file!");
            }
        };
        /**
         * Saves the act that has just been imported. Re-binds the import event listener.
         * @param e    Event passed in from the click.
         */
        Story.prototype.saveImportedAct = function (e) {
            // Need to replace and re-bind the change event to allow multiple imports!
            $("#actImportFile").replaceWith('<input id = "actImportFile" type="file" style="display:none;">');
            $("#actImportFile").bind('change', $.proxy(this.getActData, this));
            // Once we've reset for the next import, save the actual act data
            var act = this.addChild(JSON.parse(e.target.result), 0);
            act.saveData();
            this.showChild(act.data.id);
        };
        /**
         * Uploads an image to the backend using a hidden form.
         * @param e    Event passed in from the click.
         */
        Story.prototype.uploadImage = function (e) {
            var form = $(e.target).parent().parent();
            form.attr('action', AjaxUrlProvider.getLegacyApiBaseUrl() + '/api');
            // Get story ID so upload is sent to the right place
            var storyIdInput = form.find('input[name=story_id]');
            storyIdInput.val(this.data.id);
            Ajax.formSubmit(form, $.proxy(function (response) {
                this.data.displayImage = response.bind.info;
                this.saveData();
            }, this));
        };
        /**
         * Triggers a click on a hidden button to upload an image.
         * @param e    Event passed in from the click.
         */
        Story.prototype.uploadImageClicked = function (e) {
            e.preventDefault();
            this.content.find('.upload-story-image').trigger('click');
        };
        /**
         * Called when a user re-orders the act order list. Saves the new order to the Database.
         * @param e    Event passed in from the click.
         */
        Story.prototype.actOrderListChange = function (e) {
            //update the acts order and save the info to the database
            var _this = this;
            this.content.find('.actOrderList li').each(function (i) {
                var id = $(this).data('id');
                var act = _this.children[id];
                act.data.order = i + 1;
                act.saveData();
            });
        };
        /**
         * Saves this instance data to the Database.
         */
        Story.prototype.saveData = function () {
            var params = this.data;
            params['resourcesBucket'] = this.resourcesBucket;
            params['renderedBucket'] = this.renderedBucket;
            params['uploadsBucket'] = this.uploadsBucket;
            params['whitelistedUrls'] = this.whitelistedUrls;
            var maxCharMsg = 'Story JSON character count exceeds max length of ' + this.maxJsonLength;
            if (JSON.stringify(params).length <= this.maxJsonLength) {
                var route = '/story';
                var method = 'POST';
                if (!this.firstSave) {
                    route = '/story/' + this.data.id;
                    method = 'PATCH';
                }
                Ajax.request(method, new JWTAjaxRequest(route, params, $.proxy(this.dataSaved, this), $.proxy(this.dataSaveFailed, this)));
                EventBus.dispatch(Nickel.StoriesView.UPDATE_NODES);
                EventBus.dispatch(Nickel.Debug.LOG, "Save Story");
                Main.saveState();
            }
            else {
                alert(maxCharMsg);
                console.error(maxCharMsg);
            }
        };
        /**
         * Called if this story's data saves successfully.
         */
        Story.prototype.dataSaved = function (response) {
            if (response.id) {
                if (this.firstSave) {
                    this.firstSave = false;
                }
                EventBus.dispatch(Nickel.Debug.SUCCESS, "Story Saved Successfully");
                EventBus.dispatch(Nickel.StoryPicker.UPDATE_STORIES);
                EventBus.dispatch(Story.SAVED_STORY);
            }
            else {
                EventBus.dispatch(Nickel.Debug.ERROR, {
                    text: 'Story Could Not Be Saved',
                    error: { status: "Message: ", statusText: 'Could Not Parse Response' }
                });
            }
        };
        /**
         * Called if this story's data does not save successfully.
         */
        Story.prototype.dataSaveFailed = function (e) {
            var response = JSON.parse(e.jqXHR.responseText);
            if (response && response.error) {
                var error = response.error;
                var consoleError = 'An Error Occurred';
                // Provide additional info in console if there is talk about the S3 bucket being invalid.
                if (error.toLowerCase().indexOf('s3') != -1 && error.toLowerCase().indexOf('invalid') != -1) {
                    consoleError = 'S3 Bucket Was Invalid';
                    error = 'Please update the Uploads / Rendered Buckets on the story and hit the "Check" button to make sure the bucket specified is reachable by the system.';
                }
                alert(error);
                EventBus.dispatch(Nickel.Debug.ERROR, {
                    text: 'Story Could Not Be Saved',
                    error: { status: "Message: ", statusText: consoleError }
                });
            }
            else {
                EventBus.dispatch(Nickel.Debug.ERROR, {
                    text: 'Story Could Not Be Saved',
                    error: { status: "Message: ", statusText: 'Could Not Parse Error From Response' }
                });
            }
        };
        /**
         * Creates and returns a new Act class.
         * @param data        The data object to build the Act off of.
         * @param index    The index of the Act in the children array.
         * @returns        The new Act class it just created.
         */
        Story.prototype.addChild = function (data, index) {
            var i = (index) ? index : this.children.length;
            var act = new Nickel.Act(this.content.find(".actHolder"), data, i, this);
            this.children[act.data.id] = act;
            return act;
        };
        /**
         * Receives click event from Add Act button.
         */
        Story.prototype.addActBtnClicked = function () {
            this.addAct();
        };
        /**
         * Adds a new act to this Story's children object. Saves to the database. Shows the new Act.
         * @param data Initial data to initialize the act with. Set to <null> to use default value object.
         */
        Story.prototype.addAct = function (data) {
            if (data === void 0) { data = null; }
            var act = this.addChild(data, this.children.length);
            //TODO figure out why this is nessassary. Empty object from VO is being converted to []. GW
            if (this.data.acts instanceof Array) {
                this.data.acts = {};
            }
            this.data.acts[act.data.id] = act.data;
            this.saveData();
            this.showChild(act.data.id);
        };
        /**
         * Duplicates an existing act, changes all scene, cut and overlay IDs, shows that new act. Saves to the
         * Database.
         * @param act
         */
        Story.prototype.duplicateChildItem = function (act) {
            var data = $.extend(true, {}, act.data);
            data.id = Utils.generateUUID();
            var sceneIds = Object.keys(data.scenes);
            for (var i = 0; i < sceneIds.length; i++) {
                // change out scene ids
                var newId = Utils.generateUUID();
                data.scenes[newId] = data.scenes[sceneIds[i]];
                data.scenes[newId].id = newId;
                delete data.scenes[sceneIds[i]];
                // change out cut ids
                if (data.scenes[newId].sceneData.cuts && data.scenes[newId].sceneData.cuts.length > 0) {
                    for (var cutIndex in data.scenes[newId].sceneData.cuts) {
                        data.scenes[newId].sceneData.cuts[cutIndex].id = Utils.generateUUID();
                        // change out overlay ids
                        if (data.scenes[newId].sceneData.cuts[cutIndex].overlays && data.scenes[newId].sceneData.cuts[cutIndex].overlays.length > 0) {
                            for (var overlayIndex in data.scenes[newId].sceneData.cuts[cutIndex].overlays) {
                                data.scenes[newId].sceneData.cuts[cutIndex].overlays[overlayIndex].id = Utils.generateUUID();
                            }
                        }
                    }
                }
            }
            this.addAct(data);
        };
        /**
         * Shows this story, sets the storyID and storyTitle.
         */
        Story.prototype.showMe = function () {
            _super.prototype.showMe.call(this);
            Main.storyId = this.data.id;
            Main.storyTitle = this.data.name;
            this.populateModerationSceneSelects();
        };
        /**
         * Populates the moderation scene select fields with the latest list of api scenes on the story.
         */
        Story.prototype.populateModerationSceneSelects = function () {
            var select = this.content.find('select.sceneItems.api');
            select.empty().append(new Option('', ''));
            for (var actId in this.data.acts) {
                if (this.data.acts.hasOwnProperty(actId)) {
                    for (var sceneId in this.data.acts[actId].scenes) {
                        if (this.data.acts[actId].scenes.hasOwnProperty(sceneId)) {
                            var scene = this.data.acts[actId].scenes[sceneId];
                            if (scene.type.indexOf('Api') != -1) {
                                select.append(new Option(sceneId, sceneId));
                            }
                        }
                    }
                }
            }
            select.filter('.approved').val(this.data.moderationApprovedScene);
            select.filter('.rejected').val(this.data.moderationRejectedScene);
        };
        /**
         * Removes and Kills the selected Act.
         */
        Story.prototype.deleteChild = function () {
            //kill the view
            var child = this.children[this.childToDelete];
            child.killMe();
            child = null;
            this.activeChild = null;
            //remove the data
            delete this.children[this.childToDelete];
            delete this.data.acts[this.childToDelete];
            //save
            this.showMe();
            this.saveData();
        };
        /**
         * Removes this story and tells the StoriesView to clear it from the database.
         */
        Story.prototype.removeMe = function () {
            this.delegate.childToDelete = this.data.id;
            this.delegate.deleteChild();
        };
        /**
         * Removes this story from interface, but leaves it in the database
         */
        Story.prototype.killMe = function () {
            this.unbindEvents();
            _super.prototype.killMe.call(this);
        };
        /**
         * Removes this story from the database.
         */
        Story.prototype.deleteMe = function () {
            Ajax.request('DELETE', new JWTAjaxRequest('/story/' + this.data.id, null, $.proxy(this.storyRemoved, this)));
            this.killMe();
        };
        /**
         * Specifies where the story's data can be found on this level.
         * @param storyData An object containing the story JSON structure.
         */
        Story.prototype.onDataReload = function (storyData) {
            $.extend(true, this.data, storyData);
        };
        /**
         * Update the story dropdown once the story is removed.
         */
        Story.prototype.storyRemoved = function () {
            EventBus.dispatch(Nickel.StoryPicker.UPDATE_STORIES);
        };
        /**
         * Event for telling this story to send its data to the Database.
         */
        Story.SAVE_STORY = "savestory";
        /**
         * Event for telling this story that its data has been successfully written to the Database.
         */
        Story.SAVED_STORY = "savedstory";
        /**
         * The Uploads s3 bucket to use if none is set.
         */
        Story.DEFAULT_UPLOADS_BUCKET = "imposium-dev-uploads";
        /**
         * The Rendered s3 bucket to use if none is set.
         */
        Story.DEFAULT_RENDERED_BUCKET = "imposium-dev-renders";
        /**
         * The Resources s3 bucket to use if none is set.
         */
        Story.DEFAULT_RESOURCES_BUCKET = "imposium-resources";
        return Story;
    })(Nickel.Level);
    Nickel.Story = Story;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    var NodeTree = (function (_super) {
        __extends(NodeTree, _super);
        function NodeTree(container, data, delegate) {
            _super.call(this, container, data, delegate);
            this.selected = false;
            this.duration = 750;
            this.treeData = data;
            EventBus.addEventListener(NodeTree.SELECT_NODE, $.proxy(this.selectNode, this), this);
            $(window).bind('resize', $.proxy(this.resize, this));
            this.init();
        }
        NodeTree.prototype.resize = function () {
            var _this = this;
            clearTimeout(this.resizeTimeout);
            this.resizeTimeout = setTimeout(function () {
                _this.killMe();
                _this.init();
                _this.update(_this.treeData);
            }, 100);
        };
        NodeTree.prototype.selectNode = function (data) {
            this.activeId = data.id;
            var node = this.svg.selectAll(".node");
            var scope = this;
            //reset all nodes
            node.attr('class', 'node');
            this.svg.selectAll('.node')
                .each(function (d) {
                //add the selected class to the one node with the correct ID
                if (d.id == data.id) {
                    var selectedNode = d3.select(this);
                    // scope.centerNode(d);
                    var g = $(selectedNode[0][0]).context;
                    $(g).attr('class', 'node selected');
                }
            });
        };
        NodeTree.prototype.setData = function (data) {
            this.treeData = data;
            this.update(this.treeData);
            if (Main.activeNode) {
                EventBus.dispatch(Nickel.Level.SHOW_DETAILS, { id: Main.activeNode });
            }
        };
        NodeTree.prototype.init = function () {
            var pad = 200;
            this.width = this.container.width();
            this.height = this.container.height() - 100;
            // this.infoBox = new Nickel.InfoBox($('#storyView'), {}, this);
            this.zoomListener = d3.behavior.zoom().scaleExtent([0.5, 2]);
            // this.zoomListener = d3.behavior.zoom().scaleExtent([0.5, 2]).on("zoom", $.proxy(this.zoom, this));
            this.cluster = d3.layout.tree()
                .size([this.height - pad, this.width - pad]);
            this.diagonal = d3.svg.diagonal()
                .projection(function (d) { return [d.y, d.x]; });
            this.svg = d3.select(this.container[0]).append("svg")
                .attr("width", this.width)
                .attr("height", this.height)
                .call(this.zoomListener);
            //.attr("transform", "translate(40,0)");
            this.svgGroup = this.svg.append("g");
            this.svgGroup.attr('transform', 'translate(100, 100)');
        };
        NodeTree.prototype.killMe = function () {
            _super.prototype.killMe.call(this);
            d3.select(".nodeHolder svg")
                .remove();
            // this.container.empty();
        };
        NodeTree.prototype.getColorFromType = function (type) {
            var color;
            switch (type) {
                case "story":
                    color = "#b35d2a";
                    break;
                case "act":
                    color = "#e4c038";
                    break;
                case "scene":
                    color = "#6f0808";
                    break;
                case "cut":
                    color = "#54846a";
                    break;
                case "overlay":
                    color = "#2ab3ab";
                    break;
                case "inventory":
                    color = "#75369c";
                    break;
            }
            return color;
        };
        NodeTree.prototype.update = function (source) {
            var scope = this;
            var duration = 750;
            var nodes = this.cluster.nodes(this.treeData), links = this.cluster.links(nodes);
            var link = this.svgGroup.selectAll(".link")
                .data(links)
                .enter().append("path")
                .attr("class", "link")
                .attr("d", this.diagonal);
            var node = this.svgGroup.selectAll(".node")
                .data(nodes)
                .enter().append("g")
                .attr("class", "node")
                .attr("transform", function (d) { return "translate(" + d.y + "," + d.x + ")"; })
                .each(function (d) {
                var currentNode = d3.select(this);
                d.cls = 'item-' + d.type;
                var template = new Nickel.Component(null, d, this);
                template.viewLoaded(Main.templates.find('.node_item').clone());
                if ("enabled" in d) {
                    if (d.enabled == false) {
                        template.content.addClass('disabled');
                    }
                }
                var markup = template.content[0].outerHTML;
                currentNode.append("foreignObject")
                    .attr("x", -60)
                    .attr("y", -20)
                    .attr("width", 120)
                    .attr("height", 40)
                    .append("xhtml:body")
                    .html(markup);
                // .style("font", "14px 'Helvetica Neue'")
            });
            node.on('click', function (d, i) {
                EventBus.dispatch(Nickel.Level.SHOW_DETAILS, { id: d.id });
            });
            // On node hover, examine the links to see if their
            // source or target properties match the hovered node.
            node.on('mouseover', function (d) {
                link.style('stroke-width', function (l) {
                    if (d === l.source || d === l.target) {
                        return 4;
                    }
                    else {
                        return 2;
                    }
                });
                var selectedNode = d3.select(this);
                scope.nodeData = d;
                //scope.findEmptySpace(selectedNode);
            });
            // Set the stroke width back to normal when mouse leaves the node.
            node.on('mouseout', function () {
                // scope.infoBox.hideMe();
                link.style('stroke-width', 2);
            });
            this.svg.selectAll(".node")
                .each(function (d) {
                var currentNode = d3.select(this);
                //var offset = -40;
                //if(currentNode.data()[0].type == 'overlay' || currentNode.data()[0].type == 'inventory'){
                //    offset = -28;
                //}
                var offset = -28;
                // currentNode
                // currentNode.append('text')
                //     .attr("dy", offset)
                //     .attr('fill', "#ccc")
                //     .attr('font-family', "Arial, helvetica, sans-serif")
                //     .attr('font-size', "14px")
                //     .text(function(d) { return d.name; });
                // var textWidth = $(currentNode[0][0]).find('text').width();
                // $(currentNode[0][0]).find('text').attr('dx', -(textWidth/2));
            });
            this.selectNode({ "id": this.activeId });
        };
        NodeTree.prototype.zoom = function () {
            this.svgGroup.attr("transform", "translate(" + d3.event.translate + ")scale(" + d3.event.scale + ")");
        };
        NodeTree.prototype.centerNode = function (source) {
            var scale = this.zoomListener.scale();
            var x = -source.y;
            var y = -source.x;
            x = x * scale + this.width / 2;
            y = y * scale + this.height / 2;
            this.svgGroup.attr("transform", "translate(" + x + "," + y + ")scale(" + scale + ")");
            //TODO: animate this fucker! GW            
            //this.svgGroup.transition()
            //.duration(this.duration)
            //.attr("transform", "translate(" + x + "," + y + ")scale(" + scale + ")");
            this.zoomListener.scale(scale);
            this.zoomListener.translate([x, y]);
        };
        //Look to see if there is space free around the node
        NodeTree.prototype.findEmptySpace = function (selectedNode) {
            var offset = 155;
            var emptySpaces = {
                'top': true,
                'bottom': true,
                'left': true,
                'right': true
            };
            var selectedNodePosition = $(selectedNode[0][0]).position();
            var node = this.svg.selectAll(".node");
            for (var i = 0; i < node[0].length; i++) {
                var compareNode = $(node[0][i]).position();
                if (compareNode.top == selectedNodePosition.top) {
                    if (compareNode.left > selectedNodePosition.left && selectedNodePosition.left + offset > compareNode.left) {
                        emptySpaces.right = false;
                    }
                    if (compareNode.left < selectedNodePosition.left && selectedNodePosition.left - offset < compareNode.left) {
                        emptySpaces.left = false;
                    }
                }
                if (compareNode.left == selectedNodePosition.left) {
                    if (compareNode.top > selectedNodePosition.top && selectedNodePosition.top + offset > compareNode.top) {
                        emptySpaces.bottom = false;
                    }
                    if (compareNode.top < selectedNodePosition.top && selectedNodePosition.top - offset < compareNode.top) {
                        emptySpaces.top = false;
                    }
                }
                if (!emptySpaces.right && !emptySpaces.left && !emptySpaces.bottom && !emptySpaces.top) {
                    emptySpaces.top = true;
                    break;
                }
            }
            this.setPositionOfInfoBox(emptySpaces, selectedNodePosition);
        };
        //Set the position of the Infobox off the spaces available
        NodeTree.prototype.setPositionOfInfoBox = function (emptySpaces, selectedNodePosition) {
            var offset = {
                'right': 105,
                'left': 215,
                'top': 105,
                'bottom': 65
            };
            var nodeHolder = $('.nodeHolder');
            var popUpBox = $('.infoBox');
            var nPosition = { 'top': selectedNodePosition.top, 'left': selectedNodePosition.left };
            if (emptySpaces.top && nPosition.top - popUpBox.height() > nodeHolder.position().top) {
                nPosition.top = nPosition.top - offset.top;
            }
            else {
                if (emptySpaces.right) {
                    nPosition.left = nPosition.left + offset.right;
                }
                else {
                    if (emptySpaces.left) {
                        nPosition.left = nPosition.left - offset.left;
                    }
                    else {
                        if (emptySpaces.bottom) {
                            nPosition.top = nPosition.top + offset.bottom;
                        }
                    }
                }
            }
            this.showInfoBox(nPosition);
        };
        NodeTree.prototype.toggleChildren = function (d) {
            if (d.children) {
                d._children = d.children;
                d.children = null;
            }
            else {
                if (d._children) {
                    d.children = d._children;
                    d._children = null;
                }
            }
            return d;
        };
        // Toggle children on click.
        NodeTree.prototype.click = function (d) {
            if (d3.event.defaultPrevented) {
                return;
            } // click suppressed
            d = this.toggleChildren(d);
            this.update(d);
        };
        NodeTree.prototype.collapse = function (d) {
            if (d.children) {
                d._children = d.children;
                d._children.forEach(this.collapse);
                d.children = null;
            }
        };
        NodeTree.prototype.showInfoBox = function (position) {
            this.infoBox.updateMe(this.nodeData);
            this.infoBox.setPosition(position);
            this.infoBox.showMe();
        };
        NodeTree.SELECT_NODE = "selectnode";
        return NodeTree;
    })(Nickel.Component);
    Nickel.NodeTree = NodeTree;
})(Nickel || (Nickel = {}));
/**
 * Created by webbergreg on 15-03-21.
 */
var Nickel;
(function (Nickel) {
    var RightInterface = (function (_super) {
        __extends(RightInterface, _super);
        function RightInterface(container, data, delegate) {
            _super.call(this, container, data, delegate);
            this.viewLoaded(Main.templates.find('.rightInterface').clone());
            EventBus.addEventListener(RightInterface.SHOW_IN_INTERFACE, $.proxy(this.showView, this), this);
            this.title = this.content.find('.title');
            this.videoPlayer = new Nickel.VideoPlayer(this.content.find('.video'), {}, this);
            this.videoPlayer.showMe();
            this.btnDuplicate = this.content.find('.btnDuplicate').bind('click', $.proxy(this.duplicateView, this));
            this.btnDelete = this.content.find('.btnRemove').bind('click', $.proxy(this.removeView, this));
            this.btnExport = this.content.find('.btnExport').bind('click', $.proxy(this.exportView, this));
            this.viewHolder = this.content.find('.viewHolder');
            this.panel = new Nickel.DynamicPanel(this.content, {
                'handleX': 'left',
                'handleY': 'center',
                'minWidth': 450,
                'handleStyle': 'vertical'
            });
            this.panel.on(Nickel.DynamicPanel.RESIZE, $.proxy(this.resize, this));
            this.resize();
        }
        RightInterface.prototype.exportView = function () {
            if (this.activeInterface) {
                this.activeInterface.exportMe();
            }
        };
        RightInterface.prototype.duplicateView = function () {
            if (this.activeInterface) {
                this.activeInterface.duplicateMe();
            }
        };
        RightInterface.prototype.resize = function () {
            var _this = this;
            RightInterface.WIDTH = this.panel.currentWidth;
            EventBus.dispatchEvent(RightInterface.RESIZE_INTERFACE);
            this.videoPlayer.resize();
            if (this.activeInterface) {
                //if the interface can resize, resize it
                if (typeof this.activeInterface.resize == 'function') {
                    setTimeout(function () {
                        _this.activeInterface.resize();
                    }, 50);
                }
            }
        };
        RightInterface.prototype.removeView = function () {
            var r = confirm("Are you sure you want to remove " + this.data.label + "?\nThis action cannot be undone.");
            if (r == true) {
                if (this.activeInterface) {
                    this.activeInterface.removeMe();
                }
            }
            else {
            }
        };
        RightInterface.prototype.showView = function (data) {
            //remove the old active interface
            if (this.activeInterface) {
                if (this.activeInterface.content) {
                    this.activeInterface.content.detach();
                }
            }
            //set the new active interface
            this.activeInterface = data.view;
            //if this class doesn't need a video player, hide it
            if (!this.activeInterface.videoPlayer) {
                this.videoPlayer.hideMe();
                this.videoPlayer.clearVideo();
            }
            //add the active interface
            this.viewHolder.append(data.view.content);
            //set the labels
            this.data.label = data.view.data.name;
            this.data.type = data.type;
            //update the dropdown to show releavent function calls
            this.updateDropdown();
            this.resize();
        };
        RightInterface.prototype.clear = function () {
            if (this.activeInterface) {
                if (this.activeInterface.content) {
                    this.activeInterface.content.detach();
                }
            }
            this.activeInterface = null;
            this.videoPlayer.hideMe();
            this.videoPlayer.clearVideo();
            this.data.label = "";
            this.data.type = "";
            this.updateDropdown();
        };
        RightInterface.prototype.updateDropdown = function () {
            if (this.activeInterface) {
                if (typeof this.activeInterface.exportMe == 'function') {
                    this.btnExport.show();
                }
                else {
                    this.btnExport.hide();
                }
                if (this.activeInterface.delegate && typeof this.activeInterface.delegate.duplicateChildItem == 'function' && typeof this.activeInterface.duplicateMe == 'function') {
                    this.btnDuplicate.show();
                }
                else {
                    this.btnDuplicate.hide();
                }
            }
            else {
                this.btnDuplicate.hide();
                this.btnExport.hide();
            }
        };
        RightInterface.SHOW_IN_INTERFACE = "showininterface";
        RightInterface.RESIZE_INTERFACE = "resizerightinterface";
        RightInterface.WIDTH = 500;
        return RightInterface;
    })(Nickel.Component);
    Nickel.RightInterface = RightInterface;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    var VideoPlayer = (function (_super) {
        __extends(VideoPlayer, _super);
        function VideoPlayer(container, data, delegate) {
            _super.call(this, container, data, delegate);
            this.vidWidth = 1920;
            this.vidHeight = 1080;
            this.activeSrc = "";
            this.viewLoaded(Main.templates.find('.videoPlayer').clone());
            this.$videoNode = this.content.find('.mainVideo');
            this.videoNode = this.$videoNode[0];
            this.$videoNode.on('ended', $.proxy(this.vidOver, this));
            EventBus.addEventListener(VideoPlayer.LOAD_VIDEO, $.proxy(this.loadVideo, this), this);
            EventBus.addEventListener(VideoPlayer.CLEAR_VIDEO, $.proxy(this.clearVideo, this), this);
        }
        VideoPlayer.prototype.vidOver = function () {
        };
        VideoPlayer.prototype.clearVideo = function () {
            this.activeSrc = null;
            this.hideVideo();
            this.videoNode.src = "";
            this.videoNode.load();
        };
        VideoPlayer.prototype.loadVideo = function (data) {
            if (data.src != this.activeSrc) {
                this.activeSrc = data.src;
                this.$videoNode.one('canplaythrough', $.proxy(this.videoReady, this));
                this.videoNode.src = data.src;
                this.videoNode.load();
            }
            if (!this.onStage) {
                this.showMe();
            }
        };
        VideoPlayer.prototype.videoReady = function () {
            this.showMe();
            this.showVideo();
            //this.playVideo();
        };
        VideoPlayer.prototype.playVideo = function () {
            this.videoNode.play();
        };
        VideoPlayer.prototype.showVideo = function () {
            this.$videoNode.show();
        };
        VideoPlayer.prototype.hideVideo = function () {
            this.videoNode.pause();
            this.$videoNode.hide();
        };
        VideoPlayer.prototype.showLoader = function () {
            this.hideVideo();
        };
        VideoPlayer.prototype.hideMe = function () {
            _super.prototype.hideMe.call(this);
            this.videoNode.pause();
        };
        VideoPlayer.prototype.resize = function () {
            this.aspectRatio = this.vidWidth / this.vidHeight;
            var w = this.container.width() - 20;
            this.content.css({
                'width': w,
                'height': w / this.aspectRatio
            });
        };
        VideoPlayer.LOAD_VIDEO = "loadvideo";
        VideoPlayer.CLEAR_VIDEO = "clearvideo";
        return VideoPlayer;
    })(Nickel.Component);
    Nickel.VideoPlayer = VideoPlayer;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    var StoryPublisher = (function (_super) {
        __extends(StoryPublisher, _super);
        function StoryPublisher(container, data, delegate) {
            _super.call(this, container, data, delegate);
            this.polling = false;
            this.viewLoaded(Main.templates.find('.publishing-module').clone());
        }
        StoryPublisher.prototype.updateStatus = function () {
            if (!this.polling) {
                this.getStatus($.proxy(function (response) {
                    // noinspection JSPotentiallyInvalidUsageOfClassThis
                    this.handleStatus(response);
                    if (response && response.publishing) {
                        // noinspection JSPotentiallyInvalidUsageOfClassThis
                        this.pollCallback();
                    }
                }, this));
            }
            else {
            }
        };
        StoryPublisher.prototype.viewLoaded = function (v) {
            _super.prototype.viewLoaded.call(this, v);
            this.publishBtn = this.content.find('.btnPublish');
            this.loader = new Loader(this.content.find('.spinner'));
            this.status = this.content.find('.status');
            this.messageInput = this.content.find('input');
            this.publishBtn.bind('click', $.proxy(this.publishStory, this));
        };
        StoryPublisher.prototype.publishStory = function () {
            var story = this.delegate.activeChild;
            if (story.uploadsBucket == Nickel.Story.DEFAULT_UPLOADS_BUCKET) {
                alert("Please create a unique uploads bucket for this story. You cannot publish a story with the default dev bucket.");
                return;
            }
            if (story.renderedBucket == Nickel.Story.DEFAULT_RENDERED_BUCKET) {
                alert("Please create a unique rendered bucket for this story. You cannot publish a story with the default dev bucket.");
                return;
            }
            if (story.data.acts && Object.keys(story.data.acts).length > 0) {
                for (var actId in story.data.acts) {
                    var actData = story.data.acts[actId];
                    if (actData.scenes && Object.keys(actData.scenes).length > 0) {
                        for (var sceneId in actData.scenes) {
                            var sceneData = actData.scenes[sceneId];
                            if (!Nickel[sceneData.type].validatePublishAction(sceneData)) {
                                return;
                            }
                        }
                    }
                    else {
                        alert(actData.name + ' does not have a scene. Please add a scene or delete the act before publishing.');
                        return;
                    }
                }
            }
            else {
                alert('Please add at least one act before publishing.');
                return;
            }
            EventBus.dispatch(Nickel.Debug.LOG, "Publish Story");
            Ajax.post(new JWTAjaxRequest('/job/publish', {
                'story_id': Main.storyId,
                'message': this.messageInput.val().trim()
            }, $.proxy(function (response) {
                if (response.error) {
                    EventBus.dispatch(Nickel.Debug.ERROR, {
                        text: 'Publish Story Error',
                        error: { status: "Failure: ", statusText: response.error }
                    });
                }
                else if (response.id) {
                    // noinspection JSPotentiallyInvalidUsageOfClassThis
                    this.jobId = response.id;
                    // noinspection JSPotentiallyInvalidUsageOfClassThis
                    this.getStatus($.proxy(function (version) {
                        // noinspection JSPotentiallyInvalidUsageOfClassThis
                        this.handleStatus(version);
                        // noinspection JSPotentiallyInvalidUsageOfClassThis
                        this.pollCallback();
                    }, this));
                }
            }, this)));
        };
        StoryPublisher.prototype.pollCallback = function () {
            this.polling = true;
            this.getStatus($.proxy(this.checkIfPublished, this));
        };
        StoryPublisher.prototype.checkIfPublished = function (response) {
            if (response) {
                if (response.version && !response.publishing) {
                    this.handleStatus(response);
                }
                else {
                    setTimeout($.proxy(this.pollCallback, this), 2500);
                }
            }
            else {
                setTimeout($.proxy(this.pollCallback, this), 2500);
            }
        };
        StoryPublisher.prototype.getStatus = function (callback) {
            Ajax.get(new JWTAjaxRequest('/story/' + Main.storyId + '/version', null, callback));
        };
        StoryPublisher.prototype.handleStatus = function (data) {
            if (data) {
                // Found an archive
                if (data.publishing) {
                    if (this.polling) {
                        EventBus.dispatch(Nickel.Debug.LOG, "Story Is Publishing");
                    }
                    // Is currently publishing
                    this.showAsPublishing(data.version, data.message);
                }
                else {
                    // Is published
                    if (this.polling) {
                        EventBus.dispatch(Nickel.Debug.SUCCESS, "Story Published Successfully");
                        if (this.jobId) {
                            Nickel.JobHandler.outputLogInConsole(this.jobId);
                            this.jobId = null;
                        }
                    }
                    this.showAsPublished(data.version, data.message);
                }
            }
            else {
                // No archive found
                this.showAsWorkingCopy();
            }
            this.polling = false;
        };
        StoryPublisher.prototype.disableInput = function () {
            this.publishBtn.attr('disabled', 'disabled');
            this.messageInput.attr('disabled', 'disabled');
        };
        StoryPublisher.prototype.enableInput = function () {
            this.publishBtn.removeAttr('disabled');
            this.messageInput.removeAttr('disabled');
        };
        StoryPublisher.prototype.showAsPublishing = function (version, msg) {
            this.messageInput.val(msg);
            this.disableInput();
            this.loader.show();
            this.status.html('Publishing v' + version + '...');
        };
        StoryPublisher.prototype.showAsPublished = function (version, msg) {
            this.messageInput.val('');
            this.enableInput();
            this.loader.hide();
            this.status.html('Version ' + version + ' published.');
        };
        StoryPublisher.prototype.showAsWorkingCopy = function () {
            this.enableInput();
            this.loader.hide();
            this.status.html('Using working copy.');
        };
        return StoryPublisher;
    })(Nickel.Component);
    Nickel.StoryPublisher = StoryPublisher;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    var CutPickerVO = (function () {
        function CutPickerVO() {
            this.inFrame = 0;
            this.outFrame = 0;
            this.currentFrame = 0;
            this.inTime = 0;
            this.outTime = 0;
            this.currentTime = 0.00001;
            this.duration = 0;
        }
        return CutPickerVO;
    })();
    Nickel.CutPickerVO = CutPickerVO;
    var CutPicker = (function (_super) {
        __extends(CutPicker, _super);
        function CutPicker(container, data, id) {
            _super.call(this, container, data, id);
            this.videoFrame = null;
            //public videoNode:any;
            this.video = null;
            this.image = null;
            this.playHeadWidth = 1;
            this.editing = false;
            this.open = false;
            this.multipleVideos = false;
            this.data = (data) ? data : new CutPickerVO();
            this.viewLoaded(Main.templates.find('.cutPicker').clone());
            $(document).on('keydown', $.proxy(this.checkKeys, this));
        }
        CutPicker.prototype.useImageBasedSeek = function (shouldUse) {
            if (shouldUse === void 0) { shouldUse = true; }
            if (shouldUse) {
                this.image = this.content.find('#cut-picker-img');
                this.content.find('#cut-picker-video').addClass('inactive');
                this.content.find('#cut-picker-img').addClass('active');
                this.btnPlay.hide();
            }
            else {
                this.image = null;
                this.content.find('#cut-picker-video').removeClass('inactive');
                this.content.find('#cut-picker-img').removeClass('active');
                this.btnPlay.show();
            }
        };
        CutPicker.prototype.loadFrameImage = function (index) {
            if (index === void 0) { index = 0; }
            this.image.attr('src', this.videoFile.frames.replace('%d', index + 1)); // +1 because frames are cut 1-based
            this.data.currentFrame = index;
        };
        CutPicker.prototype.setData = function (data) {
            this.data = $.extend(this.data, data);
        };
        CutPicker.prototype.viewLoaded = function (v) {
            _super.prototype.viewLoaded.call(this, v);
            this.playHead = this.content.find('.playHead');
            this.playHead.draggable({
                "containment": "parent",
                "drag": $.proxy(this.onPlayheadDrag, this),
                "stop": $.proxy(this.onPlayheadStop, this)
            });
            this.inPoint = this.content.find('.in');
            this.outPoint = this.content.find('.out');
            this.content.find('.vidPicker select').bind('change', $.proxy(this.changeVideo, this));
            this.btnPlay = this.content.find('.btnPlay').bind('click', $.proxy(this.playVideo, this));
            this.btnPause = this.content.find('.btnPause').bind('click', $.proxy(this.pauseVideo, this));
            this.btnIn = this.content.find('.btnIn').bind('click', $.proxy(this.setInFrame, this));
            this.btnOut = this.content.find('.btnOut').bind('click', $.proxy(this.setOutFrame, this));
            this.btnCreate = this.content.find('.btnCreate').bind('click', $.proxy(this.createCut, this));
            this.btnClose = this.content.find('.btnClose').bind('click', $.proxy(this.killMe, this));
            this.btnSave = this.content.find('.btnSave').bind('click', $.proxy(this.saveCut, this));
            this.loader = new Loader(this.content.find('.cut-picker-loader'));
            $(window).bind('resize', $.proxy(this.resize, this));
        };
        CutPicker.prototype.resize = function () {
            this.timelineWidth = this.content.find('.timeline').width() - this.playHeadWidth;
            var w = 800;
            var maxH = 440;
            var pad = 20;
            var r = this.videoFile.width / this.videoFile.height;
            var vWidth = w - (pad * 2);
            var vHeight = vWidth / r;
            if (vHeight > maxH) {
                vHeight = maxH;
                vWidth = vHeight * r;
            }
            this.content.find('.cut-picker-element').css({
                'width': vWidth,
                'height': vHeight
            });
        };
        CutPicker.prototype.onPlayheadDrag = function (e) {
            var perc = (e.target.offsetLeft + this.playHeadWidth) / this.timelineWidth;
            this.updateFrameBasedOnPercent(perc);
        };
        CutPicker.prototype.onPlayheadStop = function (e) {
            var perc = e.target.offsetLeft / this.timelineWidth;
            this.updateFrameBasedOnPercent(perc);
        };
        CutPicker.prototype.removeOutPoint = function () {
            this.data.outFrame = 0;
            this.data.outTime = 0;
            this.outPoint.hide();
        };
        CutPicker.prototype.removeInPoint = function () {
            this.data.inFrame = 0;
            this.data.inTime = 0;
            this.inPoint.hide();
        };
        CutPicker.prototype.checkPoints = function (justChanged) {
            if (justChanged == 'in' && this.data.inTime > this.data.outTime) {
                this.removeOutPoint();
            }
            else {
                if (justChanged == 'out' && this.data.outTime < this.data.inTime) {
                    this.removeInPoint();
                }
            }
        };
        CutPicker.prototype.metaDataLoaded = function (video) {
            var dFallback = (video) ? video.duration : 0;
            this.data.duration = (this.videoFile.duration) ? this.videoFile.duration : dFallback;
            this.data.frames = (this.videoFile.totalFrames) ? this.videoFile.totalFrames : Math.ceil(this.videoFile.duration * this.videoFile.rate);
            this.maxFrame = this.data.frames - 1;
            if (video) {
                this.videoFrame = new VideoFrame({ 'id': this.video.id, 'frameRate': this.videoFile.rate });
            }
            this.showMe();
        };
        CutPicker.prototype.createCut = function () {
            if (this.data.inFrame == this.data.outFrame) {
                alert("In frame and Out frame can't be the same");
            }
            else {
                if (this.data.inFrame > this.data.outFrame) {
                    alert("In frame can't be higher than Out frame");
                }
                else {
                    this.delegate.addCut(this.data, false);
                    this.delegate.updateChildTags();
                    this.killMe();
                }
            }
        };
        CutPicker.prototype.saveCut = function () {
            this.delegate.saveCut(this.data);
            if (typeof this.delegate.updateChildTags === 'function') {
                this.delegate.updateChildTags();
            }
            this.killMe();
        };
        CutPicker.prototype.resetData = function () {
            this.data.inTime = 0;
            this.data.outTime = 0;
            this.data.currentTime = 0;
            this.data.duration = 0;
            this.data.currentFrame = 0;
            this.data.inFrame = 0;
            this.data.outFrame = 0;
            this.updatePlayhead();
            this.updateInPoint();
            this.updateOutPoint();
        };
        CutPicker.prototype.changeVideo = function (e) {
            var select = $(e.target);
            var videoOptions = this.delegate.data.sceneData.videoFiles;
            this.loadChosenVideo(videoOptions[select.val()]);
        };
        CutPicker.prototype.loadChosenVideo = function (opt) {
            this.pauseVideo();
            this.loadVideo(opt);
            this.resetData();
        };
        CutPicker.prototype.loadVideoOptions = function () {
            var optHtml = '';
            var select = this.content.find('.vidPicker select');
            this.content.find('.vidPicker').show();
            var selectedOptId = null;
            var videoOptions = this.delegate.data.sceneData.videoFiles;
            for (var id in videoOptions) {
                var opt = videoOptions[id];
                optHtml += '<option value="' + id + '">' + opt.name + '</option>';
                if (this.videoFile.name == opt.name) {
                    selectedOptId = id;
                }
            }
            select.html(optHtml);
            var sorted = select.find('option').sort(function (a, b) { return a.innerHTML.toUpperCase().localeCompare(b.innerHTML.toUpperCase()); });
            select.html(sorted);
            if (selectedOptId) {
                select.val(selectedOptId);
            }
            else {
                this.loadChosenVideo(videoOptions[select.val()]);
            }
        };
        CutPicker.prototype.loadVideo = function (src, imageBased) {
            if (imageBased === void 0) { imageBased = null; }
            this.videoFile = src;
            Main.cutRate = src.rate;
            if (this.multipleVideos) {
                this.delegate.data.sceneData.videoFile = this.videoFile;
                this.loadVideoOptions();
            }
            if (imageBased) {
                this.image = this.content.find('#cut-picker-img');
            }
            this.updateVideo();
        };
        CutPicker.prototype.showMe = function () {
            _super.prototype.showMe.call(this);
            this.resize();
            this.updatePlayhead();
            this.updateInPoint();
            this.updateOutPoint();
            if (this.editing) {
                this.btnCreate.hide();
                this.btnSave.show();
            }
            else {
                this.btnCreate.show();
                this.btnSave.hide();
            }
            this.open = true;
        };
        CutPicker.prototype.hideMe = function () {
            _super.prototype.hideMe.call(this);
            this.pauseVideo();
            this.editing = false;
            this.open = false;
        };
        CutPicker.prototype.checkKeys = function (e) {
            if (this.open) {
                switch (e.which) {
                    case 37:
                        var amt = (e.shiftKey) ? 10 : 1;
                        if (this.data.currentFrame > 0) {
                            this.seekBackward(amt);
                        }
                        break;
                    case 39:
                        var amt = (e.shiftKey) ? 10 : 1;
                        if (this.data.currentFrame < this.maxFrame) {
                            this.seekForward(amt);
                        }
                        break;
                    case 73:
                        this.setInFrame();
                        break;
                    case 79:
                        this.setOutFrame();
                        break;
                    default:
                        return; // exit this handler for other keys
                }
                e.preventDefault();
            }
        };
        CutPicker.prototype.removeKeyboardListeners = function () {
        };
        CutPicker.prototype.setInFrame = function () {
            this.data.inFrame = this.data.currentFrame;
            this.data.inTime = this.data.currentTime;
            this.updateInPoint();
        };
        CutPicker.prototype.updateInPoint = function () {
            var perc = this.data.inTime / this.data.duration;
            var l = perc * this.timelineWidth;
            this.inPoint.css('left', l);
            this.inPoint.show();
            this.checkPoints('in');
        };
        CutPicker.prototype.updateOutPoint = function () {
            var perc = this.data.outTime / this.data.duration;
            var l = perc * this.timelineWidth;
            this.outPoint.css('left', l);
            this.outPoint.show();
            this.checkPoints('out');
        };
        CutPicker.prototype.setOutFrame = function () {
            this.data.outFrame = this.data.currentFrame;
            this.data.outTime = this.data.currentTime;
            this.updateOutPoint();
        };
        CutPicker.prototype.updateFrameBasedOnPercent = function (perc) {
            var frame = Math.round(this.maxFrame * perc);
            if (this.image) {
                this.loadFrameImage(frame);
            }
            else {
                this.videoFrame.seekTo({ 'frame': frame });
            }
            this.updateTime();
        };
        CutPicker.prototype.updateTime = function () {
            if (this.image) {
                this.data.currentTime = this.data.currentFrame / this.videoFile.rate;
            }
            else {
                this.data.currentTime = this.video.currentTime;
                this.data.currentFrame = this.videoFrame.get();
            }
            this.updatePlayhead();
        };
        CutPicker.prototype.seekForward = function (amt) {
            if (amt === void 0) { amt = 1; }
            if (this.image) {
                this.loadFrameImage(this.data.currentFrame + amt);
                this.updateTime();
            }
            else {
                this.videoFrame.seekForward(amt, $.proxy(this.updateTime, this));
            }
        };
        CutPicker.prototype.seekBackward = function (amt) {
            if (amt === void 0) { amt = 1; }
            if (this.image) {
                this.loadFrameImage(this.data.currentFrame - amt);
                this.updateTime();
            }
            else {
                this.videoFrame.seekBackward(amt, $.proxy(this.updateTime, this));
            }
        };
        CutPicker.prototype.updatePlayhead = function () {
            var perc = this.data.currentFrame / this.maxFrame;
            var x = this.timelineWidth * perc;
            this.playHead.css('left', x);
        };
        CutPicker.prototype.playVideo = function () {
            this.btnPlay.hide();
            this.btnPause.show();
            this.video.play();
            clearInterval(this.updateInt);
            this.updateInt = setInterval($.proxy(this.updateTime, this), this.videoFile.rate);
        };
        CutPicker.prototype.pauseVideo = function () {
            clearInterval(this.updateInt);
            this.btnPlay.show();
            this.btnPause.hide();
            this.video.pause();
        };
        CutPicker.prototype.updateVideo = function () {
            if (this.image) {
                this.loadFrameImage();
                this.metaDataLoaded(null);
            }
            else {
                if (!this.video) {
                    var videoSelector = $('#cut-picker-video');
                    this.video = videoSelector[0];
                    videoSelector.bind('loadedmetadata', $.proxy(function () {
                        this.metaDataLoaded(this.video);
                    }, this));
                }
                this.video.src = this.videoFile.url;
                this.video.load();
            }
        };
        CutPicker.prototype.killMe = function () {
            _super.prototype.killMe.call(this);
            $(window).unbind('resize', $.proxy(this.resize, this));
            if (this.video) {
                this.video.src = "";
                this.video.load();
            }
            this.btnPlay.unbind('click', $.proxy(this.playVideo, this));
            this.btnPause.unbind('click', $.proxy(this.pauseVideo, this));
            this.btnIn.unbind('click', $.proxy(this.setInFrame, this));
            this.btnOut.unbind('click', $.proxy(this.setOutFrame, this));
            this.btnCreate.unbind('click', $.proxy(this.createCut, this));
            this.btnClose.unbind('click', $.proxy(this.killMe, this));
            $(document).off('keydown', $.proxy(this.checkKeys, this));
            if (this.content) {
                this.content.remove();
                this.content = null;
            }
            this.delegate.cutPicker = null;
            this.open = false;
        };
        CutPicker.preparing = false;
        return CutPicker;
    })(Nickel.Component);
    Nickel.CutPicker = CutPicker;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    var InfoBox = (function (_super) {
        __extends(InfoBox, _super);
        function InfoBox(container, data, delegate) {
            _super.call(this, container, data, delegate);
            this.viewLoaded(Main.templates.find('.infoBox').clone());
        }
        InfoBox.prototype.viewLoaded = function (v) {
            _super.prototype.viewLoaded.call(this, v);
        };
        InfoBox.prototype.updateMe = function (data) {
            this.data = data;
            this.content.html(data.name);
        };
        InfoBox.prototype.setPosition = function (position) {
            this.content.css('left', position.left).css('top', position.top);
        };
        return InfoBox;
    })(Nickel.Component);
    Nickel.InfoBox = InfoBox;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    var JobHandler = (function () {
        function JobHandler() {
        }
        JobHandler.handleError = function (error, onFailCallback) {
            if (onFailCallback === void 0) { onFailCallback = null; }
            EventBus.dispatch(Nickel.Debug.ERROR, {
                text: 'CMS Job Error',
                error: { status: "Failure: ", statusText: error }
            });
            if (onFailCallback) {
                onFailCallback();
            }
        };
        JobHandler.pollFor = function (jobId, jobName, onFinish, onFail) {
            Ajax.get(new JWTAjaxRequest('/job/' + jobId, null, function (response) {
                if (response && response.processing) {
                    console.log('Polled status for job ' + jobId + ' (' + jobName + ')');
                    JobHandler.checkIfRunning(jobId, jobName, response.status == "running");
                    // Job still processing
                    setTimeout(function () {
                        JobHandler.pollFor(jobId, jobName, onFinish, onFail);
                    }, JobHandler.pollInterval);
                }
                else {
                    JobHandler.clearRunningJobEntry(jobId);
                    // Job complete
                    if (response && response.error) {
                        JobHandler.handleError(response.error, onFail);
                    }
                    else {
                        if (onFinish) {
                            EventBus.dispatch(Nickel.Debug.SUCCESS, 'Job ' + jobId + ' (' + jobName + ') completed successfully');
                            if (response && response.output) {
                                onFinish(jobId, jobName, response.output);
                            }
                            else {
                                onFinish(jobId, jobName);
                            }
                        }
                    }
                    JobHandler.outputLogInConsole(jobId);
                }
            }, function (xhr) {
                // Keep polling, likely a gateway timeout or blip
                setTimeout(function () {
                    JobHandler.pollFor(jobId, jobName, onFinish, onFail);
                }, JobHandler.pollInterval);
            }));
        };
        JobHandler.outputLogInConsole = function (jobId) {
            EventBus.dispatch(Nickel.Debug.LOG, 'Retrieving log...');
            Nickel.JobLogFinder.findLogHtml(jobId, function (html) {
                EventBus.dispatch(Nickel.Debug.HTML, html);
            });
        };
        JobHandler.checkIfRunning = function (jobId, jobName, isRunning) {
            if (isRunning && !JobHandler.jobsRunning[jobId]) {
                EventBus.dispatch(Nickel.Debug.LOG, 'Processing started on job ' + jobId + ' (' + jobName + ')');
                JobHandler.jobsRunning[jobId] = true;
            }
        };
        JobHandler.clearRunningJobEntry = function (jobId) {
            if (JobHandler.jobsRunning[jobId]) {
                delete JobHandler.jobsRunning[jobId];
            }
        };
        JobHandler.runJob = function (jobName, workload, onFinishCallback, onFailCallback, defaultError) {
            if (workload === void 0) { workload = {}; }
            if (onFinishCallback === void 0) { onFinishCallback = null; }
            if (onFailCallback === void 0) { onFailCallback = null; }
            if (defaultError === void 0) { defaultError = 'Could not process job'; }
            var route = '/job/' + jobName.replace(/([A-Z])/g, function (l) { return ("-" + l[0].toLowerCase()); });
            Ajax.post(new JWTAjaxRequest(route, workload, function (response) {
                if (response && response.id) {
                    EventBus.dispatch(Nickel.Debug.LOG, 'Queued ' + response.id + ' (' + jobName + ')');
                    // Job created successfully
                    JobHandler.pollFor(response.id, jobName, onFinishCallback, onFailCallback);
                }
                else {
                    JobHandler.handleError(defaultError, onFailCallback);
                }
            }, function (xhr) {
                var response = JSON.parse(xhr.jqXHR.responseText);
                // Server response error
                JobHandler.handleError((response && response.error) ? response.error : defaultError, onFailCallback);
            }));
        };
        JobHandler.pollInterval = 2000;
        JobHandler.jobsRunning = {};
        return JobHandler;
    })();
    Nickel.JobHandler = JobHandler;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    var JobLogFinder = (function () {
        function JobLogFinder() {
        }
        /**
         * Called to locate a job log based on the job ID and populate it somewhere via a callback.
         * @param jobId An Imposium job ID.
         * @param callback The callback function that is called with the log HTML passed in.
         * @param delay How long, in milliseconds, to wait before making the request.
         * @param recursivelyRetry Whether or not to recursively retry if a 404 is received.
         */
        JobLogFinder.findLogHtml = function (jobId, callback, delay, recursivelyRetry) {
            if (delay === void 0) { delay = 0; }
            if (recursivelyRetry === void 0) { recursivelyRetry = false; }
            Ajax.get(new JWTAjaxRequest('/job/' + jobId + '/log-url', null, function (response) {
                if (response && response.signed_url) {
                    JobLogFinder.loadLog(response.signed_url, callback, delay, recursivelyRetry);
                }
                else {
                    console.error('Could not retrieve log URL of job ID ' + jobId);
                }
            }, function (xhr) {
                console.error('The request to retrieve the log URL of job ID ' + jobId + ' failed with a status of ' + xhr.jqXHR.status + ' ' + xhr.jqXHR.statusText);
            }));
        };
        /**
         * Converts the log URL contents (ANSI) to HTMl and passes it to the callback.
         * @param url
         * @param callback
         * @param delay
         * @param recursivelyRetry
         */
        JobLogFinder.loadLog = function (url, callback, delay, recursivelyRetry) {
            setTimeout(function () {
                $.get(url, function (ansi) {
                    callback(ansi_up.ansi_to_html(ansi));
                }).fail(function (e) {
                    if (e.status == 404 && recursivelyRetry) {
                        JobLogFinder.loadLog(url, callback, delay, recursivelyRetry);
                    }
                    else {
                        console.error('Could not load job log. A ' + e.status + ' status code was returned when requesting this URL: ' + url);
                    }
                });
            }, delay);
        };
        return JobLogFinder;
    })();
    Nickel.JobLogFinder = JobLogFinder;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    var SceneRecutter = (function (_super) {
        __extends(SceneRecutter, _super);
        function SceneRecutter(container, data, delegate) {
            _super.call(this, container, data, delegate);
            this.polling = false;
            this.content = Main.templates.find('.btnRecutScene').clone();
            this.content.insertBefore(container.find('.btnGenerate').first());
            this.content.bind('click', $.proxy(this.recut, this));
            this.ladda = Ladda.create(this.content[0]);
        }
        SceneRecutter.prototype.recut = function () {
            var scene = this.delegate;
            var act = scene.delegate;
            var story = act.delegate;
            if (!(scene.data.sceneData.cuts && scene.data.sceneData.cuts.length > 0)) {
                alert('Please add at least one cut to re-render');
                return;
            }
            this.ladda.start();
            Nickel.JobHandler.runJob('recutScene', {
                'story_id': story.data.id,
                'act_id': act.data.id,
                'scene_id': scene.data.id
            }, $.proxy(function () {
                this.ladda.stop();
                this.delegate.reloadMe();
            }, this), $.proxy(function () {
                this.ladda.stop();
            }, this));
        };
        return SceneRecutter;
    })(Nickel.Component);
    Nickel.SceneRecutter = SceneRecutter;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    var Debug = (function (_super) {
        __extends(Debug, _super);
        function Debug(container, data, id) {
            _super.call(this, container, data, id);
            this.debugString = "";
            this.hidden = false;
            this.defaultHeight = 150;
            this.headerHeight = 20;
            this.viewLoaded(Main.templates.find('.debug_console').clone());
            $(window).bind('resize', $.proxy(this.resize, this));
            this.btnClear = this.content.find('.btnClear').bind('click', $.proxy(this.clearConsole, this));
            this.btnToggle = this.content.find('.btnToggle').bind('click', $.proxy(this.toggleConsole, this));
            this.resize();
            this.panel = new Nickel.DynamicPanel(this.content, {
                'handleX': 'center',
                'handleY': 'top',
                'minHeight': 30,
                'handleStyle': 'horizontal'
            });
            this.panel.on(Nickel.DynamicPanel.RESIZE, $.proxy(this.resize, this));
            this.panel.on(Nickel.DynamicPanel.RESIZE, $.proxy(this.setMaxHeight, this));
        }
        Debug.prototype.bindGlobalEvents = function () {
            EventBus.addEventListener(Debug.LOG, $.proxy(this.log, this), this);
            EventBus.addEventListener(Debug.ERROR, $.proxy(this.error, this), this);
            EventBus.addEventListener(Debug.SUCCESS, $.proxy(this.success, this), this);
            EventBus.addEventListener(Debug.HTML, $.proxy(this.logHtml, this), this);
        };
        Debug.prototype.isHidden = function () {
            return this.hidden;
        };
        Debug.prototype.toggleConsole = function () {
            if (this.hidden) {
                this.panel.initialHeight = this.defaultHeight;
                this.panel.currentHeight = this.defaultHeight;
                this.panel.setSize();
                this.btnToggle.addClass('down');
                this.hidden = false;
                this.resize();
            }
            else {
                this.panel.initialHeight = this.headerHeight;
                this.panel.currentHeight = this.headerHeight;
                this.panel.setSize();
                this.btnToggle.removeClass('down');
                this.hidden = true;
            }
        };
        Debug.prototype.clearConsole = function () {
            this.textArea.empty();
        };
        Debug.prototype.logHtml = function (log) {
            this.textArea.append(log);
            this.scrollToBottom();
        };
        Debug.prototype.log = function (log) {
            this.logHtml("<span class = 'log'>" + log + "</span>");
        };
        Debug.prototype.error = function (data) {
            this.logHtml("<span class = 'error'>" + data.text + "<br>" + data.error.status + " " + data.error.statusText + "</span>");
        };
        Debug.prototype.success = function (log) {
            this.logHtml("<span class = 'success'>" + log + "</span>");
        };
        Debug.prototype.scrollToBottom = function () {
            this.textArea[0].scrollTop = this.textArea[0].scrollHeight;
        };
        Debug.prototype.resize = function () {
            this.hidden = false;
            var h = this.content.height();
            this.textArea.css('height', (h - 25) + 'px');
        };
        Debug.prototype.setMaxHeight = function () {
            var maxH = this.container.height();
            this.content.css('max-height', maxH + 'px');
        };
        Debug.prototype.viewLoaded = function (v) {
            _super.prototype.viewLoaded.call(this, v);
            this.textArea = this.content.find('.debug');
        };
        Debug.LOG = "debuglog";
        Debug.ERROR = "debugerror";
        Debug.SUCCESS = "debugsuccess";
        Debug.HTML = "debughtml";
        return Debug;
    })(Nickel.Component);
    Nickel.Debug = Debug;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    var ListInterface = (function (_super) {
        __extends(ListInterface, _super);
        function ListInterface(container, data, delegate) {
            _super.call(this, container, data, delegate);
            this.activeItems = {};
            this.viewLoaded(Main.templates.find('.list_interface').clone());
            $(window).bind('resize', $.proxy(this.resize, this));
            this.resize();
        }
        ListInterface.prototype.setData = function (data) {
            this.listData = data;
            this.listData.active = true;
            var found = this.dig(Main.activeNode);
            this.storyNode = this.addItem(data);
            if (!found) {
                EventBus.dispatch(Nickel.Level.SHOW_DETAILS, { id: this.listData.id });
            }
        };
        ListInterface.prototype.hideSelected = function (type) {
            var t = (type == "inventory") ? "scene" : type;
            if (this.activeItems[t]) {
                this.activeItems[t].removeChildren();
            }
        };
        ListInterface.prototype.showChildren = function (parent, data) {
            var type = (data.type == "inventory") ? "scene" : data.type;
            this.activeItems[type] = parent;
            if (data.children) {
                if (data.children.length > 0) {
                    this.content.find("." + data.children[0].type).empty();
                    for (var i = 0; i < data.children.length; i++) {
                        var item = this.addItem(data.children[i]);
                        parent.children.push(item);
                    }
                }
            }
        };
        ListInterface.prototype.addItem = function (itemData) {
            itemData.cls = 'item-' + itemData.type;
            itemData.numChildren = (itemData.children) ? itemData.children.length : 0;
            var cont = this.content.find("." + itemData.type);
            var item = new Nickel.ListItem(cont, itemData, this);
            return item;
        };
        ListInterface.prototype.itemClicked = function () {
        };
        ListInterface.prototype.resize = function () {
            this.content.find('.col').css('height', this.container.height() - 200);
        };
        ListInterface.prototype.killMe = function () {
            this.content.remove();
            this.content = null;
            $(window).unbind('resize', $.proxy(this.resize, this));
        };
        //digs through the data object looking for an id, if it finds it, shows all of the parents and that object.
        // TODO, make this not suck so bad
        ListInterface.prototype.dig = function (id) {
            if (this.listData.children) {
                for (var s = 0; s < this.listData.children.length; s++) {
                    var act = this.listData.children[s];
                    if (act.id == id) {
                        act.click = true;
                        return true;
                    }
                    else {
                        if (act.children) {
                            for (var a = 0; a < act.children.length; a++) {
                                var scene = act.children[a];
                                if (scene.id == id) {
                                    act.active = true;
                                    scene.click = true;
                                    return true;
                                }
                                else {
                                    if (scene.children) {
                                        for (var c = 0; c < scene.children.length; c++) {
                                            var cut = scene.children[c];
                                            if (cut.id == id) {
                                                act.active = true;
                                                scene.active = true;
                                                cut.click = true;
                                                return true;
                                            }
                                            else {
                                                if (cut.children) {
                                                    for (var o = 0; o < cut.children.length; o++) {
                                                        var overlay = cut.children[o];
                                                        if (overlay.id == id) {
                                                            act.active = true;
                                                            scene.active = true;
                                                            cut.active = true;
                                                            overlay.click = true;
                                                            return true;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            return false;
        };
        return ListInterface;
    })(Nickel.Component);
    Nickel.ListInterface = ListInterface;
    var ListItem = (function (_super) {
        __extends(ListItem, _super);
        function ListItem(container, data, delegate) {
            _super.call(this, container, data, delegate);
            this.children = [];
            this.viewLoaded(Main.templates.find('.list_item').clone());
            this.content.bind('click', $.proxy(this.itemClicked, this));
            if (this.data.numChildren == 0) {
                this.content.find('.num').hide();
            }
            if (this.data.disabled) {
                this.content.addClass('disabled');
            }
            if (this.data.active) {
                delete this.data.active;
                this.selectNode();
            }
            else {
                if (this.data.click) {
                    this.itemClicked();
                    delete this.data.click;
                }
            }
        }
        ListItem.prototype.selectNode = function () {
            this.delegate.hideSelected(this.data.type);
            this.delegate.showChildren(this, this.data);
            this.content.parent().find('.selected').removeClass('selected');
            this.content.addClass('selected');
        };
        ListItem.prototype.itemClicked = function () {
            this.selectNode();
            EventBus.dispatch(Nickel.Level.SHOW_DETAILS, { id: this.data.id });
        };
        ListItem.prototype.killMe = function () {
            this.removeChildren();
            this.content.unbind('click', $.proxy(this.itemClicked, this));
            this.content.remove();
            this.content = null;
        };
        ListItem.prototype.removeChildren = function () {
            for (var i = 0; i < this.children.length; i++) {
                this.children[i].killMe();
            }
            this.children = [];
        };
        return ListItem;
    })(Nickel.Component);
    Nickel.ListItem = ListItem;
})(Nickel || (Nickel = {}));
var S3FilePicker = (function () {
    function S3FilePicker(container, bucketUrl) {
        if (bucketUrl === void 0) { bucketUrl = null; }
        this.callback = null;
        this.bucketUrl = null;
        this.s3Excludes = /(\.DS_STORE)$/i;
        this.container = container;
        this.container.on('click', '.s3Link', $.proxy(this.handleS3LinkClick, this));
        this.container.on('click', '.backBtn', $.proxy(this.handleBackClick, this));
        this.container.on('click', '.okBtn', $.proxy(this.handleOkClick, this));
        if (bucketUrl != null) {
            this.bucketUrl = bucketUrl;
        }
        else {
            this.bucketUrl = "https://" + Main.s3ResourcesBucket + ".s3.amazonaws.com/";
        }
    }
    S3FilePicker.prototype.open = function (currentValue, callback) {
        if (currentValue === void 0) { currentValue = ""; }
        if (callback === void 0) { callback = null; }
        this.getS3Data(false, null, null);
        this.container.modal('show');
        this.container.find('textarea.s3Preview').val(currentValue);
        if (callback) {
            this.callback = callback;
        }
        else {
            this.callback = null;
        }
    };
    S3FilePicker.prototype.handleOkClick = function (e) {
        this.container.modal('hide');
        if (this.callback) {
            this.callback(this.container.find('textarea.s3Preview').val());
        }
    };
    S3FilePicker.prototype.handleBackClick = function (e) {
        this.container.find('.s3Link').each($.proxy(function (i, elem) {
            if ($(elem).hasClass('undefined')) {
                $(elem).click();
                return false;
            }
            return true;
        }, this));
    };
    S3FilePicker.prototype.handleS3LinkClick = function (e) {
        e.preventDefault();
        var elem = $(e.target);
        var href = elem.attr('href');
        if (elem.hasClass('directory')) {
            this.getS3Data(null, href, null);
        }
        else {
            if (href.indexOf('https') > -1) {
                var fileKey = decodeURIComponent(href.replace(this.bucketUrl, ""));
                if (fileKey.indexOf('.jpg') > -1 || fileKey.indexOf('.png') > -1) {
                    fileKey = fileKey.replace(/(.*)([^0-9])([0-9]*\.)(jpg|png)/i, "$1$2%d.$4");
                }
                this.container.find('textarea.s3Preview').val(fileKey);
            }
            else {
                this.getS3Data(null, href, null);
            }
        }
    };
    S3FilePicker.getInstance = function () {
        if (!S3FilePicker.instance) {
            S3FilePicker.instance = new S3FilePicker(Main.templates.find('.s3-file-picker').clone());
        }
        return S3FilePicker.instance;
    };
    S3FilePicker.prototype.getS3Data = function (marker, prefix, html) {
        var s3_rest_url = this.createS3QueryUrl(marker, prefix);
        // set loading notice
        $('.s3-list').html('<img src="/img/ajax-loader-circle.gif" />');
        $.get(s3_rest_url)
            .done($.proxy(function (data) {
            // clear loading notice
            $('.s3-list').html('');
            var xml = $(data);
            var info = this.getInfoFromS3Data(xml);
            this.currentFolder = info.prefix.replace('&prefix=', '');
            html = (typeof html !== 'undefined' && html != null) ? html + this.prepareTable(info) : this.prepareTable(info);
            if (info.nextMarker != "null") {
                this.getS3Data(info.nextMarker, html);
            }
            else {
                //this.container.innerHTML = '<pre>' + html + '</pre>';
                this.container.find('.s3-list')[0].innerHTML = '<pre>' + html + '</pre>';
            }
        }, this))
            .fail(function (error) {
            console.error(error);
            $('.s3-list').html('<strong>Error: ' + error + '</strong>');
        });
    };
    S3FilePicker.prototype.createS3QueryUrl = function (marker, prefix) {
        if (prefix === void 0) { prefix = null; }
        var s3_rest_url;
        if (typeof this.bucketUrl != 'undefined') {
            s3_rest_url = this.bucketUrl;
        }
        else {
            s3_rest_url = location.protocol + '//' + location.hostname;
        }
        s3_rest_url += '?delimiter=/';
        if (prefix) {
            // make sure we end in /
            prefix = prefix.replace(/\/$/, '') + '/';
            prefix = (prefix.indexOf("?prefix=") > -1 ? prefix.substr(8) : this.currentFolder + prefix);
            prefix = (prefix == "/") ? "" : prefix;
            s3_rest_url += '&prefix=' + prefix;
        }
        if (marker) {
            s3_rest_url += '&marker=' + marker;
        }
        return s3_rest_url;
    };
    S3FilePicker.prototype.getInfoFromS3Data = function (xml) {
        var files = $.map(xml.find('Contents'), function (item) {
            item = $(item);
            return {
                Key: item.find('Key').text(),
                LastModified: item.find('LastModified').text(),
                Size: item.find('Size').text(),
                Type: 'file'
            };
        });
        var directories = $.map(xml.find('CommonPrefixes'), function (item) {
            item = $(item);
            return {
                Key: item.find('Prefix').text(),
                LastModified: '',
                Size: '0',
                Type: 'directory'
            };
        });
        var nextMarker;
        if ($(xml.find('IsTruncated')[0]).text() == 'true') {
            nextMarker = $(xml.find('NextMarker')[0]).text();
        }
        else {
            nextMarker = null;
        }
        return {
            files: files,
            directories: directories,
            prefix: $(xml.find('Prefix')[0]).text(),
            nextMarker: encodeURIComponent(nextMarker)
        };
    };
    // info is object like:
    // {
    //    files: ..
    //    directories: ..
    //    prefix: ...
    // }
    S3FilePicker.prototype.prepareTable = function (info) {
        var files = info.files.concat(info.directories), prefix = info.prefix;
        var cols = [25, 25, 15];
        var content = [];
        content.push(this.padRight('Last Modified', cols[1]) + '  ' + this.padRight('Size', cols[2]) + 'Key \n');
        content.push(new Array(cols[0] + cols[1] + cols[2] + 4).join('-') + '\n');
        // add the ../ at the start of the directory listing
        if (prefix) {
            var up = prefix.replace(/\/$/, '').split('/').slice(0, -1).concat('').join('/'), item = {
                Key: (up == null) ? "" : up,
                LastModified: '',
                Size: '',
                keyText: '../',
                href: '?prefix=' + up
            }, row = this.renderRow(item, cols);
            content.push(row + '\n');
        }
        jQuery.each(files, $.proxy(function (idx, item) {
            // strip off the prefix
            item.keyText = item.Key.substring(prefix.length);
            // some tools create directory keys like dir/ which show as empty names
            if (item.keyText == "") {
                return;
            }
            // Skip any excluded paths
            if (typeof this.s3Excludes != 'undefined' && this.s3Excludes != null && this.s3Excludes.test(item.keyText)) {
                return;
            }
            if (item.Type === 'directory') {
                item.href = item.keyText;
            }
            else {
                item.href = this.bucketUrl + encodeURIComponent(item.Key);
            }
            var row = this.renderRow(item, cols);
            content.push(row + '\n');
        }, this));
        return content.join('');
    };
    S3FilePicker.prototype.renderRow = function (item, cols) {
        var row = '';
        row += this.padRight(item.LastModified, cols[1]) + '  ';
        row += this.padRight(item.Size, cols[2]);
        row += '<a href="' + item.href + '" class="s3Link ' + item.Type + '">' + item.keyText + '</a>';
        return row;
    };
    S3FilePicker.prototype.padRight = function (padString, length) {
        var str = padString.slice(0, length - 3);
        if (padString.length > str.length) {
            str += '...';
        }
        while (str.length < length) {
            str = str + ' ';
        }
        return str;
    };
    /**
     * Creates an S3 bucket if it does not already exist.
     * @param bucket The name of the bucket.
     * @param region The region of the bucket.
     * @param createDistribution Whether to associate a CloudFront distribution with the new bucket.
     * @param readOnly Whether to allow writing/deleting to the bucket.
     * @param callback Function to call when request is complete (such as hiding a loader)
     */
    S3FilePicker.createS3Bucket = function (bucket, region, createDistribution, readOnly, callback) {
        EventBus.dispatch(Nickel.Debug.LOG, 'Create Bucket');
        Ajax.post(new JWTAjaxRequest('/bucket/' + encodeURIComponent(bucket), {
            'region': region,
            'create_distribution': (createDistribution) ? 1 : 0,
            'read_only': (readOnly) ? 1 : 0,
        }, function (response) {
            if (callback) {
                callback(response);
            }
            if (response.created) {
                // Created
                EventBus.dispatch(Nickel.Debug.SUCCESS, 'New S3 Bucket "' + response.bucket + '" Created Successfully');
            }
            else {
                // Already existed
                EventBus.dispatch(Nickel.Debug.SUCCESS, 'S3 Bucket "' + response.bucket + '" Already Exists');
            }
            if (response.created_distribution) {
                EventBus.dispatch(Nickel.Debug.SUCCESS, 'New CloudFront Distribution "' + response.distribution + '" For Bucket "' + response.bucket + '" Created Successfully');
            }
        }, function (xhr) {
            var response = JSON.parse(xhr.jqXHR.responseText);
            if (callback) {
                callback(response);
            }
            var error = (response && response.error) ? response.error : 'Could not create S3 bucket';
            EventBus.dispatch(Nickel.Debug.ERROR, {
                text: 'S3 Bucket Create Error',
                error: { status: "Failure: ", statusText: error }
            });
        }));
    };
    S3FilePicker.bucketExists = function (bucket, callback, errorCallback) {
        EventBus.dispatch(Nickel.Debug.LOG, 'Check Bucket');
        Ajax.get(new JWTAjaxRequest('/bucket/' + encodeURIComponent(bucket), null, function (response) {
            if (callback) {
                callback(response.exists);
            }
        }, function (xhr) {
            var response = JSON.parse(xhr.jqXHR.responseText);
            var error = (response && response.error) ? response.error : 'Could not check if S3 bucket exists';
            EventBus.dispatch(Nickel.Debug.ERROR, {
                text: 'S3 Bucket Check Error',
                error: { status: "Message: ", statusText: error }
            });
            if (errorCallback) {
                errorCallback();
            }
        }));
    };
    S3FilePicker.instance = null;
    return S3FilePicker;
})();
var Nickel;
(function (Nickel) {
    /**
     * Value Object for defining the structure of the top level Act JSON data.
     */
    var ActVO = (function (_super) {
        __extends(ActVO, _super);
        function ActVO() {
            _super.apply(this, arguments);
            this.name = "New Act";
            this.label = "New Act";
            this.description = "";
            this.isPremium = false;
            this.inventory = {};
            this.scenes = {};
            this.order = null;
            this.displayImage = null;
            this.comingSoon = false;
            this.minimumAppVersion = "1.0";
            this.usesFacebook = false;
            this.usesTwitter = false;
        }
        return ActVO;
    })(Nickel.VO);
    Nickel.ActVO = ActVO;
    /**
     * Act Node, containing scene and inventory information.
     */
    var Act = (function (_super) {
        __extends(Act, _super);
        /**
         * Stores the global vars, loads this Node's DOM view, and creates an new Value Object if needed.
         * @param container        A jQuery object containing the parent div for this view.
         * @param data          The JSON data unique to this node.
         * @param index        The index of this node in the delegate's child array (only applicable if stored in an
         *     array and not an object).
         * @param delegate        The Class that created this instance.
         */
        function Act(container, data, index, delegate) {
            _super.call(this, container, data, index, delegate);
            /**
             * Array containing all child Inventory classes.
             */
            this.inventory = [];
            /**
             * Object containing all child Scene classes. Stored in key / value pairs, indexed by the Scene ID.
             */
            this.children = {};
            /**
             * Label for this type of node used in the CMS.
             */
            this.label = "Act";
            /**
             * If true, show the video player in the right interface when this view is visible.
             */
            this.videoPlayer = false;
            if (data) {
                this.data = data;
            }
            else {
                this.data = new ActVO();
                this.firstSave = true;
            }
            //load this views markup
            this.viewLoaded(Main.templates.find(".act").clone());
        }
        /**
         * Binds this.data to the view using rivets. Creates all of the child Scenes and Inventory Items.
         * @param v        A jQuery object to act as this node's view and to bind it's data to.
         */
        Act.prototype.viewLoaded = function (v) {
            _super.prototype.viewLoaded.call(this, v);
            //loop through and create the acts
            var count = 0;
            for (var key in this.data.scenes) {
                this.addChild(this.data.scenes[key], count);
                count++;
            }
            //loop through and create the inventory objects
            count = 0;
            for (var iKey in this.data.inventory) {
                this.addInventoryItem(this.data.inventory[iKey], count);
            }
            //add to the act order list
            this.actOrderListItem = $("<li data-id = '" + this.data.id + "' class = 'list-group-item'>" + this.data.name + "</li>");
            this.delegate.content.find(".actOrderList").append(this.actOrderListItem);
            this.bindEvents();
        };
        /**
         * Binds all of the event listeners for this Class.
         */
        Act.prototype.bindEvents = function () {
            this.content.find('.addSceneDropdown li a').bind('click', $.proxy(this.addSceneBtnClicked, this));
            this.content.find(".btnAddInventory").bind('click', $.proxy(this.newInventory, this));
            this.content.find(".btnExportAct").bind('click', $.proxy(this.exportMe, this));
            this.content.find(".btnImportScene").bind('click', $.proxy(this.importScene, this));
            $("#sceneImportFile").bind('change', $.proxy(this.getSceneData, this));
            //image upload stuff
            this.content.find('.upload-act-image').bind('change', $.proxy(this.uploadImage, this));
            this.content.find('.btnUploadActImage').bind('click', $.proxy(this.uploadImageClicked, this));
        };
        /**
         * Exports this classes this.data obeject to a .json file that the user downloads.
         */
        Act.prototype.exportMe = function () {
            //save the story
            this.saveData();
            var actId = this.data.id;
            var filename = this.data.name + '.json';
            Ajax.get(new JWTAjaxRequest('/story/' + this.delegate.data.id, null, function (storyData) {
                var actData = storyData['acts'][actId];
                saveAs(new Blob([JSON.stringify(actData)], { type: "text/plain;charset=utf-8" }), filename);
            }));
        };
        /**
         * Calls the click event on a hidden button to trigger the import scene form.
         */
        Act.prototype.importScene = function (e) {
            $("#sceneImportFile").trigger('click');
        };
        /**
         * Creates a FileReader instance to parse the incoming JSON file.
         * @param e    Event passed in from the click.
         */
        Act.prototype.getSceneData = function (e) {
            if (e.target.files[0]) {
                var r = new FileReader();
                r.onload = $.proxy(this.saveImportedScene, this);
                r.readAsText(e.target.files[0]);
            }
            else {
                console.log("Failed to load file!");
            }
        };
        /**
         * Saves the act that has just been imported. Re-binds the import event listener.
         * @param e    Event passed in from the click.
         */
        Act.prototype.saveImportedScene = function (e) {
            // Need to replace and re-bind the change event to allow multiple imports!
            $("#sceneImportFile").replaceWith('<input id = "sceneImportFile" type="file" style="display:none;">');
            $("#sceneImportFile").bind('change', $.proxy(this.getSceneData, this));
            // Once we've reset for the next import, save the actual act data
            var scene = this.addChild(JSON.parse(e.target.result), 0);
            scene.saveData();
            this.showChild(scene.data.id);
        };
        /**
         * Uploads an image to the backend using a hidden form.
         * @param e    Event passed in from the click.
         */
        Act.prototype.uploadImage = function (e) {
            var form = $(e.target).parent().parent();
            form.attr('action', AjaxUrlProvider.getLegacyApiBaseUrl() + '/api');
            // Get story ID so upload is sent to the right place
            var storyIdInput = form.find('input[name=story_id]');
            storyIdInput.val(this.delegate.data.id);
            Ajax.formSubmit(form, $.proxy(function (response) {
                this.data.displayImage = response.bind.info;
                this.saveData();
            }, this));
        };
        /**
         * Triggers a click on a hidden button to upload an image.
         * @param e    Event passed in from the click.
         */
        Act.prototype.uploadImageClicked = function (e) {
            e.preventDefault();
            this.content.find('.upload-act-image').trigger('click');
        };
        /**
         * Creates a new Inventory Item and shows it. Saves the Story.
         */
        Act.prototype.newInventory = function () {
            //TODO figure out why this bullshit is nessassary. Empty object from VO is being converted to []. GW
            if (this.data.inventory instanceof Array) {
                this.data.inventory = {};
            }
            var item = this.addInventoryItem(null, this.inventory.length);
            this.data.inventory[item.data.id] = item.data;
            item.showMe();
            this.saveData();
        };
        /**
         * Creates a new Inventory Item adds it to the inventory array. returns the item.
         * @param data        The data associated with this new item.
         * @param index    The index of this item in the inventory array.
         * @returns            The inventory item just created.
         */
        Act.prototype.addInventoryItem = function (data, index) {
            var item = new Nickel.InventoryItem(this.content.find(".inventoryHolder"), data, index, this);
            this.inventory.push(item);
            return item;
        };
        /**
         * Removes an inventory item from the database, and from the UI.
         * @param item        The inventory item we want to remove.
         */
        Act.prototype.removeInventoryItem = function (item) {
            delete this.data.inventory[item.data.id];
            item.killMe();
        };
        /**
         * Receives click event from the Add Scene dropdown.
         * @param e        Event passed in from the click.
         */
        Act.prototype.addSceneBtnClicked = function (e) {
            //add the scene
            var type = $(e.currentTarget).data('id');
            this.addScene(type);
        };
        /**
         * Creates a new scene, adds its data to the global data structure, shows that new scene. Saves to the Database.
         * @param type The class name of the type of scene to be created.
         * @param data Initial data to initialize the scene with. Set to <null> to use default value object.
         */
        Act.prototype.addScene = function (type, data) {
            if (data === void 0) { data = null; }
            var scene = this.addChild(data, this.children.length, type);
            //TODO figure out why this bullshit is nessassary. Empty object from VO is being converted to []. GW
            if (this.data.scenes instanceof Array) {
                this.data.scenes = {};
            }
            this.data.scenes[scene.data.id] = scene.data;
            //show the scene
            this.showChild(scene.data.id);
            //save
            this.saveData();
        };
        /**
         * Duplicates an existing scene, changes all cut and overlay IDs, shows that new scene. Saves to the Database.
         * @param scene The scene object to duplicate.
         */
        Act.prototype.duplicateChildItem = function (scene) {
            var data = $.extend(true, {}, scene.data);
            data.id = Utils.generateUUID();
            // change out cut ids
            if (data.sceneData.cuts && data.sceneData.cuts.length > 0) {
                for (var cutIndex in data.sceneData.cuts) {
                    data.sceneData.cuts[cutIndex].id = Utils.generateUUID();
                    // change out overlay ids
                    if (data.sceneData.cuts[cutIndex].overlays && data.sceneData.cuts[cutIndex].overlays.length > 0) {
                        for (var overlayIndex in data.sceneData.cuts[cutIndex].overlays) {
                            data.sceneData.cuts[cutIndex].overlays[overlayIndex].id = Utils.generateUUID();
                        }
                    }
                }
            }
            this.addScene(data.type, data);
        };
        /**
         * Creates a new scene, adds it's data to the global data structure, shows that new scene. Saves to the
         * Database.
         * @param data        The JSON data associated with this new Scene.
         * @param index    The index of this new Scene in the children array.
         * @param type        The type of scene we want to create.
         * @returns        The newly created Scene.
         */
        Act.prototype.addChild = function (data, index, type) {
            var t = (type) ? type : data.type;
            var scene;
            scene = new Nickel[t](this.content.find('.sceneHolder'), data, index, this);
            this.children[scene.data.id] = scene;
            return scene;
        };
        /**
         * Show's this Act. sets the actID and actTitle.
         */
        Act.prototype.showMe = function () {
            _super.prototype.showMe.call(this);
            Main.actId = this.data.id;
            Main.actTitle = this.data.name;
        };
        /**
         * Hides this act. Hides the active child as well.
         */
        Act.prototype.hideMe = function () {
            _super.prototype.hideMe.call(this);
            if (this.activeChild) {
                this.activeChild.hideMe();
            }
        };
        /**
         * Builds this Acts inventory data object. and saves the global data object to the Database.
         */
        Act.prototype.saveData = function () {
            EventBus.dispatch(Nickel.Story.SAVE_STORY);
        };
        /**
         * Tells this Act's delegate to remove it.
         */
        Act.prototype.removeMe = function () {
            this.delegate.childToDelete = this.data.id;
            this.delegate.deleteChild();
        };
        /**
         * Removes and completely kills a child Scene. Saves the Story to the database.
         */
        Act.prototype.deleteChild = function () {
            //kill the view
            var child = this.children[this.childToDelete];
            child.killMe();
            child = null;
            this.activeChild = null;
            //remove the data
            delete this.children[this.childToDelete];
            delete this.data.scenes[this.childToDelete];
            //save
            this.showMe();
            this.saveData();
        };
        /**
         * Completely removes this Act from the DOM.
         */
        Act.prototype.killMe = function () {
            _super.prototype.killMe.call(this);
            this.actOrderListItem.remove();
        };
        /**
         * Specifies where the story's data can be found on this level.
         * @param storyData An object containing the story JSON structure.
         */
        Act.prototype.onDataReload = function (storyData) {
            $.extend(true, this.data, storyData['acts'][this.data.id]);
        };
        return Act;
    })(Nickel.Level);
    Nickel.Act = Act;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    var InventoryItemDefaultVO = (function () {
        function InventoryItemDefaultVO() {
        }
        return InventoryItemDefaultVO;
    })();
    Nickel.InventoryItemDefaultVO = InventoryItemDefaultVO;
    var InventoryItemDefaultEnumVO = (function (_super) {
        __extends(InventoryItemDefaultEnumVO, _super);
        function InventoryItemDefaultEnumVO() {
            _super.apply(this, arguments);
            this.options = [];
        }
        return InventoryItemDefaultEnumVO;
    })(InventoryItemDefaultVO);
    Nickel.InventoryItemDefaultEnumVO = InventoryItemDefaultEnumVO;
    var InventoryItemVO = (function (_super) {
        __extends(InventoryItemVO, _super);
        function InventoryItemVO() {
            _super.apply(this, arguments);
            this.label = "New Inventory";
            this.name = "New Inventory";
            this.type = "";
            this.description = "";
            this.optional = false;
            this.hidden = false;
            this.createThumbnail = false;
            this.includeInExperienceData = false;
            this.defaultItem = new InventoryItemDefaultVO();
        }
        return InventoryItemVO;
    })(Nickel.VO);
    Nickel.InventoryItemVO = InventoryItemVO;
    var InventoryItem = (function (_super) {
        __extends(InventoryItem, _super);
        function InventoryItem(container, data, index, delegate) {
            _super.call(this, container, data, index, delegate);
            this.label = "Inventory";
            if (!data) {
                this.data = new InventoryItemVO();
            }
            else {
                this.data = data;
            }
            this.viewLoaded(Main.templates.find(".inventoryItem").clone());
        }
        /**
         * Binds this.data to the view using rivets and the event listeners for this Class.
         * @param v A jQuery object to act as this node's view and to bind it's data to.
         */
        InventoryItem.prototype.viewLoaded = function (v) {
            _super.prototype.viewLoaded.call(this, v);
            this.content.find('.uploadInventoryDisplayImage').bind('change', $.proxy(this.uploadDisplayImage, this));
            this.content.find('.btnUploadInventoryDisplayImage').bind('click', $.proxy(this.uploadDisplayImageClicked, this));
            this.content.find('.uploadInventoryDefault').bind('change', $.proxy(this.uploadDefaultFile, this));
            this.content.find('.btnUploadInventoryDefaultFile').bind('click', $.proxy(this.uploadDefaultFileClicked, this));
            this.content.find('.typeSelect').bind('change', $.proxy(this.resetDefaultValue, this));
            this.content.find('.btnEditId').bind('click', $.proxy(this.editId, this));
            this.content.find('.btnChangeId').bind('click', $.proxy(this.saveId, this));
            this.content.find('.btnCancelIdChange').bind('click', $.proxy(this.resetId, this));
            this.content.find('.enumControls .btnAddEnumOption').bind('click', $.proxy(this.addEnumOption, this));
            this.content.find('.enumControls .btnRemoveEnumOption').bind('click', $.proxy(this.removeEnumOption, this));
            this.resetId();
        };
        /**
         * Opens the ID text field for editing and displays save/cancel buttons.
         */
        InventoryItem.prototype.editId = function () {
            this.content.find('.btnEditId').hide().parent().find('input').prop('disabled', false).focus();
            this.content.find('.btnChangeId').show();
            this.content.find('.btnCancelIdChange').show();
        };
        /**
         * Validates an ID change and changes this item's ID if successful.
         */
        InventoryItem.prototype.saveId = function () {
            var id = this.content.find('.btnEditId').parent().find('input').val().trim();
            if (id !== this.data.id) {
                for (var inventoryId in this.delegate.inventory) {
                    if (this.delegate.inventory.hasOwnProperty(inventoryId)) {
                        var inventoryItem = this.delegate.inventory[inventoryId];
                        if (inventoryItem !== this && inventoryItem.data.id === id) {
                            alert("There is already an inventory item on this act with an ID of \"" + id + "\". Please choose another ID.");
                            return;
                        }
                    }
                }
                // Remove data stored with old ID
                delete this.delegate.data.inventory[this.data.id];
                // Set new ID
                this.data.id = id;
                // Store this object's data referenced by new ID
                this.delegate.data.inventory[id] = this.data;
                // Save new data with ID change
                this.delegate.saveData();
            }
            this.resetId();
        };
        /**
         * Resets the ID text field with the current ID stored in this item's data.
         */
        InventoryItem.prototype.resetId = function () {
            this.content.find('.btnEditId').show().parent().find('input').prop('disabled', true).val(this.data.id);
            this.content.find('.btnChangeId').hide();
            this.content.find('.btnCancelIdChange').hide();
        };
        /**
         * Reset the default item so that type-specific fields are erased.
         */
        InventoryItem.prototype.resetDefaultValue = function () {
            this.content.find('video,audio').removeAttr('src');
            var dataTypeIdentifier = this.data.type.toLowerCase();
            var defaultItem = (dataTypeIdentifier === 'enum') ? new InventoryItemDefaultEnumVO() : new InventoryItemDefaultVO();
            defaultItem.type = this.data.type;
            switch (dataTypeIdentifier) {
                case "number":
                    defaultItem.src = 0;
                    break;
                case "boolean":
                    defaultItem.src = false;
                    break;
                default:
                    defaultItem.src = "";
                    break;
            }
            this.data.defaultItem = defaultItem;
        };
        /**
         * Adds an Enum option to the defaultItem dropdown.
         */
        InventoryItem.prototype.addEnumOption = function () {
            var option = prompt('Please provide a name for the new Enum option');
            if (option && this.data.defaultItem.options.indexOf(option) === -1) {
                this.data.defaultItem.options.push(option);
                this.data.defaultItem.src = option;
            }
        };
        /**
         * Removes an Enum option from the defaultItem dropdown.
         */
        InventoryItem.prototype.removeEnumOption = function () {
            var enumDropdown = this.content.find('.enumDropdown');
            this.data.defaultItem.options.splice(this.data.defaultItem.options.indexOf(enumDropdown.val()), 1);
            if (this.data.defaultItem.options.length === 0) {
                this.data.defaultItem.src = "";
                // Reloads the dropdown to reflect having no options
                // (otherwise, the last option hangs around until you click on the dropdown and THEN it disappears)
                enumDropdown.parent().prepend(enumDropdown.detach());
            }
        };
        /**
         * Removes the item from the story JSON.
         */
        InventoryItem.prototype.removeMe = function () {
            this.delegate.removeInventoryItem(this);
            this.delegate.saveData();
        };
        /**
         * Performs the default item file upload (image/video/audio).
         * @param e The click event.
         */
        InventoryItem.prototype.uploadDefaultFile = function (e) {
            var form = $(e.currentTarget).parent();
            form.attr('action', AjaxUrlProvider.getLegacyApiBaseUrl() + '/api');
            form.find('input:not([type=file])').remove();
            $('<input>', { name: 'task', value: 'upload-file', type: 'hidden' }).appendTo(form);
            $('<input>', { name: 'story_id', value: this.delegate.delegate.data.id, type: 'hidden' }).appendTo(form);
            var fileInput = $(e.currentTarget)[0];
            var fileData = fileInput.files[0];
            if (fileData) {
                EventBus.dispatch(Nickel.Debug.LOG, "Uploading Default File");
                Ajax.formSubmit(form, $.proxy(function (response) {
                    EventBus.dispatch(Nickel.Debug.SUCCESS, "Default File Uploaded");
                    this.data.defaultItem = response.bind.info;
                    this.delegate.saveData();
                }, this));
            }
        };
        /**
         * Passes the click event from the button to the file input.
         * @param e The click event.
         */
        InventoryItem.prototype.uploadDefaultFileClicked = function (e) {
            e.preventDefault();
            switch (this.data.type.toLowerCase()) {
                case "image":
                    this.content.find('.imagePreview .uploadInventoryDefault').trigger('click');
                    break;
                case "video":
                    this.content.find('.videoPreview .uploadInventoryDefault').trigger('click');
                    break;
                case "audio":
                    this.content.find('.audioPreview .uploadInventoryDefault').trigger('click');
                    break;
            }
        };
        /**
         * Passes the click event from the button to the file input.
         * @param e The click event.
         */
        InventoryItem.prototype.uploadDisplayImageClicked = function (e) {
            e.preventDefault();
            this.content.find('.uploadInventoryDisplayImage').trigger('click');
        };
        /**
         * Performs the display image file upload.
         * @param e The click event.
         */
        InventoryItem.prototype.uploadDisplayImage = function (e) {
            var form = $(e.currentTarget).parent();
            // Get story ID so upload is sent to the right place
            form.find('input[name=story_id]').val(this.delegate.delegate.data.id);
            Ajax.formSubmit(form, $.proxy(function (response) {
                this.data.displayImage = response.bind.info;
                this.delegate.saveData();
            }, this));
        };
        return InventoryItem;
    })(Nickel.Level);
    Nickel.InventoryItem = InventoryItem;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    /**
     * Value Object for defining the structure of the top level Scene JSON data. All other Scene VO's extend SceneVO.
     */
    var SceneVO = (function (_super) {
        __extends(SceneVO, _super);
        function SceneVO() {
            _super.apply(this, arguments);
            this.label = "New Scene";
            this.name = "New Scene";
            this.decision = {
                "class": "",
                "options": {}
            };
            this.metaData = {};
            this.trigger = "doneProcessing";
        }
        return SceneVO;
    })(Nickel.VO);
    Nickel.SceneVO = SceneVO;
    /**
     * Parent class for all types of Scenes.
     */
    var Scene = (function (_super) {
        __extends(Scene, _super);
        /**
         * Passes all of the Scenes params on to the parent Level Class.
         * @param container        A jQuery object containing the parent div for this view.
         * @param data          The JSON data unique to this node.
         * @param index        The index of this node in the delegate's child array (only applicable if stored in an
         *     array and not an object).
         * @param delegate        The Class that created this instance.
         */
        function Scene(container, data, index, delegate) {
            _super.call(this, container, data, index, delegate);
            /**
             * Array holding all of the list items for Decisions.
             */
            this.decisionOptions = [];
        }
        /**
         * Binds this.data to the view using rivets. Populates the Decision dropdown.
         * @param v        A jQuery object to act as this node's view and to bind it's data to.
         */
        Scene.prototype.viewLoaded = function (v) {
            _super.prototype.viewLoaded.call(this, v);
            this.content.find(".btnExportScene").bind('click', $.proxy(this.exportMe, this));
            if (this.data.decision) {
                // Ajax.post(request);
                this.decisionDropdown = this.content.find('.decisionDropdown').val(this.data.decision.class);
                this.decisionDropdown.bind('change', $.proxy(this.getDecisionOptions, this));
                this.populateDecisionOptions();
            }
        };
        /**
         * Exports this classes this.data obeject to a .json file that the user downloads.
         */
        Scene.prototype.exportMe = function () {
            //save the story
            this.saveData();
            var sceneId = this.data.id;
            var actId = this.delegate.data.id;
            var filename = this.data.name + '.json';
            Ajax.get(new JWTAjaxRequest('/story/' + this.delegate.delegate.data.id, null, function (storyData) {
                var sceneData = storyData['acts'][actId]['scenes'][sceneId];
                saveAs(new Blob([JSON.stringify(sceneData)], { type: "text/plain;charset=utf-8" }), filename);
            }));
        };
        /**
         * Hits the database to get all of the different options for decision scripts.
         */
        Scene.prototype.getDecisionOptions = function () {
            this.clearOptions();
            this.data.decision.options = {};
            if (this.data.decision.class) {
                var data = { 'task': 'get-decision-options', "class": this.data.decision.class };
                var request = new AjaxRequest(data, $.proxy(this.gotDecisionOptions, this), function (e) {
                    console.log("error");
                    console.log(e);
                }, null, '/api/1.1');
                Ajax.post(request);
            }
        };
        /**
         * Dets the decision options in the data object, calls the populateDecisionOptions function to populate the
         * options.
         * @param d    The Data from the DB.
         */
        Scene.prototype.gotDecisionOptions = function (d) {
            this.data.decision.options = d.bind.options;
            this.populateDecisionOptions();
        };
        /**
         * Dets the decision options in the data object
         */
        Scene.prototype.populateDecisionOptions = function () {
            //remove this scene from the list
            var scenes = $.extend({}, this.delegate.data.scenes);
            delete scenes[this.data.id];
            if (this.data.decision.options) {
                for (var i = 0; i < this.data.decision.options.length; i++) {
                    var data = this.data.decision.options[i];
                    var option = new Nickel.DecisionOption(this.content.find('.decisionOptions'), data, i, this);
                    option.addScenes(scenes);
                    this.decisionOptions.push(option);
                }
            }
        };
        /**
         * Removes all of the options from the decision dropdown.
         */
        Scene.prototype.clearOptions = function () {
            for (var i = 0; i < this.decisionOptions.length; i++) {
                var option = this.decisionOptions[i];
                option.killMe();
            }
            this.decisionOptions = [];
        };
        /**
         * Saves the global story data.
         */
        Scene.prototype.saveData = function () {
            EventBus.dispatch(Nickel.Story.SAVE_STORY);
        };
        /**
         * Shows the scene, sets the sceneID
         */
        Scene.prototype.showMe = function () {
            _super.prototype.showMe.call(this);
            Main.sceneId = this.data.id;
        };
        /**
         * Tells the delegate to remove this child.
         */
        Scene.prototype.removeMe = function () {
            this.delegate.childToDelete = this.data.id;
            this.delegate.deleteChild();
        };
        /**
         * Called by StoryPublisher to validate scene-specific fields before publishing
         * @param data
         * @returns {boolean} Whether or not to allow publishing to occur based on current parameters
         */
        Scene.validatePublishAction = function (data) {
            return true;
        };
        /**
         * Specifies where the story's data can be found on this level.
         * @param storyData An object containing the story JSON structure.
         */
        Scene.prototype.onDataReload = function (storyData) {
            $.extend(true, this.data, storyData['acts'][this.delegate.data.id]['scenes'][this.data.id]);
        };
        return Scene;
    })(Nickel.Level);
    Nickel.Scene = Scene;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    var DecisionOption = (function (_super) {
        __extends(DecisionOption, _super);
        function DecisionOption(container, data, index, delegate) {
            _super.call(this, container, data, index, delegate);
            this.data = data;
            this.viewLoaded(Main.templates.find('.decision').clone());
            this.container.append(this.content);
            this.sceneDropdown = this.content.find('.sceneSelect');
            this.sceneDropdown.bind('change', $.proxy(this.dropdownChanged, this));
        }
        DecisionOption.prototype.dropdownChanged = function () {
            var id = this.sceneDropdown.find('option:selected').attr('data-id');
            this.data.value = id;
        };
        DecisionOption.prototype.addScenes = function (scenes) {
            for (var key in scenes) {
                if (scenes.hasOwnProperty(key)) {
                    var scene = scenes[key];
                    var option = $('<option data-id = "' + scene.id + '">' + scene.id + '</option>');
                    this.sceneDropdown.append(option);
                }
            }
            if (this.data.value) {
                if (scenes[this.data.value]) {
                    this.sceneDropdown.val(scenes[this.data.value].id);
                }
                else {
                    this.data.value = "";
                }
            }
        };
        DecisionOption.prototype.killMe = function () {
            _super.prototype.killMe.call(this);
            this.sceneDropdown.unbind('change');
        };
        return DecisionOption;
    })(Nickel.Level);
    Nickel.DecisionOption = DecisionOption;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    /**
     * Value Object for defining the data structure specific to VideoScene01
     */
    var VideoScene01VO = (function (_super) {
        __extends(VideoScene01VO, _super);
        function VideoScene01VO() {
            _super.apply(this, arguments);
            this.sceneDataFilter = '';
            this.sceneData = {
                cuts: [],
                videoFile: {},
                encodingSettings: [
                    {
                        "name": "MP4 H.264 1920x1080",
                        "video": "-c:v libx264 -preset fast -profile:v high -level 5.1 -crf 22 -pix_fmt yuv420p",
                        "audio": "-c:a aac -b:a 192k -strict -2",
                        "width": "1920",
                        "height": "1080",
                        "extension": "mp4"
                    },
                    {
                        "name": "MP4 H.264 1280x720",
                        "video": "-c:v libx264 -preset fast -profile:v high -level 4.1 -crf 22 -pix_fmt yuv420p",
                        "audio": "-c:a aac -b:a 192k -strict -2",
                        "width": "1280",
                        "height": "720",
                        "extension": "mp4"
                    },
                    {
                        "name": "MP4 H.264 854x480",
                        "video": "-c:v libx264 -preset fast -profile:v high -level 4.1 -crf 22 -pix_fmt yuv420p",
                        "audio": "-c:a aac -b:a 192k -strict -2",
                        "width": "854",
                        "height": "480",
                        "extension": "mp4"
                    }
                ],
                savePosterFrame: false,
                posterFrame: 0,
                saveSnapshotFrame: false,
                snapshotFrame: 0,
                saveSocialFrame: false,
                socialFrame: 0,
                limitProcessConcurrency: true,
                processConcurrency: 20
            };
            this.type = "VideoScene01";
            this.exportAsPlaylist = false;
            this.postProcessingScript = '';
        }
        return VideoScene01VO;
    })(Nickel.SceneVO);
    Nickel.VideoScene01VO = VideoScene01VO;
    /**
     * Linear Video Scene class. Contains all logic for creating Cuts, uploading a base video.
     */
    var VideoScene01 = (function (_super) {
        __extends(VideoScene01, _super);
        /**
         * Stores the global vars, loads this Node's DOM view, and creates an new Value Object if needed. Instantiates
         * this Levels components.
         * @param container        A jQuery object containing the parent div for this view.
         * @param data          The JSON data unique to this node.
         * @param index        The index of this node in the delegate's child array (only applicable if stored in an
         *     array and not an object).
         * @param delegate        The Class that created this instance.
         */
        function VideoScene01(container, data, index, delegate) {
            _super.call(this, container, data, index, delegate);
            /**
             * Array containing child Cut classes.
             */
            this.children = [];
            /**
             * Label for this type of node used in the CMS.
             */
            this.label = "Linear Video Scene";
            /**
             * If true, show the video player in the right interface when this view is visible.
             */
            this.videoPlayer = true;
            if (data) {
                this.data = data;
            }
            else {
                this.data = new VideoScene01VO();
                this.firstSave = true;
            }
            this.viewLoaded(Main.templates.find(".videoscene01").clone());
            this.updateEncodingSettings();
            this.recutter = new Nickel.SceneRecutter(this.content.find('.sceneOptions'), {}, this);
        }
        /**
         * Binds this.data to the view using rivets. Sets the progress and progress bar.
         * @param v        A jQuery object to act as this node's view and to bind it's data to.
         */
        VideoScene01.prototype.viewLoaded = function (v) {
            _super.prototype.viewLoaded.call(this, v);
            this.progress = this.content.find('.progress');
            this.progressBar = this.content.find('.uploadProgress');
            this.content.find('.cutList').sortable({
                "stop": $.proxy(this.cutOrderListChange, this)
            });
            this.bindEvents();
            this.addExistingCuts();
            this.content.find('[data-toggle="tooltip"]').tooltip();
        };
        /**
         * Binds all of the event listeners for this Class.
         */
        VideoScene01.prototype.bindEvents = function () {
            this.content.find('.upload-main-video').bind('change', $.proxy(this.prepareVideoUpload, this));
            this.content.find('.btnUploadVideo').bind('click', $.proxy(this.uploadVideoClicked, this));
            this.content.find('.btnAddCut').bind('click', $.proxy(this.addCutBtnClicked, this));
            this.content.find(".btnGenerate").bind('click', $.proxy(this.generatePreview, this));
            this.content.find(".btnSelectAudio").bind('click', $.proxy(this.selectAudioClicked, this));
            this.content.find(".btnAddOutput").bind('click', $.proxy(this.addEncodingOutput, this));
            this.content.find(".btnAddOutputFromPreset").bind('click', $.proxy(this.addEncodingOutputFromPreset, this));
            this.content.find('.btnSelectSocialScript').bind('click', $.proxy(this.selectSocialScriptClicked, this));
            this.content.find('.btnSelectDataFilter').bind('click', $.proxy(this.selectDataFilterClicked, this));
            this.content.find('.btnSelectPostProcessingScript').bind('click', $.proxy(this.selectPostProcessingScriptClicked, this));
            this.content.find('.btnAutoCut').bind('click', $.proxy(this.triggerAutoCut, this));
        };
        VideoScene01.prototype.triggerAutoCut = function () {
            if (this.data.sceneData.videoFile && this.data.sceneData.videoFile.url) {
                var ladda = Ladda.create(this.content.find('.btnAutoCut')[0]);
                ladda.start();
                Nickel.JobHandler.runJob('autoCutScene', {
                    'story_id': this.delegate.delegate.data.id,
                    'act_id': this.delegate.data.id,
                    'scene_id': this.data.id
                }, $.proxy(function () {
                    ladda.stop();
                    this.reloadMe();
                }, this), $.proxy(function () {
                    ladda.stop();
                }, this));
            }
            else {
                alert('Please upload a base video before creating cuts');
            }
        };
        /**
         * Called when the Select Script button is pressed.
         * @param e The click event.
         */
        VideoScene01.prototype.selectDataFilterClicked = function (e) {
            S3FilePicker.getInstance().open($(e.target).parent().parent().find('input').val(), $.proxy(function (fileKey) {
                this.data.sceneDataFilter = fileKey.trim();
                this.saveData();
            }, this));
        };
        /**
         * Called when the Select Script button is pressed.
         * @param e The click event.
         */
        VideoScene01.prototype.selectSocialScriptClicked = function (e) {
            S3FilePicker.getInstance().open($(e.target).parent().parent().find('input').val(), $.proxy(function (fileKey) {
                this.data.sceneData.socialScript = fileKey.trim();
                this.saveData();
            }, this));
        };
        /**
         * Called when the Select Script button is pressed.
         * @param e The click event.
         */
        VideoScene01.prototype.selectPostProcessingScriptClicked = function (e) {
            S3FilePicker.getInstance().open($(e.target).parent().parent().find('input').val(), $.proxy(function (fileKey) {
                this.data.postProcessingScript = fileKey.trim();
                this.saveData();
            }, this));
        };
        VideoScene01.prototype.selectAudioClicked = function (e) {
            var textarea = $(e.target).parent().parent().find('input');
            console.log(textarea);
            S3FilePicker.getInstance().open(textarea.val(), $.proxy(this.saveSelectedAudio, this));
        };
        VideoScene01.prototype.saveSelectedAudio = function (fileKey) {
            this.data.sceneData.customAudioScript = fileKey;
            this.saveData();
        };
        VideoScene01.prototype.cutOrderListChange = function (e) {
            //update the acts order and save the info to the database
            var _this = this;
            var orderedCuts = [];
            this.content.find('.cutList li').each(function (i) {
                var cut = $.grep(_this.data.sceneData.cuts, $.proxy(function (e) {
                    return e.id == this.id;
                }, this))[0];
                orderedCuts.push(cut);
            });
            for (var j in orderedCuts) {
                this.children[this.children.length - 1].removeMe();
            }
            for (var i = 0; i < orderedCuts.length; i++) {
                var newCut = this.addChild(orderedCuts[i], this.children.length);
                this.data.sceneData.cuts.push(newCut.data);
            }
            this.saveData();
        };
        VideoScene01.prototype.generatePreview = function () {
            if (!(this.data.sceneData.videoFile && this.data.sceneData.videoFile.url)) {
                alert('Please upload a base video before previewing');
                return;
            }
            var storyId = this.delegate.delegate.data.id;
            var actId = this.delegate.data.id;
            window.location.href = '/preview/' + storyId + '/' + actId;
        };
        VideoScene01.prototype.uploadVideoClicked = function (e) {
            e.preventDefault();
            this.content.find('.upload-main-video').trigger('click');
        };
        VideoScene01.prototype.deleteChild = function () {
            _super.prototype.deleteChild.call(this);
            var child = this.data.sceneData.cuts.splice(this.childToDelete, 1)[0];
            child = null;
            this.saveData();
        };
        VideoScene01.prototype.addExistingCuts = function () {
            for (var i = 0; i < this.data.sceneData.cuts.length; i++) {
                this.addChild(this.data.sceneData.cuts[i], this.children.length);
            }
            // this.showChild(0);
            // $( ".cutList li:nth-child(1)" ).addClass('list-group-item-info');
        };
        VideoScene01.prototype.editCut = function (cutData) {
            var imageBased = (this.data.sceneData.videoFile.frames) ? true : false;
            if (!this.cutPicker) {
                this.cutPicker = new Nickel.CutPicker($("#storyView"), cutData, this);
                this.cutPicker.editing = true;
                this.cutPicker.loadVideo(this.data.sceneData.videoFile, imageBased);
            }
            else {
                this.cutPicker.setData(cutData);
                this.cutPicker.editing = true;
                this.cutPicker.showMe();
            }
            this.cutPicker.useImageBasedSeek(imageBased);
        };
        VideoScene01.prototype.openCutPicker = function () {
            if (this.data.sceneData.videoFile) {
                if (!this.cutPicker) {
                    this.cutPicker = new Nickel.CutPicker($("#storyView"), null, this);
                    this.cutPicker.loadVideo(this.data.sceneData.videoFile);
                }
                else {
                    console.log('Cut picker problems');
                }
            }
            else {
                alert('Please upload a base video');
            }
        };
        VideoScene01.prototype.saveCut = function (vo, encode) {
            if (encode === void 0) { encode = true; }
            var cut = this.cutBeingEdited;
            cut.pickerVO = vo;
            cut.data.startFrame = vo.inFrame;
            cut.data.endFrame = vo.outFrame;
            this.saveData();
            cut.updateCut();
        };
        VideoScene01.prototype.showMe = function () {
            _super.prototype.showMe.call(this);
            if (this.data.sceneData.videoFile && this.data.sceneData.videoFile.url) {
                EventBus.dispatch(Nickel.VideoPlayer.LOAD_VIDEO, { src: this.data.sceneData.videoFile.url });
            }
        };
        VideoScene01.prototype.prepareVideoUpload = function (e) {
            var form = $(e.target).parent().parent();
            var file = form.find('input[type=file]')[0];
            var fileData = file.files[0];
            if (fileData) {
                EventBus.dispatch(Nickel.VideoPlayer.CLEAR_VIDEO);
                EventBus.dispatch(Nickel.Debug.LOG, "Preparing video upload");
                Ajax.get(new JWTAjaxRequest('/story/' + this.delegate.delegate.data.id + '/upload-url', {
                    'filename': fileData.name,
                    'content_type': fileData.type
                }, $.proxy(this.uploadVideo, this, fileData), function (e) {
                    console.error("Error getting base video upload URL");
                    console.log(e);
                }));
            }
        };
        VideoScene01.prototype.uploadVideo = function (fileData, signedUrlResponse) {
            EventBus.dispatch(Nickel.Debug.LOG, "Uploading video");
            this.progressBar.css('width', '0%');
            this.progress.show();
            var params = {
                url: signedUrlResponse.signed_url,
                type: 'PUT',
                data: fileData,
                cache: false,
                contentType: false,
                processData: false,
                headers: {
                    'Content-Type': fileData.type
                },
                xhr: $.proxy(function () {
                    var xhr = new XMLHttpRequest();
                    xhr.upload.addEventListener("progress", $.proxy(function (evt) {
                        this.progressBar.css('width', ((evt.loaded / evt.total) * 100 + "%"));
                    }, this), false);
                    return xhr;
                }, this),
                success: $.proxy(function (response) {
                    Nickel.JobHandler.runJob('processBaseVideo', {
                        'story_id': this.delegate.delegate.data.id,
                        'file_key': signedUrlResponse.file_key
                    }, $.proxy(function (jobId, jobName, output) {
                        // noinspection JSPotentiallyInvalidUsageOfClassThis
                        this.progress.hide();
                        if (output && output.url) {
                            this.data.sceneData.videoFile = output;
                            this.saveData();
                            EventBus.dispatch(Nickel.VideoPlayer.LOAD_VIDEO, { src: this.data.sceneData.videoFile.url });
                        }
                    }, this), $.proxy(function () {
                        // noinspection JSPotentiallyInvalidUsageOfClassThis
                        this.progress.hide();
                    }, this));
                }, this),
                error: $.proxy(function (response) {
                    this.progress.hide();
                    EventBus.dispatch(Nickel.Debug.ERROR, "Error uploading video to S3");
                    console.log(response);
                }, this)
            };
            $.ajax(params);
        };
        VideoScene01.prototype.addChild = function (data, index) {
            var cut = new Nickel.Cut(this.content.find('.cutDetails'), data, index, this);
            this.children.push(cut);
            return cut;
        };
        VideoScene01.prototype.addCutBtnClicked = function () {
            if (this.data.sceneData.videoFile && this.data.sceneData.videoFile.url) {
                this.addCut();
            }
            else {
                alert('Please upload a base video before creating a cut');
            }
        };
        VideoScene01.prototype.addCut = function (data) {
            if (data === void 0) { data = null; }
            var cut = this.addChild(data, this.children.length);
            this.data.sceneData.cuts.push(cut.data);
            this.showChild(cut.index);
            this.saveData();
        };
        VideoScene01.prototype.addExistingCut = function (data) {
            if (data === void 0) { data = null; }
            var cut = this.addChild(data, this.children.length);
            this.showChild(cut.index);
        };
        VideoScene01.prototype.duplicateChildItem = function (cut) {
            var data = $.extend(true, {}, cut.data);
            data.id = Utils.generateUUID();
            if (data.overlays && data.overlays.length > 0) {
                for (var index in data.overlays) {
                    data.overlays[index].id = Utils.generateUUID();
                }
            }
            this.addCut(data);
        };
        VideoScene01.prototype.updateProgress = function (data) {
            this.content.find('.progress-msg').text(data.msg);
        };
        VideoScene01.validatePublishAction = function (data) {
            if (!(data.sceneData.videoFile && data.sceneData.videoFile.url)) {
                alert('Video scene ' + data.name + ' does not have a base video. Please upload a base video before publishing.');
                return false;
            }
            return true;
        };
        /*
         * Specifies where the story's data can be found on this level.
         * @param storyData An object containing the story JSON structure.
         */
        VideoScene01.prototype.onDataReload = function (storyData) {
            var existingIds = [];
            for (var i = 0; i < this.data.sceneData.cuts.length; i++) {
                var cut = this.data.sceneData.cuts[i];
                existingIds.push(cut.id);
            }
            $.extend(true, this.data, storyData['acts'][this.delegate.data.id]['scenes'][this.data.id]);
            for (var i = 0; i < this.data.sceneData.cuts.length; i++) {
                var cut = this.data.sceneData.cuts[i];
                if (existingIds.indexOf(cut.id) == -1) {
                    this.addExistingCut(cut);
                }
            }
            this.saveData();
        };
        /**
         * Called every time the encoding setting list is updated.
         */
        VideoScene01.prototype.updateEncodingSettings = function () {
            // refresh delete click event so old entries are unbound and new entries are bound
            this.content.find('.btnDeleteOutput').unbind('click').bind('click', $.proxy(this.removeEncodingOutput, this));
            // refresh toggle click event so old entries are unbound and new entries are bound
            this.content.find('.outputFormat .outputLabel').unbind('click').bind('click', function (e) {
                VideoScene01.toggleEncodingOutputDetails($(e.currentTarget).parent().parent().find('.outputDetails'));
            });
        };
        /**
         * Toggles the display details of an encoding setting.
         * @param detailsArea
         */
        VideoScene01.toggleEncodingOutputDetails = function (detailsArea) {
            if (detailsArea.hasClass('active')) {
                detailsArea.removeClass('active');
            }
            else {
                detailsArea.addClass('active');
            }
        };
        /**
         * Removes an encoding setting from the list.
         * @param e The click event.
         */
        VideoScene01.prototype.removeEncodingOutput = function (e) {
            var index = $(e.currentTarget).parent().parent().parent().index();
            this.data.sceneData.encodingSettings.splice(index, 1);
            this.updateEncodingSettings();
        };
        /**
         * Adds an encoding setting to the list.
         */
        VideoScene01.prototype.addEncodingOutput = function () {
            var name = prompt('Please provide a name for the new output format \n\nExample: "ProRes422 HQ 1920x1080 Stereo"');
            if (name) {
                var myWidth = "";
                var myHeight = "";
                if (this.data.sceneData.videoFile && this.data.sceneData.videoFile.width && this.data.sceneData.videoFile.height) {
                    myWidth = "" + this.data.sceneData.videoFile.width;
                    myHeight = "" + this.data.sceneData.videoFile.height;
                }
                this.data.sceneData.encodingSettings.push({
                    "name": name,
                    "video": "",
                    "audio": "",
                    "width": myWidth,
                    "height": myHeight,
                    "extension": "mp4"
                });
                this.updateEncodingSettings();
                var detailsArea = this.content.find('.outputFormat .outputDetails').last();
                VideoScene01.toggleEncodingOutputDetails(detailsArea);
                detailsArea.find('input[type=text]').first().focus();
            }
        };
        /**
         * Adds an encoding setting from the preset dropdown to the list.
         * @param e The click event.
         */
        VideoScene01.prototype.addEncodingOutputFromPreset = function (e) {
            var dropdown = $(e.currentTarget).parent().find('select');
            var option = dropdown.find(':selected');
            this.data.sceneData.encodingSettings.push({
                "name": option.data('name'),
                "video": option.data('video'),
                "audio": option.data('audio'),
                "width": option.data('width'),
                "height": option.data('height'),
                "extension": option.data('extension')
            });
            this.updateEncodingSettings();
        };
        return VideoScene01;
    })(Nickel.Scene);
    Nickel.VideoScene01 = VideoScene01;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    var ChatScene01VO = (function (_super) {
        __extends(ChatScene01VO, _super);
        function ChatScene01VO() {
            _super.apply(this, arguments);
            this.type = "ChatScene01";
            this.sceneData = {
                conversation: "",
                delay: null,
                phoneNumber: ""
            };
        }
        return ChatScene01VO;
    })(Nickel.SceneVO);
    Nickel.ChatScene01VO = ChatScene01VO;
    var ChatScene01 = (function (_super) {
        __extends(ChatScene01, _super);
        function ChatScene01(container, data, index, delegate) {
            _super.call(this, container, data, index, delegate);
            this.label = "ChatBot Scene";
            if (data) {
                this.data = data;
            }
            else {
                this.data = new ChatScene01VO();
            }
            this.viewLoaded(Main.templates.find('.chatscene01').clone());
        }
        ChatScene01.prototype.viewLoaded = function (v) {
            _super.prototype.viewLoaded.call(this, v);
            //get the conversations from the chatbot
            $.getJSON('http://bot.jnickel.com/programo/get-bots.php', $.proxy(this.populateStories, this));
        };
        ChatScene01.prototype.populateStories = function (bots) {
            for (var i = 0; i < bots.length; i++) {
                this.content.find('.conversationSelect').append("<option value = '" + bots[i] + "'>" + bots[i] + "</option>");
            }
            this.conversationSelect = this.content.find(".conversationSelect").bind('change', $.proxy(this.conversationChanged, this));
            this.conversationSelect.val(this.data.sceneData.conversation);
        };
        ChatScene01.prototype.conversationChanged = function (e) {
            this.data.sceneData.conversation = $(e.target).val();
        };
        ChatScene01.prototype.killMe = function () {
            _super.prototype.killMe.call(this);
            this.conversationSelect.unbind('change');
        };
        return ChatScene01;
    })(Nickel.Scene);
    Nickel.ChatScene01 = ChatScene01;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    var TextScene01VO = (function (_super) {
        __extends(TextScene01VO, _super);
        function TextScene01VO() {
            _super.apply(this, arguments);
            this.type = "TextScene01";
            this.sceneData = {
                message: "",
                delay: null,
                phoneNumber: ""
            };
        }
        return TextScene01VO;
    })(Nickel.SceneVO);
    Nickel.TextScene01VO = TextScene01VO;
    var TextScene01 = (function (_super) {
        __extends(TextScene01, _super);
        function TextScene01(container, data, index, delegate) {
            _super.call(this, container, data, index, delegate);
            this.label = "Text Message Scene";
            this.nodeStyle = {
                "bg": {
                    "fill": 0x62e5a9,
                    "stroke": 0x929292
                },
                "hover": {
                    "fill": 0x46de86,
                    "stroke": 0x929292
                },
                "highlight": {
                    "fill": 0x22b567,
                    "stroke": 0x307c64
                }
            };
            if (data) {
                this.data = data;
            }
            else {
                this.data = new TextScene01VO();
            }
            this.viewLoaded(Main.templates.find('.textscene01').clone());
        }
        return TextScene01;
    })(Nickel.Scene);
    Nickel.TextScene01 = TextScene01;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    var YoutubeMessageScene01VO = (function (_super) {
        __extends(YoutubeMessageScene01VO, _super);
        function YoutubeMessageScene01VO() {
            _super.apply(this, arguments);
            this.type = "YoutubeMessageScene01";
            this.sceneData = {
                message: "",
                delay: null,
                phoneNumber: ""
            };
        }
        return YoutubeMessageScene01VO;
    })(Nickel.SceneVO);
    Nickel.YoutubeMessageScene01VO = YoutubeMessageScene01VO;
    var YoutubeMessageScene01 = (function (_super) {
        __extends(YoutubeMessageScene01, _super);
        function YoutubeMessageScene01(container, data, index, delegate) {
            _super.call(this, container, data, index, delegate);
            this.label = "Youtube Message Scene";
            this.nodeStyle = {
                "bg": {
                    "fill": 0xe5bb62,
                    "stroke": 0x929292
                },
                "hover": {
                    "fill": 0xde9146,
                    "stroke": 0x929292
                },
                "highlight": {
                    "fill": 0xb56722,
                    "stroke": 0x7c5030
                }
            };
            if (data) {
                this.data = data;
            }
            else {
                this.data = new YoutubeMessageScene01VO();
            }
            this.viewLoaded(Main.templates.find('.youtubemessagescene01').clone());
        }
        return YoutubeMessageScene01;
    })(Nickel.Scene);
    Nickel.YoutubeMessageScene01 = YoutubeMessageScene01;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    var TwitterLogin = (function () {
        function TwitterLogin(button, callback) {
            this.authenticated = false;
            this.button = button;
            this.callback = callback;
            window.addEventListener("message", $.proxy(function (ev) {
                if (ev.data.message === "handleTwitterLogin") {
                    // noinspection JSPotentiallyInvalidUsageOfClassThis
                    clearInterval(this.interval);
                    // noinspection JSPotentiallyInvalidUsageOfClassThis
                    this.handleTwitterLogin(ev.data);
                    ev.source.close();
                }
            }, this));
        }
        TwitterLogin.prototype.login = function () {
            var url = AjaxUrlProvider.getLegacyApiBaseUrl() + '/auth.twitter.php?authorize=1';
            var child = Utils.openWindow(url, 500, 600);
            this.interval = setInterval($.proxy(function () {
                if (child.closed) {
                    // noinspection JSPotentiallyInvalidUsageOfClassThis
                    clearInterval(this.interval);
                    return;
                }
                child.postMessage({ message: "requestLogin" }, "*");
            }, this), 500);
        };
        TwitterLogin.prototype.handleTwitterLogin = function (response) {
            if (this.callback) {
                this.callback(response);
            }
        };
        return TwitterLogin;
    })();
    Nickel.TwitterLogin = TwitterLogin;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    var TweetScene01VO = (function (_super) {
        __extends(TweetScene01VO, _super);
        function TweetScene01VO() {
            _super.apply(this, arguments);
            this.type = "TweetScene01";
            this.sceneData = {
                toItem: '',
                replyItem: '',
                videoItem: '',
                message: '',
                fromHandle: '',
                credentials: {
                    oauthToken: '',
                    oauthTokenSecret: ''
                }
            };
        }
        return TweetScene01VO;
    })(Nickel.SceneVO);
    Nickel.TweetScene01VO = TweetScene01VO;
    var TweetScene01 = (function (_super) {
        __extends(TweetScene01, _super);
        function TweetScene01(container, data, index, delegate) {
            _super.call(this, container, data, index, delegate);
            this.label = "Tweet Scene";
            if (data) {
                this.data = data;
            }
            else {
                this.data = new TweetScene01VO();
            }
            this.viewLoaded(Main.templates.find('.tweetscene01').clone());
        }
        TweetScene01.prototype.loginClicked = function (e) {
            if (e) {
                e.preventDefault();
            }
            this.login.login();
        };
        TweetScene01.prototype.loggedIn = function (response) {
            this.data.sceneData.fromHandle = response.username;
            this.data.sceneData.credentials.oauthToken = response.access_token;
            this.data.sceneData.credentials.oauthTokenSecret = response.access_token_secret;
        };
        TweetScene01.prototype.viewLoaded = function (v) {
            _super.prototype.viewLoaded.call(this, v);
            this.btnLogin = this.content.find('.twitterLogin');
            this.btnLogin.bind('click', $.proxy(this.loginClicked, this));
            this.login = new Nickel.TwitterLogin(this.btnLogin, $.proxy(this.loggedIn, this));
            this.content.find('select.inventoryItems.to').bind('change', $.proxy(this.changeInventoryToSelect, this));
            this.content.find('select.inventoryItems.reply').bind('change', $.proxy(this.changeInventoryReplySelect, this));
            this.content.find('select.sceneItems.video').bind('change', $.proxy(this.changeSceneVideoSelect, this));
        };
        TweetScene01.prototype.changeInventoryToSelect = function (e) {
            this.data.sceneData.toItem = $(e.currentTarget).val();
        };
        TweetScene01.prototype.changeInventoryReplySelect = function (e) {
            this.data.sceneData.replyItem = $(e.currentTarget).val();
        };
        TweetScene01.prototype.changeSceneVideoSelect = function (e) {
            this.data.sceneData.videoItem = $(e.currentTarget).val();
        };
        TweetScene01.prototype.populateInventorySelect = function () {
            var optHtml = '<option value=""></option>';
            for (var invId in this.delegate.data.inventory) {
                var invItem = this.delegate.data.inventory[invId];
                if (!Nickel.InventoryInterface.isMediaBasedItem(invItem['type'].toLowerCase())) {
                    optHtml += '<option value="' + invId + '">' + (invItem.label || invItem.name) + '</option>';
                }
            }
            this.content.find('select.inventoryItems').html(optHtml);
            this.content.find('select.inventoryItems.to').val(this.data.sceneData.toItem);
            this.content.find('select.inventoryItems.reply').val(this.data.sceneData.replyItem);
        };
        TweetScene01.prototype.populateSceneSelect = function () {
            var optHtml = '<option value=""></option>';
            for (var sceneId in this.delegate.data.scenes) {
                var scene = this.delegate.data.scenes[sceneId];
                if (scene.type.indexOf('Video') != -1) {
                    optHtml += '<option value="' + sceneId + '">' + sceneId + '</option>';
                }
            }
            this.content.find('select.sceneItems').html(optHtml);
            this.content.find('select.sceneItems.video').val(this.data.sceneData.videoItem);
        };
        TweetScene01.prototype.showMe = function () {
            _super.prototype.showMe.call(this);
            this.populateInventorySelect();
            this.populateSceneSelect();
        };
        TweetScene01.prototype.killMe = function () {
            _super.prototype.killMe.call(this);
        };
        return TweetScene01;
    })(Nickel.Scene);
    Nickel.TweetScene01 = TweetScene01;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    var YoutubeLogin = (function () {
        function YoutubeLogin(button, callback) {
            this.authenticated = false;
            this.button = button;
            this.callback = callback;
            window.addEventListener("message", $.proxy(function (ev) {
                if (ev.data.message === "handleYoutubeLogin") {
                    // noinspection JSPotentiallyInvalidUsageOfClassThis
                    clearInterval(this.interval);
                    // noinspection JSPotentiallyInvalidUsageOfClassThis
                    this.handleYoutubeLogin(ev.data);
                    ev.source.close();
                }
            }, this));
        }
        YoutubeLogin.prototype.login = function () {
            var url = AjaxUrlProvider.getLegacyApiBaseUrl() + '/auth.youtube.php';
            var child = Utils.openWindow(url, 500, 600);
            this.interval = setInterval($.proxy(function () {
                if (child.closed) {
                    // noinspection JSPotentiallyInvalidUsageOfClassThis
                    clearInterval(this.interval);
                    return;
                }
                child.postMessage({ message: "requestLogin" }, "*");
            }, this), 500);
        };
        YoutubeLogin.prototype.handleYoutubeLogin = function (response) {
            if (this.callback) {
                this.callback(response);
            }
        };
        return YoutubeLogin;
    })();
    Nickel.YoutubeLogin = YoutubeLogin;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    var YoutubeScene01VO = (function (_super) {
        __extends(YoutubeScene01VO, _super);
        function YoutubeScene01VO() {
            _super.apply(this, arguments);
            this.type = "YoutubeScene01";
            this.sceneData = {
                videoItem: '',
                metadataScript: '',
                channelName: '',
                credentials: {
                    id: null
                },
                onProcessed: {
                    requestType: null,
                    url: null
                }
            };
        }
        return YoutubeScene01VO;
    })(Nickel.SceneVO);
    Nickel.YoutubeScene01VO = YoutubeScene01VO;
    var YoutubeScene01 = (function (_super) {
        __extends(YoutubeScene01, _super);
        function YoutubeScene01(container, data, index, delegate) {
            _super.call(this, container, data, index, delegate);
            this.label = "Youtube Scene";
            if (data) {
                this.data = data;
            }
            else {
                this.data = new YoutubeScene01VO();
            }
            this.viewLoaded(Main.templates.find('.youtubescene01').clone());
        }
        YoutubeScene01.prototype.loginClicked = function (e) {
            if (e) {
                e.preventDefault();
            }
            this.login.login();
        };
        YoutubeScene01.prototype.loggedIn = function (response) {
            this.data.sceneData.channelName = response.user;
            this.data.sceneData.credentials.id = response.credentials_id;
        };
        YoutubeScene01.prototype.viewLoaded = function (v) {
            _super.prototype.viewLoaded.call(this, v);
            this.btnLogin = this.content.find('.youtubeLogin');
            this.btnLogin.bind('click', $.proxy(this.loginClicked, this));
            this.login = new Nickel.YoutubeLogin(this.btnLogin, $.proxy(this.loggedIn, this));
            this.content.find('.btnSelectMetadataScript').bind('click', $.proxy(this.selectMetadataScriptClicked, this));
            this.content.find('select.sceneItems.video').bind('change', $.proxy(this.changeSceneVideoSelect, this));
        };
        YoutubeScene01.prototype.changeSceneVideoSelect = function (e) {
            this.data.sceneData.videoItem = $(e.currentTarget).val();
        };
        YoutubeScene01.prototype.populateSceneSelect = function () {
            var optHtml = '<option value=""></option>';
            for (var sceneId in this.delegate.data.scenes) {
                var scene = this.delegate.data.scenes[sceneId];
                if (scene.type.indexOf('Video') != -1) {
                    optHtml += '<option value="' + sceneId + '">' + sceneId + '</option>';
                }
            }
            this.content.find('select.sceneItems').html(optHtml);
            this.content.find('select.sceneItems.video').val(this.data.sceneData.videoItem);
        };
        YoutubeScene01.prototype.showMe = function () {
            _super.prototype.showMe.call(this);
            this.populateSceneSelect();
        };
        YoutubeScene01.prototype.selectMetadataScriptClicked = function (e) {
            S3FilePicker.getInstance().open($(e.target).parent().parent().find('input').val(), $.proxy(function (fileKey) {
                this.data.sceneData.metadataScript = fileKey.trim();
                this.saveData();
            }, this));
        };
        return YoutubeScene01;
    })(Nickel.Scene);
    Nickel.YoutubeScene01 = YoutubeScene01;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    var ApiScene01VO = (function (_super) {
        __extends(ApiScene01VO, _super);
        function ApiScene01VO() {
            _super.apply(this, arguments);
            this.type = "ApiScene01";
            this.sceneData = {
                url: '',
                requestType: 'get',
                setupScript: '',
                videoItem: '',
                headers: [],
                additionalParams: [],
            };
        }
        return ApiScene01VO;
    })(Nickel.SceneVO);
    Nickel.ApiScene01VO = ApiScene01VO;
    var ApiScene01 = (function (_super) {
        __extends(ApiScene01, _super);
        function ApiScene01(container, data, index, delegate) {
            _super.call(this, container, data, index, delegate);
            this.label = "API Request Scene";
            if (data) {
                this.data = data;
            }
            else {
                this.data = new ApiScene01VO();
            }
            this.viewLoaded(Main.templates.find('.apiscene01').clone());
        }
        ApiScene01.prototype.viewLoaded = function (v) {
            _super.prototype.viewLoaded.call(this, v);
            this.content.find('.btnSelectSetupScript').bind('click', $.proxy(this.selectSetupScriptClicked, this));
            this.content.find('.btnAddParam').bind('click', $.proxy(this.addParameter, this));
            this.content.find('.btnAddHeader').bind('click', $.proxy(this.addHeader, this));
            this.updateParameters();
            this.updateHeaders();
        };
        /**
         * Adds an empty / blank parameter to the parameter list.
         */
        ApiScene01.prototype.addParameter = function () {
            this.data.sceneData.additionalParams.push({ key: '', value: '' });
            this.populateInventorySelects();
            this.updateParameters();
        };
        /**
         * Adds an empty / blank header to the header list.
         */
        ApiScene01.prototype.addHeader = function () {
            this.data.sceneData.headers.push({ key: '', value: '' });
            this.updateHeaders();
        };
        /**
         * Removes a parameter from the parameter list.
         * @param e The click event.
         */
        ApiScene01.prototype.removeParameter = function (e) {
            this.data.sceneData.additionalParams.splice($(e.currentTarget).parent().parent().index(), 1);
            this.updateParameters();
        };
        /**
         * Removes a header from the header list.
         * @param e The click event.
         */
        ApiScene01.prototype.removeHeader = function (e) {
            this.data.sceneData.headers.splice($(e.currentTarget).parent().parent().index(), 1);
            this.updateHeaders();
        };
        /**
         * Called every time the parameter list is updated.
         */
        ApiScene01.prototype.updateParameters = function () {
            // refresh delete click event so deleted entries are unbound and new entries are bound
            this.content.find('.btnRemoveParam').unbind('click').bind('click', $.proxy(this.removeParameter, this));
        };
        /**
         * Called every time the header list is updated.
         */
        ApiScene01.prototype.updateHeaders = function () {
            // refresh delete click event so deleted entries are unbound and new entries are bound
            this.content.find('.btnRemoveHeader').unbind('click').bind('click', $.proxy(this.removeHeader, this));
        };
        /**
         * Called when the Select Script button is pressed.
         * @param e The click event.
         */
        ApiScene01.prototype.selectSetupScriptClicked = function (e) {
            S3FilePicker.getInstance().open($(e.target).parent().parent().find('input').val(), $.proxy(function (fileKey) {
                this.data.sceneData.setupScript = fileKey.trim();
                this.saveData();
            }, this));
        };
        /**
         * Populate the "Attach Video" select field with the latest list of video scenes on the act.
         */
        ApiScene01.prototype.populateSceneSelect = function () {
            var select = this.content.find('select.sceneItems');
            select.empty().append(new Option('', ''));
            for (var sceneId in this.delegate.data.scenes) {
                if (this.delegate.data.scenes.hasOwnProperty(sceneId)) {
                    var scene = this.delegate.data.scenes[sceneId];
                    if (scene.type.indexOf('Video') != -1) {
                        select.append(new Option(sceneId, sceneId));
                    }
                }
            }
            this.content.find('select.sceneItems.video').val(this.data.sceneData.videoItem);
        };
        /**
         * Populate the "Parameters" select fields with the latest list of text-based inventory items on the act.
         */
        ApiScene01.prototype.populateInventorySelects = function () {
            var _this = this;
            var select = this.content.find('.params .param select');
            select.empty().append(new Option('', ''));
            select.append(new Option('Experience ID', 'experience_id'));
            select.append(new Option('Story ID', 'story_id'));
            select.append(new Option('Story Image URL', 'story_image_url'));
            select.append(new Option('Act ID', 'act_id'));
            select.append(new Option('Scene ID', 'scene_id'));
            select.append(new Option('Scene Duration', 'scene_duration'));
            for (var sceneId in this.delegate.data.scenes) {
                if (this.delegate.data.scenes.hasOwnProperty(sceneId)) {
                    var scene = this.delegate.data.scenes[sceneId];
                    if (scene.id === this.data.sceneData.videoItem) {
                        var increments = [];
                        var formatNames = [];
                        var formatKeys = [];
                        for (var j = 0; j < scene.sceneData.encodingSettings.length; j++) {
                            var encodingSettings = scene.sceneData.encodingSettings[j];
                            if (encodingSettings.extension && encodingSettings.height) {
                                var formatName = encodingSettings.name;
                                var formatKey = encodingSettings.extension + '_' + encodingSettings.height;
                                if (typeof increments[formatKey] === 'undefined') {
                                    increments[formatKey] = 0;
                                }
                                increments[formatKey]++;
                                formatNames.push(formatName);
                                if (formatKeys.indexOf(formatKey) <= -1) {
                                    formatKeys.push(formatKey);
                                }
                                else {
                                    formatKeys.push(formatKey + '_' + increments[formatKey]);
                                }
                            }
                        }
                        for (var k = 0; k < formatKeys.length; k++) {
                            select.append(new Option(formatNames[k], formatKeys[k]));
                        }
                        select.append(new Option('M3U8', 'm3u8'));
                    }
                }
            }
            for (var invId in this.delegate.data.inventory) {
                if (this.delegate.data.inventory.hasOwnProperty(invId)) {
                    var invItem = this.delegate.data.inventory[invId];
                    select.append(new Option(invItem.label || invItem.name, invId));
                }
            }
            select.each(function (index, element) {
                $(element).val(_this.data.sceneData.additionalParams[index].value);
            });
        };
        ApiScene01.prototype.showMe = function () {
            _super.prototype.showMe.call(this);
            this.populateSceneSelect();
            this.populateInventorySelects();
        };
        return ApiScene01;
    })(Nickel.Scene);
    Nickel.ApiScene01 = ApiScene01;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    var CutVO = (function () {
        function CutVO() {
            this.id = "";
            this.name = "New Cut";
            this.label = "New Cut";
            this.startFrame = "";
            this.endFrame = "";
            this.overlays = [];
            this.description = "";
            this.previewURL = "";
            this.staticOverlayMethod = "auto";
            this.clipSwap = false;
            this.tags = [];
            this.id = Utils.generateUUID();
        }
        return CutVO;
    })();
    Nickel.CutVO = CutVO;
    var Cut = (function (_super) {
        __extends(Cut, _super);
        function Cut(container, data, index, delegate) {
            _super.call(this, container, data, index, delegate);
            //cms config
            this.label = "Cut";
            this.videoPlayer = true;
            if (data) {
                this.data = data;
            }
            else {
                this.data = new CutVO();
                this.firstSave = true;
            }
            this.viewLoaded(Main.templates.find('.cut').clone());
        }
        Cut.prototype.viewLoaded = function (v) {
            _super.prototype.viewLoaded.call(this, v);
            //populate the class dropdown
            for (var i = 0; i < this.data.overlays.length; i++) {
                var overlay = new Nickel.Overlay(this.content.find(".childHolder"), this.data.overlays[i], i, this);
                this.children.push(overlay);
            }
            //buttons
            this.btnAddOverlay = this.content.find(".btnAddOverlay").bind("click", $.proxy(this.addOverlayBtnClicked, this));
            this.btnUpdateCut = this.content.find(".btnUpdateCut").bind('click', $.proxy(this.updateCut, this));
            this.btnMoveMenu = this.content.find(".btnMoveMenu");
            this.content.find(".btnEditFrames").bind('click', $.proxy(this.editClicked, this));
            //tag buttons
            this.content.find('select.tagSourceType').bind('change', $.proxy(this.changeTagSourceType, this));
            this.content.find('.btnAddTag').bind('click', $.proxy(this.addTag, this));
            this.content.find('.btnRemoveTag').unbind('click').bind('click', $.proxy(this.removeTag, this));
            this.content.find('.tags .tag').unbind('click').bind('click', $.proxy(this.toggleRequiredTag, this));
            this.content.find('.tagInput').bind('keypress', $.proxy(function (e) {
                var code = e.keyCode || e.which;
                if (code == 13) {
                    this.addTag();
                    e.preventDefault();
                }
            }, this));
            //components
            this.stopWatch = new Stopwatch(this.content.find('.stopwatch'));
        };
        Cut.prototype.checkUrl = function () {
            if (this.data.url) {
                var urlPieces = this.data.url.split('/');
                var name = urlPieces[urlPieces.length - 1];
                var namePieces = name.split('_');
                var frames = namePieces[namePieces.length - 1].split('.')[0].split('-');
                delete namePieces[namePieces.length - 1];
                var prefix = namePieces.join('_');
                if (prefix.substr(prefix.length - 1)) {
                    prefix = prefix.substring(0, prefix.length - 1);
                }
                if (!(this.delegate.data.sceneData.videoFile.url.indexOf(prefix) > -1) || (!(frames[0] == this.data.startFrame && frames[1] == this.data.endFrame))) {
                    // If frames don't match, or base video has been changed, erase URL so it regenerates
                    this.data.url = null;
                }
            }
        };
        Cut.prototype.updateCut = function () {
            this.checkUrl();
            // Make cut after story data is saved
            EventBus.addEventListener(Nickel.Story.SAVED_STORY, $.proxy(this.makeCut, this), this);
            this.saveData();
        };
        Cut.prototype.makeCut = function () {
            EventBus.removeEventListener(Nickel.Story.SAVED_STORY, $.proxy(this.makeCut, this), this);
            EventBus.dispatch(Nickel.VideoPlayer.CLEAR_VIDEO);
            this.stopWatch.start();
            this.data.previousRenderTime = this.data.renderTime;
            Nickel.JobHandler.runJob('makeCut', {
                'story_id': this.delegate.delegate.delegate.data.id,
                'act_id': this.delegate.delegate.data.id,
                'scene_id': this.delegate.data.id,
                'cut_id': this.data.id,
            }, $.proxy(function (jobId, jobName, output) {
                // noinspection JSPotentiallyInvalidUsageOfClassThis
                this.stopWatch.stop();
                if (output && output.url && output.previewURL) {
                    $.extend(true, this.data, output);
                    // noinspection JSPotentiallyInvalidUsageOfClassThis
                    this.data.renderTime = this.stopWatch.curTime;
                    // noinspection JSPotentiallyInvalidUsageOfClassThis
                    this.saveData();
                    // noinspection JSPotentiallyInvalidUsageOfClassThis
                    this.loadVideo();
                }
            }, this), $.proxy(function () {
                // noinspection JSPotentiallyInvalidUsageOfClassThis
                this.stopWatch.stop();
            }, this));
        };
        Cut.prototype.editClicked = function () {
            var vo = (this.pickerVO) ? this.pickerVO : this.createPickerVO();
            this.delegate.cutBeingEdited = this;
            this.delegate.editCut(vo);
        };
        Cut.prototype.createPickerVO = function () {
            var vo = new Nickel.CutPickerVO();
            vo.inFrame = this.data.startFrame;
            vo.outFrame = this.data.endFrame;
            vo.inTime = this.data.startFrame / this.delegate.data.sceneData.videoFile.rate;
            vo.outTime = this.data.endFrame / this.delegate.data.sceneData.videoFile.rate;
            return vo;
        };
        Cut.prototype.saveData = function () {
            EventBus.dispatch(Nickel.Story.SAVE_STORY);
        };
        Cut.prototype.deleteChild = function () {
            _super.prototype.deleteChild.call(this);
            var data = this.data.overlays.splice(this.childToDelete, 1)[0];
        };
        Cut.prototype.addOverlayBtnClicked = function () {
            this.addOverlay();
        };
        Cut.prototype.addOverlay = function (data) {
            if (data === void 0) { data = null; }
            var overlay = this.addChild(data, this.children.length);
            this.data.overlays.push(overlay.data);
            this.showChild(overlay.index);
            this.saveData();
        };
        Cut.prototype.duplicateChildItem = function (overlay) {
            var data = $.extend(true, {}, overlay.data);
            data.id = Utils.generateUUID();
            this.addOverlay(data);
        };
        Cut.prototype.addChild = function (data, index) {
            var overlay = new Nickel.Overlay(this.content.find(".childHolder"), data, index, this);
            this.children.push(overlay);
            return overlay;
        };
        Cut.prototype.showMe = function () {
            _super.prototype.showMe.call(this);
            this.loadVideo();
            this.populateInventorySelect();
        };
        Cut.prototype.loadVideo = function () {
            EventBus.dispatch(Nickel.VideoPlayer.LOAD_VIDEO, { src: this.data.previewURL });
        };
        /**
         * Specifies where the story's data can be found on this level.
         * @param storyData An object containing the story JSON structure.
         */
        Cut.prototype.onDataReload = function (storyData) {
            var cuts = storyData['acts'][this.delegate.delegate.data.id]['scenes'][this.delegate.data.id]['sceneData']['cuts'];
            for (var i = 0; i < cuts.length; i++) {
                if (cuts[i]['id'] == this.data.id) {
                    $.extend(true, this.data, cuts[i]);
                    break;
                }
            }
        };
        Cut.prototype.populateInventorySelect = function () {
            var act = this.delegate.delegate;
            var select = this.content.find('.inventoryItemIds');
            var currentValue = select.val();
            select.empty();
            for (var invId in act.data.inventory) {
                if (act.data.inventory.hasOwnProperty(invId)) {
                    var invItemType = act.data.inventory[invId].type.toLowerCase();
                    if (invItemType != 'image' && invItemType != 'video' && invItemType != 'audio') {
                        select.append(new Option(act.data.inventory[invId].label || act.data.inventory[invId].name, invId));
                    }
                }
            }
            if (currentValue != null) {
                select.val(currentValue);
            }
        };
        Cut.prototype.changeTagSourceType = function (e) {
            var select = $(e.currentTarget);
            this.content.find('.tagInput').addClass('hidden');
            this.content.find('.tagInput[data-source-type=' + select.val() + ']').removeClass('hidden');
        };
        Cut.prototype.addTag = function () {
            var tagInput = this.content.find(".tagInput").not('.hidden');
            var type = this.content.find("select.tagSourceType").val();
            var value = tagInput.val();
            if (value != null) {
                value = value.trim();
            }
            if (value != null && value != "" && this.getTagIndex(value, type) < 0) {
                this.data.tags.push({ type: type, value: value, required: false });
                this.content.find('.btnRemoveTag').last().bind('click', $.proxy(this.removeTag, this));
                this.content.find('.tags .tag').last().bind('click', $.proxy(this.toggleRequiredTag, this));
            }
            if (tagInput.get(0).tagName.toLowerCase() == 'input') {
                tagInput.val("");
            }
        };
        Cut.prototype.removeTag = function (e) {
            var tagValue = $(e.currentTarget).parent().find('.tagValue');
            var tagIndex = this.getTagIndex(tagValue.text(), tagValue.attr('data-type'));
            if (tagIndex >= 0) {
                this.data.tags.splice(tagIndex, 1);
                e.stopPropagation();
            }
        };
        Cut.prototype.toggleRequiredTag = function (e) {
            var tagValue = $(e.currentTarget).find('.tagValue');
            var tagIndex = this.getTagIndex(tagValue.text(), tagValue.attr('data-type'));
            if (tagIndex >= 0) {
                this.data.tags[tagIndex].required = !this.data.tags[tagIndex].required;
                e.stopPropagation();
            }
        };
        Cut.prototype.getTagIndex = function (tagValue, tagType) {
            if (!this.data.tags) {
                this.data.tags = [];
            }
            for (var i = 0; i < this.data.tags.length; i++) {
                if (this.data.tags[i].value == tagValue && this.data.tags[i].type == tagType) {
                    return i;
                }
            }
            return -1;
        };
        return Cut;
    })(Nickel.Level);
    Nickel.Cut = Cut;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    var OverlayVO = (function () {
        function OverlayVO() {
            this.id = "";
            this.name = "New Overlay";
            this.label = "New Overlay";
            this.description = "";
            this.type = "";
            this.enabled = true;
            this.position = "";
            this.position_inputs = null;
            this.layers = [];
            this.width = null;
            this.height = null;
            this.background_color = null;
            this.phantom_version = 2;
            this.effects = null;
            this.id = Utils.generateUUID();
        }
        return OverlayVO;
    })();
    Nickel.OverlayVO = OverlayVO;
    var AnimationVO = (function () {
        function AnimationVO() {
            this.ease = null;
            this.type = null;
            this.duration = null;
        }
        return AnimationVO;
    })();
    Nickel.AnimationVO = AnimationVO;
    var Overlay = (function (_super) {
        __extends(Overlay, _super);
        function Overlay(container, data, index, delegate) {
            var _this = this;
            _super.call(this, container, data, index, delegate);
            //cms config
            this.label = "Overlay";
            this.videoPlayer = true;
            if (data) {
                if (!data.hasOwnProperty("phantom_version")) {
                    data.phantom_version = 1;
                }
                this.data = data;
            }
            else {
                this.data = new OverlayVO();
                this.firstSave = true;
            }
            //convert [] to {}
            if (this.data.effects instanceof Array && this.data.effects.length == 0) {
                this.data.effects = {};
            }
            this.viewLoaded(Main.templates.find('.overlay').clone());
            this.sourceContainer = this.content.find('.sourceInput');
            this.inputContainer = this.content.find('.positionInput');
            this.content.find('.groupHeader').bind('click', function (e) { return _this.toggleGroup(e); });
            this.content.find('.effect').bind('change', function (e) { return _this.effectUpdated(e); });
            this.content.find('.animationInput').bind('change', function (e) { return _this.updateAnimationInput(e); });
            this.setEffects();
            this.setPositionInput();
            this.setSource(this.sourceSelect.val());
            this.setAnimation('animate_on');
            this.setAnimation('animate_off');
        }
        Overlay.prototype.viewLoaded = function (v) {
            var _this = this;
            _super.prototype.viewLoaded.call(this, v);
            this.sourceSelect = this.content.find('.sourceTypes').bind('change', function (e) { return _this.sourceChanged(e); });
            if (this.data.source && this.data.source.from) {
                this.sourceSelect.val(this.data.source.from);
            }
            else if (this.data.generating_script) {
                this.sourceSelect.val('script');
            }
            this.positionSelect = this.content.find('.positionTypes').bind('change', function (e) { return _this.positionChanged(e); });
            this.positionSelect.val(this.data.position);
            this.typeSelect = this.content.find('.overlayTypes').bind('change', function (e) { return _this.typeChanged(e); });
            this.typeSelect.val(this.data.type);
            this.content.find('.btnUpdateOverlay').bind('click', $.proxy(this.updateClicked, this));
        };
        Overlay.prototype.updateAnimationInput = function (e) {
            var targ = $(e.target);
            var key = targ.attr('data-key');
            var subKey = targ.attr('data-val');
            var val = this.transformVal(targ.val());
            if (val !== "") {
                var obj = this.data.effects[key];
                if (!obj) {
                    this.data.effects[key] = new AnimationVO();
                }
                this.data.effects[key][subKey] = val;
            }
            else {
                if (!this.checkIfSet(key)) {
                    delete this.data.effects[key];
                }
            }
        };
        Overlay.prototype.checkIfSet = function (key) {
            var set = true;
            var cont = this.content.find('.animationGroup[data-key="' + key + '"]');
            cont.find('.animationInput').each(function () {
                var input = $(this);
                if (!input.val()) {
                    set = false;
                }
            });
            return set;
        };
        Overlay.prototype.setAnimation = function (key) {
            if (!this.data.effects) {
                this.data.effects = {};
            }
            if (this.data.effects[key]) {
                var data = this.data.effects[key];
                var cont = this.content.find('.animationGroup[data-key="' + key + '"]');
                for (var k in data) {
                    if (data.hasOwnProperty(k)) {
                        var c = cont.find('.animationInput[data-val="' + k + '"]');
                        c.val(data[k]);
                    }
                }
            }
        };
        Overlay.prototype.sourceChanged = function (e) {
            this.setSource($(e.target).val());
        };
        Overlay.prototype.positionChanged = function (e) {
            this.data.position = $(e.target).val();
            this.setPositionInput();
        };
        Overlay.prototype.typeChanged = function (e) {
            this.data.type = $(e.target).val();
            if (this.data.type == 'swap' || this.data.type == 'audio') {
                // Prevent source from getting saved on unsupported types
                if (this.source) {
                    this.source.killMe();
                    this.data.source = null;
                    this.source = null;
                }
            }
            else if (this.sourceSelect.val() === 'script') {
                // Handle conflicting rivets bindings to show/hide MediaScript field
                this.content.find('.generatingScript').show();
            }
            else if (this.source) {
                // Proxy type change event
                this.source.overlayTypeChanged(this.data.type);
            }
        };
        Overlay.prototype.setPositionInput = function () {
            if (this.positionInput) {
                this.positionInput.killMe();
                this.data.position_inputs = null;
                this.positionInput = null;
            }
            if (this.data.position) {
                switch (this.data.position) {
                    case "MotionTrackQuad":
                        this.positionInput = new Nickel.MTQuadInput(this.inputContainer, this.data.position_inputs, null, this);
                        break;
                    case "StaticRect":
                        this.positionInput = new Nickel.StaticRectInput(this.inputContainer, this.data.position_inputs, null, this);
                        break;
                    case "StaticQuad":
                        this.positionInput = new Nickel.StaticQuadInput(this.inputContainer, this.data.position_inputs, null, this);
                        break;
                    case "MotionTrackRect":
                        this.positionInput = new Nickel.MTRectInput(this.inputContainer, this.data.position_inputs, null, this);
                        break;
                }
                if (this.positionInput) {
                    this.data.position_inputs = this.positionInput.data;
                }
            }
        };
        Overlay.prototype.setSource = function (from) {
            if (this.source) {
                this.source.killMe();
                this.data.source = null;
                this.source = null;
            }
            if (from) {
                switch (from) {
                    case "script":
                        this.source = null;
                        break;
                    case "inventory_id":
                        this.source = new Nickel.InventorySource(this.sourceContainer, this.data.source, null, this);
                        break;
                    case "asset_id":
                        this.source = new Nickel.AssetIdSource(this.sourceContainer, this.data.source, null, this);
                        break;
                    case "asset_tags":
                        this.source = new Nickel.AssetTagsSource(this.sourceContainer, this.data.source, null, this);
                        break;
                }
                if (this.source) {
                    this.data.source = this.source.data;
                    this.data.generating_script = null;
                }
            }
        };
        Overlay.prototype.effectUpdated = function (e) {
            var targ = $(e.target);
            var key = targ.attr('data-effect');
            var val = targ.val();
            if (val !== "" && key) {
                val = this.transformVal(val);
                if (!this.data.effects) {
                    this.data.effects = {};
                }
                this.data.effects[key] = val;
            }
            else {
                delete this.data.effects[key];
            }
        };
        Overlay.prototype.setEffects = function () {
            if (this.data.effects) {
                for (var key in this.data.effects) {
                    if (this.data.effects.hasOwnProperty(key)) {
                        var value = this.data.effects[key];
                        var targ = this.content.find('.effect[data-effect="' + key + '"]');
                        targ.val(value);
                    }
                }
            }
        };
        Overlay.prototype.transformVal = function (val) {
            var str = isNaN(val);
            if (!str) {
                val = Number(val);
            }
            return val;
        };
        Overlay.prototype.toggleGroup = function (e) {
            var targ = $(e.target).parent();
            if (targ.hasClass('closed')) {
                targ.removeClass('closed');
                targ.find('.groupIcon').removeClass('fa-caret-right').addClass('fa-caret-down');
            }
            else {
                targ.addClass('closed');
                targ.find('.groupIcon').addClass('fa-caret-right').removeClass('fa-caret-down');
            }
        };
        Overlay.prototype.updateClicked = function () {
            this.delegate.updateCut();
        };
        Overlay.prototype.showMe = function () {
            _super.prototype.showMe.call(this);
            if (this.source) {
                this.source.showMe();
            }
            this.delegate.loadVideo();
            this.populateAssetSelects();
        };
        Overlay.prototype.populateAssetSelects = function () {
            Nickel.AssetProvider.getAssets($.proxy(function (assets) {
                var _this = this;
                var select = this.content.find('.assetIds');
                select.empty().append(new Option('', ''));
                select.each(function (index, element) {
                    var selectElement = $(element);
                    var assetType = selectElement.data('asset-type');
                    if (assets[assetType]) {
                        for (var id in assets[assetType]) {
                            if (assets[assetType].hasOwnProperty(id)) {
                                selectElement.append(new Option(assets[assetType][id], id));
                            }
                        }
                    }
                    var effect = selectElement.data('effect');
                    if (effect) {
                        if (_this.data.effects && _this.data.effects[effect]) {
                            selectElement.val(_this.data.effects[effect]);
                        }
                    }
                    else if (selectElement.hasClass('fromSourceData')) {
                        var dataPoint = selectElement.attr('rv-value').split('.').pop();
                        if (_this.data.source && _this.data.source[dataPoint]) {
                            selectElement.val(_this.data.source[dataPoint]);
                        }
                    }
                });
            }, this));
        };
        Overlay.prototype.hideMe = function () {
            _super.prototype.hideMe.call(this);
            if (this.source) {
                this.hideMe();
            }
        };
        Overlay.prototype.saveData = function () {
            EventBus.dispatch(Nickel.Story.SAVE_STORY);
        };
        return Overlay;
    })(Nickel.Level);
    Nickel.Overlay = Overlay;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    var InventorySourceVO = (function () {
        function InventorySourceVO() {
            this.from = "inventory_id";
            this.inventory_id = "";
        }
        return InventorySourceVO;
    })();
    Nickel.InventorySourceVO = InventorySourceVO;
    var AssetSourceVO = (function () {
        function AssetSourceVO() {
            this.asset_type = ""; // can be any asset type except audio: video, image, image_sequence, template
            this.asset_vars = []; // only used if type is template
        }
        return AssetSourceVO;
    })();
    Nickel.AssetSourceVO = AssetSourceVO;
    var AssetIdSourceVO = (function (_super) {
        __extends(AssetIdSourceVO, _super);
        function AssetIdSourceVO() {
            _super.apply(this, arguments);
            this.from = "asset_id";
            this.asset_id = "";
        }
        return AssetIdSourceVO;
    })(AssetSourceVO);
    Nickel.AssetIdSourceVO = AssetIdSourceVO;
    var AssetTagsSourceVO = (function (_super) {
        __extends(AssetTagsSourceVO, _super);
        function AssetTagsSourceVO() {
            _super.apply(this, arguments);
            this.from = "asset_tags";
            this.asset_tags = [];
        }
        return AssetTagsSourceVO;
    })(AssetSourceVO);
    Nickel.AssetTagsSourceVO = AssetTagsSourceVO;
    var OverlaySource = (function (_super) {
        __extends(OverlaySource, _super);
        function OverlaySource(container, data, index, delegate) {
            _super.call(this, container, data, index, delegate);
            if (data) {
                var keys = Object.keys(this.getVO());
                for (var dataKey in data) {
                    if (data.hasOwnProperty(dataKey) && keys.indexOf(dataKey) == -1) {
                        delete data[dataKey];
                    }
                }
                this.data = data;
            }
            else {
                this.data = this.getVO();
            }
            this.showInPopup = false;
            var selector = this.getTemplateSelector();
            if (!selector) {
                throw new Error('Template selector not configured on an overlay source');
            }
            this.viewLoaded(Main.templates.find(selector).clone());
            this.container.append(this.content);
        }
        /**
         * Creates the rivets data binding and binds all Level generic events.
         * @param v A JQuery object containing this level's content selector.
         */
        OverlaySource.prototype.viewLoaded = function (v) {
            this.content = v;
            this.rivets = rivets.bind(this.content, {
                "data": this.data,
                "delegate": this.delegate.data,
                "controller": this
            });
            $(window).bind("resize", $.proxy(this.resize, this));
        };
        OverlaySource.prototype.overlayTypeChanged = function (type) {
            // Override me
        };
        OverlaySource.prototype.getTemplateSelector = function () {
            return null;
        };
        OverlaySource.prototype.getVO = function () {
            return {};
        };
        return OverlaySource;
    })(Nickel.Level);
    Nickel.OverlaySource = OverlaySource;
    var InventorySource = (function (_super) {
        __extends(InventorySource, _super);
        function InventorySource(container, data, index, delegate) {
            _super.call(this, container, data, index, delegate);
            this.populateInventorySelect();
        }
        InventorySource.prototype.getTemplateSelector = function () {
            return '.inventoryIdSource';
        };
        InventorySource.prototype.getVO = function () {
            return new InventorySourceVO();
        };
        InventorySource.prototype.overlayTypeChanged = function (type) {
            this.data.inventory_id = '';
            this.populateInventorySelect();
        };
        InventorySource.prototype.showMe = function () {
            _super.prototype.showMe.call(this);
            this.populateInventorySelect();
        };
        InventorySource.prototype.populateInventorySelect = function () {
            var overlay = this.delegate;
            var act = overlay.delegate.delegate.delegate;
            var select = this.content.find('.inventoryItemIds');
            select.empty().append(new Option('', ''));
            for (var invId in act.data.inventory) {
                if (act.data.inventory.hasOwnProperty(invId)) {
                    var invItemType = act.data.inventory[invId].type.toLowerCase();
                    if (invItemType == overlay.data.type) {
                        select.append(new Option(act.data.inventory[invId].label || act.data.inventory[invId].name, invId));
                    }
                }
            }
            select.val(this.data.inventory_id);
        };
        return InventorySource;
    })(OverlaySource);
    Nickel.InventorySource = InventorySource;
    var AssetIdSource = (function (_super) {
        __extends(AssetIdSource, _super);
        function AssetIdSource(container, data, index, delegate) {
            _super.call(this, container, data, index, delegate);
            this.delegate.populateAssetSelects();
        }
        AssetIdSource.prototype.getTemplateSelector = function () {
            return '.assetIdSource';
        };
        AssetIdSource.prototype.getVO = function () {
            return new AssetIdSourceVO();
        };
        AssetIdSource.prototype.overlayTypeChanged = function (type) {
            if (["template"].indexOf(this.data.asset_type) == -1) {
                this.data.asset_type = '';
                this.data.asset_vars = [];
                this.data.asset_id = '';
            }
        };
        AssetIdSource.prototype.viewLoaded = function (v) {
            _super.prototype.viewLoaded.call(this, v);
            // parameter setup
            this.content.find('.btnAddAssetParameter').bind('click', $.proxy(this.addParameter, this));
            this.updateParameters();
        };
        // parameter functionality
        AssetIdSource.prototype.addParameter = function () {
            this.data.asset_vars.push({ var_type: 'text', var_source: 'text', key: '', value: '' });
            this.updateParameters();
        };
        AssetIdSource.prototype.removeParameter = function (e) {
            this.data.asset_vars.splice($(e.currentTarget).parent().parent().index(), 1);
            this.updateParameters();
        };
        AssetIdSource.prototype.updateParameters = function () {
            // refresh delete click event so deleted entries are unbound and new entries are bound
            this.content.find('.btnRemoveAssetParameter').unbind('click').bind('click', $.proxy(this.removeParameter, this));
        };
        return AssetIdSource;
    })(OverlaySource);
    Nickel.AssetIdSource = AssetIdSource;
    var AssetTagsSource = (function (_super) {
        __extends(AssetTagsSource, _super);
        function AssetTagsSource(container, data, index, delegate) {
            _super.call(this, container, data, index, delegate);
            this.populateInventorySelect();
        }
        AssetTagsSource.prototype.getTemplateSelector = function () {
            return '.assetTagsSource';
        };
        AssetTagsSource.prototype.getVO = function () {
            return new AssetTagsSourceVO();
        };
        AssetTagsSource.prototype.overlayTypeChanged = function (type) {
            if (["template"].indexOf(this.data.asset_type) == -1) {
                this.data.asset_type = '';
                this.data.asset_vars = [];
                this.data.asset_tags = [];
            }
        };
        AssetTagsSource.prototype.showMe = function () {
            _super.prototype.showMe.call(this);
            this.populateInventorySelect();
        };
        AssetTagsSource.prototype.viewLoaded = function (v) {
            _super.prototype.viewLoaded.call(this, v);
            // parameter setup
            this.content.find('.btnAddAssetParameter').bind('click', $.proxy(this.addParameter, this));
            this.updateParameters();
            // tag setup
            this.content.find('select.tagSourceType').bind('change', $.proxy(this.changeTagSourceType, this));
            this.content.find('.btnAddTag').bind('click', $.proxy(this.addTag, this));
            this.content.find('.btnRemoveTag').unbind('click').bind('click', $.proxy(this.removeTag, this));
            this.content.find('.tags .tag').unbind('click').bind('click', $.proxy(this.toggleRequiredTag, this));
            this.content.find('.tagInput').bind('keypress', $.proxy(function (e) {
                var code = e.keyCode || e.which;
                if (code == 13) {
                    this.addTag();
                    e.preventDefault();
                }
            }, this));
        };
        // parameter functionality
        AssetTagsSource.prototype.addParameter = function () {
            this.data.asset_vars.push({ var_type: 'text', var_source: 'text', key: '', value: '' });
            this.updateParameters();
        };
        AssetTagsSource.prototype.removeParameter = function (e) {
            this.data.asset_vars.splice($(e.currentTarget).parent().parent().index(), 1);
            this.updateParameters();
        };
        AssetTagsSource.prototype.updateParameters = function () {
            // refresh delete click event so deleted entries are unbound and new entries are bound
            this.content.find('.btnRemoveAssetParameter').unbind('click').bind('click', $.proxy(this.removeParameter, this));
        };
        // tag functionality
        AssetTagsSource.prototype.populateInventorySelect = function () {
            var act = this.delegate.delegate.delegate.delegate;
            var select = this.content.find('.inventoryItemIds');
            var currentValue = select.val();
            select.empty();
            for (var invId in act.data.inventory) {
                if (act.data.inventory.hasOwnProperty(invId)) {
                    var invItemType = act.data.inventory[invId].type.toLowerCase();
                    if (invItemType != 'image' && invItemType != 'video' && invItemType != 'audio') {
                        select.append(new Option(act.data.inventory[invId].label || act.data.inventory[invId].name, invId));
                    }
                }
            }
            if (currentValue != null) {
                select.val(currentValue);
            }
        };
        AssetTagsSource.prototype.changeTagSourceType = function (e) {
            var select = $(e.currentTarget);
            this.content.find('.tagInput').addClass('hidden');
            this.content.find('.tagInput[data-source-type=' + select.val() + ']').removeClass('hidden');
        };
        AssetTagsSource.prototype.addTag = function () {
            var tagInput = this.content.find(".tagInput").not('.hidden');
            var type = this.content.find("select.tagSourceType").val();
            var value = tagInput.val();
            if (value != null) {
                value = value.trim();
            }
            if (value != null && value != "" && this.getTagIndex(value, type) < 0) {
                this.data.asset_tags.push({ type: type, value: value, required: false });
                this.content.find('.btnRemoveTag').last().bind('click', $.proxy(this.removeTag, this));
                this.content.find('.tags .tag').last().bind('click', $.proxy(this.toggleRequiredTag, this));
            }
            if (tagInput.get(0).tagName.toLowerCase() == 'input') {
                tagInput.val("");
            }
        };
        AssetTagsSource.prototype.removeTag = function (e) {
            var tagValue = $(e.currentTarget).parent().find('.tagValue');
            var tagIndex = this.getTagIndex(tagValue.text(), tagValue.attr('data-type'));
            if (tagIndex >= 0) {
                this.data.asset_tags.splice(tagIndex, 1);
                e.stopPropagation();
            }
        };
        AssetTagsSource.prototype.toggleRequiredTag = function (e) {
            var tagValue = $(e.currentTarget).find('.tagValue');
            var tagIndex = this.getTagIndex(tagValue.text(), tagValue.attr('data-type'));
            if (tagIndex >= 0) {
                this.data.asset_tags[tagIndex].required = !this.data.asset_tags[tagIndex].required;
                e.stopPropagation();
            }
        };
        AssetTagsSource.prototype.getTagIndex = function (tagValue, tagType) {
            if (!this.data.asset_tags) {
                this.data.asset_tags = [];
            }
            for (var i = 0; i < this.data.asset_tags.length; i++) {
                if (this.data.asset_tags[i].value == tagValue && this.data.asset_tags[i].type == tagType) {
                    return i;
                }
            }
            return -1;
        };
        return AssetTagsSource;
    })(OverlaySource);
    Nickel.AssetTagsSource = AssetTagsSource;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    var MTQuadVO = (function () {
        function MTQuadVO() {
            this.coords = null;
        }
        return MTQuadVO;
    })();
    Nickel.MTQuadVO = MTQuadVO;
    var MTQuadInput = (function (_super) {
        __extends(MTQuadInput, _super);
        function MTQuadInput(container, data, index, delegate) {
            _super.call(this, container, data, index, delegate);
            this.perFrame = 8;
            if (data) {
                this.data = data;
            }
            else {
                this.data = new MTQuadVO();
            }
            this.showInPopup = false;
            this.viewLoaded(Main.templates.find('.inputMTQuad').clone());
            this.container.append(this.content);
            this.textArea = this.content.find('textarea');
            this.addConversionButton();
            this.addScaleInput();
            this.checkTextArea();
            this.addFrameCounter();
        }
        MTQuadInput.prototype.addScaleInput = function () {
            this.scaleContainer = $('<div class="coords-scale">scale:</div>');
            this.scaleInput = $('<input type = "number" min = "0" step = "any">');
            this.scaleContainer.append(this.scaleInput);
            this.content.append(this.scaleContainer);
        };
        MTQuadInput.prototype.addFrameCounter = function () {
            this.content.append('<div class="coords-counter">frames: <span>0</span></div>');
            this.textArea.bind('keyup', $.proxy(this.updateCoordsFrameCount, this));
            this.updateCoordsFrameCount({ 'target': this.textArea[0] });
        };
        MTQuadInput.prototype.addConversionButton = function () {
            var _this = this;
            this.btnConvertCoords = $('<button class = "btn btn-primary btn-xs btnConvertCoords" disabled>Format Corner Pin Data</button>');
            this.content.append(this.btnConvertCoords);
            this.btnConvertCoords.bind('click', function () { return _this.convertCornerPinData(); });
            this.textArea.bind('input propertychange"', function () { return _this.checkTextArea(); });
        };
        MTQuadInput.prototype.checkTextArea = function () {
            var val = this.textArea.val();
            if (val.indexOf('Adobe After Effects') != -1 && val.indexOf('Keyframe Data') != -1) {
                this.btnConvertCoords.removeAttr('disabled');
                this.scaleContainer.css('display', 'inline-block');
            }
            else {
                this.btnConvertCoords.attr('disabled', 'disabled');
                this.scaleContainer.css('display', 'none');
                this.scaleInput.val('');
            }
            this.updateCoordsFrameCount({ 'target': this.textArea[0] });
        };
        MTQuadInput.prototype.convertCornerPinData = function () {
            var data = this.textArea.val();
            if (data == undefined || data.trim() == '') {
                alert('Invalid data');
                return;
            }
            if (data.indexOf('ADBE Corner Pin') != -1) {
                this.convertMocha();
            }
            else if (data.indexOf('Effects Corner Pin') != -1) {
                this.convertAECorner();
            }
            else {
                this.convertAE();
            }
        };
        MTQuadInput.prototype.convertAE = function () {
            var data = this.textArea.val();
            var points = '';
            var transformScale = Number(this.scaleInput.val());
            if (transformScale == 0)
                transformScale = 1;
            //dimensions
            var width = Number(StringUtils.between(data, 'Source Width', 'Source Height'));
            var height = Number(StringUtils.between(data, 'Source Height', 'Source Pixel'));
            //scale
            var scale = StringUtils.between(data, 'Scale', 'Transform');
            var scaleLine = scale.split('\n')[2];
            var scaleArr = scaleLine.match(/\S+/g);
            var scaleX = Number(scaleArr[1]) / 100;
            var scaleY = Number(scaleArr[2]) / 100;
            //anchor
            var anchor = StringUtils.between(data, 'Anchor Point', 'End');
            var anchorLine = anchor.split('\n')[2];
            var anchorArr = anchorLine.match(/\S+/g);
            var anchorX = Number(anchorArr[1]);
            var anchorY = Number(anchorArr[2]);
            //position
            var position = StringUtils.between(data, 'Position', 'Transform');
            var positionLines = position.split('\n');
            positionLines.shift();
            positionLines.shift();
            positionLines.pop();
            positionLines.pop();
            //loop through all of the points and create the coords for that point
            var realWidth = width * scaleX * transformScale;
            var realHeight = height * scaleY * transformScale;
            var realAnchorX = anchorX * scaleX * transformScale;
            var realAnchorY = anchorY * scaleY * transformScale;
            for (var i = 0; i < positionLines.length; i++) {
                var line = positionLines[i];
                var arr = line.match(/\S+/g);
                var x = (Number(arr[1]) * transformScale) - realAnchorX;
                var y = (Number(arr[2]) * transformScale) - realAnchorY;
                //get corner pins from x,y
                var tlX = x;
                var tlY = y;
                var trX = x + realWidth;
                var trY = y;
                var brX = x + realWidth;
                var brY = y + realHeight;
                var blX = x;
                var blY = y + realHeight;
                //build out the coords string
                var pt = tlX.toFixed(2) + ',' + tlY.toFixed(2) + ',' + trX.toFixed(2) + ',' + trY.toFixed(2) + ',' + brX.toFixed(2) + ',' + brY.toFixed(2) + ',' + blX.toFixed(2) + ',' + blY.toFixed(2);
                if (i != positionLines.length - 1) {
                    pt += ',';
                }
                points += pt;
            }
            this.data.coords = points;
            this.checkTextArea();
        };
        MTQuadInput.prototype.convertMocha = function () {
            var data = this.textArea.val();
            var pinData = [];
            var transformScale = Number(this.scaleInput.val());
            if (transformScale == 0)
                transformScale = 1;
            pinData.push(StringUtils.between(data, 'Pin-0001', 'Effects'));
            pinData.push(StringUtils.between(data, 'Pin-0002', 'Effects'));
            pinData.push(StringUtils.between(data, 'Pin-0004', 'End'));
            pinData.push(StringUtils.between(data, 'Pin-0003', 'Effects'));
            for (var i = 0; i < 4; i++) {
                pinData[i] = pinData[i].split('\n');
                pinData[i].shift();
                pinData[i].shift();
                pinData[i].pop();
                pinData[i].pop();
            }
            var lines = data.split('\n');
            var sourceWidth, sourceHeight, fps, allObjArray = [];
            var coords = '';
            for (var index = 0; index < pinData[0].length; index++) {
                var currLine = lines[index];
                if (StringUtils.contains('Source Width', currLine)) {
                    sourceWidth = Number(StringUtils.afterLast(currLine, 'Source Width')).toString();
                }
                if (StringUtils.contains('Source Height', currLine)) {
                    sourceHeight = Number(StringUtils.afterLast(currLine, 'Source Height')).toString();
                }
                if (StringUtils.contains('Units Per Second', currLine)) {
                    fps = Number(StringUtils.afterLast(currLine, 'Units Per Second')).toString();
                }
                for (var i = 0; i < 4; i++) {
                    var arr = pinData[i][index].match(/\S+/g);
                    coords += Number(Number(arr[1]) * transformScale).toFixed(2);
                    coords += ",";
                    coords += Number(Number(arr[2]) * transformScale).toFixed(2);
                    coords += ",";
                }
            }
            ;
            //trim off the last comma
            coords = coords.slice(0, -1);
            //overwrite the existing value
            this.data.coords = coords;
            //this will disable the button if the coords converted successfully
            this.checkTextArea();
        };
        MTQuadInput.prototype.convertAECorner = function () {
            var data = this.textArea.val();
            var pinData = [];
            var transformScale = Number(this.scaleInput.val());
            if (transformScale == 0)
                transformScale = 1;
            pinData.push(StringUtils.between(data, 'Left #2', 'Effects'));
            pinData.push(StringUtils.between(data, 'Right #3', 'Effects'));
            pinData.push(StringUtils.between(data, 'Right #5', 'End'));
            pinData.push(StringUtils.between(data, 'Left #4', 'Effects'));
            for (var i = 0; i < 4; i++) {
                pinData[i] = pinData[i].split('\n');
                pinData[i].shift();
                pinData[i].shift();
                pinData[i].pop();
                pinData[i].pop();
            }
            var lines = data.split('\n');
            var sourceWidth, sourceHeight, fps, allObjArray = [];
            var coords = '';
            for (var index = 0; index < pinData[0].length; index++) {
                var currLine = lines[index];
                if (StringUtils.contains('Source Width', currLine)) {
                    sourceWidth = Number(StringUtils.afterLast(currLine, 'Source Width')).toString();
                }
                if (StringUtils.contains('Source Height', currLine)) {
                    sourceHeight = Number(StringUtils.afterLast(currLine, 'Source Height')).toString();
                }
                if (StringUtils.contains('Units Per Second', currLine)) {
                    fps = Number(StringUtils.afterLast(currLine, 'Units Per Second')).toString();
                }
                for (var i = 0; i < 4; i++) {
                    var arr = pinData[i][index].match(/\S+/g);
                    coords += Number(Number(arr[1]) * transformScale).toFixed(2);
                    coords += ",";
                    coords += Number(Number(arr[2]) * transformScale).toFixed(2);
                    coords += ",";
                }
            }
            ;
            //trim off the last comma
            coords = coords.slice(0, -1);
            //overwrite the existing value
            this.data.coords = coords;
            //this will disable the button if the coords converted successfully
            this.checkTextArea();
        };
        MTQuadInput.prototype.updateCoordsFrameCount = function (e) {
            var textField = $(e.target);
            var counterDiv = this.content.find('.coords-counter');
            var textVal = textField.val();
            if (textVal) {
                textVal = textVal.trim();
                if (textVal.substr(textVal.length - 1) == ',') {
                    textVal = textVal.substring(0, textVal.length - 1);
                }
                var commaCountMatches = textVal.match(/,/g);
                var commaCount = (!commaCountMatches) ? 0 : commaCountMatches.length + 1;
                var frameCount = commaCount / this.perFrame;
                counterDiv.find('span').html(frameCount);
                if (frameCount % 1 === 0) {
                    counterDiv.removeClass('bad');
                }
                else {
                    counterDiv.addClass('bad');
                }
            }
        };
        return MTQuadInput;
    })(Nickel.Level);
    Nickel.MTQuadInput = MTQuadInput;
    var MTRectVO = (function () {
        function MTRectVO() {
            this.coords = null;
        }
        return MTRectVO;
    })();
    Nickel.MTRectVO = MTRectVO;
    var MTRectInput = (function (_super) {
        __extends(MTRectInput, _super);
        function MTRectInput(container, data, index, delegate) {
            _super.call(this, container, data, index, delegate);
            this.perFrame = 2;
            if (data) {
                this.data = data;
            }
            else {
                this.data = new MTRectVO();
            }
            this.showInPopup = false;
            this.viewLoaded(Main.templates.find('.inputMTRect').clone());
            this.container.append(this.content);
            this.textArea = this.content.find('textarea');
            this.addFrameCounter();
        }
        MTRectInput.prototype.addFrameCounter = function () {
            this.content.append('<div class="coords-counter">frames: <span>0</span></div>');
            this.textArea.bind('keyup', $.proxy(this.updateCoordsFrameCount, this));
            this.updateCoordsFrameCount({ 'target': this.textArea[0] });
        };
        MTRectInput.prototype.updateCoordsFrameCount = function (e) {
            var textField = $(e.target);
            var counterDiv = this.content.find('.coords-counter');
            var textVal = textField.val();
            if (textVal) {
                textVal = textVal.trim();
                if (textVal.substr(textVal.length - 1) == ',') {
                    textVal = textVal.substring(0, textVal.length - 1);
                }
                var commaCountMatches = textVal.match(/,/g);
                var commaCount = (!commaCountMatches) ? 0 : commaCountMatches.length + 1;
                var frameCount = commaCount / this.perFrame;
                counterDiv.find('span').html(frameCount);
                if (frameCount % 1 === 0) {
                    counterDiv.removeClass('bad');
                }
                else {
                    counterDiv.addClass('bad');
                }
            }
        };
        return MTRectInput;
    })(Nickel.Level);
    Nickel.MTRectInput = MTRectInput;
    var StaticRectVO = (function () {
        function StaticRectVO() {
            this.x = null;
            this.y = null;
            this.width = null;
            this.height = null;
        }
        return StaticRectVO;
    })();
    Nickel.StaticRectVO = StaticRectVO;
    var StaticRectInput = (function (_super) {
        __extends(StaticRectInput, _super);
        function StaticRectInput(container, data, index, delegate) {
            _super.call(this, container, data, index, delegate);
            if (data) {
                this.data = data;
            }
            else {
                this.data = new StaticRectVO();
            }
            this.showInPopup = false;
            this.viewLoaded(Main.templates.find('.inputStaticRect').clone());
            this.container.append(this.content);
        }
        return StaticRectInput;
    })(Nickel.Level);
    Nickel.StaticRectInput = StaticRectInput;
    var StaticQuadVO = (function () {
        function StaticQuadVO() {
            this.top_left = {
                "x": null,
                "y": null
            };
            this.top_right = {
                "x": null,
                "y": null
            };
            this.bottom_left = {
                "x": null,
                "y": null
            };
            this.bottom_right = {
                "x": null,
                "y": null
            };
        }
        return StaticQuadVO;
    })();
    Nickel.StaticQuadVO = StaticQuadVO;
    var StaticQuadInput = (function (_super) {
        __extends(StaticQuadInput, _super);
        function StaticQuadInput(container, data, index, delegate) {
            _super.call(this, container, data, index, delegate);
            if (data) {
                this.data = data;
            }
            else {
                this.data = new StaticQuadVO();
            }
            this.showInPopup = false;
            this.viewLoaded(Main.templates.find('.inputStaticQuad').clone());
            this.container.append(this.content);
        }
        return StaticQuadInput;
    })(Nickel.Level);
    Nickel.StaticQuadInput = StaticQuadInput;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    /**
     * View for moderating and viewing user experiences associated with a specific story.
     * Moderatiors can view all user experience data including UGC, EGC, and account information.
     * Moderators can move stories into 5 different buckets (pending, approved, used, vip, rejected)
     */
    var ModerationView = (function (_super) {
        __extends(ModerationView, _super);
        /**
         * Stores the global vars, and adds the state change listener
         * @param container    A jQuery object containing the parent div for this view.
         * @param id            The unique ID associated with this view, used to determine if this view should be
         *     visible or not by listening to the browser state.
         * @param displayText    The text to show in the header for this view.
         */
        function ModerationView(container, id, displayText) {
            _super.call(this, container, id, displayText);
            /**
             * The label of the current story we're viewing
             */
            this.storyLabel = "";
            /**
             * The current page we're on
             */
            this.page = 1;
            /**
             * How many experiences we want per page
             */
            this.itemsPerPage = 50;
            /**
             * The current status we're viewing
             */
            this.bucket = "pending";
            /**
             * The different bucket names, used to create the bucket toggles
             */
            this.buckets = [];
            /**
             * The bucket button options for each experience item
             */
            this.bucketOptions = {
                "pending": ["approved", "used", "vip", "rejected"],
                "approved": ["pending", "used", "vip", "rejected"],
                "used": ["pending", "approved", "vip", "rejected"],
                "vip": ["pending", "approved", "used", "rejected"],
                "rejected": ["pending", "approved"]
            };
            /**
             * The total number of pages in this bucket
             */
            this.pages = 1;
            /**
             * The total number of experiences in this bucket
             */
            this.total = 1;
            /**
             * If this view has been initilized yet
             */
            this.initialized = false;
            /**
             * An array holding all ExperienceItem classes
             */
            this.experiences = [];
            /**
             * Filters for passing into the getExperiences call.
             */
            this.expFilter = "";
            this.experienceHolder = this.container.find('.experienceHolder');
            this.subHeader = this.container.find('.subHeader');
            this.bucketButtonHolder = this.container.find('.bucketButtons');
            this.storyDropdown = new Nickel.StoryPicker(this.container.find('.storyDropdownContainer'), null, this);
            this.loader = this.container.find('.expLoader');
            this.countLoader = this.container.find('.total .totalLoader');
            this.shortcuts = this.container.find('.shortcuts');
            this.overlay = new Nickel.ModerationOverlay(this.container, {}, this);
            this.rejectionSelector = new Nickel.RejectionSelector(this.container, {}, this);
        }
        /**
         * Binds this view to the DOM, populates the dynamic header buttons, binds all of the event listeners, and
         * get's the first batch of experiences.
         */
        ModerationView.prototype.init = function () {
            this.initialized = true;
            this.rivets = rivets.bind(this.container, {
                "controller": this
            });
            this.bindEvents();
        };
        /**
         * Binds all of the event listeners for this view.
         */
        ModerationView.prototype.bindEvents = function () {
            //buttons
            this.btnNextPage = this.container.find('.btnNextPage').bind('click', $.proxy(this.nextPageClicked, this));
            this.btnPrevPage = this.container.find('.btnPrevPage').bind('click', $.proxy(this.prevPageClicked, this));
            this.btnFirstPage = this.container.find('.btnFirstPage').bind('click', $.proxy(this.firstPageClicked, this));
            this.btnLastPage = this.container.find('.btnLastPage').bind('click', $.proxy(this.lastPageClicked, this));
            this.btnRefresh = this.container.find('.btnRefresh').bind('click', $.proxy(this.getExperiences, this, true));
            this.container.find('.btnFilter').bind('click', $.proxy(this.filterClicked, this));
            this.container.find('.btnClear').bind('click', $.proxy(this.clearClicked, this));
            this.container.find('.btnShortcuts').bind('click', $.proxy(this.toggleShortcuts, this));
            this.container.find('.btnCloseShortcuts').bind('click', $.proxy(this.toggleShortcuts, this));
            this.container.find('.searchable').keyup($.proxy(this.searchKeyup, this));
            this.container.find('.autoRefreshToggle input[type=checkbox]').bind('click', $.proxy(this.toggleAutoRefresh, this));
            this.storyDropdown.on(Nickel.StoryPicker.STORY_CLICKED, $.proxy(this.storyClicked, this));
            //hotkeys
            $(document).on('keydown', null, 'shift+left', $.proxy(this.prevBucket, this));
            $(document).on('keydown', null, 'shift+right', $.proxy(this.nextBucket, this));
            $(document).on('keypress', null, 'shift+r', $.proxy(this.getExperiences, this, true));
            $(document).on('keydown', null, 'right', $.proxy(this.rightPressed, this));
            $(document).on('keydown', null, 'left', $.proxy(this.leftPressed, this));
            $(document).on('keydown', null, 'up', $.proxy(this.upPressed, this));
            $(document).on('keydown', null, 'down', $.proxy(this.downPressed, this));
            //events
            EventBus.addEventListener(Nickel.ExperienceItem.CLICKED, $.proxy(this.itemClicked, this), this);
            EventBus.addEventListener(Nickel.ExperienceItem.STATUS_CHANGED, $.proxy(this.itemStatusChanged, this), this);
        };
        /**
         * Removes all of the event listeners for this view.
         */
        ModerationView.prototype.unbindEvents = function () {
            this.btnNextPage.unbind('click', $.proxy(this.nextPageClicked, this));
            this.btnPrevPage.unbind('click', $.proxy(this.prevPageClicked, this));
            this.btnFirstPage.unbind('click', $.proxy(this.firstPageClicked, this));
            this.btnLastPage.unbind('click', $.proxy(this.lastPageClicked, this));
            this.btnRefresh.unbind('click', $.proxy(this.getExperiences, this, true));
            EventBus.removeEventListener(Nickel.ExperienceItem.CLICKED, $.proxy(this.itemClicked, this), this);
            EventBus.removeEventListener(Nickel.ExperienceItem.STATUS_CHANGED, $.proxy(this.itemStatusChanged, this), this);
        };
        /**
         * Decide which story to display.
         */
        ModerationView.prototype.stateChanged = function () {
            _super.prototype.stateChanged.call(this);
            if (this.initialized) {
                var urlStrings = History['getState']().url.split("?")[0].split("/");
                var storyId = urlStrings[4];
                var status_1 = urlStrings[5];
                if (storyId) {
                    if (storyId !== this.storyId) {
                        Ajax.get(new JWTAjaxRequest('/story/' + storyId, null, $.proxy(this.setStory, this, status_1), $.proxy(this.clickDefaultStory, this)));
                    }
                    else if (status_1 && status_1 !== this.bucket) {
                        this.applyBucket(status_1);
                    }
                    else {
                        this.resetPagination();
                        this.getExperiences();
                    }
                }
                else {
                    if (!Main.setDefaultHeaderStory()) {
                        this.clickDefaultStory();
                    }
                }
            }
        };
        /**
         * Called if an invalid story is provided.
         */
        ModerationView.prototype.clickDefaultStory = function () {
            if (Main.stories && Main.stories[0]) {
                this.storyClicked(Main.stories[0]);
            }
        };
        /**
         * Called when a story is selected.
         */
        ModerationView.prototype.storyClicked = function (story) {
            if (story.id !== this.storyId) {
                Utils.pushState('/moderation/' + story.id);
            }
        };
        /**
         * Called if a user clicks on the filter icon on a filterable field.
         */
        ModerationView.prototype.filterClicked = function (evt) {
            var targ = $(evt.currentTarget);
            targ.parent().addClass('search').find('input[type=text]').focus();
        };
        /**
         * Called if the user clicks on the close filter button. Clears the filters and gets the new experiences.
         */
        ModerationView.prototype.clearClicked = function (evt) {
            var targ = $(evt.currentTarget);
            var field = targ.attr('data-filter');
            if (this[field] != "") {
                this[field] = "";
                this.getExperiences();
            }
            targ.parent().removeClass('search');
        };
        /**
         * Toggles the visibility of the keyboard shortcuts popup.
         */
        ModerationView.prototype.toggleShortcuts = function (evt) {
            if (this.shortcuts.hasClass('active')) {
                this.shortcuts.removeClass('active');
            }
            else {
                this.shortcuts.addClass('active');
            }
        };
        /**
         * Toggles the auto refresh.
         */
        ModerationView.prototype.toggleAutoRefresh = function (evt) {
            if (this.autoRefreshInterval) {
                clearInterval(this.autoRefreshInterval);
            }
            if ($(evt.currentTarget).is(':checked')) {
                this.autoRefreshInterval = setInterval($.proxy(this.handleAutoRefresh, this), 15000);
            }
        };
        /**
         * Refreshes if modal is not open.
         */
        ModerationView.prototype.handleAutoRefresh = function () {
            if (!this.overlay.onStage) {
                this.getExperiences();
            }
        };
        /**
         * Hide this view, clearing the auto-refresh interval if set.
         */
        ModerationView.prototype.hideMe = function () {
            _super.prototype.hideMe.call(this);
            if (this.autoRefreshInterval) {
                clearInterval(this.autoRefreshInterval);
                this.container.find('.autoRefreshToggle input[type=checkbox]').attr('checked', false);
            }
        };
        /**
         * Changes the story we're currently viewing in this view.
         */
        ModerationView.prototype.setStory = function (status, storyData) {
            this.activeStoryData = storyData;
            this.storyId = storyData.id;
            this.storyLabel = storyData.name;
            Main.setHeaderStory(storyData);
            this.page = 1;
            if (storyData['moderationBuckets']) {
                this.buckets = storyData['moderationBuckets'];
            }
            else {
                this.buckets = ModerationView.DEFAULT_BUCKETS;
            }
            this.populateHeader();
            if (status && this.buckets.indexOf(status) > -1) {
                this.setBucket(status);
            }
            else {
                this.setBucket(this.bucket);
            }
        };
        /**
         * Sets the current bucket we're viewing
         * @param bucket    The bucket we want to display.
         */
        ModerationView.prototype.applyBucket = function (bucket) {
            this.subHeader.removeClass(this.bucket);
            this.bucketButtonHolder.find('.active').removeClass('active');
            this.bucketButtonHolder.find('.btn' + Utils.capitalize(bucket)).addClass('active');
            this.bucket = bucket;
            this.subHeader.addClass(this.bucket);
            this.resetPagination();
            this.getExperiences();
        };
        /**
         * Hides the total value and shows a loader in it's place.
         */
        ModerationView.prototype.showCountLoader = function () {
            this.countLoader.parent().find('.totalNumber').addClass('hidden');
            this.countLoader.removeClass('hidden');
        };
        /**
         * Shows the total value and hides the loader.
         */
        ModerationView.prototype.hideCountLoader = function () {
            this.countLoader.addClass('hidden');
            this.countLoader.parent().find('.totalNumber').removeClass('hidden');
        };
        /**
         * Hits the backend for a new page of experiences. Filters it by page, story, and searches like user id and
         * experience id.
         */
        ModerationView.prototype.getExperiences = function (refreshCount) {
            if (refreshCount === void 0) { refreshCount = true; }
            this.checkPaginationButtons();
            this.clearActive();
            this.clearExperiences();
            this.loader.show();
            if (this.experiencesRequest) {
                this.experiencesRequest.abort();
            }
            var route = '/experiences/by-story/' + this.storyId;
            var queryData = {
                'page': this.page,
                'items_per_page': this.itemsPerPage,
                'status': this.bucket,
                'count': false,
                'entries': true,
            };
            //add the filters
            if (this.expFilter != "") {
                queryData['experience_id'] = this.expFilter;
            }
            var request = new JWTAjaxRequest(route, queryData, $.proxy(this.gotExperiences, this), $.proxy(this.experienceError, this));
            request.apiVersion = Ajax.oldestApiVersion;
            this.experiencesRequest = Ajax.get(request);
            if (refreshCount) {
                this.showCountLoader();
                if (this.experiencesCountRequest) {
                    this.experiencesCountRequest.abort();
                }
                queryData['count'] = true;
                queryData['entries'] = false;
                this.experiencesCountRequest = Ajax.get(new JWTAjaxRequest(route, queryData, $.proxy(this.gotExperiencesCount, this), $.proxy(this.experienceCountError, this)));
            }
        };
        /**
         * Called once we get data back from the DB. Clears the old experiences and adds the new experiences.
         * @param d    The data passed back from the DB
         */
        ModerationView.prototype.gotExperiences = function (d) {
            this.experiencesRequest = null;
            this.loader.hide();
            this.addExperiences(d.experiences);
        };
        /**
         * Called once we get a count back from the DB. Enables pagination by setting pages and total values.
         * @param d    The data passed back from the DB
         */
        ModerationView.prototype.gotExperiencesCount = function (d) {
            this.experiencesCountRequest = null;
            this.hideCountLoader();
            this.pages = d.total_pages;
            this.total = d.experience_count;
            this.checkPaginationButtons();
        };
        /**
         * Creats new ExperienceItem classes and adds them to the this.experiences object.
         * @param expData        Array of JSON objects for each experience
         */
        ModerationView.prototype.addExperiences = function (expData) {
            var buckets = [];
            var bucketOptions = this.bucketOptions[this.bucket];
            for (var i = 0; i < bucketOptions.length; i++) {
                if (this.buckets.indexOf(bucketOptions[i]) != -1) {
                    buckets.push(bucketOptions[i]);
                }
            }
            for (var i = 0; i < expData.length; i++) {
                var data = expData[i];
                data.buckets = buckets;
                var exp = new Nickel.ExperienceItem(this.experienceHolder, data, this);
                this.experiences.push(exp);
            }
        };
        /**
         * Removes and kills all of the existing ExperienceItem classes.
         */
        ModerationView.prototype.clearExperiences = function () {
            for (var i = 0; i < this.experiences.length; i++) {
                var exp = this.experiences[i];
                exp.killMe();
                exp = null;
            }
            this.activeItem = null;
            this.experiences = [];
        };
        /**
         * Called when an ExperienceItem is clicked, sets that item as active, and deselects the previously active item.
         */
        ModerationView.prototype.itemClicked = function (item) {
            if (this.activeItem) {
                this.activeItem.unselectItem();
            }
            this.activeItem = item;
        };
        ModerationView.prototype.clearActive = function () {
            if (this.activeItem) {
                this.activeItem.unselectItem();
            }
            if (this.overlay.onStage) {
                this.overlay.hideMe();
            }
            this.activeItem = null;
        };
        /**
         * Called when the status on an item changes. Removes that ExperienceItem completely and if there's none left,
         * gets more.
         */
        ModerationView.prototype.itemStatusChanged = function (item) {
            //if the item that changed was the activeItem, clear it
            if (item == this.activeItem) {
                this.activeItem = null;
            }
            //completely remove all references to this item
            this.total--;
            var id = item.data.id;
            var index = this.experiences.indexOf(item);
            item.killMe();
            this.experiences.splice(index, 1);
            //select the next experince, if no next, select the previous
            var next = this.experiences[index];
            if (!next) {
                next = this.experiences[index - 1];
            }
            if (next && this.overlay.onStage) {
                next.selectItem();
            }
            else {
                if (this.overlay.onStage) {
                    this.overlay.hideMe();
                }
            }
            //if there's no experiences left from the last batch. get more
            if (this.experiences.length == 0) {
                this.getExperiences();
            }
        };
        /**
         * Makes sure that the pagination buttons are enabled / disabled appropriately.
         */
        ModerationView.prototype.checkPaginationButtons = function () {
            if (this.page <= 1) {
                this.btnPrevPage.addClass('disabled');
                this.btnFirstPage.addClass('disabled');
            }
            else {
                this.btnPrevPage.removeClass('disabled');
                this.btnFirstPage.removeClass('disabled');
            }
            if (this.page == this.pages) {
                this.btnNextPage.addClass('disabled');
                this.btnLastPage.addClass('disabled');
            }
            else {
                this.btnNextPage.removeClass('disabled');
                this.btnLastPage.removeClass('disabled');
            }
            if (this.pages == 0) {
                this.container.find('.paginationPiece').hide();
            }
            else {
                this.container.find('.paginationPiece').show();
            }
        };
        /**
         * Creates the bucket buttons for navigating through the different bucket types.
         */
        ModerationView.prototype.populateHeader = function () {
            var currentBucket = this.bucketButtonHolder.find('.active').attr('data-bucket');
            //add a button for each
            this.bucketButtonHolder.empty();
            for (var i = 0; i < this.buckets.length; i++) {
                var title = Utils.capitalize(this.buckets[i]);
                this.bucketButtonHolder.append('<button type="button" data-bucket = "' + this.buckets[i] + '" class="btnBucket btn' + title + '">' + title + '</button>');
            }
            var buttons = this.bucketButtonHolder.find('.btnBucket');
            buttons.unbind('click', $.proxy(this.changeBucket, this)).bind('click', $.proxy(this.changeBucket, this));
            if (currentBucket) {
                var currentBucketElement = buttons.filter('.btnBucket[data-bucket=' + currentBucket + ']');
                if (currentBucketElement.length) {
                    currentBucketElement.addClass('active');
                }
                else {
                    this.setBucket(buttons.first().attr('data-bucket'));
                }
            }
            else {
                buttons.first().addClass('active');
            }
        };
        /**
         * Changes the currently active bucket.
         * @param e    Event passed in from the button click.
         */
        ModerationView.prototype.changeBucket = function (e) {
            var targ = $(e.currentTarget);
            var bucket = targ.attr('data-bucket');
            this.setBucket(bucket);
        };
        /**
         * Sets the current bucket we're viewing
         * @param bucket    The bucket we want to display.
         */
        ModerationView.prototype.setBucket = function (bucket) {
            Utils.pushState('/moderation/' + this.storyId + '/' + bucket);
        };
        /**
         * Resets the pagination info back to default values.
         */
        ModerationView.prototype.resetPagination = function () {
            this.page = 1;
            this.pages = 1;
            this.total = 1;
        };
        /**
         * Called if the user hits enter after entering a serch filter.
         * e    The event passed in from the keypress.
         */
        ModerationView.prototype.searchKeyup = function (e) {
            if (e.keyCode === 13) {
                this.resetPagination();
                this.getExperiences();
            }
        };
        /**
         * Moves this.page variable ahead and gets some new experiences.
         * @param e    Event passed in from the button click.
         */
        ModerationView.prototype.nextPageClicked = function (e) {
            this.page++;
            this.getExperiences(false);
        };
        /**
         * Moves this.page variable back and gets some new experiences.
         * @param e    Event passed in from the button click.
         */
        ModerationView.prototype.prevPageClicked = function (e) {
            this.page--;
            this.getExperiences(false);
        };
        /**
         * Sets this.page to 1 and gets the experiences.
         * @param e    Event passed in from the button click.
         */
        ModerationView.prototype.firstPageClicked = function (e) {
            this.page = 1;
            this.getExperiences(false);
        };
        /**
         * Sets this.page to the last page and gets the experiences.
         * @param e    Event passed in from the button click.
         */
        ModerationView.prototype.lastPageClicked = function (e) {
            this.page = this.pages;
            this.getExperiences(false);
        };
        /**
         * Shows the previous bucket.
         */
        ModerationView.prototype.prevBucket = function (e) {
            var index = this.buckets.indexOf(this.bucket);
            if (index > 0) {
                this.setBucket(this.buckets[index - 1]);
            }
        };
        /**
         * Shows the next bucket.
         */
        ModerationView.prototype.nextBucket = function (e) {
            var index = this.buckets.indexOf(this.bucket);
            if (index < this.buckets.length - 1) {
                this.setBucket(this.buckets[index + 1]);
            }
        };
        /**
         * If there is a previous page, show it.
         */
        ModerationView.prototype.leftPressed = function () {
            if (this.page > 1) {
                this.page--;
                this.getExperiences(false);
            }
        };
        /**
         * If there is a next page, show it.
         */
        ModerationView.prototype.rightPressed = function () {
            if (this.page < this.pages) {
                this.page++;
                this.getExperiences(false);
            }
        };
        /**
         * If there isn't an active item, show the first one, if there is, show the previous one.
         */
        ModerationView.prototype.upPressed = function (e) {
            e.preventDefault();
            e.stopPropagation();
            if (this.activeItem) {
                var index = this.experiences.indexOf(this.activeItem);
                if (index > 0) {
                    this.experiences[index - 1].selectItem();
                }
            }
            else {
                var item = this.experiences[0];
                if (item) {
                    item.selectItem();
                }
            }
        };
        /**
         * If there isn't an active item, show the last one, if there is, show the next one.
         */
        ModerationView.prototype.downPressed = function (e) {
            e.preventDefault();
            e.stopPropagation();
            if (this.activeItem) {
                var index = this.experiences.indexOf(this.activeItem);
                if (index < this.experiences.length - 1) {
                    this.experiences[index + 1].selectItem();
                }
            }
            else {
                var item = this.experiences[this.experiences.length - 1];
                if (item) {
                    item.selectItem();
                }
            }
        };
        /**
         * Sets this.page to the last page and gets the experiences.
         * @param subSection    The current subSection to display
         */
        ModerationView.prototype.showMe = function (subSection) {
            _super.prototype.showMe.call(this, subSection);
            if (!this.initialized) {
                this.init();
            }
        };
        /**
         * Called if there's an error trying to get new experiences.
         */
        ModerationView.prototype.experienceError = function (e) {
            if (e.textStatus != "abort") {
                console.error("Error getting user experiences");
                console.log(e);
            }
        };
        /**
         * Called if there's an error trying to get the experiences count.
         */
        ModerationView.prototype.experienceCountError = function (e) {
            if (e.textStatus != "abort") {
                console.error("Error getting user experiences count");
                console.log(e);
            }
        };
        ModerationView.DEFAULT_BUCKETS = ["pending", "approved", "used", "vip", "rejected"];
        return ModerationView;
    })(Nickel.View);
    Nickel.ModerationView = ModerationView;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    /**
     * Component for displaying a single user experience data, and providing a UI to manipulate it.
     */
    var ExperienceItem = (function (_super) {
        __extends(ExperienceItem, _super);
        /**
         * Stores the global vars, loads the template, populates the dynamic buttons, and binds the event listeners.
         * @param container        A jQuery object containing the parent div for this view.
         * @param data          The JSON data unique to this component.
         * @param delegate        The Class that created this instance.
         */
        function ExperienceItem(container, data, delegate) {
            _super.call(this, container, data, delegate);
            /**
             * Set to true if this item is clicked, set to false once another item is clicked.
             */
            this.active = false;
            this.viewLoaded(Main.templates.find('.experienceItem').clone());
            this.buttonHolder = this.content.find('.buttonHolder');
            this.populateButtons();
            this.bindEvents();
        }
        /**
         * Binds all of the event listeners for this view.
         */
        ExperienceItem.prototype.bindEvents = function () {
            this.content.bind('click', $.proxy(this.selectItem, this));
            this.content.find('.btnMoveToBucket').bind('click', $.proxy(this.moveToBucket, this));
            this.content.find('.btnTriggerReject').bind('click', $.proxy(this.triggerReject, this));
        };
        /**
         * Unbinds all of the event listeners for this view.
         */
        ExperienceItem.prototype.unbindEvents = function () {
            this.content.unbind('click', $.proxy(this.selectItem, this));
            this.content.find('.btnMoveToBucket').unbind('click', $.proxy(this.moveToBucket, this));
            this.content.find('.btnTriggerReject').unbind('click', $.proxy(this.triggerReject, this));
        };
        /**
         * Show the rejection Interface
         */
        ExperienceItem.prototype.triggerReject = function (e) {
            var _this = this;
            if (e) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
            }
            this.delegate.rejectionSelector.attach(this.content.find('.rejectContainer'), function (r) { return _this.gotRejectionReason(r); });
            this.delegate.rejectionSelector.showMe();
        };
        /**
         * Handle the reason list being clicked
         */
        ExperienceItem.prototype.gotRejectionReason = function (reason) {
            this.moveToBucket(null, 'rejected', reason);
        };
        /**
         * Called if this is the active Experience item when another item is clicked. Removes the active class, and
         * sets active to false.
         */
        ExperienceItem.prototype.unselectItem = function () {
            this.active = false;
            this.content.removeClass('selected');
        };
        /**
         * Called if the user clicks on this item in the list.
         */
        ExperienceItem.prototype.selectItem = function () {
            this.active = true;
            EventBus.dispatch(ExperienceItem.CLICKED, this);
            this.content.addClass('selected');
        };
        /**
         * Changes the current status of this user experince in the DB.
         */
        ExperienceItem.prototype.moveToBucket = function (e, status, reason) {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
            }
            var params = { 'task': 'update-experience-status', 'experience_id': this.data.id };
            if (status) {
                // From hotkey
                params['status'] = status;
            }
            else {
                // From button
                params['status'] = $(e.currentTarget).attr('data-bucket');
            }
            if (params['status'] == 'rejected') {
                params['reason'] = reason;
            }
            Ajax.post(new AjaxRequest(params, $.proxy(this.bucketChanged, this), $.proxy(this.bucketChangeError, this), null, '/api/1.1'));
        };
        /**
         * Called if the bucket change is successful.
         */
        ExperienceItem.prototype.bucketChanged = function (data) {
            if (data.bind.success == 1) {
                EventBus.dispatch(ExperienceItem.STATUS_CHANGED, this);
            }
        };
        /**
         * Pouplates the dynamic bucket buttons.
         */
        ExperienceItem.prototype.populateButtons = function () {
            for (var i = 0; i < this.data.buckets.length; i++) {
                var title = Utils.capitalize(this.data.buckets[i]);
                if (this.data.buckets[i] == "rejected") {
                    var btn = $('<div class = "rejectContainer"></div>');
                    var btnReject = $('<button type="button" data-bucket = "' + this.data.buckets[i] + '" class="btn btn-default btn-xs btnTriggerReject btnSend' + title + '"><div class = "flag"><i class = "fa fa-chevron-right"></i></div>' + title + '</button>');
                    btn.append(btnReject);
                }
                else {
                    var btn = $('<button type="button" data-bucket = "' + this.data.buckets[i] + '" class="btn btn-default btn-xs btnMoveToBucket btnSend' + title + '"><div class = "flag"><i class = "fa fa-chevron-right"></i></div>' + title + '</button>');
                }
                this.buttonHolder.append(btn);
            }
            //if this experience item is in the rejected bucket, add the delete button
            if (this.delegate.bucket == "rejected") {
                var del = $('<button type="button"" class="btn btn-default btn-xs btnDelete"><div class = "flag"><i class = "fa fa-close"></i></div>Delete</button>');
                this.buttonHolder.append(del);
                del.bind('click', $.proxy(this.deleteExperience, this));
            }
        };
        /**
         * Hits the API to completely remove the experience and all of it's media.
         */
        ExperienceItem.prototype.deleteExperience = function (e) {
            e.preventDefault();
            e.stopPropagation();
            Ajax.request('DELETE', new JWTAjaxRequest('/experience/' + this.data.id, { 'erase_media': true }, $.proxy(function () { EventBus.dispatch(ExperienceItem.STATUS_CHANGED, this); }, this), $.proxy(function (e) { console.error("ERROR", e); }, this)));
        };
        /**
         * Completely removes the view from the DOM, clears all event listeners and kills rivets.
         */
        ExperienceItem.prototype.killMe = function () {
            clearTimeout(this.displayTimeout);
            this.unbindEvents();
            this.rivets.unbind();
            this.content.remove();
            this.content = null;
        };
        /**
         * Called if there's an error updating this items status.
         */
        ExperienceItem.prototype.bucketChangeError = function (e) {
            console.error("Error updating experience status");
            console.log(e);
        };
        /**
         * Dispatched when the user clicks on this specific experince item.
         */
        ExperienceItem.CLICKED = "expitemclicked";
        /**
         * Dispatched when the status on this item changes.
         */
        ExperienceItem.STATUS_CHANGED = "expstatuschanged";
        return ExperienceItem;
    })(Nickel.Component);
    Nickel.ExperienceItem = ExperienceItem;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    /**
     * Overlay for displaying detailed information about a specific ExperienceItem.
     * Includes UX elements and hotkeys to change buckets, view all inventory, and add / modify notes.
     */
    var ModerationOverlay = (function (_super) {
        __extends(ModerationOverlay, _super);
        /**
         * Stores the global vars, binds the experience item clicked event for initializing and displaying this
         * component for the first time.
         * @param container        A jQuery object containing the parent div for this component.
         * @param data          The JSON data unique to this component.
         * @param delegate        The Class that created this instance.
         */
        function ModerationOverlay(container, data, delegate) {
            _super.call(this, container, data, delegate);
            /**
             * Max width of the modal popup window.
             */
            this.modalMaxWidth = 900;
            /**
             * Max Height of the modal popup window.
             */
            this.modalMaxHeight = 700;
            /**
             * Whether or not this component has been initialized yet or not.
             */
            this.initialized = false;
            /**
             * if we should jump to the first dynamic cut in a video inventory item.
             */
            this.jumpToDynamic = true;
            /**
             * If we should autoplay audio and video inventory items.
             */
            this.autoplay = true;
            /**
             * All of the inventory classes currently instantiated.
             */
            this.inventory = [];
            /**
             * The inventory item we're currently viewing.
             */
            this.activeInventory = 0;
            /**
             * Initial values to bind rivets to for the inventory item data.
             */
            this.invData = {
                "label": "",
                "type": "",
                "inventory_type": "",
                "width": "",
                "height": "",
                "filesize": "",
                "id": "",
                "url": "",
                "mp4Url": ""
            };
            EventBus.addEventListener(Nickel.ExperienceItem.CLICKED, $.proxy(this.showItem, this), this);
        }
        /**
         * Loads the view for this component, creates the video and audio player, and binds all events.
         */
        ModerationOverlay.prototype.init = function () {
            this.initialized = true;
            this.viewLoaded(Main.templates.find('.moderationOverlay').clone());
            this.videoPlayer = new Nickel.ModVideoPlayer(this.modal.find('.player'), {}, this);
            this.audioPlayer = new Nickel.AudioPlayer(this.modal.find('.player'), {}, this);
            this.bindEvents();
            this.resize();
        };
        /**
         * Binds this.data, this.invData, and this to the view using rivets. Sets all of the JQuery object global
         * variables.
         * @param v        A jQuery object to act as this node's view and to bind it's data to.
         */
        ModerationOverlay.prototype.viewLoaded = function (v) {
            this.content = v;
            if (this.container) {
                this.container.append(this.content);
            }
            this.rivets = rivets.bind(this.content, {
                "data": this.data,
                "invData": this.invData,
                "controller": this
            });
            this.modal = this.content.find('.content');
            this.inventoryHolder = this.content.find('.inventoryItems');
            this.textViewer = this.content.find('.textViewer');
            this.imageViewer = this.content.find('.imageViewer');
            this.storyLabel = this.content.find('.storyLabel');
            this.buttonHolder = this.content.find('.buttonHolder');
        };
        /**
         * Binds all of the event listeners for this Class.
         */
        ModerationOverlay.prototype.bindEvents = function () {
            $(window).bind('resize', $.proxy(this.resize, this));
            this.content.find('.btnClose').on('click', $.proxy(this.hideMe, this));
            this.content.find('.btnSaveNotes').on('click', $.proxy(this.saveNotes, this));
            $(document).on('keypress', null, 'a', $.proxy(this.bucketHotkeyPressed, this, "approved"));
            $(document).on('keypress', null, 'u', $.proxy(this.bucketHotkeyPressed, this, "used"));
            $(document).on('keypress', null, 'v', $.proxy(this.bucketHotkeyPressed, this, "vip"));
            $(document).on('keypress', null, 'p', $.proxy(this.bucketHotkeyPressed, this, "pending"));
            $(document).on('keydown', null, 'shift+up', $.proxy(this.prevInventory, this));
            $(document).on('keydown', null, 'shift+down', $.proxy(this.nextInventory, this));
            $('body').on('click', '.moderationOverlay .btnMoveToBucket', $.proxy(this.moveToBucketClicked, this));
            $('body').on('click', '.moderationOverlay .btnTriggerReject', $.proxy(this.rejectClicked, this));
            $('body').on('click', '.moderationOverlay .btnDelete', $.proxy(this.deleteClicked, this));
        };
        /**
         * Unbinds all of the event listeners for this Class.
         */
        ModerationOverlay.prototype.unbindEvents = function () {
        };
        /**
         * Moves the active Item to a specific bucket.
         */
        ModerationOverlay.prototype.moveToBucketClicked = function (e) {
            this.delegate.activeItem.moveToBucket(e);
        };
        /**
         * Triggers the rejection overlay
         */
        ModerationOverlay.prototype.rejectClicked = function (e) {
            var _this = this;
            this.delegate.rejectionSelector.attach($(e.target).parent(), function (r) { return _this.gotRejectionReason(r); });
            this.delegate.rejectionSelector.showMe();
        };
        /**
         * Handle the reason list being clicked
         */
        ModerationOverlay.prototype.gotRejectionReason = function (reason) {
            this.delegate.activeItem.moveToBucket(null, 'rejected', reason);
        };
        /**
         * Deletes the active item.
         */
        ModerationOverlay.prototype.deleteClicked = function (e) {
            this.delegate.activeItem.deleteExperience(e);
        };
        /**
         * Moves the active Item to a specific bucket, from a hotkey press.
         */
        ModerationOverlay.prototype.bucketHotkeyPressed = function (bucket, e) {
            if (this.onStage) {
                if (this.data.buckets.indexOf(bucket) != -1) {
                    this.delegate.activeItem.moveToBucket(null, bucket);
                }
            }
        };
        /**
         * Shows the information for a specific ExperienceItem, popupates the dynamic buttons, the inventory, and shows
         * the first inventory item.
         * @item        The ExperienceItem class we want to display the details for.
         */
        ModerationOverlay.prototype.showItem = function (item) {
            for (var key in this.data) {
                if (this.data.hasOwnProperty(key) && !item.data.hasOwnProperty(key)) {
                    this.data[key] = null;
                }
            }
            for (var key in item.data) {
                if (item.data.hasOwnProperty(key)) {
                    this.data[key] = item.data[key];
                }
            }
            this.activeItem = item;
            if (!this.onStage) {
                this.showMe();
            }
            this.clearOldData();
            this.populateButtons();
            this.populateEGC();
            this.populateUGC();
            //if there is at least one inventory item, show it
            if (this.inventory[0]) {
                this.activeInventory = 0;
                this.inventory[0].viewContent();
            }
            else {
                this.videoPlayer.hideMe();
                this.imageViewer.hide();
                this.audioPlayer.hideMe();
                this.textViewer.hide();
            }
        };
        /**
         * Merges the data passed in with the current invData.
         * @data        Inventory item data we want to merge in, to display.
         */
        ModerationOverlay.prototype.setInvData = function (data) {
            for (var key in this.invData) {
                if (this.invData.hasOwnProperty(key) && !data.hasOwnProperty(key)) {
                    this.invData[key] = null;
                }
            }
            for (var key in data) {
                if (data.hasOwnProperty(key)) {
                    this.invData[key] = data[key];
                }
            }
        };
        /**
         * Plays an audio inventory item. hides the other inventory display classes.
         * @src        The url to the audio clip we want to play.
         * @item        The inventory item we're playing the clip for.
         */
        ModerationOverlay.prototype.playAudio = function (src, item) {
            this.setInvData(item.data);
            this.activeInventory = this.inventory.indexOf(item);
            this.audioPlayer.autoplay = this.autoplay;
            this.audioPlayer.loadAudio(src);
            this.audioPlayer.showMe();
            this.videoPlayer.hideMe();
            this.textViewer.hide();
            this.imageViewer.hide();
        };
        /**
         * Plays a video inventory item. hides the other inventory display classes.
         * @url        The url to the video file we want to play.
         * @item        The inventory item we're playing the clip for.
         */
        ModerationOverlay.prototype.playVideo = function (url, item) {
            this.setInvData(item.data);
            this.activeInventory = this.inventory.indexOf(item);
            //if it's an EGC video, use the scene id to find the first cut, and jump to that
            var time = 0;
            if (this.invData.inventory_type == "egc" && this.jumpToDynamic) {
                time = StoryUtils.getFirstCutTime(this.delegate.activeStoryData, this.invData.id) - 0.25;
            }
            this.videoPlayer.startTime = time;
            this.videoPlayer.autoplay = this.autoplay;
            this.videoPlayer.loadVideo(url);
            this.videoPlayer.showMe();
            this.textViewer.hide();
            this.imageViewer.hide();
            this.audioPlayer.hideMe();
        };
        /**
         * Displays a text inventory item. hides the other inventory display classes. Checks to see if the text is
         * JSON, and if so, displays it as JSON.
         * @text        The string we want to show.
         * @item        The inventory item we're showing the text for.
         */
        ModerationOverlay.prototype.showText = function (text, item) {
            this.setInvData(item.data);
            this.activeInventory = this.inventory.indexOf(item);
            if (Utils.isValidJSON(text)) {
                var str = '<pre>' + Utils.formatJSONString(text) + '</pre>';
                this.textViewer.html(str);
            }
            else {
                this.textViewer.html(text);
            }
            this.videoPlayer.hideMe();
            this.imageViewer.hide();
            this.audioPlayer.hideMe();
            this.textViewer.show();
        };
        /**
         * Shows an image inventory item. hides the other inventory display classes. Resizes the image to fit in the
         * container.
         * @src        The url to the image file we want to display.
         * @item        The inventory item we're loading the image for.
         */
        ModerationOverlay.prototype.showImage = function (src, item) {
            var _this = this;
            this.setInvData(item.data);
            this.activeInventory = this.inventory.indexOf(item);
            var cont = this.content.find('.player');
            var img = new Image();
            img.onload = function () {
                _this.imageViewer[0]["src"] = src;
                var posData = Utils.fitToContainer({
                    'containerWidth': cont.width(),
                    'containerHeight': cont.height(),
                    'contentWidth': img.naturalWidth,
                    'contentHeight': img.naturalHeight,
                    'scaleMode': 'proportionalInside',
                    'hAlign': 'center',
                    'vAlign': 'center'
                });
                _this.imageViewer.css(posData);
                _this.imageViewer.show();
            };
            img.src = src;
            this.videoPlayer.hideMe();
            this.textViewer.hide();
            this.audioPlayer.hideMe();
        };
        /**
         * Hides the overlay, hides all of the inventory display classes, clears the active experience.
         */
        ModerationOverlay.prototype.hideMe = function (e) {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
            }
            _super.prototype.hideMe.call(this);
            this.videoPlayer.hideMe();
            this.audioPlayer.hideMe();
            this.textViewer.hide();
            this.imageViewer.hide();
            this.delegate.clearActive();
        };
        /**
         * If there is a previous inventory item, show it.
         */
        ModerationOverlay.prototype.prevInventory = function () {
            if (this.onStage) {
                if (this.inventory[this.activeInventory - 1]) {
                    this.activeInventory--;
                    this.inventory[this.activeInventory].viewContent();
                }
            }
        };
        /**
         * If there is a next inventory item, show it.
         */
        ModerationOverlay.prototype.nextInventory = function () {
            if (this.onStage) {
                if (this.inventory[this.activeInventory + 1]) {
                    this.activeInventory++;
                    this.inventory[this.activeInventory].viewContent();
                }
            }
        };
        /**
         * Kills all of the existing inventory items, and dynamic bucket buttons.
         */
        ModerationOverlay.prototype.clearOldData = function () {
            for (var i = 0; i < this.inventory.length; i++) {
                var item = this.inventory[i];
                item.killMe();
                item = null;
            }
            this.inventory = [];
            this.buttonHolder.empty();
        };
        /**
         * Creates an inventory item, based off of the "type" attribute in that inventory item's data.
         * @return        The inventory item just created.
         */
        ModerationOverlay.prototype.addInventoryItem = function (data) {
            var item;
            switch (data.type) {
                case "video":
                    item = new Nickel.VideoInventoryItem(this.inventoryHolder, data, this);
                    break;
                case "text":
                case "enum":
                case "boolean":
                case "number":
                    item = new Nickel.TextInventoryItem(this.inventoryHolder, data, this);
                    break;
                case "image":
                    item = new Nickel.ImageInventoryItem(this.inventoryHolder, data, this);
                    break;
                case "audio":
                    item = new Nickel.AudioInventoryItem(this.inventoryHolder, data, this);
                    break;
            }
            if (item) {
                this.inventory.push(item);
                return item;
            }
        };
        /**
         * Loops through the Experience generated content inventory data, creates an Inventory Item for each one.
         */
        ModerationOverlay.prototype.populateEGC = function () {
            if (!this.data.generated_inventory_items) {
                return;
            }
            var keys = Object.keys(this.data.generated_inventory_items).sort();
            for (var i = 0; i < keys.length; i++) {
                var key = keys[i];
                if (this.data.generated_inventory_items.hasOwnProperty(key)) {
                    var d = this.data.generated_inventory_items[key];
                    var scene = StoryUtils.getScene(this.delegate.activeStoryData, key);
                    if (scene) {
                        d.label = scene.label || scene.name || 'Scene';
                    }
                    d.inventory_type = "egc";
                    if (d.poster) {
                        this.addInventoryItem({
                            id: "poster",
                            type: "image",
                            inventory_type: "egc",
                            label: d.label + " poster",
                            width: d.width,
                            height: d.height,
                            url: d.poster,
                            filesize: 0
                        });
                    }
                    if (d.snapshot) {
                        this.addInventoryItem({
                            id: "snapshot",
                            type: "image",
                            inventory_type: "egc",
                            label: d.label + " snapshot",
                            width: d.width,
                            height: d.height,
                            url: d.snapshot,
                            filesize: 0
                        });
                    }
                    this.addInventoryItem(d);
                }
            }
        };
        /**
         * Loops through the User generated content inventory data, creates an Inventory Item for each one.
         */
        ModerationOverlay.prototype.populateUGC = function () {
            if (!this.data.user_inventory_items) {
                return;
            }
            var keys = Object.keys(this.data.user_inventory_items).sort();
            for (var i = 0; i < keys.length; i++) {
                var key = keys[i];
                if (this.data.user_inventory_items.hasOwnProperty(key)) {
                    var d = this.data.user_inventory_items[key];
                    var inv = StoryUtils.getInventory(this.delegate.activeStoryData, key);
                    if (inv) {
                        d.label = inv.label || inv.name;
                    }
                    d.inventory_type = "ugc";
                    this.addInventoryItem(d);
                }
            }
        };
        /**
         * Positions the modal in the middle of the screen.
         */
        ModerationOverlay.prototype.resize = function (e) {
            var sWidth = $(window).width();
            var sHeight = $(window).height();
            var w = Math.min(this.modalMaxWidth, sWidth);
            var h = Math.min(this.modalMaxHeight, sHeight);
            var size = Utils.fitToContainer({
                'containerWidth': sWidth,
                'containerHeight': sHeight,
                'contentWidth': w,
                'contentHeight': h,
                'scaleMode': 'none',
                'hAlign': 'center',
                'vAlign': 'center'
            });
            if (this.modal) {
                this.modal.css(size);
            }
        };
        /**
         * Pouplates the dynamic bucket buttons.
         */
        ModerationOverlay.prototype.populateButtons = function () {
            for (var i = 0; i < this.data.buckets.length; i++) {
                var title = Utils.capitalize(this.data.buckets[i]);
                if (this.data.buckets[i] == "rejected") {
                    var btn = $('<div class = "rejectContainer"></div>');
                    var btnReject = $('<button type="button" data-bucket = "' + this.data.buckets[i] + '" class="btn btn-default btn-xs btnTriggerReject btnSend' + title + '"><div class = "flag"><i class = "fa fa-chevron-right"></i></div>' + title + '</button>');
                    btn.append(btnReject);
                }
                else {
                    var btn = $('<button type="button" data-bucket = "' + this.data.buckets[i] + '" class="btn btn-default btn-xs btnMoveToBucket btnSend' + title + '"><div class = "flag"><i class = "fa fa-chevron-right"></i></div>' + title + '</button>');
                }
                this.buttonHolder.append(btn);
            }
            //if this experience item is in the rejected bucket, add the delete button
            if (this.delegate.bucket == "rejected") {
                var del = $('<button type="button" class="btn btn-default btn-xs btnDelete"><div class = "flag"><i class = "fa fa-close"></i></div>Delete</button>');
                this.buttonHolder.append(del);
                del.bind('click', $.proxy(this.deleteClicked, this));
            }
        };
        /**
         * Saves the notes field to the database for the specific experience.
         */
        ModerationOverlay.prototype.saveNotes = function () {
            Ajax.post(new AjaxRequest({
                'task': 'update-experience-notes',
                'notes': this.data.notes,
                'experience_id': this.data.id
            }, $.proxy(this.notesUpdated, this), $.proxy(this.notesUpdateError, this), null, '/api/1.1'));
        };
        /**
         * Shows the component, if it hasn't been initialized, call init.
         */
        ModerationOverlay.prototype.showMe = function () {
            if (!this.initialized) {
                this.init();
            }
            this.storyLabel.html(this.delegate.activeStoryData.name);
            _super.prototype.showMe.call(this);
        };
        ModerationOverlay.prototype.notesUpdated = function () {
        };
        /**
         * Called if there's an error trying to update the notes on an experience.
         */
        ModerationOverlay.prototype.notesUpdateError = function (e) {
            console.error("Error updating experience notes");
            console.log(e);
        };
        return ModerationOverlay;
    })(Nickel.Component);
    Nickel.ModerationOverlay = ModerationOverlay;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    /**
     * Simple component for loading and playing an video file with a <video> tag.
     */
    var ModVideoPlayer = (function (_super) {
        __extends(ModVideoPlayer, _super);
        /**
         * Stores the global vars, sets the references to the video node, and loader.
         * @param container        A jQuery object containing the parent div for this component.
         * @param data          The JSON data unique to this component.
         * @param delegate        The Class that created this instance.
         */
        function ModVideoPlayer(container, data, delegate) {
            _super.call(this, container, data, delegate);
            /**
             * The url we're currently playing through the video node.
             */
            this.activeSrc = "";
            /**
             * If we should play the video as soon as it's loaded.
             */
            this.autoplay = false;
            /**
             * If the video is playing right now or not.
             */
            this.isPlaying = false;
            /**
             * Whether or not we should be checking the progress of the video as it plays. Disabled by default for
             * performace reasons.
             */
            this.checkProgress = false;
            /**
             * What time we should start the video at.
             */
            this.startTime = 0;
            this.viewLoaded(Main.templates.find('.moderationVideoPlayer').clone());
            this.$videoNode = this.content.find('.mainVideo');
            this.videoNode = this.$videoNode[0];
            this.$videoNode.on('ended', $.proxy(this.vidOver, this));
            this.loader = this.content.find('.loader');
        }
        /**
         * Called when the native ended event fires. Dispatches the VIDEO_END event.
         */
        ModVideoPlayer.prototype.vidOver = function () {
            clearInterval(this.checkTimeInt);
            this.dispatch(ModVideoPlayer.VIDEO_END);
        };
        /**
         * Loads a video through the video node, binds the canplaythrough event.
         * @data        The url and poster frame for the video we want to load.
         */
        ModVideoPlayer.prototype.loadVideo = function (data) {
            if (!this.onStage) {
                this.showMe();
            }
            this.activeSrc = data.src;
            this.$videoNode.one('canplaythrough', $.proxy(this.videoReady, this));
            if (data.poster) {
                this.$videoNode.attr('poster', data.poster);
            }
            else {
                this.$videoNode.removeAttr('poster');
            }
            this.videoNode.src = data.src;
            this.videoNode.currentTime = this.startTime;
            this.videoNode.load();
        };
        /**
         * Called when "canplaythrough" fires on the video node, only once.
         */
        ModVideoPlayer.prototype.videoReady = function () {
            this.showVideo();
            if (this.autoplay) {
                this.playVideo();
            }
        };
        /**
         * Plays the video node, if we're checking progress, sets the checkTimeInt.
         */
        ModVideoPlayer.prototype.playVideo = function () {
            clearInterval(this.checkTimeInt);
            if (this.checkProgress) {
                this.checkTimeInt = setInterval($.proxy(this.checkTime, this), 43);
            }
            this.videoNode.play();
            this.dispatch(ModVideoPlayer.PLAY);
        };
        /**
         * Pauses the video node.
         */
        ModVideoPlayer.prototype.pauseVideo = function () {
            clearInterval(this.checkTimeInt);
            this.videoNode.pause();
        };
        /**
         * Starts the video playback again.
         */
        ModVideoPlayer.prototype.replayVideo = function () {
            this.videoNode.currentTime = 0;
            this.videoNode.play();
            this.isPlaying = true;
        };
        /**
         * Checks the video node current time against it's duration to calculate the percentage complete, dispatches
         * the PROGRESS event.
         */
        ModVideoPlayer.prototype.checkTime = function () {
            var current = this.videoNode.currentTime;
            var perc = current / this.videoNode.duration;
            this.dispatch(ModVideoPlayer.PROGRESS, { 'currentTime': current, 'perc': perc });
        };
        /**
         * Shows the video node and hides the loader.
         */
        ModVideoPlayer.prototype.showVideo = function () {
            this.$videoNode.show();
            this.loader.hide();
        };
        /**
         * Hides the video node.
         */
        ModVideoPlayer.prototype.hideVideo = function () {
            this.videoNode.pause();
            this.$videoNode.hide();
        };
        /**
         * Shows the loades and hides the video node.
         */
        ModVideoPlayer.prototype.showLoader = function () {
            this.hideVideo();
            this.loader.show();
        };
        /**
         * Pauses the video and hides the component.
         */
        ModVideoPlayer.prototype.hideMe = function () {
            _super.prototype.hideMe.call(this);
            this.pauseVideo();
        };
        /**
         * Event Dispatched once the video node begins to play.
         */
        ModVideoPlayer.PLAY = "playvideo";
        /**
         * Event Dispatched once the video node reaches the end.
         */
        ModVideoPlayer.VIDEO_END = "videoend";
        /**
         * Event Dispatched on an interval as the video plays.
         */
        ModVideoPlayer.PROGRESS = "progress";
        return ModVideoPlayer;
    })(Nickel.Component);
    Nickel.ModVideoPlayer = ModVideoPlayer;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    /**
     * Simple component for loading and playing an audio file with a <audio> tag.
     */
    var AudioPlayer = (function (_super) {
        __extends(AudioPlayer, _super);
        /**
         * Stores the global vars, Loads the view, and sets the audio node vars.
         * @param container        A jQuery object containing the parent div for this component.
         * @param data          The JSON data unique to this component.
         * @param delegate        The Class that created this instance.
         */
        function AudioPlayer(container, data, del) {
            _super.call(this, container, data, del);
            /**
             * Whether we should play the file as soon as it's loaded.
             */
            this.autoplay = false;
            this.viewLoaded(Main.templates.find('.audioPlayer').clone());
            this.$audioNode = this.content.find('.audioNode');
            this.audioNode = this.$audioNode[0];
        }
        /**
         * Loads an audio file and adds the canplaythrough event listener.
         * @param src        the url to the audio clip we want to load.
         */
        AudioPlayer.prototype.loadAudio = function (src) {
            this.$audioNode.one('canplaythrough', $.proxy(this.audioLoaded, this));
            this.audioNode.src = src;
        };
        /**
         * If we're autoplaying, play the audio.
         */
        AudioPlayer.prototype.audioLoaded = function () {
            if (this.autoplay) {
                this.audioNode.play();
            }
        };
        /**
         * Hide the node, and pause the audio.
         */
        AudioPlayer.prototype.hideMe = function () {
            _super.prototype.hideMe.call(this);
            this.audioNode.pause();
        };
        return AudioPlayer;
    })(Nickel.Component);
    Nickel.AudioPlayer = AudioPlayer;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    /**
     * Parent class for the 4 kinds of inventory item used on the moderation overlay.
     */
    var Inventory = (function (_super) {
        __extends(Inventory, _super);
        /**
         * Stores the global vars.
         * @param container        A jQuery object containing the parent div for this component.
         * @param data          The JSON data unique to this component.
         * @param delegate        The Class that created this instance.
         */
        function Inventory(container, data, delegate) {
            _super.call(this, container, data, delegate);
        }
        /**
         * Binds the click event listener for this item.
         */
        Inventory.prototype.bindEvents = function () {
            this.content.bind('click', $.proxy(this.viewContent, this));
        };
        /**
         * Unbind the click event listener for this item.
         */
        Inventory.prototype.unbindEvents = function () {
            this.content.unbind('click', $.proxy(this.viewContent, this));
        };
        /**
         * Make this item active and make the previously active item inactive.
         */
        Inventory.prototype.viewContent = function () {
            this.container.find('.active').removeClass('active');
            this.content.addClass('active');
        };
        /**
         * Remove event listeners, remove this.content and set it to null.
         */
        Inventory.prototype.killMe = function () {
            this.unbindEvents();
            this.content.remove();
            this.content = null;
        };
        return Inventory;
    })(Nickel.Component);
    Nickel.Inventory = Inventory;
    var VideoInventoryItem = (function (_super) {
        __extends(VideoInventoryItem, _super);
        function VideoInventoryItem(container, data, delegate) {
            _super.call(this, container, data, delegate);
            this.viewLoaded(Main.templates.find('.videoInventoryItem').clone());
            this.bindEvents();
        }
        VideoInventoryItem.prototype.viewContent = function () {
            _super.prototype.viewContent.call(this);
            var url = (this.data.mp4Url) ? this.data.mp4Url : this.data.url;
            var d = { 'src': url };
            this.delegate.playVideo(d, this);
        };
        return VideoInventoryItem;
    })(Inventory);
    Nickel.VideoInventoryItem = VideoInventoryItem;
    var TextInventoryItem = (function (_super) {
        __extends(TextInventoryItem, _super);
        function TextInventoryItem(container, data, delegate) {
            _super.call(this, container, data, delegate);
            this.viewLoaded(Main.templates.find('.textInventoryItem').clone());
            this.bindEvents();
        }
        TextInventoryItem.prototype.viewContent = function () {
            _super.prototype.viewContent.call(this);
            this.delegate.showText(this.data.src + "", this);
        };
        return TextInventoryItem;
    })(Inventory);
    Nickel.TextInventoryItem = TextInventoryItem;
    var ImageInventoryItem = (function (_super) {
        __extends(ImageInventoryItem, _super);
        function ImageInventoryItem(container, data, delegate) {
            _super.call(this, container, data, delegate);
            this.viewLoaded(Main.templates.find('.imageInventoryItem').clone());
            this.bindEvents();
        }
        ImageInventoryItem.prototype.viewContent = function () {
            _super.prototype.viewContent.call(this);
            this.delegate.showImage(this.data.url, this);
        };
        return ImageInventoryItem;
    })(Inventory);
    Nickel.ImageInventoryItem = ImageInventoryItem;
    var AudioInventoryItem = (function (_super) {
        __extends(AudioInventoryItem, _super);
        function AudioInventoryItem(container, data, delegate) {
            _super.call(this, container, data, delegate);
            this.viewLoaded(Main.templates.find('.audioInventoryItem').clone());
            this.bindEvents();
        }
        AudioInventoryItem.prototype.viewContent = function () {
            _super.prototype.viewContent.call(this);
            this.delegate.playAudio(this.data.url, this);
        };
        return AudioInventoryItem;
    })(Inventory);
    Nickel.AudioInventoryItem = AudioInventoryItem;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    /**
     * Component for selecting why an experience was rejected
     */
    var RejectionSelector = (function (_super) {
        __extends(RejectionSelector, _super);
        /**
         * Stores the global vars, Loads the view, and sets the audio node vars.
         * @param container        A jQuery object containing the parent div for this component.
         * @param data          The JSON data unique to this component.
         * @param delegate        The Class that created this instance.
         */
        function RejectionSelector(container, data, del) {
            var _this = this;
            _super.call(this, container, data, del);
            /**
             * Config
             */
            this.rejectionReasons = [
                {
                    "category": "Violence and Threats",
                    "content": [
                        "Contains Threats to harm others, or organize acts of real-world violence.",
                        "Contains Threats of self-harm or promotion or encouragement of self-mutilation.",
                        "Suggests or encourages illegal activity.",
                        "Contains content suggesting political affiliations.",
                        "Contains Racial comments/affiliations including flags or symbols."
                    ]
                },
                {
                    "category": "Adult/Obscene",
                    "content": [
                        "Contains Obscene, profane, lewd, graphic or suggestive content.",
                        "Exploits children or minors in any form.",
                        "Contains sex toys in the frame."
                    ]
                },
                {
                    "category": "Bullying and Harassment",
                    "content": [
                        "Bullying or stalking, including abusive behavior directed at individuals.",
                        "Harassment of others including but not limited to name-calling, taunting, teasing or threats.",
                        "Contains Personal identifying information (phone #, address, SSN, etc.)."
                    ]
                },
                {
                    "category": "Hate Speech",
                    "content": [
                        "Contains Racially motivated or otherwise offensive language.",
                        "Attacks a person based on their race, ethnicity, national origin, religion, sex, gender, sexual orientation, disability or medical condition."
                    ]
                },
                {
                    "category": "Illegal Drug Use",
                    "content": [
                        "Describes or encourages illegal drug use.",
                        "Describes underage drinking.",
                        "Contains alcoholic beverages.",
                        "Contains drug paraphernalia."
                    ]
                },
                {
                    "category": "Graphic Content",
                    "content": [
                        "Encourages or depicts cruelty to animals",
                        "Contains gang signs.",
                        "Contains racist content."
                    ]
                },
                {
                    "category": "Other",
                    "content": [
                        "Technical difficulties"
                    ]
                }
            ];
            /**
             * The max width and height of the component
             */
            this.width = 300;
            this.height = 250;
            this.btnWidth = 80;
            this.btnHeight = 20;
            this.viewLoaded(Main.templates.find('.rejectionSelector').clone());
            this.rejectionList = this.content.find('.rejectionList');
            this.populateList();
            this.content.find('.btnCloseRejection').on('click', function () { return _this.hideMe(); });
            this.content.find('.reason').on('click', function (e) { return _this.reasonSelected(e); });
        }
        /**
         * On click handler for rejection reasons
         */
        RejectionSelector.prototype.reasonSelected = function (e) {
            this.callback($(e.target).text());
            this.hideMe();
        };
        /**
         * Toggle the visibility of each category
         */
        RejectionSelector.prototype.toggleCategory = function (e) {
            var p = $(e.target).parent();
            if (p.hasClass('open')) {
                p.removeClass('open');
            }
            else {
                p.addClass('open');
            }
        };
        /**
         * Create all of the categories and reasons for the list
         */
        RejectionSelector.prototype.populateList = function () {
            var _this = this;
            for (var i = 0; i < this.rejectionReasons.length; i++) {
                var cat = this.rejectionReasons[i];
                var catContainer = $('<div class = "categoryContainer"><ul class = "reasons"></ul></div>');
                var title = $('<span class = "title">' + cat.category + '<i class = "icon fa fa-caret-right"/></span>');
                title.on('click', function (e) { return _this.toggleCategory(e); });
                catContainer.prepend(title);
                for (var j = 0; j < cat.content.length; j++) {
                    var item = cat.content[j];
                    catContainer.find('.reasons').append('<li class = "reason">' + item + '</li>');
                }
                this.rejectionList.append(catContainer);
            }
        };
        /**
         * Move the rejection Selector to be on top of the button that triggered it, set the callback
         */
        RejectionSelector.prototype.attach = function (target, callback) {
            this.callback = callback;
            //top left default
            var x = target.offset().left;
            var y = target.offset().top;
            var pos = {};
            var xKey = 'left';
            var yKey = 'top';
            //if too close to the right, change to right pos
            if (x + this.width > window.innerWidth) {
                x = x - this.width + this.btnWidth;
            }
            //if too close to the bottom, change to bottom pos
            if (y + this.height > window.innerHeight) {
                y = y - this.height + this.btnHeight;
            }
            pos[xKey] = x;
            pos[yKey] = y;
            this.content.css(pos);
        };
        RejectionSelector.prototype.hideMe = function () {
            _super.prototype.hideMe.call(this);
            this.content.find('.open').removeClass('open');
        };
        return RejectionSelector;
    })(Nickel.Component);
    Nickel.RejectionSelector = RejectionSelector;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    var DataView = (function (_super) {
        __extends(DataView, _super);
        /**
         * Stores the global vars, and adds the state change listener
         * @param container    A jQuery object containing the parent div for this view.
         * @param id            The unique ID associated with this view, used to determine if this view should be
         *     visible or not by listening to the browser state.
         * @param displayText    The text to show in the header for this view.
         */
        function DataView(container, id, displayText) {
            _super.call(this, container, id, displayText);
            /**
             * The label of the current story we're viewing batches for
             */
            this.storyLabel = "";
            /**
             * The label of the current batch we're viewing
             */
            this.batchLabel = "";
            /**
             * An array holding all BatchRowItem classes
             */
            this.batchRows = [];
            /**
             * An array holding all BatchColumn classes
             */
            this.batchColumns = [];
            /**
             * A JSON-encoded string representing the last loaded batch column result.
             * Used to only reload columns if changes have occurred to the column structure.
             */
            this.batchColumnResult = "";
            /**
             * If this view has been initilized yet
             */
            this.initialized = false;
            /**
             * The number of rows to render. Will be 0 unless a batch is currently running.
             * The default value of 1 is overwritten as soon as data is returned. This hides the Render button until we
             * have data.
             */
            this.rowsToRender = 1;
            this.storyDropdown = new Nickel.StoryPicker(this.container.find('.storyDropdownContainer'), null, this);
            this.batchDropdown = new Nickel.BatchPicker(this.container.find('.batchDropdownContainer'), null, this);
            this.loader = this.container.find('.batchLoader');
            this.exportLadda = Ladda.create(this.container.find('.btnExportBatch').get(0));
            this.container.find('[data-toggle="tooltip"]').tooltip();
        }
        /**
         * Binds this view to the DOM, populates the dynamic header buttons, binds all of the event listeners, and
         * get's the first batch of experiences.
         */
        DataView.prototype.init = function () {
            this.initialized = true;
            this.rivets = rivets.bind(this.container, {
                "controller": this
            });
            this.storyDropdown.on(Nickel.StoryPicker.STORY_CLICKED, $.proxy(this.storyClicked, this));
            this.batchDropdown.on(Nickel.BatchPicker.BATCH_CLICKED, $.proxy(this.batchClicked, this));
            this.container.find('.btnNewBatch').bind('click', $.proxy(this.createNewBatch, this));
            this.container.find('.btnImportBatchData').bind('click', $.proxy(this.triggerBatchDataImport, this));
            this.container.find('.btnDeleteBatch').bind('click', $.proxy(this.deleteBatch, this));
            this.container.find('.btnEditColumns').bind('click', $.proxy(this.editColumns, this));
            this.container.find('.btnCloseColumnEditor').bind('click', $.proxy(this.hideColumnEditor, this));
            this.container.find('.btnExportBatch').bind('click', $.proxy(this.exportBatch, this));
            this.container.find('.btnRenderBatch').bind('click', $.proxy(this.renderBatch, this));
            this.container.find('.btnCancelBatch').bind('click', $.proxy(this.cancelBatch, this));
            this.container.find('.btnSaveColumns').bind('click', $.proxy(this.saveColumns, this));
            this.container.find('.btnAddColumn').bind('click', $.proxy(this.addColumn, this));
            this.spreadsheetUpload = this.container.find('.spreadsheetUpload');
            this.spreadsheetUpload.bind('click', function (e) { $(this).prop("value", ""); e.stopPropagation(); });
            this.spreadsheetUpload.bind('change', $.proxy(this.importBatchData, this));
        };
        /**
         * Decide which story and batch to load and display.
         */
        DataView.prototype.stateChanged = function () {
            _super.prototype.stateChanged.call(this);
            if (this.initialized) {
                var urlStrings = History['getState']().url.split("?")[0].split("/");
                var storyId = urlStrings[4];
                var batchId = urlStrings[5];
                this.setState(storyId, batchId);
            }
        };
        /**
         * Reloads data from the db and sets current state based on story id / batch id specified.
         * This should only be called from stateChanged. Nowhere else.
         * @param storyId
         * @param batchId
         */
        DataView.prototype.setState = function (storyId, batchId) {
            if (storyId === void 0) { storyId = null; }
            if (batchId === void 0) { batchId = null; }
            if (storyId) {
                Ajax.get(new JWTAjaxRequest('/story/' + storyId + '/batches', null, $.proxy(function (response) {
                    this.storyId = response.story_id;
                    this.storyLabel = response.story_name;
                    Main.setHeaderStory({ id: this.storyId, name: this.storyLabel });
                    var batches = response.batches;
                    this.batchDropdown.populateBatchDropdown(batches, this.storyId);
                    if (batchId) {
                        for (var i = 0; i < batches.length; i++) {
                            if (batches[i]['id'] === batchId) {
                                this.batchId = batchId;
                                this.batchLabel = batches[i]['name'];
                                break;
                            }
                        }
                        this.page = 1;
                        this.reloadPaginatedData();
                    }
                }, this)));
            }
            else {
                Main.setDefaultHeaderStory();
            }
        };
        /**
         * Clears current story & batch state.
         */
        DataView.prototype.clearState = function () {
            this.storyId = null;
            this.clearBatchState();
        };
        /**
         * Clears current batch state.
         */
        DataView.prototype.clearBatchState = function () {
            this.batchId = null;
            this.batchLabel = "";
            this.batchColumnResult = '';
            this.clearColumns();
        };
        /**
         * Handles story being clicked from Story dropdown.
         * @param story
         */
        DataView.prototype.storyClicked = function (story) {
            if (story.id !== this.storyId) {
                this.clearState();
                Utils.pushState('/data/' + story.id);
            }
        };
        /**
         * Handles batch being clicked from Batch dropdown.
         * @param batch
         */
        DataView.prototype.batchClicked = function (batch) {
            if (batch.id !== this.batchId) {
                this.clearBatchState();
                Utils.pushState('/data/' + batch.story_id + '/' + batch.id);
            }
        };
        /**
         * Adds a batch entry under the current story (without data).
         */
        DataView.prototype.createNewBatch = function () {
            var name = prompt('Please provide a name for the new batch.');
            if (name) {
                Ajax.post(new JWTAjaxRequest('/batch', {
                    'story_id': this.storyId,
                    'name': name,
                }, $.proxy(function (batch) {
                    this.batchClicked(batch);
                }, this)));
            }
        };
        /**
         * Reaches out to server to get batch data.
         */
        DataView.prototype.getPaginatedData = function () {
            this.clearExperiences();
            if (!this.batchId) {
                return;
            }
            var filters = [];
            for (var i = 0; i < this.batchColumns.length; i++) {
                if (this.batchColumns[i].hasFilter()) {
                    filters.push(this.batchColumns[i].getFilter());
                }
            }
            Ajax.get(new JWTAjaxRequest('/batch/' + this.batchId + '?' + $.param({ 'page': this.page, 'filters': filters }), null, $.proxy(this.gotBatch, this), null, this.loader));
        };
        /**
         * Loads batch data into display.
         */
        DataView.prototype.gotBatch = function (result) {
            this.pages = result.total_pages;
            this.total = result.row_count;
            this.rowsToRender = result.rows_to_render;
            // Add columns
            this.setColumns(result.columns);
            // Add rows
            this.addExperiences(result.data);
            // Size dynamic columns evenly
            var totalColumns = this.container.find('.columnHolder .col.col-auto').length;
            var percent = (totalColumns > 0) ? (100 / totalColumns) : 0;
            this.container.find('.col-auto').not('.col-status').css('width', percent + '%');
            this.checkPaginationButtons();
        };
        /**
         * Creates new BatchColumn classes and adds them to the this.batchColumns object.
         * @param columnData
         */
        DataView.prototype.setColumns = function (columnData) {
            // Check to see if columns have changed - return immediately if not, cache and continue if so
            var encodedResult = JSON.stringify(columnData);
            if (encodedResult === this.batchColumnResult) {
                return;
            }
            this.batchColumnResult = encodedResult;
            // Wipe existing columns / column holder
            this.clearColumns();
            // Add columns
            var columnHolder = this.container.find('.columnHolder');
            for (var index in columnData) {
                var col = new Nickel.BatchColumn(columnHolder, parseInt(index), columnData[index], this);
                this.batchColumns.push(col);
            }
            columnHolder.append('<div class = "col col-auto">Moderation Status</div>'); // Add status
        };
        /**
         * Creates new BatchRowItem classes and adds them to the this.batchRows object.
         * @param rowData
         */
        DataView.prototype.addExperiences = function (rowData) {
            var batchHolder = this.container.find('.batchHolder');
            for (var i = 0; i < rowData.length; i++) {
                var exp = new Nickel.BatchRowItem(batchHolder, rowData[i], this);
                this.batchRows.push(exp);
            }
        };
        /**
         * Removes and kills all of the existing BatchRowItem classes.
         */
        DataView.prototype.clearExperiences = function () {
            for (var i = 0; i < this.batchRows.length; i++) {
                var exp = this.batchRows[i];
                exp.killMe();
                exp = null;
            }
            this.batchRows = [];
        };
        /**
         * Removes and kills all of the existing BatchColumn classes.
         */
        DataView.prototype.clearColumns = function () {
            for (var i = 0; i < this.batchColumns.length; i++) {
                var col = this.batchColumns[i];
                col.killMe();
                col = null;
            }
            this.batchColumns = [];
            var columnHolder = this.container.find('.columnHolder');
            columnHolder.empty();
        };
        /**
         * Brings up the interface to associate EGC/UGC with certain columns.
         */
        DataView.prototype.editColumns = function () {
            if (this.batchColumns.length <= 0) {
                alert('Please import a CSV file before modifying EGC/UGC column associations.');
                return;
            }
            Ajax.get(new JWTAjaxRequest('/story/' + this.storyId, null, $.proxy(this.showColumnEditor, this)));
        };
        /**
         * Show column editor.
         */
        DataView.prototype.showColumnEditor = function (storyData) {
            // Bind association select handler
            this.container.find('.columns ul[data-column] select.columnTypeSelect').each(function () {
                $(this).unbind('change').bind('change', $.proxy(DataView.configureAssociationDropdown, $(this).parent().parent(), storyData)).change();
            });
            this.container.find('.columns ul[data-column] select.associationSelect').unbind('change').bind('change', $.proxy(this.saveAssociationDetail, this));
            // Populate latest detail value on each column (because selection options are dynamic)
            for (var i = 0; i < this.batchColumns.length; i++) {
                var column = this.batchColumns[i];
                this.container.find('.columns ul[data-column=' + column.getColumnIndex() + '] select.associationSelect').val(column.data.detail).change();
            }
            // Show the view
            this.container.find('.columnEditor').addClass('active');
        };
        /**
         * Hide column editor.
         */
        DataView.prototype.hideColumnEditor = function () {
            // Hide the view
            this.container.find('.columnEditor').removeClass('active');
            // Force column refresh in case of cancelled changes
            this.batchColumnResult = '';
            // Reload data so user can see column changes
            this.reloadPaginatedData();
        };
        /**
         * Exports batch data with added EGC columns to a CSV file.
         */
        DataView.prototype.exportBatch = function (e) {
            this.exportLadda.start();
            Ajax.get(new JWTAjaxRequest('/batch/' + this.batchId + '/export', null, $.proxy(function (response) {
                if (response.id) {
                    this.downloadBatchExport(response.id);
                }
                else {
                    Ajax.post(new JWTAjaxRequest('/batch/' + this.batchId + '/export', null, $.proxy(this.batchDataExportQueued, this, this.batchId)));
                }
            }, this)));
        };
        /**
         * Asks for confirmation and triggers the batch render if confirmed.
         */
        DataView.prototype.renderBatch = function () {
            Ajax.post(new JWTAjaxRequest('/batch/' + this.batchId + '/prepare', null, $.proxy(function (response) {
                var triggered = false;
                /*var avgRenderTime: number = response.average_render_time;
                if (!avgRenderTime) {
                    alert('Please render at least 1 video before attempting to run the batch.')
                } else */ if (!response.rows_to_render || response.rows_to_render <= 0) {
                    alert('Nothing to render.');
                }
                else {
                    var confirmation = confirm(DataView.getBatchConfirmationMsg(response.rows_to_render));
                    if (confirmation) {
                        this.triggerBatchRender();
                        triggered = true;
                    }
                }
                if (!triggered) {
                    Ajax.post(new JWTAjaxRequest('/batch/' + this.batchId + '/cancel'));
                }
            }, this), null, this.loader));
        };
        /**
         * Starts the batch render.
         */
        DataView.prototype.triggerBatchRender = function () {
            Ajax.post(new JWTAjaxRequest('/job/render-batch', {
                'batch_id': this.batchId,
            }, $.proxy(this.reloadPaginatedData, this)));
        };
        /**
         * Cancels a batch by setting the # of rows to render to 0.
         */
        DataView.prototype.cancelBatch = function () {
            var confirmation = confirm('Are you sure? This will cancel the current batch and any partially collected EGC data will be left in its current state.');
            if (confirmation) {
                Ajax.post(new JWTAjaxRequest('/batch/' + this.batchId + '/cancel', null, $.proxy(this.reloadPaginatedData, this)));
            }
        };
        /**
         * Returns the final warning message before triggering the batch to render.
         * @param numJobs
         * @returns {string}
         */
        DataView.getBatchConfirmationMsg = function (numJobs) {
            var confirmMsg = 'Found ' + numJobs + ' ';
            confirmMsg += (numJobs > 1) ? 'jobs' : 'job';
            confirmMsg += ' to render. \n\n';
            confirmMsg += 'Press OK to run the batch.';
            return confirmMsg;
        };
        /**
         * Configures an association dropdown based on the currently selected column type. Context is a column <ul>.
         */
        DataView.configureAssociationDropdown = function (storyData) {
            var inventory, videoScene;
            for (var actId in storyData['acts']) {
                if (storyData['acts'].hasOwnProperty(actId)) {
                    inventory = storyData['acts'][actId]['inventory'];
                    for (var sceneId in storyData['acts'][actId]['scenes']) {
                        if (storyData['acts'][actId]['scenes'].hasOwnProperty(sceneId) &&
                            (storyData['acts'][actId]['scenes'][sceneId]['type'].indexOf('Video') != -1 || storyData['acts'][actId]['scenes'][sceneId]['type'].indexOf('Composition') != -1)) {
                            videoScene = storyData['acts'][actId]['scenes'][sceneId];
                            break;
                        }
                    }
                    break;
                }
            }
            var associationFields = $(this).find('.association');
            var associationSelect = associationFields.find('select.associationSelect');
            associationSelect.empty();
            var columnType = $(this).find('select.columnTypeSelect').val();
            switch (columnType) {
                case 'UGC':
                    associationFields.show();
                    associationSelect.append(new Option('Composition ID', 'composition_id'));
                    associationSelect.append(new Option('Composition Tag', 'composition_tag'));
                    if (inventory) {
                        for (var inventoryId in inventory) {
                            if (inventory.hasOwnProperty(inventoryId)) {
                                var inventoryItem = inventory[inventoryId];
                                associationSelect.append(new Option(inventoryItem.label || inventoryItem.name, inventoryId));
                            }
                        }
                    }
                    break;
                case 'EGC':
                    associationFields.show();
                    associationSelect.append(new Option('Experience ID', 'id'));
                    associationSelect.append(new Option('Story ID', 'story_id'));
                    if (videoScene) {
                        var encodingOptions = videoScene['sceneData']['encodingSettings'];
                        var increments = [];
                        var formatNames = [];
                        var formatKeys = [];
                        for (var i = 0; i < encodingOptions.length; i++) {
                            if (encodingOptions[i].extension && encodingOptions[i].height) {
                                var formatName = encodingOptions[i].name;
                                var formatKey = encodingOptions[i].extension + '_' + encodingOptions[i].height;
                                if (typeof increments[formatKey] === 'undefined') {
                                    increments[formatKey] = 0;
                                }
                                increments[formatKey]++;
                                formatNames.push(formatName);
                                if (formatKeys.indexOf(formatKey) <= -1) {
                                    formatKeys.push(formatKey);
                                }
                                else {
                                    formatKeys.push(formatKey + '_' + increments[formatKey]);
                                }
                            }
                        }
                        for (var i = 0; i < formatKeys.length; i++) {
                            associationSelect.append(new Option(formatNames[i], 'video_url_' + formatKeys[i]));
                        }
                        if (videoScene['exportAsPlaylist']) {
                            associationSelect.append(new Option('M3U8', 'video_url_m3u8'));
                        }
                        if (videoScene['sceneData']['savePosterFrame']) {
                            associationSelect.append(new Option('Poster Frame', 'poster'));
                        }
                        if (videoScene['sceneData']['saveSnapshotFrame']) {
                            associationSelect.append(new Option('Snapshot Frame', 'snapshot'));
                        }
                        if (videoScene['sceneData']['saveSocialFrame']) {
                            associationSelect.append(new Option('Social Frame', 'social'));
                        }
                    }
                    break;
                default:
                    associationFields.hide();
                    break;
            }
            // Trigger association select change to save type/detail parameters.
            associationSelect.change();
        };
        /**
         * Binds dropdowns to the type/detail parameter of a column.
         * @param e The click event.
         */
        DataView.prototype.saveAssociationDetail = function (e) {
            var detailDropdown = $(e.currentTarget);
            var typeDropdown = detailDropdown.parent().parent().find('select.columnTypeSelect');
            var columnIndex = detailDropdown.parent().parent().data('column');
            this.batchColumns[columnIndex].data.type = typeDropdown.val();
            this.batchColumns[columnIndex].data.detail = detailDropdown.val();
        };
        /**
         * Deletes the current batch.
         */
        DataView.prototype.deleteBatch = function () {
            var confirmation = confirm("Delete " + this.batchLabel + "? This will permanently remove the batch along with all of it's data.");
            if (confirmation) {
                Ajax.request('DELETE', new JWTAjaxRequest('/batch/' + this.batchId, null, $.proxy(function (response) {
                    this.clearBatchState();
                    Utils.pushState('/data/' + this.storyId);
                }, this)));
            }
        };
        /**
         * Triggers file upload.
         * @param e
         */
        DataView.prototype.triggerBatchDataImport = function (e) {
            this.spreadsheetUpload.click();
        };
        /**
         * Imports chosen file into current batch's data.
         */
        DataView.prototype.importBatchData = function (e) {
            var input = this.spreadsheetUpload[0];
            var file = input.files[0];
            if (file) {
                var myFormData = new FormData();
                myFormData.append('csv', file);
                this.loader.show();
                $.ajax({
                    url: AjaxUrlProvider.getRestApiBaseUrl() + '/batch/' + this.batchId + '/data/from-csv',
                    type: 'POST',
                    processData: false,
                    contentType: false,
                    dataType: 'json',
                    data: myFormData,
                    headers: {
                        'Authorization': 'Bearer ' + JWTAjaxRequest.idToken
                    },
                    success: $.proxy(this.batchDataImportQueued, this)
                });
            }
        };
        /**
         * Callback for when a spreadsheet has been queued to be imported.
         * @param response
         */
        DataView.prototype.batchDataImportQueued = function (response) {
            if (response['job_id'] && response['job_name']) {
                Nickel.JobHandler.pollFor(response['job_id'], response['job_name'], $.proxy(function () {
                    this.loader.hide();
                    this.reloadPaginatedData();
                }, this), $.proxy(function () {
                    this.loader.hide();
                    alert('Failed to import CSV data - check the Console logs at the bottom of the Stories tab for more information');
                }, this));
            }
            else {
                this.loader.hide();
                alert('Unable to create job to import CSV data');
            }
        };
        /**
         * Callback for when a batch has been queued to be exported.
         * @param batchId
         * @param response
         */
        DataView.prototype.batchDataExportQueued = function (batchId, response) {
            if (response['job_id'] && response['job_name']) {
                Nickel.JobHandler.pollFor(response['job_id'], response['job_name'], $.proxy(function () {
                    Ajax.get(new JWTAjaxRequest('/batch/' + this.batchId + '/export', null, $.proxy(function (response) {
                        if (response.id) {
                            this.downloadBatchExport(response.id);
                        }
                        else {
                            this.exportLadda.stop();
                            alert('Failed to export CSV data');
                        }
                    }, this)));
                }, this), $.proxy(function () {
                    this.exportLadda.stop();
                    alert('Failed to export CSV data - check the Console logs at the bottom of the Stories tab for more information');
                }, this));
            }
            else {
                this.exportLadda.stop();
                alert('Unable to create job to export CSV data');
            }
        };
        /**
         * Downloads a batch export.
         * @param exportId
         */
        DataView.prototype.downloadBatchExport = function (exportId) {
            var anchor = this.container.find('a.btnExportBatchLink');
            $.ajax({
                url: AjaxUrlProvider.getRestApiBaseUrl() + '/batch/' + this.batchId + '/export/' + exportId + '?preserve=true',
                type: 'GET',
                dataType: 'binary',
                headers: {
                    'Authorization': 'Bearer ' + JWTAjaxRequest.idToken
                },
                processData: false,
                success: $.proxy(function (blob) {
                    var windowUrl = window['URL'] || window['webkitURL'];
                    var url = windowUrl.createObjectURL(blob);
                    anchor.prop('href', url);
                    anchor.prop('download', this.batchLabel + '.csv');
                    anchor.get(0).click();
                    windowUrl.revokeObjectURL(url);
                    this.exportLadda.stop();
                }, this),
                error: $.proxy(function (jqXHR, textStatus, errorThrown) {
                    this.exportLadda.stop();
                }, this)
            });
        };
        /**
         * Save latest columns to the db.
         */
        DataView.prototype.saveColumns = function () {
            var columns = [];
            for (var i = 0; i < this.batchColumns.length; i++) {
                columns.push(this.batchColumns[i].data);
            }
            Ajax.request('PUT', new JWTAjaxRequest('/batch/' + this.batchId + '/columns', columns, $.proxy(this.hideColumnEditor, this)));
        };
        /**
         * Add a new column.
         */
        DataView.prototype.addColumn = function () {
            var newColumnIndex = this.batchColumns.length;
            var column = new Nickel.BatchColumn(this.container.find('.columnHolder'), newColumnIndex, {
                'name': '',
                'type': 'EGC',
                'detail': 'id'
            }, this);
            this.batchColumns.push(column);
            Ajax.get(new JWTAjaxRequest('/story/' + this.storyId, null, $.proxy(function (storyData) {
                var newColumn = this.container.find('.columns ul[data-column=' + newColumnIndex + ']');
                newColumn.find('select.columnTypeSelect').bind('change', $.proxy(DataView.configureAssociationDropdown, newColumn, storyData)).change();
                newColumn.find('select.associationSelect').bind('change', $.proxy(this.saveAssociationDetail, this));
            }, this)));
        };
        /**
         * Shows the data view.
         * @param subSection
         */
        DataView.prototype.showMe = function (subSection) {
            _super.prototype.showMe.call(this, subSection);
            if (!this.initialized) {
                this.init();
            }
        };
        return DataView;
    })(Nickel.PaginatedView);
    Nickel.DataView = DataView;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    /**
     * Component for displaying a single user experience data, and providing a UI to manipulate it.
     */
    var BatchRowItem = (function (_super) {
        __extends(BatchRowItem, _super);
        /**
         * Stores the global vars, loads the template, populates the dynamic buttons, and binds the event listeners.
         * @param container        A jQuery object containing the parent div for this view.
         * @param data          The JSON data unique to this component.
         * @param delegate        The Class that created this instance.
         */
        function BatchRowItem(container, data, delegate) {
            _super.call(this, container, data, delegate);
            /**
             * Set to true if this item is clicked, set to false once another item is clicked.
             */
            this.active = false;
            if (data && data['values']) {
                data['displayValues'] = [];
                for (var i = 0; i < data['values'].length; i++) {
                    data['displayValues'][i] = BatchRowItem.filterDisplayValue(data['values'][i]);
                }
            }
            this.data = data;
            this.viewLoaded(Main.templates.find('.batchRowItem').clone());
            this.bindEvents();
        }
        /**
         * Returns the display value for an item.
         * @param originalValue
         */
        BatchRowItem.filterDisplayValue = function (originalValue) {
            if (originalValue.substr(0, 7) === 'http://' || originalValue.substr(0, 8) === 'https://') {
                return '<a href="' + originalValue + '" target="_blank">' + originalValue + '</a>';
            }
            return originalValue;
        };
        /**
         * Binds all of the event listeners for this view.
         */
        BatchRowItem.prototype.bindEvents = function () {
            this.content.bind('click', $.proxy(this.selectItem, this));
        };
        /**
         * Unbinds all of the event listeners for this view.
         */
        BatchRowItem.prototype.unbindEvents = function () {
            this.content.unbind('click', $.proxy(this.selectItem, this));
        };
        /**
         * Called if the user clicks on this item in the list.
         */
        BatchRowItem.prototype.selectItem = function () {
            EventBus.dispatch(BatchRowItem.CLICKED, this);
        };
        /**
         * Completely removes the view from the DOM, clears all event listeners and kills rivets.
         */
        BatchRowItem.prototype.killMe = function () {
            clearTimeout(this.displayTimeout);
            this.unbindEvents();
            this.rivets.unbind();
            this.content.remove();
            this.content = null;
        };
        /**
         * Dispatched when the user clicks on this specific batch row.
         */
        BatchRowItem.CLICKED = "batchrowitemclicked";
        return BatchRowItem;
    })(Nickel.Component);
    Nickel.BatchRowItem = BatchRowItem;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    var BatchColumn = (function (_super) {
        __extends(BatchColumn, _super);
        /**
         * Stores the global vars, loads the template, populates the dynamic buttons, and binds the event listeners.
         * @param container A jQuery object containing the parent div for this view.
         * @param index     The column index (used for identification).
         * @param data      The JSON data unique to this component.
         * @param delegate  The Class that created this instance.
         */
        function BatchColumn(container, index, data, delegate) {
            _super.call(this, container, data, delegate);
            this.viewLoaded(Main.templates.find('.batchColumn').clone());
            this.bindEvents();
            this.content.attr('name', this.data.name);
            this.columnIndex = index;
        }
        /**
         * Gets column index (read only property)
         */
        BatchColumn.prototype.getColumnIndex = function () {
            return this.columnIndex;
        };
        /**
         * Binds all of the event listeners for this view.
         */
        BatchColumn.prototype.bindEvents = function () {
            this.content.find('.btnFilter').bind('click', $.proxy(this.filterClicked, this));
            this.content.find('.btnClear').bind('click', $.proxy(this.clearClicked, this));
            this.content.find('.searchInput').bind('keyup', $.proxy(this.searchKeyup, this));
        };
        /**
         * Unbinds all of the event listeners for this view.
         */
        BatchColumn.prototype.unbindEvents = function () {
            this.content.find('.btnFilter').unbind('click', $.proxy(this.filterClicked, this));
            this.content.find('.btnClear').unbind('click', $.proxy(this.clearClicked, this));
            this.content.find('.searchInput').unbind('keyup', $.proxy(this.searchKeyup, this));
        };
        /**
         * Called if a user clicks on the filter icon on a filterable field.
         */
        BatchColumn.prototype.filterClicked = function () {
            this.content.addClass('search').find('input[type=text]').focus();
        };
        /**
         * Called if the user clicks on the close filter button. Clears the filters and gets the new experiences.
         */
        BatchColumn.prototype.clearClicked = function () {
            this.content.find('.searchInput').val('');
            this.content.removeClass('search');
            this.reloadResults();
        };
        /**
         * Called if the user hits enter after entering a serch filter.
         * e    The event passed in from the keypress.
         */
        BatchColumn.prototype.searchKeyup = function (e) {
            if (e.keyCode === 13) {
                this.reloadResults();
            }
        };
        /**
         * Reloads the results given new column filter configuration.
         */
        BatchColumn.prototype.reloadResults = function () {
            this.delegate.page = 1;
            this.delegate.reloadPaginatedData();
        };
        /**
         * Get an object representing this column's filter. Key is column index, value is the search string.
         */
        BatchColumn.prototype.getFilter = function () {
            return {
                'column_index': this.columnIndex,
                'value': this.content.find('.searchInput').val().trim()
            };
        };
        /**
         * Whether or not we should search by this column.
         */
        BatchColumn.prototype.hasFilter = function () {
            return this.content.hasClass('search');
        };
        /**
         * Completely removes the view from the DOM, clears all event listeners and kills rivets.
         */
        BatchColumn.prototype.killMe = function () {
            clearTimeout(this.displayTimeout);
            this.unbindEvents();
            this.rivets.unbind();
            this.content.remove();
            this.content = null;
        };
        return BatchColumn;
    })(Nickel.Component);
    Nickel.BatchColumn = BatchColumn;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    /**
     * View for testing out a story full flow, using the same imposium.js lib that a front end site would be using.
     */
    var PreviewView = (function (_super) {
        __extends(PreviewView, _super);
        /**
         * Basic component constructor.
         */
        function PreviewView(container, id, displayText) {
            _super.call(this, container, id, displayText);
            /**
             * Whether or not this class has been initialized.
             */
            this.initialized = false;
            /**
             * Story name bound to the DOM through rivets.
             */
            this.storyLabel = "";
            this.actLabel = "";
            /**
             * The status updates from imposium.js
             */
            this.status = "";
            /**
             * The video download src, set once a video is done rendering.
             */
            this.src = "";
            /**
             * The social authentication data on the experience if any.
             */
            this.socialData = null;
            /**
             * If the selected act is meant to have Facebook authentication.
             */
            this.actUsesFacebook = false;
            /**
             * If the selected act is meant to have Twitter authentication.
             */
            this.actUsesTwitter = false;
            /**
             * The interfaces for displaying / uploading inventory items.
             */
            this.inventoryInterfaces = [];
            /**
             * Whether or not the Imposium.js client is initialized
             */
            this.imposiumInitialized = false;
            /**
             * Stores the dynamic cuts from the story
             */
            this.dynamicCutArray = [];
            /**
             * Keeps track of the current dynamic section we are on
             */
            this.currentDynamicSection = 0;
        }
        /**
         * Bind all event listeners, create the date pickers, get the stories, and create the story dropdown.
         */
        PreviewView.prototype.init = function () {
            this.storyDropdown = new Nickel.StoryPicker(this.container.find('.storyDropdownContainer'), null, this);
            this.storyDropdown.on(Nickel.StoryPicker.STORY_CLICKED, $.proxy(this.storyClicked, this));
            this.actDropdown = new Nickel.ActPicker(this.container.find('.actDropdownContainer'), null, this);
            this.actDropdown.on(Nickel.ActPicker.ACT_CLICKED, $.proxy(this.actClicked, this));
            this.container.find('.socialReset').bind('click', $.proxy(this.resetSocialConnections, this));
            this.container.find('.socialOpts .socialOptFacebook').bind('click', $.proxy(this.connectWithFacebook, this));
            this.btnTwitter = this.container.find('.socialOpts .socialOptTwitter').bind('click', $.proxy(this.connectWithTwitter, this));
            this.twitterLogin = new Nickel.TwitterLogin(this.btnTwitter, $.proxy(this.connectedWithTwitter, this));
            this.btnGeneratePreview = this.container.find('.btnGeneratePreview').bind('click', $.proxy(this.createExperience, this));
            this.btnResetPreview = this.container.find('.btnResetPreview').bind('click', $.proxy(this.resetPreview, this));
            this.btnPrevPreview = this.container.find('.btnPrevPreview').bind('click', $.proxy(this.previousDynamicCut, this));
            this.btnNextPreview = this.container.find('.btnNextPreview').bind('click', $.proxy(this.nextDynamicCut, this));
            this.btnCopyPreview = this.container.find('.btnCopyPreview');
            this.playerHolder = this.container.find('.player .playerHolder');
            this.topPlayerBtns = this.container.find('.topBtns');
            this.topInteractables = this.container.find('.topInteractables');
            this.videoNode = this.playerHolder.find('video');
            this.loader = this.container.find('.loaderHolder');
            this.invHolder = this.container.find('.inventory');
            this.rivets = rivets.bind(this.container, {
                "controller": this
            });
            $(window).bind('resize', $.proxy(this.resize, this));
            this.resize();
            this.initialized = true;
        };
        /**
         * Configure the Imposium client for a given story.
         * @param storyId
         * @returns Imposium.Client
         */
        PreviewView.prototype.configureImposiumClient = function (storyId) {
            if (!this.imposiumInitialized) {
                var apiUrl = AjaxUrlProvider.getRestApiBaseUrl();
                var clientConfig = { 'accessToken': 'Bearer ' + JWTAjaxRequest.idToken, 'storyId': storyId };
                if (apiUrl.split('/').pop() === AjaxUrlProvider.localApiHost) {
                    clientConfig['environment'] = 'local';
                }
                else if (apiUrl.indexOf('.staging.') != -1) {
                    clientConfig['environment'] = 'staging';
                }
                this.imposium = new Imposium.Client(clientConfig);
                this.imposiumPlayer = new Imposium.Player(this.videoNode[0], this.imposium);
                this.imposium.on(Imposium.Events.STATUS_UPDATE, $.proxy(this.onStatusUpdate, this));
                this.imposium.on(Imposium.Events.EXPERIENCE_CREATED, $.proxy(this.experienceCreated, this));
                this.imposium.on(Imposium.Events.GOT_EXPERIENCE, $.proxy(this.gotScene, this));
                this.imposiumInitialized = true;
            }
            else {
                this.imposium.setup({ 'storyId': storyId });
            }
            return this.imposium;
        };
        /**
         * Remove any social auth data.
         * @param e The click event.
         */
        PreviewView.prototype.resetSocialConnections = function (e) {
            if (e === void 0) { e = null; }
            this.socialData = null;
        };
        /**
         * Bring up Facebook login page.
         * @param e The click event.
         */
        PreviewView.prototype.connectWithFacebook = function (e) {
            FB.login($.proxy(function (auth) {
                if (auth.authResponse && auth.authResponse.accessToken && auth.authResponse.userID) {
                    FB.api('/me', 'get', {}, $.proxy(function (response) {
                        this.connectedWithFacebook($.extend(auth.authResponse, response));
                    }, this));
                }
            }, this), { 'scope': 'user_photos, user_location' });
        };
        /**
         * Called on successful Facebook login.
         * @param data Login response data from Facebook.
         */
        PreviewView.prototype.connectedWithFacebook = function (data) {
            this.socialData = {
                'message': 'Connected to Facebook as ' + data['name'] + '.',
                'auth': PreviewView.AUTH_FACEBOOK,
                'id': data['id'],
                'credentials': {
                    'access_token': data['accessToken'],
                },
                'info': {
                    'name': data['name'],
                    'first_name': data['first_name'],
                    'last_name': data['last_name'],
                    'gender': data['gender'],
                }
            };
        };
        /**
         * Bring up Twitter login page.
         * @param e The click event.
         */
        PreviewView.prototype.connectWithTwitter = function (e) {
            e.preventDefault();
            this.twitterLogin.login();
        };
        /**
         * Called on successful Twitter login.
         * @param data Login response data from server.
         */
        PreviewView.prototype.connectedWithTwitter = function (data) {
            this.socialData = {
                'message': 'Connected to Twitter as @' + data['username'] + '.',
                'auth': PreviewView.AUTH_TWITTER,
                'id': data['uid'],
                'credentials': {
                    'access_token': data['access_token'],
                    'access_token_secret': data['access_token_secret'],
                },
                'info': {
                    'name': data['name'],
                    'username': data['username'],
                }
            };
        };
        /**
         * Clear which social auths are shown.
         */
        PreviewView.prototype.clearShownSocial = function () {
            this.actUsesFacebook = false;
            this.actUsesTwitter = false;
        };
        /**
         * Set which social auths are shown based on the selected act.
         */
        PreviewView.prototype.setShownSocial = function (act) {
            this.actUsesFacebook = act.usesFacebook;
            this.actUsesTwitter = act.usesTwitter;
        };
        /**
         * Clear out the experienceID, forcing the preview and inventory items to reset.
         */
        PreviewView.prototype.resetPreview = function () {
            Utils.pushState('/preview/' + PreviewView.storyId + "/" + PreviewView.actId);
        };
        /**
         * Force a new story ID into the url.
         */
        PreviewView.prototype.storyClicked = function (d) {
            Utils.pushState('/preview/' + d.id);
        };
        /**
         * Force a new act ID into the url.
         */
        PreviewView.prototype.actClicked = function (d) {
            Utils.pushState('/preview/' + PreviewView.storyId + '/' + d.id);
        };
        /**
         * Decide which story, act, and experience we have to load and display.
         */
        PreviewView.prototype.stateChanged = function () {
            _super.prototype.stateChanged.call(this);
            if (this.initialized) {
                var urlStrings = History['getState']().url.split("?")[0].split("/");
                var storyId = urlStrings[4];
                var actId = urlStrings[5];
                var expId = urlStrings[7];
                if (expId) {
                    //play an existing video
                    this.experienceId = expId;
                }
                else {
                    this.experienceId = null;
                    this.src = "";
                    this.videoNode.hide();
                    this.resetInventory();
                }
                if (storyId) {
                    if (storyId != PreviewView.storyId) {
                        PreviewView.storyId = storyId;
                        PreviewView.actId = actId;
                        this.getStoryData();
                    }
                    else {
                        if (actId != PreviewView.actId) {
                            PreviewView.actId = actId;
                            this.setStory(this.currentStoryData);
                        }
                        else {
                            if (this.experienceId) {
                                this.loadVideo();
                                this.getExperienceData();
                            }
                        }
                    }
                }
                else {
                    if (!Main.setDefaultHeaderStory()) {
                        this.clearStory();
                    }
                }
            }
        };
        /**
         * Get a single experiences data, so we can display the inventory.
         */
        PreviewView.prototype.getExperienceData = function () {
            Ajax.get(new JWTAjaxRequest('/experience/' + this.experienceId, null, $.proxy(this.gotExperienceData, this), $.proxy(this.expDataError, this)));
        };
        /**
         * Set the inventory items for this specific experience.
         */
        PreviewView.prototype.gotExperienceData = function (d) {
            var inventory = d.experience.inventory_items;
            for (var i = 0; i < this.inventoryInterfaces.length; i++) {
                var inter = this.inventoryInterfaces[i];
                var inv = _.find(inventory, function (item) {
                    return item["id"] == inter.data.id;
                });
                if (inv) {
                    inter.setInventory(inv);
                }
            }
        };
        /**
         * Clear the UI elements from a previous story.
         */
        PreviewView.prototype.clearStory = function () {
            this.storyLabel = "";
            this.actLabel = "";
            this.actDropdown.clearActDropdown();
            this.clearInventory();
            this.clearShownSocial();
            PreviewView.storyId = "";
            PreviewView.actId = "";
        };
        /**
         * Get the story data for a single story, so we can determine the acts, and the inventory required for each act.
         */
        PreviewView.prototype.getStoryData = function () {
            if (PreviewView.storyId) {
                Ajax.get(new JWTAjaxRequest('/story/' + PreviewView.storyId, null, $.proxy(this.gotStory, this), $.proxy(this.getStoriesError, this)));
            }
        };
        /**
         * Set the story data to the DOM.
         */
        PreviewView.prototype.gotStory = function (d) {
            if (d) {
                this.setStory(d);
            }
            else {
                this.getStoriesError(null);
            }
        };
        /**
         * Leaving this here so if we have to add validation we know where it should go.
         */
        PreviewView.prototype.validate = function () {
            return true;
        };
        /**
         * Create a solo experience, with the inventory set through the inventory interfaces.
         */
        PreviewView.prototype.createExperience = function () {
            this.loader.show();
            this.videoNode.hide();
            if (this.src) {
                this.videoNode[0].src = "";
            }
            this.status = "Creating Experience";
            //if there is a caption in the input
            if (this.validate()) {
                //build a data object to pass into Imposium
                var inventory = this.getInventory();
                if (this.socialData) {
                    var data = {
                        'task': 'solo-experience',
                        'story_id': PreviewView.storyId,
                        'inventory': inventory,
                    };
                    data['auth'] = this.socialData['auth'];
                    data['id'] = this.socialData['id'];
                    for (var key in this.socialData['credentials']) {
                        if (this.socialData['credentials'].hasOwnProperty(key)) {
                            data[key] = this.socialData['credentials'][key];
                        }
                    }
                    for (var key in this.socialData['info']) {
                        if (this.socialData['info'].hasOwnProperty(key)) {
                            data[key] = this.socialData['info'][key];
                        }
                    }
                    Ajax.post(new AjaxRequest(data, $.proxy(this.legacyExperienceCreated, this), $.proxy(this.experienceError, this), null, '/api/1.0'));
                }
                else {
                    this.imposium.createExperience(inventory, false);
                }
            }
        };
        /**
         * Get the inventory values from the interfaces, put them in an object, and return it so we can send it to
         * Imposium.
         */
        PreviewView.prototype.getInventory = function () {
            var inv = {};
            for (var i = 0; i < this.inventoryInterfaces.length; i++) {
                var invInt = this.inventoryInterfaces[i];
                var val = invInt.checkValue();
                if (val || val === false) {
                    inv[invInt.data.id] = val;
                }
            }
            return inv;
        };
        /**
         * Once the experience is created, push the new expID to the url to trigger the event processor.
         */
        PreviewView.prototype.experienceCreated = function (expData) {
            if (expData.id) {
                this.status = "Experience Created";
                this.experienceId = expData.id;
                Utils.pushState('/preview/' + PreviewView.storyId + '/' + PreviewView.actId + '/v/' + this.experienceId);
            }
            else {
                this.status = "Error Creating Experience";
            }
        };
        /**
         * Once the experience is created, push the new expID to the url to trigger the event processor.
         */
        PreviewView.prototype.legacyExperienceCreated = function (expData) {
            if (expData.bind.success == 1) {
                this.status = "Experience Created";
                this.experienceId = expData.bind.experience_id;
                Utils.pushState('/preview/' + PreviewView.storyId + '/' + PreviewView.actId + '/v/' + this.experienceId);
            }
            else {
                this.status = "Error Creating Experience";
            }
        };
        /**
         * Start the event processor to eventually return a video.
         */
        PreviewView.prototype.loadVideo = function () {
            this.loader.show();
            this.videoNode.hide();
            this.status = 'Start Event Processor';
            //build the data object to pass into the event processor
            var data = {};
            if (PreviewView.actId && PreviewView.actId !== 'undefined') {
                data['act_id'] = PreviewView.actId;
                var sceneId = this.getActVideoSceneId(PreviewView.actId);
                if (sceneId) {
                    data['scene_id'] = sceneId;
                }
            }
            //begin the user's flow through the act
            this.imposium.renderExperience(this.experienceId, true);
            var attempts = 100;
            clearInterval(this.interval);
            this.interval = setInterval($.proxy(function () {
                // noinspection JSPotentiallyInvalidUsageOfClassThis
                if (this.imposium.consumer.stomp.client.connected) {
                    // noinspection JSPotentiallyInvalidUsageOfClassThis
                    clearInterval(this.interval);
                    // noinspection JSPotentiallyInvalidUsageOfClassThis
                    this.triggerEvent(data);
                }
                else if (attempts <= 0) {
                    // noinspection JSPotentiallyInvalidUsageOfClassThis
                    clearInterval(this.interval);
                    console.log('Failed to establish stomp subscription');
                }
                else {
                    attempts--;
                }
            }, this), 100);
        };
        /**
         * Hits the API to trigger the next render event.
         * @param data Object containing act/scene ID overrides.
         */
        PreviewView.prototype.triggerEvent = function (data) {
            var request = new JWTAjaxRequest('/experience/' + this.experienceId + '/trigger-event', data, $.proxy(function (responseData) {
                if (responseData && responseData.job_id) {
                    // noinspection JSPotentiallyInvalidUsageOfClassThis
                    this.status = 'Added job to queue...';
                    // noinspection JSPotentiallyInvalidUsageOfClassThis
                    this.jobId = responseData.job_id;
                }
            }, this), $.proxy(this.experienceError, this));
            request.apiVersion = Ajax.latestApiVersion;
            Ajax.post(request);
        };
        /**
         * Gets the only video scene in an act. Returns false if no video scenes or null if more than 1 video scene.
         * @param actId The ID of the act to look for scenes in.
         */
        PreviewView.prototype.getActVideoSceneId = function (actId) {
            var videoSceneIds = [];
            if (this.currentStoryData && this.currentStoryData.acts) {
                for (var actId_1 in this.currentStoryData.acts) {
                    for (var sceneId in this.currentStoryData.acts[actId_1].scenes) {
                        if (this.currentStoryData.acts[actId_1].scenes[sceneId].type.substr(0, 10) === 'VideoScene') {
                            videoSceneIds.push(sceneId);
                        }
                    }
                }
            }
            if (videoSceneIds.length === 1) {
                return videoSceneIds[0];
            }
            else if (videoSceneIds.length > 1) {
                return null;
            }
            return false;
        };
        /**
         * Play the newly created scene.
         */
        PreviewView.prototype.gotScene = function (data) {
            this.status = 'Got Scene';
            this.loader.hide();
            this.videoNode.show();
            if (data.output && data.output.videos) {
                this.src = PreviewView.findHighestQualitySrc(data.output.videos);
            }
            this.topPlayerBtns.css('display', 'inline-block');
            this.resize();
            var checkBoxIsCheck = this.container.find('.checkBox').is(":checked");
            if (checkBoxIsCheck) {
                this.videoNode[0].currentTime = this.dynamicCutArray[0];
            }
            this.setCopyVideoPath();
            // Output log in console if jobId was set (ie. not "Found existing content.")
            if (this.jobId && this.debug) {
                this.debug.log('Retrieving log...');
                Nickel.JobLogFinder.findLogHtml(this.jobId, $.proxy(function (html) {
                    this.debug.logHtml(html);
                }, this), 2000, true);
                this.jobId = null;
            }
        };
        /**
         * Find the format best suited for video download.
         */
        PreviewView.findHighestQualitySrc = function (outputVideos) {
            var videos = [];
            for (var formatKey in outputVideos) {
                if (outputVideos.hasOwnProperty(formatKey) && formatKey != 'm3u8') {
                    videos.push(outputVideos[formatKey]);
                }
            }
            if (videos.length >= 1) {
                videos.sort(function (a, b) { return (b.width * b.height) - (a.width * a.height); });
                return videos[0].url;
            }
            return "";
        };
        /**
         * Update the status variable to display on front end.
         */
        PreviewView.prototype.onStatusUpdate = function (event) {
            this.status = event.status;
        };
        /**
         * Set the storyData to the DOM, create the inventory interfaces, load the video if there is one.
         */
        PreviewView.prototype.setStory = function (storyData) {
            this.currentStoryData = storyData;
            this.createDynamicCutsArray();
            this.storyLabel = this.currentStoryData.name;
            Main.setHeaderStory(this.currentStoryData);
            this.configureImposiumClient(this.currentStoryData.id);
            //populate the act dropdown 
            this.actDropdown.populateActDropdown(this.currentStoryData);
            this.actLabel = "";
            this.clearInventory();
            if (PreviewView.actId) {
                var act = StoryUtils.getAct(this.currentStoryData, PreviewView.actId);
                if (act) {
                    this.actLabel = act.name;
                    this.setShownSocial(act);
                    this.addInventory();
                }
            }
            else {
                for (var key in storyData.acts) {
                    if (storyData.acts.hasOwnProperty(key)) {
                        this.actClicked(storyData.acts[key]);
                        return;
                    }
                }
            }
            if (this.experienceId) {
                this.loadVideo();
                this.getExperienceData();
            }
            else {
                this.resetInventory();
            }
        };
        /**
         * Reset the user inventory items in the interfaces.
         */
        PreviewView.prototype.resetInventory = function () {
            for (var i = 0; i < this.inventoryInterfaces.length; i++) {
                var inv = this.inventoryInterfaces[i];
                inv.clearDefault();
            }
        };
        /**
         * Remove the old inventory interfaces.
         */
        PreviewView.prototype.clearInventory = function () {
            for (var i = 0; i < this.inventoryInterfaces.length; i++) {
                var inv = this.inventoryInterfaces[i];
                inv.killMe();
            }
            this.inventoryInterfaces = [];
        };
        /**
         * Create the inventory interfaces with the inventory data from the Act.
         */
        PreviewView.prototype.addInventory = function () {
            var inv = this.currentStoryData.acts[PreviewView.actId].inventory;
            for (var key in inv) {
                if (inv.hasOwnProperty(key)) {
                    var invData = inv[key];
                    if (!invData['hidden']) {
                        var item = new Nickel.InventoryInterface(this.invHolder, invData, this);
                        this.inventoryInterfaces.push(item);
                    }
                }
            }
        };
        /**
         * Loop through the storyData and make an array of the dynamic cuts
         */
        PreviewView.prototype.createDynamicCutsArray = function () {
            var acts = this.currentStoryData.acts;
            for (var act in acts) {
                var scenes = acts[act].scenes;
                for (var scene in scenes) {
                    var cuts = scenes[scene].sceneData.cuts;
                    for (var cut in cuts) {
                        var currentCut = cuts[cut];
                        if (currentCut.overlays.length != 0) {
                            var time = currentCut.startFrame / currentCut.rate;
                            this.dynamicCutArray.push(time);
                        }
                    }
                }
            }
        };
        /**
         * Move to the previous dynamic cut
         */
        PreviewView.prototype.previousDynamicCut = function () {
            for (var i = 0; i <= this.dynamicCutArray.length; i++) {
                if (this.videoNode[0].currentTime > this.dynamicCutArray[i]) {
                    this.currentDynamicSection = i;
                }
            }
            var diff = this.videoNode[0].currentTime - this.dynamicCutArray[this.currentDynamicSection];
            if (diff < 1.5 && this.currentDynamicSection != 0) {
                this.currentDynamicSection--;
            }
            this.videoNode[0].currentTime = this.dynamicCutArray[this.currentDynamicSection];
        };
        /**
         * Move to the next dynamic cut
         */
        PreviewView.prototype.nextDynamicCut = function () {
            for (var i = 0; i < this.dynamicCutArray.length; i++) {
                if (this.videoNode[0].currentTime < this.dynamicCutArray[i]) {
                    this.videoNode[0].currentTime = this.dynamicCutArray[i];
                    this.currentDynamicSection = i;
                    break;
                }
            }
        };
        /**
         * Use ZeroClipboard to copy the path to the video
         */
        PreviewView.prototype.setCopyVideoPath = function () {
            this.btnCopyPreview.attr('data-clipboard-text', this.src);
            this.zero = new ZeroClipboard(this.btnCopyPreview[0]);
            var toolTip = this.container.find('[data-toggle="tooltip"]');
            this.btnCopyPreview.on('mousedown', function () {
                toolTip.tooltip('show');
            });
            this.zero.on("aftercopy", function () {
                toolTip.find('[data-toggle="tooltip"]').tooltip('destroy');
            });
        };
        /**
         * Position the video player inside of the screen.
         */
        PreviewView.prototype.resize = function () {
            // Position the video player
            var cWidth = this.container.find('.player').width();
            var cHeight = this.container.find('.player').height();
            var pad = 60;
            var pos = Utils.fitToContainer({
                'containerWidth': cWidth,
                'containerHeight': cHeight,
                'contentWidth': 1280,
                'contentHeight': 720,
                'scaleMode': 'proportionalInside',
                'hAlign': 'center',
                'vAlign': 'center',
                'maxWidth': cWidth - pad,
                'maxHeight': cHeight - pad,
            });
            this.playerHolder.css(pos);
            this.positionTopBtns(pos);
            // Shift up to make way for debug console
            var shiftUp = parseFloat(this.topInteractables.css('top')) - 5;
            this.topInteractables.css('top', '-=' + shiftUp + 'px');
            this.playerHolder.css('top', '-=' + shiftUp + 'px');
        };
        /**
         * Position the copy, download, next and previous btns
         */
        PreviewView.prototype.positionTopBtns = function (pos) {
            var buffer = 5;
            var buttonHeight = this.topInteractables.height();
            var nButtonHeight = pos.top - buttonHeight - buffer;
            var displayType = "block";
            if (nButtonHeight < 0) {
                nButtonHeight = 0;
            }
            if (pos.width < 165) {
                displayType = 'none';
            }
            this.topInteractables.css({ 'display': displayType, 'top': nButtonHeight, 'right': pos.left });
        };
        /**
         * Show this view, and if it's not initialized, call init.
         */
        PreviewView.prototype.showMe = function (subView) {
            _super.prototype.showMe.call(this, subView);
            if (!this.initialized) {
                this.init();
            }
        };
        PreviewView.prototype.getStoriesError = function (e) {
            console.error("Error getting the stories");
            console.log(e);
        };
        PreviewView.prototype.expDataError = function (e) {
            console.error("Error getting experience data");
            console.log(e);
        };
        PreviewView.prototype.experienceError = function (e) {
            console.error("Experience Error");
            console.log(e);
        };
        PreviewView.AUTH_FACEBOOK = 1;
        PreviewView.AUTH_TWITTER = 4;
        /**
         * The current storyId in the url
         */
        PreviewView.storyId = "";
        /**
         * The current actId in the url
         */
        PreviewView.actId = "";
        return PreviewView;
    })(Nickel.View);
    Nickel.PreviewView = PreviewView;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    /**
     * Component for displaying the inventory needed for an act, setting / uploading new inventory data, and displaying
     * data for existing experiences.
     */
    var InventoryInterface = (function (_super) {
        __extends(InventoryInterface, _super);
        /**
         * Basic component constructor.
         */
        function InventoryInterface(container, data, id) {
            _super.call(this, container, data, id);
            /**
             * The current value of a non media-based inventory item.
             */
            this.input = "";
            this.type = this.data.type.toLowerCase();
            if (!this.type) {
                this.type = "text";
            }
            this.viewLoaded(Main.templates.find('.inventoryInterface').clone());
            this.setInterface();
        }
        /**
         * Set the input and preview variables, add this view to the DOM, bind with rivets.
         * @param v     The view we want to use for this class.
         */
        InventoryInterface.prototype.viewLoaded = function (v) {
            this.content = v;
            this.rivets = rivets.bind(this.content, {
                "data": this.data,
                "controller": this
            });
            if (this.container) {
                this.container.append(this.content);
            }
            this.textInput = this.content.find('.textInput');
            this.enumInput = this.content.find('.enumInput');
            this.boolInput = this.content.find('.boolInput');
            this.mediaInput = this.content.find('.mediaInput');
            this.imagePreview = this.content.find('.imagePreview');
            this.videoPreview = this.content.find('.videoPreview');
            this.audioPreview = this.content.find('.audioPreview');
        };
        /**
         * Check this interfaces inputs value, pass it back if it's set.
         */
        InventoryInterface.prototype.checkValue = function () {
            var val = null;
            if (InventoryInterface.isMediaBasedItem(this.type)) {
                var input = this.mediaInput.find('.media')[0];
                if (input.files.length > 0) {
                    val = input;
                }
                else if (this.input) {
                    val = this.input;
                }
            }
            else {
                if (this.input != "" || this.input === false) {
                    val = this.input;
                }
            }
            return val;
        };
        /**
         * Show the correct UI for this inventory item.
         */
        InventoryInterface.prototype.setInterface = function () {
            if (InventoryInterface.isMediaBasedItem(this.type)) {
                this.mediaInput.show();
            }
            else {
                var defaultValue = this.data.defaultItem.src;
                switch (this.type) {
                    case "boolean":
                        this.input = defaultValue;
                        this.boolInput.show();
                        return;
                    case "enum":
                        this.input = defaultValue;
                        this.enumInput.show();
                        return;
                    default:
                        this.textInput.show();
                        break;
                }
                var text = "Default: " + defaultValue;
                if (Utils.isValidJSON(defaultValue)) {
                    text = 'Default: <pre>' + Utils.formatJSONString(defaultValue) + '</pre>';
                }
                this.content.find('.default .val').html(text);
            }
        };
        /**
         * Checks if this item is a media-based item vs. a text-based one.
         */
        InventoryInterface.isMediaBasedItem = function (type) {
            return (type === "image" ||
                type === "video" ||
                type === "audio");
        };
        /**
         * Set the preview for this inventory item if we're viewing an existing experience.
         */
        InventoryInterface.prototype.setInventory = function (data) {
            this.hidePreviews();
            if (!InventoryInterface.isMediaBasedItem(this.type)) {
                this.input = data.src;
            }
            else {
                this.input = data.url;
                switch (this.type) {
                    case "video":
                        this.videoPreview.attr('src', data.url);
                        this.videoPreview.show();
                        this.resizePreview(data.width, data.height);
                        break;
                    case "image":
                        this.imagePreview.attr('src', data.url);
                        this.imagePreview.show();
                        this.resizePreview(data.width, data.height);
                        break;
                    case "audio":
                        this.audioPreview.attr('src', data.url);
                        this.audioPreview.show();
                        break;
                }
            }
        };
        /**
         * Clear the preview.
         */
        InventoryInterface.prototype.clearDefault = function () {
            switch (this.type) {
                case "video":
                    this.videoPreview.attr('src', '');
                    this.videoPreview.show();
                    this.resizePreview(0, 0);
                    break;
                case "image":
                    this.imagePreview.attr('src', '');
                    this.imagePreview.show();
                    this.resizePreview(0, 0);
                    break;
                case "audio":
                    this.audioPreview.attr('src', '');
                    this.audioPreview.show();
                    break;
                case "boolean":
                case "enum":
                    this.input = this.data.defaultItem.src;
                    break;
                default:
                    this.input = "";
                    break;
            }
            this.hidePreviews();
        };
        /**
         * Resize the previews to fit in the column correctly.
         */
        InventoryInterface.prototype.resizePreview = function (w, h) {
            var contW = 410;
            var ratio = w / h;
            var css = {
                'width': contW,
                'height': contW / ratio
            };
            this.imagePreview.css(css);
            this.videoPreview.css(css);
        };
        /**
         * Hide the image, video, and audio preview.
         */
        InventoryInterface.prototype.hidePreviews = function () {
            this.imagePreview.hide();
            this.audioPreview.hide();
            this.videoPreview.hide();
        };
        return InventoryInterface;
    })(Nickel.Component);
    Nickel.InventoryInterface = InventoryInterface;
})(Nickel || (Nickel = {}));
/* Copyright © 2016 Nickel Media, Inc. */
///<reference path='../def/jquery.d.ts'/>
///<reference path='../def/modernizr.d.ts'/>
///<reference path='../def/facebook.d.ts'/>
///<reference path='../def/jquery.placeholder.d.ts'/>
///<reference path='../def/jquery-ui-1.10.3.custom.min.d.ts'/>
///<reference path='../def/history.d.ts'/>
///<reference path='../def/custom.d.ts'/>
///<reference path='../def/underscore.d.ts'/>
///<reference path='../def/zeroclipboard.d.ts'/>
///<reference path='../def/crypto-js.d.ts'/>
///<reference path='../def/imposium.d.ts'/>
///<reference path='../def/filesaver.d.ts'/>
///<reference path='../def/bootbox.d.ts'/>
///<reference path='../def/auth0.d.ts'/>
///<reference path='./scaffolding/Auth.ts'/>
///<reference path='./scaffolding/Utils.ts'/>
///<reference path='./scaffolding/View.ts'/>
///<reference path='./scaffolding/PaginatedView.ts'/>
///<reference path='./scaffolding/Component.ts'/>
///<reference path='./scaffolding/Ajax.ts'/>
///<reference path='./scaffolding/Loader.ts'/>
///<reference path='./scaffolding/EventBus.ts'/>
///<reference path='./scaffolding/Level.ts'/>
///<reference path='./scaffolding/StopWatch.ts'/>
///<reference path='./scaffolding/StoryPicker.ts'/>
///<reference path='./scaffolding/ActPicker.ts'/>
///<reference path='./scaffolding/BatchPicker.ts'/>
///<reference path='./scaffolding/DynamicPanel.ts'/>
///<reference path='./scaffolding/CloudRegionProvider.ts'/>
///<reference path='./scaffolding/AssetProvider.ts'/>
///<reference path='./stories/StoriesView.ts'/>
///<reference path='./stories/Story.ts'/>
///<reference path='./stories/ui/NodeTree.ts'/>
///<reference path='./stories/ui/RightInterface.ts'/>
///<reference path='./stories/ui/VideoPlayer.ts'/>
///<reference path='./stories/ui/StoryPublisher.ts'/>
///<reference path='./stories/ui/CutPicker.ts'/>
///<reference path='./stories/ui/InfoBox.ts'/>
///<reference path='./stories/ui/JobHandler.ts'/>
///<reference path='./stories/ui/JobLogFinder.ts'/>
///<reference path='./stories/ui/SceneRecutter.ts'/>
///<reference path='./stories/ui/Debug.ts'/>
///<reference path='./stories/ui/ListInterface.ts'/>
///<reference path='./stories/ui/S3FilePicker.ts'/>
///<reference path='./stories/act/Act.ts'/>
///<reference path='./stories/act/inventory/InventoryItem.ts'/>
///<reference path='./stories/act/scenes/Scene.ts'/>
///<reference path='./stories/act/scenes/decision/DecisionOption.ts'/>
///<reference path='./stories/act/scenes/video_scene_01/VideoScene01.ts'/>
///<reference path='./stories/act/scenes/chat_scene_01/ChatScene01.ts'/>
///<reference path='./stories/act/scenes/text_scene_01/TextScene01.ts'/>
///<reference path='./stories/act/scenes/youtube_message_scene_01/YoutubeMessageScene01.ts'/>
///<reference path='./stories/act/scenes/tweet_scene_01/TwitterLogin/TwitterLogin.ts'/>
///<reference path='./stories/act/scenes/tweet_scene_01/TweetScene01.ts'/>
///<reference path='./stories/act/scenes/youtube_scene_01/YoutubeLogin/YoutubeLogin.ts'/>
///<reference path='./stories/act/scenes/youtube_scene_01/YoutubeScene01.ts'/>
///<reference path='./stories/act/scenes/api_scene_01/ApiScene01.ts'/>
///<reference path='./stories/act/scenes/cut/Cut.ts'/>
///<reference path='./stories/act/scenes/cut/overlay/Overlay.ts'/>
///<reference path='./stories/act/scenes/cut/sources/Sources.ts'/>
///<reference path='./stories/act/scenes/cut/inputs/Inputs.ts'/>
///<reference path='./moderation/ModerationView.ts'/>
///<reference path='./moderation/ExperienceItem.ts'/>
///<reference path='./moderation/ModerationOverlay.ts'/>
///<reference path='./moderation/ModVideoPlayer.ts'/>
///<reference path='./moderation/AudioPlayer.ts'/>
///<reference path='./moderation/Inventory.ts'/>
///<reference path='./moderation/RejectionSelector.ts'/>
///<reference path='./data/DataView.ts'/>
///<reference path='./data/BatchRowItem.ts'/>
///<reference path='./data/BatchColumn.ts'/>
///<reference path='./preview/PreviewView.ts'/>
///<reference path='./preview/InventoryInterface.ts'/>
var Main = (function () {
    /**
     * Creates a new Main instance and authenticates.
     */
    function Main() {
        var hash = window.location.hash;
        // Remove hash immediately if one is set
        var requestPath = window.location.pathname + window.location.search;
        if (window.location.href.replace(window.location.protocol + "//" + window.location.host, '') != requestPath) {
            window['History']['replaceState']({ id: Utils.generateUUID() }, document.title, requestPath);
        }
        Nickel.Auth.login(hash, $.proxy(this.authenticated, this));
    }
    /**
     * Sets the templates for all views and components to use and gets the saved state from a cookie.
     * @param token
     * @param accessData
     */
    Main.prototype.authenticated = function (token, accessData) {
        JWTAjaxRequest.idToken = token;
        var tokenData = jwt_decode(token);
        Main.email = (tokenData && tokenData['https://imposium.com/email']) ? tokenData['https://imposium.com/email'] : '';
        var services = (accessData && accessData['services']) ? accessData['services'] : [];
        var hasStoriesAccess = Nickel.Auth.canAccessByName('Story Editor', services);
        var hasModerationAccess = Nickel.Auth.canAccess('/moderation', services);
        var hasDataAccess = Nickel.Auth.canAccess('/data', services);
        var hasPreviewAccess = Nickel.Auth.canAccessByName('Preview', services);
        var defaultRoute = '';
        if (hasStoriesAccess) {
            defaultRoute = '/stories';
        }
        else if (hasPreviewAccess) {
            defaultRoute = '/preview';
        }
        var serviceId = Main.determineActiveServiceId(window.location.pathname);
        if (serviceId <= 0 && defaultRoute) {
            serviceId = Main.determineActiveServiceId(defaultRoute);
        }
        var organizationData = Main.determineActiveOrganization(serviceId, accessData);
        var organizationId = organizationData[0];
        var restrictAccess = organizationData[1];
        AjaxRequest.organizationId = organizationId;
        if (accessData['organizations']) {
            for (var organizationIndex in accessData['organizations']) {
                if (accessData['organizations'].hasOwnProperty(organizationIndex) &&
                    accessData['organizations'][organizationIndex]['id'] == organizationId) {
                    Main.stories = accessData['organizations'][organizationIndex]['stories'];
                    break;
                }
            }
        }
        Main.templates = $("#templates");
        this.rivets();
        var state = JSON.parse(Utils.getCookie("editor_state"));
        if (state) {
            Main.activeNode = state.activeNode;
            if (state.interfaceType) {
                Main.interfaceType = state.interfaceType;
            }
        }
        if (organizationId && hasStoriesAccess) {
            // Cache list of assets on account
            Nickel.AssetProvider.getAssets(function () { });
        }
        // Configure views for role
        if (organizationId && hasStoriesAccess) {
            window['stories'] = new Nickel.StoriesView("storyView", "stories", "Stories");
        }
        if (organizationId && hasModerationAccess) {
            window['moderation'] = new Nickel.ModerationView("moderationView", "moderation", "Moderation");
        }
        if (organizationId && hasDataAccess) {
            window['dataTab'] = new Nickel.DataView("dataView", "data", "Data");
        }
        if (organizationId && hasPreviewAccess) {
            window['preview'] = new Nickel.PreviewView("previewView", "preview", "Preview");
        }
        if (organizationId && hasStoriesAccess && hasModerationAccess && hasDataAccess && hasPreviewAccess) {
            window['preview'].debug = new Nickel.Debug(window['preview'].container.find('.player'), {}, window['preview']);
            if (!window['preview'].debug.isHidden()) {
                window['preview'].debug.toggleConsole();
            }
        }
        var queryParams = (organizationId) ? '?organization_id=' + organizationId : '';
        var requestPath = window.location.pathname;
        if (requestPath === '' || requestPath === '/' || requestPath === '/redirect') {
            var prevRequestPath = Nickel.Auth.getPreviousRequestPath();
            if (prevRequestPath && prevRequestPath !== '/' && prevRequestPath !== '/redirect') {
                window['History']['replaceState']({ id: Utils.generateUUID() }, document.title, prevRequestPath + queryParams);
            }
            else if (defaultRoute) {
                window['History']['replaceState']({ id: Utils.generateUUID() }, document.title, defaultRoute + queryParams);
            }
            else {
                window['History']['replaceState']({ id: Utils.generateUUID() }, document.title, requestPath + queryParams);
            }
        }
        else {
            window['History']['replaceState']({ id: Utils.generateUUID() }, document.title, requestPath + queryParams);
        }
        Main.setHeader(accessData, organizationId, '');
        if (restrictAccess) {
            ReactDOM.render(React.createElement(NoAccess, { type: "restrict" }), $('#main-container').get(0));
        }
    };
    Main.determineActiveServiceId = function (requestPath) {
        var serviceId = 0;
        if (requestPath.substr(0, 8) == '/stories') {
            serviceId = 1;
        }
        else if (requestPath.substr(0, 11) == '/moderation') {
            serviceId = 4;
        }
        else if (requestPath.substr(0, 5) == '/data') {
            serviceId = 5;
        }
        else if (requestPath.substr(0, 8) == '/preview') {
            serviceId = 6;
        }
        return serviceId;
    };
    Main.determineActiveOrganizationIn = function (accessData, sharedSession, providedOrganizationId, organizationIds) {
        if (organizationIds.length > 0) {
            if (providedOrganizationId && organizationIds.indexOf(providedOrganizationId) != -1) {
                return Main.setActiveOrganization(accessData, providedOrganizationId, sharedSession);
            }
            if (sharedSession && sharedSession.organization_id && organizationIds.indexOf(sharedSession.organization_id) != -1) {
                return sharedSession.organization_id;
            }
            return Main.setActiveOrganization(accessData, organizationIds[0], sharedSession);
        }
        return "";
    };
    Main.determineActiveOrganization = function (serviceId, accessData) {
        var sharedSession = Nickel.Auth.getSharedSession();
        var providedOrganizationId = Utils.getQueryStringVariable('organization_id');
        var allOrgs = [];
        var validOrgs = [];
        if (accessData['organizations']) {
            for (var i = 0; i < accessData['organizations'].length; i++) {
                allOrgs.push(accessData['organizations'][i]['id']);
                if (accessData['organizations'][i]['services'].indexOf(serviceId) != -1) {
                    validOrgs.push(accessData['organizations'][i]['id']);
                }
            }
        }
        var activeOrgId = Main.determineActiveOrganizationIn(accessData, sharedSession, providedOrganizationId, validOrgs);
        var restrictAccess = !activeOrgId;
        if (restrictAccess) {
            activeOrgId = Main.determineActiveOrganizationIn(accessData, sharedSession, providedOrganizationId, allOrgs);
        }
        return [activeOrgId, restrictAccess];
    };
    Main.setActiveOrganization = function (accessData, organizationId, sharedSession) {
        if (!sharedSession || !sharedSession.organization_id || sharedSession.organization_id != organizationId) {
            var storyId = '';
            if (accessData['organizations']) {
                for (var organizationIndex in accessData['organizations']) {
                    if (accessData['organizations'].hasOwnProperty(organizationIndex) &&
                        accessData['organizations'][organizationIndex]['id'] == organizationId) {
                        if (accessData['organizations'][organizationIndex]['stories'] && accessData['organizations'][organizationIndex]['stories'][0] &&
                            accessData['organizations'][organizationIndex]['stories'][0].id) {
                            storyId = accessData['organizations'][organizationIndex]['stories'][0].id;
                        }
                        break;
                    }
                }
            }
            Nickel.Auth.updateSharedSession({ organization_id: organizationId, story_id: storyId });
        }
        return organizationId;
    };
    Main.setHeaderStory = function (story) {
        var storyId = (story && story['id']) ? story['id'] : '';
        var organizationId = AjaxRequest.organizationId;
        var session = Nickel.Auth.getSession();
        if (organizationId && session && session.access_data) {
            Main.setHeader(session.access_data, organizationId, storyId);
            if (storyId) {
                Nickel.Auth.updateSharedSession({ story_id: storyId });
            }
        }
    };
    Main.setDefaultHeaderStory = function () {
        var organizationId = AjaxRequest.organizationId;
        var session = Nickel.Auth.getSession();
        var sharedSession = Nickel.Auth.getSharedSession();
        if (organizationId && session && session.access_data) {
            var story;
            if (sharedSession && sharedSession.story_id && session.access_data['organizations']) {
                for (var organizationIndex in session.access_data['organizations']) {
                    if (session.access_data['organizations'].hasOwnProperty(organizationIndex) &&
                        session.access_data['organizations'][organizationIndex]['id'] == organizationId) {
                        if (session.access_data['organizations'][organizationIndex]['stories']) {
                            for (var i = 0; i < session.access_data['organizations'][organizationIndex]['stories'].length; i++) {
                                if (sharedSession.story_id === session.access_data['organizations'][organizationIndex]['stories'][i]['id']) {
                                    story = session.access_data['organizations'][organizationIndex]['stories'][i];
                                    break;
                                }
                            }
                        }
                        break;
                    }
                }
            }
            if (story) {
                Main.onStoryChange(story);
                return true;
            }
        }
        return false;
    };
    Main.setHeader = function (accessData, organizationId, storyId) {
        ReactDOM.render(React.createElement(ImposiumHeader, {
            accessData: accessData,
            activeOrganization: organizationId,
            activeStory: storyId,
            email: Main.email,
            onOrganizationChange: Main.onOrganizationChange,
            onStoryChange: Main.onStoryChange,
            logout: Nickel.Auth.logout,
        }), $('.mainNav').get(0));
    };
    Main.onOrganizationChange = function (orgId) {
        Nickel.Auth.updateSharedSession({ story_id: '' });
        if (window.location.hostname === 'localhost') {
            window.location.replace('http://localhost:3036?organization_id=' + orgId);
        }
        else if (window.location.host.indexOf('.staging.') != -1) {
            window.location.replace('https://hub.staging.imposium.com?organization_id=' + orgId);
        }
        else {
            window.location.replace('https://hub.imposium.com?organization_id=' + orgId);
        }
    };
    Main.onStoryChange = function (story) {
        var activeView;
        if (window['stories'] && window['stories']['onStage']) {
            activeView = window['stories'];
        }
        else if (window['moderation'] && window['moderation']['onStage']) {
            activeView = window['moderation'];
        }
        else if (window['dataTab'] && window['dataTab']['onStage']) {
            activeView = window['dataTab'];
        }
        else if (window['preview'] && window['preview']['onStage']) {
            activeView = window['preview'];
        }
        if (activeView) {
            activeView.storyClicked(story);
        }
    };
    /**
     * Sets our custom formatters and binders for rivets.js.
     */
    Main.prototype.rivets = function () {
        rivets.formatters.num = {
            read: function (value) {
                return value;
            },
            publish: function (value) {
                return Number(value);
            }
        };
        rivets.formatters.nullNum = {
            read: function (value) {
                return value;
            },
            publish: function (value) {
                var num = Number(value);
                if (num === 0) {
                    return null;
                }
                else {
                    return num;
                }
            }
        };
        //global rivets formatters
        rivets.formatters.date = function (value) {
            var myDate = new Date(value * 1000);
            return myDate;
        };
        rivets.formatters.bytes = function (value) {
            return Utils.formatBytes(value);
        };
        rivets.formatters.renderTime = function (value) {
            if (value) {
                return value;
            }
            else {
                return "0.00";
            }
        };
        //converts seconds to hh:mm:ss:ff
        rivets.formatters.aeTime = function (value) {
            var h = Math.floor(value / 3600); //Get whole hours
            value -= h * 3600;
            var m = Math.floor(value / 60); //Get remaining minutes
            value -= m * 60;
            var s = Math.floor(value / 1);
            value -= s;
            var f = Math.floor(Main.cutRate * value);
            var hStr = (h < 10) ? "0" + h : h;
            var mStr = (m < 10) ? "0" + m : m;
            var sStr = (s < 10) ? "0" + s : s;
            var fStr = (f < 10) ? "0" + f : f;
            var str = hStr + ":" + mStr + ":" + sStr + ":" + fStr;
            return str;
        };
        rivets.formatters.properCase = function (value) {
            return value.toLowerCase().replace(/_/g, ' ').replace(/\b([a-z\u00C0-\u00ff])/g, function (_, initial) {
                return initial.toUpperCase();
            });
        };
        rivets.formatters.length = function (value) {
            return value.length;
        };
        rivets.formatters.ceil = function (value) {
            return Math.ceil(value);
        };
        rivets.formatters.toFixed = function (value) {
            if (value) {
                return value.toFixed(2);
            }
            else {
                return 0;
            }
        };
        rivets.formatters.commaNumber = function (value) {
            if (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            else {
                return 0;
            }
        };
        rivets.formatters.dateTime = function (value) {
            if (value) {
                var multiplier = (value.toString().length > 10) ? 1 : 1000;
                return Utils.formatDate(new Date(value * multiplier), 'MM/dd/yyyy, h:mm tt');
            }
            return "";
        };
        rivets.formatters.dateTimeShort = function (value) {
            if (value) {
                var multiplier = (value.toString().length > 10) ? 1 : 1000;
                return Utils.formatDate(new Date(value * multiplier), 'MMM d h:mm tt');
            }
            return value;
        };
        rivets.formatters.dollarValue = function (value) {
            return "$" + parseFloat(value).toFixed(6);
        };
        rivets.formatters.booleanDropdown = {
            read: String,
            publish: function (value) {
                return (value === "true"); // store in data as boolean
            }
        };
        var numberFormat = function (value) {
            var result = Number(value);
            return (isNaN(result)) ? 0 : result;
        };
        rivets.formatters.number = {
            read: numberFormat,
            publish: numberFormat
        };
        rivets.formatters.in = function (value, arg) {
            var list = JSON.parse(arg);
            return list.indexOf(value) > -1;
        };
        rivets.formatters.eq = function (value, arg) {
            return value == arg;
        };
        rivets.formatters.gt = function (value, arg) {
            return value > arg;
        };
        rivets.formatters.lt = function (value, arg) {
            return value < arg;
        };
        rivets.formatters.abs = function (value) {
            return Math.abs(value);
        };
        rivets.formatters.number = function (value) {
            if (value) {
                return parseFloat(value);
            }
            else {
                return 0;
            }
        };
        rivets.formatters.tag = function (value) {
            if (value == "text") {
                return "static";
            }
            else {
                return value;
            }
        };
        rivets.binders.addclass = function (el, value) {
            if (el.addedClass) {
                $(el).removeClass(el.addedClass);
                delete el.addedClass;
            }
            if (value) {
                $(el).addClass(value);
                el.addedClass = value;
            }
        };
        rivets.binders.addposter = function (el, value) {
            var src = value.replace('.mp4', '.jpg');
            src = Main.decache(src);
            $(el).attr('poster', src);
        };
        rivets.binders.sameprotocolsrc = function (el, value) {
            if (value) {
                var src = value.replace('//', '').replace('http:', '').replace('https:', '');
                $(el).attr('src', '//' + src);
            }
        };
        rivets.binders.fpo = function (el, value) {
            if (value) {
                var src = value.replace('//', '').replace('http:', '').replace('https:', '');
                $(el).attr('src', '//' + src);
            }
            else {
                $(el).attr('src', '/img/image_fpo.jpg');
            }
        };
    };
    /**
     * Kills the cache on a url passed in by adding a custom query string to the end.
     * @param url    The url that we want to clear out of the browser cache.
     * @returns    The URL passed in with a custom query string, example: http://url.com?123473584
     */
    Main.decache = function (url) {
        var time = Math.ceil(new Date().getTime() / 1000);
        var pos = url.lastIndexOf('?');
        if (pos != -1) {
            // ? found
            return url.substr(0, pos + 1) + time;
        }
        else {
            return url + '?' + time;
        }
    };
    /**
     * Saves the current state of the CMS, including the active Story, Act, Scene, Node, and interface type.
     */
    Main.saveState = function () {
        //store the state of the editor to bring back after page refresh
        var val = JSON.stringify({
            "story": Main.storyId,
            "act": Main.actId,
            "scene": Main.sceneId,
            "activeNode": Main.activeNode,
            "interfaceType": Main.interfaceType
        });
        Utils.setCookie("editor_state", val, 2592000); // 30 days
    };
    Main.stories = [];
    Main.cutRate = 0;
    Main.interfaceType = "nodes";
    return Main;
})();
