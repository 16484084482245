class Loader {
    public view:any;

    constructor(view:any) {
        this.view = view;
        this.init();
    }

    public init() {
        this.hide();
    }

    public show() {
        this.view.removeClass('hidden');
    }

    public hide() {
        this.view.addClass('hidden');
    }
}
