module Nickel {

    export class CutVO {
        public id: string = "";
        public name: string = "New Cut";
        public label: string = "New Cut";
        public startFrame: string = "";
        public endFrame: string = "";
        public overlays: any = [];
        public description: any = "";
        public previewURL: string = "";
        public renderTime: number;
        public previousRenderTime: number;
        public staticOverlayMethod: string = "auto";
        public clipSwap: boolean = false;
        public tags: any = [];

        constructor() {
            this.id = Utils.generateUUID();
        }
    }

    export class Cut extends Level {

        //cms config
        public label: string = "Cut";
        public videoPlayer: boolean = true;

        public btnAddOverlay: any;
        public btnUpdateCut: any;
        public btnMoveMenu: any;
        public stopWatch: Stopwatch;
        public pickerVO: CutPickerVO;

        constructor(container, data, index, delegate) {

            super(container, data, index, delegate);

            if (data) {
                this.data = data;
            } else {
                this.data = new CutVO();
                this.firstSave = true;
            }

            this.viewLoaded(Main.templates.find('.cut').clone());
        }

        public viewLoaded(v) {

            super.viewLoaded(v);

            //populate the class dropdown
            for (var i = 0; i < this.data.overlays.length; i++) {
                var overlay = new Nickel.Overlay(this.content.find(".childHolder"), this.data.overlays[i], i, this);
                this.children.push(overlay);
            }

            //buttons
            this.btnAddOverlay = this.content.find(".btnAddOverlay").bind("click", $.proxy(this.addOverlayBtnClicked, this));
            this.btnUpdateCut = this.content.find(".btnUpdateCut").bind('click', $.proxy(this.updateCut, this));
            this.btnMoveMenu = this.content.find(".btnMoveMenu");
            this.content.find(".btnEditFrames").bind('click', $.proxy(this.editClicked, this));

            //tag buttons
            this.content.find('select.tagSourceType').bind('change', $.proxy(this.changeTagSourceType, this));
            this.content.find('.btnAddTag').bind('click', $.proxy(this.addTag, this));
            this.content.find('.btnRemoveTag').unbind('click').bind('click', $.proxy(this.removeTag, this));
            this.content.find('.tags .tag').unbind('click').bind('click', $.proxy(this.toggleRequiredTag, this));
            this.content.find('.tagInput').bind('keypress', $.proxy(function (e) {
                var code = e.keyCode || e.which;
                if (code == 13) {
                    this.addTag();
                    e.preventDefault();
                }
            }, this));

            //components
            this.stopWatch = new Stopwatch(this.content.find('.stopwatch'));
        }

        public checkUrl() {
            if (this.data.url) {
                var urlPieces = this.data.url.split('/');
                var name = urlPieces[urlPieces.length - 1];
                var namePieces = name.split('_');
                var frames = namePieces[namePieces.length - 1].split('.')[0].split('-');
                delete namePieces[namePieces.length - 1];
                var prefix = namePieces.join('_');
                if (prefix.substr(prefix.length - 1)) {
                    prefix = prefix.substring(0, prefix.length - 1);
                }

                if (!(this.delegate.data.sceneData.videoFile.url.indexOf(prefix) > -1) || (!(frames[0] == this.data.startFrame && frames[1] == this.data.endFrame))) {

                    // If frames don't match, or base video has been changed, erase URL so it regenerates
                    this.data.url = null;
                }
            }
        }

        public updateCut() {
            this.checkUrl();

            // Make cut after story data is saved
            EventBus.addEventListener(Story.SAVED_STORY, $.proxy(this.makeCut, this), this);

            this.saveData();
        }

        private makeCut() {
            EventBus.removeEventListener(Story.SAVED_STORY, $.proxy(this.makeCut, this), this);
            EventBus.dispatch(VideoPlayer.CLEAR_VIDEO);

            this.stopWatch.start();
            this.data.previousRenderTime = this.data.renderTime;

            JobHandler.runJob('makeCut', {
                'story_id': this.delegate.delegate.delegate.data.id,
                'act_id': this.delegate.delegate.data.id,
                'scene_id': this.delegate.data.id,
                'cut_id': this.data.id,
            }, $.proxy(function (jobId, jobName, output) {
                // noinspection JSPotentiallyInvalidUsageOfClassThis
                this.stopWatch.stop();

                if (output && output.url && output.previewURL) {
                    $.extend(true, this.data, output);

                    // noinspection JSPotentiallyInvalidUsageOfClassThis
                    this.data.renderTime = this.stopWatch.curTime;
                    // noinspection JSPotentiallyInvalidUsageOfClassThis
                    this.saveData();
                    // noinspection JSPotentiallyInvalidUsageOfClassThis
                    this.loadVideo();
                }

            }, this), $.proxy(function () {
                // noinspection JSPotentiallyInvalidUsageOfClassThis
                this.stopWatch.stop();

            }, this));
        }

        public editClicked() {

            var vo = (this.pickerVO) ? this.pickerVO : this.createPickerVO();
            this.delegate.cutBeingEdited = this;
            this.delegate.editCut(vo);
        }


        public createPickerVO() {

            var vo = new Nickel.CutPickerVO();
            vo.inFrame = this.data.startFrame;
            vo.outFrame = this.data.endFrame;
            vo.inTime = this.data.startFrame / this.delegate.data.sceneData.videoFile.rate;
            vo.outTime = this.data.endFrame / this.delegate.data.sceneData.videoFile.rate;
            return vo;
        }

        public saveData() {
            EventBus.dispatch(Story.SAVE_STORY);
        }

        public deleteChild() {

            super.deleteChild();

            var data = this.data.overlays.splice(this.childToDelete, 1)[0];
        }

        public addOverlayBtnClicked() {
            this.addOverlay();
        }

        private addOverlay(data = null) {
            var overlay = this.addChild(data, this.children.length);
            this.data.overlays.push(overlay.data);
            this.showChild(overlay.index);
            this.saveData();
        }

        public duplicateChildItem(overlay) {
            var data = $.extend(true, {}, overlay.data);
            data.id = Utils.generateUUID();
            this.addOverlay(data);
        }

        public addChild(data, index) {
            var overlay = new Nickel.Overlay(this.content.find(".childHolder"), data, index, this);
            this.children.push(overlay);
            return overlay;
        }

        public showMe() {

            super.showMe();

            this.loadVideo();
            this.populateInventorySelect();
        }

        public loadVideo() {
            EventBus.dispatch(VideoPlayer.LOAD_VIDEO, {src: this.data.previewURL});
        }

        /**
         * Specifies where the story's data can be found on this level.
         * @param storyData An object containing the story JSON structure.
         */
        public onDataReload(storyData): void {
            var cuts = storyData['acts'][this.delegate.delegate.data.id]['scenes'][this.delegate.data.id]['sceneData']['cuts'];
            for (var i = 0; i < cuts.length; i++) {
                if (cuts[i]['id'] == this.data.id) {
                    $.extend(true, this.data, cuts[i]);
                    break;
                }
            }
        }

        public populateInventorySelect(): void {
            let act:Act = this.delegate.delegate;

            let select = this.content.find('.inventoryItemIds');
            let currentValue: string = select.val();
            select.empty();

            for (let invId in act.data.inventory) {
                if (act.data.inventory.hasOwnProperty(invId)) {
                    let invItemType = act.data.inventory[invId].type.toLowerCase();
                    if (invItemType != 'image' && invItemType != 'video' && invItemType != 'audio') {
                        select.append(new Option(act.data.inventory[invId].label || act.data.inventory[invId].name, invId));
                    }
                }
            }

            if (currentValue != null) {
                select.val(currentValue);
            }
        }

        public changeTagSourceType(e): void {
            var select: JQuery = $(e.currentTarget);
            this.content.find('.tagInput').addClass('hidden');
            this.content.find('.tagInput[data-source-type=' + select.val() + ']').removeClass('hidden');
        }

        public addTag(): void {
            var tagInput: JQuery = this.content.find(".tagInput").not('.hidden');
            var type: string = this.content.find("select.tagSourceType").val();
            var value: string = tagInput.val();
            if (value != null) {
                value = value.trim();
            }
            if (value != null && value != "" && this.getTagIndex(value, type) < 0) {
                this.data.tags.push({type: type, value: value, required: false});
                this.content.find('.btnRemoveTag').last().bind('click', $.proxy(this.removeTag, this));
                this.content.find('.tags .tag').last().bind('click', $.proxy(this.toggleRequiredTag, this));
            }
            if (tagInput.get(0).tagName.toLowerCase() == 'input') {
                tagInput.val("");
            }
        }

        public removeTag(e): void {
            var tagValue: JQuery = $(e.currentTarget).parent().find('.tagValue');
            var tagIndex: number = this.getTagIndex(tagValue.text(), tagValue.attr('data-type'));
            if (tagIndex >= 0) {
                this.data.tags.splice(tagIndex, 1);
                e.stopPropagation();
            }
        }

        public toggleRequiredTag(e): void {
            var tagValue: JQuery = $(e.currentTarget).find('.tagValue');
            var tagIndex: number = this.getTagIndex(tagValue.text(), tagValue.attr('data-type'));
            if (tagIndex >= 0) {
                this.data.tags[tagIndex].required = !this.data.tags[tagIndex].required;
                e.stopPropagation();
            }
        }

        public getTagIndex(tagValue, tagType) {
            if (!this.data.tags) {
                this.data.tags = [];
            }
            for (var i = 0; i < this.data.tags.length; i++) {
                if (this.data.tags[i].value == tagValue && this.data.tags[i].type == tagType) {
                    return i;
                }
            }
            return -1;
        }
    }
}
