module Nickel {

    export class StoryPicker extends Component {

        private static stories:any = null;
        private static storiesLoading:boolean = false;
        public static STORIES_LOADED:string = "storiesloaded";
        public static STORY_CLICKED:string = "storypicked";
        public static UPDATE_STORIES:string = "updatestories";

        private btnDropdown:any;
        private storyDropdown:any;

        constructor(content, data, delegate) {

            super(content, data, delegate);

            this.viewLoaded(Main.templates.find('.storyPicker').clone());

            EventBus.addEventListener(StoryPicker.UPDATE_STORIES, $.proxy(this.getStoryData, this), this);
            EventBus.addEventListener(StoryPicker.STORIES_LOADED, $.proxy(this.populateStoryDropdown, this), this);

            this.btnDropdown = this.content.find('.btnStoryDropdown').bind('click', $.proxy(this.dropdownClicked, this));
            this.storyDropdown = this.content.find('.dropdown');

            this.content.on('click', '.dropdown .btnStory', $.proxy(this.storyButtonClick, this));

            if (!StoryPicker.stories) {
                this.getStoryData();
            } else {
                this.populateStoryDropdown(StoryPicker.stories);
            }
        }

        /**
         * Hit the backend for all story data
         */
        private getStoryData() {
            if(!StoryPicker.storiesLoading) {
                StoryPicker.storiesLoading = true;

                Ajax.get(new JWTAjaxRequest('/stories', null, $.proxy(function (data) {
                    StoryPicker.storiesLoading = false;
                    this.gotStories(data.stories);
                }, this), $.proxy(function (e) {
                    StoryPicker.storiesLoading = false;
                    console.error("Error getting the stories");
                    console.log(e);
                }, this)));
            }
        }

        /**
         * Sets the static story data and populates the dropdown
         */
        private gotStories(stories) {

            StoryPicker.stories = stories;
            EventBus.dispatch(StoryPicker.STORIES_LOADED, StoryPicker.stories);
        }

        /**
         * Toggles the visibility of the story dropdown.
         */
        private dropdownClicked():void {

            if (this.btnDropdown.hasClass('active')) {
                this.btnDropdown.removeClass('active');
                this.storyDropdown.hide();
            } else {
                this.btnDropdown.addClass('active');
                this.storyDropdown.show();
            }
        }

        /**
         * Populates the story dropdown for this view.
         */
        private populateStoryDropdown(storyData:any[]):void {
            storyData.sort(function(a, b){
                if(a.name < b.name) return -1;
                if(a.name > b.name) return 1;
                return 0;
            });

            this.clearStoryDropdown();

            for (var i = 0; i < storyData.length; i++) {

                var btn = $('<a class = "btnStory" data-id = "' + storyData[i].id + '">' + storyData[i].name + '</a>');
                this.storyDropdown.append(btn);
            }
        }

        /**
         * Removes all of the items from the dropdown
         */
        private clearStoryDropdown():void {

            this.storyDropdown.empty();
        }

        /**
         * Sets the story based off of whatever story button the user clicked in the story dropdown.
         */
        private storyButtonClick(evt:Event):void {

            this.dropdownClicked();

            var id = $(evt.target).attr('data-id');
            var result = $.grep(StoryPicker.stories, function (e:any) { return e.id == id; });
            if (result[0]) {
                this.dispatch(StoryPicker.STORY_CLICKED, result[0]);
            }

        }
    }
}
