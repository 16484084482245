class Stopwatch {

    public interval:any;
    public offset:any;
    public clock:any;
    public options:any = {
        delay: 100
    };
    public container:any;
    public time:any;
    public curTime:any;


    constructor(container) {
        this.container = container;

        this.time = this.container.find('.time');
        // initialize
        this.reset();
    }


    public start(offset = 0) {

        if (!this.interval) {
            this.clock = 0;
            this.render();
            this.offset = Date.now() - (offset * 1000);
            this.interval = setInterval($.proxy(this.update, this), this.options.delay);
        }
    }

    public stop() {
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        }
    }

    public reset() {
        this.clock = 0;
        // this.render();
    }

    public update() {
        this.clock += this.delta();
        this.render();
    }

    public render() {

        this.curTime = this.clock / 1000;
        this.curTime = this.curTime.toFixed(2);
        this.time.val(this.curTime);

    }

    public delta() {
        var now = Date.now(),
            d = now - this.offset;
        this.offset = now;
        return d;
    }
}
