module Nickel {

    /**
     * Component for displaying the inventory needed for an act, setting / uploading new inventory data, and displaying
     * data for existing experiences.
     */
    export class InventoryInterface extends Component {

        /**
         * The input used if this is a boolean inventory item.
         */
        private boolInput:JQuery;

        /**
         * The input used if this is a text inventory item.
         */
        private textInput:JQuery;

        /**
         * The input used if this is an enum inventory item.
         */
        private enumInput:JQuery;

        /**
         * The current value of a non media-based inventory item.
         */
        private input:any = "";

        /**
         * The input used if this is an audio, video, or image inventory item.
         */
        private mediaInput:JQuery;

        /**
         * Preview image tag for image inventory items.
         */
        private imagePreview:JQuery;

        /**
         * Preview video tag for video inventory items.
         */
        private videoPreview:JQuery;

        /**
         * Preview audio tag for audio inventory items.
         */
        private audioPreview:JQuery;

        /**
         * The type of inventory item this interface is representing.
         */
        private type:any;

        /**
         * Basic component constructor.
         */
        constructor(container, data, id) {

            super(container, data, id);

            this.type = this.data.type.toLowerCase();
            if (!this.type) {
                this.type = "text";
            }

            this.viewLoaded(Main.templates.find('.inventoryInterface').clone());

            this.setInterface();
        }

        /**
         * Set the input and preview variables, add this view to the DOM, bind with rivets.
         * @param v     The view we want to use for this class.
         */
        public viewLoaded(v) {

            this.content = v;
            this.rivets = rivets.bind(this.content, {
                "data": this.data,
                "controller": this
            });

            if (this.container) {
                this.container.append(this.content);
            }

            this.textInput = this.content.find('.textInput');
            this.enumInput = this.content.find('.enumInput');
            this.boolInput = this.content.find('.boolInput');
            this.mediaInput = this.content.find('.mediaInput');

            this.imagePreview = this.content.find('.imagePreview');
            this.videoPreview = this.content.find('.videoPreview');
            this.audioPreview = this.content.find('.audioPreview');
        }

        /**
         * Check this interfaces inputs value, pass it back if it's set.
         */
        public checkValue() {

            var val = null;

            if (InventoryInterface.isMediaBasedItem(this.type)) {
                var input:any = this.mediaInput.find('.media')[0];
                if (input.files.length > 0) {
                    val = input;
                }else if(this.input){
                    val = this.input;
                }
            } else {
                if (this.input != "" || this.input === false) {
                    val = this.input;
                }
            }

            return val;
        }

        /**
         * Show the correct UI for this inventory item.
         */
        private setInterface() {

            if (InventoryInterface.isMediaBasedItem(this.type)) {
                this.mediaInput.show();
            } else {
                var defaultValue = this.data.defaultItem.src;

                switch (this.type) {
                    case "boolean":
                        this.input = defaultValue;
                        this.boolInput.show();
                        return;
                    case "enum":
                        this.input = defaultValue;
                        this.enumInput.show();
                        return;
                    default:
                        this.textInput.show();
                        break;
                }

                var text = "Default: " + defaultValue;
                if (Utils.isValidJSON(defaultValue)) {
                    text = 'Default: <pre>' + Utils.formatJSONString(defaultValue) + '</pre>';
                }
                this.content.find('.default .val').html(text);
            }
        }

        /**
         * Checks if this item is a media-based item vs. a text-based one.
         */
        static isMediaBasedItem(type) {
            return (
                type === "image" ||
                type === "video" ||
                type === "audio"
            );
        }

        /**
         * Set the preview for this inventory item if we're viewing an existing experience.
         */
        private setInventory(data) {
            this.hidePreviews();
            if (!InventoryInterface.isMediaBasedItem(this.type)) {
                this.input = data.src;
            } else {
                this.input = data.url;
                switch(this.type) {
                    case "video":
                        this.videoPreview.attr('src', data.url);
                        this.videoPreview.show();
                        this.resizePreview(data.width, data.height);
                        break;
                    case "image":
                        this.imagePreview.attr('src', data.url);
                        this.imagePreview.show();
                        this.resizePreview(data.width, data.height);
                        break;
                    case "audio":
                        this.audioPreview.attr('src', data.url);
                        this.audioPreview.show();
                        break;

                }
            }
        }

        /**
         * Clear the preview.
         */
        private clearDefault() {

            switch(this.type) {
                case "video":
                    this.videoPreview.attr('src', '');
                    this.videoPreview.show();
                    this.resizePreview(0, 0);
                    break;
                case "image":
                    this.imagePreview.attr('src', '');
                    this.imagePreview.show();
                    this.resizePreview(0, 0);
                    break;
                case "audio":
                    this.audioPreview.attr('src', '');
                    this.audioPreview.show();
                    break;
                case "boolean":
                case "enum":
                    this.input = this.data.defaultItem.src;
                    break;
                default:
                    this.input = "";
                    break;
            }

            this.hidePreviews();
        }

        /**
         * Resize the previews to fit in the column correctly.
         */
        private resizePreview(w, h) {

            var contW = 410;
            var ratio = w / h;

            var css = {
                'width': contW,
                'height': contW / ratio
            };

            this.imagePreview.css(css);
            this.videoPreview.css(css);
        }

        /**
         * Hide the image, video, and audio preview.
         */
        private hidePreviews() {

            this.imagePreview.hide();
            this.audioPreview.hide();
            this.videoPreview.hide();
        }
    }
}
