module Nickel {

    export class StoryPublisher extends Component {

        public publishBtn;
        public loader;
        public status;
        public messageInput;
        public jobId;
        private polling:Boolean = false;

        constructor(container, data, delegate) {

            super(container, data, delegate);

            this.viewLoaded(Main.templates.find('.publishing-module').clone());
        }

        public updateStatus() {

            if (!this.polling) {

                this.getStatus($.proxy(function (response) {
                    // noinspection JSPotentiallyInvalidUsageOfClassThis
                    this.handleStatus(response);
                    if (response && response.publishing) {
                        // noinspection JSPotentiallyInvalidUsageOfClassThis
                        this.pollCallback();
                    }
                }, this));
            } else {
                // console.log('already polling for publishing status');
            }
        }

        public viewLoaded(v) {

            super.viewLoaded(v);

            this.publishBtn = this.content.find('.btnPublish');
            this.loader = new Loader(this.content.find('.spinner'));
            this.status = this.content.find('.status');
            this.messageInput = this.content.find('input');
            this.publishBtn.bind('click', $.proxy(this.publishStory, this));
        }

        public publishStory() {
            var story:Story = this.delegate.activeChild;

            if(story.uploadsBucket == Story.DEFAULT_UPLOADS_BUCKET){
                alert("Please create a unique uploads bucket for this story. You cannot publish a story with the default dev bucket.");
                return;
            }

            if(story.renderedBucket == Story.DEFAULT_RENDERED_BUCKET){
                alert("Please create a unique rendered bucket for this story. You cannot publish a story with the default dev bucket.");
                return;
            }

            if (story.data.acts && Object.keys(story.data.acts).length > 0) {
                for (var actId in story.data.acts) {
                    var actData = story.data.acts[actId];
                    if (actData.scenes && Object.keys(actData.scenes).length > 0) {
                        for (var sceneId in actData.scenes) {
                            var sceneData = actData.scenes[sceneId];
                            if (!Nickel[sceneData.type].validatePublishAction(sceneData)) {
                                return;
                            }
                        }
                    } else {
                        alert(actData.name + ' does not have a scene. Please add a scene or delete the act before publishing.');
                        return;
                    }
                }
            } else {
                alert('Please add at least one act before publishing.');
                return;
            }

            EventBus.dispatch(Debug.LOG, "Publish Story");

            Ajax.post(new JWTAjaxRequest('/job/publish', {
                'story_id': Main.storyId,
                'message': this.messageInput.val().trim()
            }, $.proxy(function (response) {
                if (response.error) {
                    EventBus.dispatch(Nickel.Debug.ERROR, {
                        text: 'Publish Story Error',
                        error: {status: "Failure: ", statusText: response.error}
                    });
                } else if (response.id) {
                    // noinspection JSPotentiallyInvalidUsageOfClassThis
                    this.jobId = response.id;
                    // noinspection JSPotentiallyInvalidUsageOfClassThis
                    this.getStatus($.proxy(function (version) {
                        // noinspection JSPotentiallyInvalidUsageOfClassThis
                        this.handleStatus(version);
                        // noinspection JSPotentiallyInvalidUsageOfClassThis
                        this.pollCallback();
                    }, this));
                }
            }, this)));
        }

        public pollCallback() {
            this.polling = true;
            this.getStatus($.proxy(this.checkIfPublished, this));
        }

        public checkIfPublished(response) {
            if (response) {
                if (response.version && !response.publishing) {
                    this.handleStatus(response);
                } else {
                    setTimeout($.proxy(this.pollCallback, this), 2500);
                }
            } else {
                setTimeout($.proxy(this.pollCallback, this), 2500);
            }
        }

        public getStatus(callback) {
            Ajax.get(new JWTAjaxRequest('/story/' + Main.storyId + '/version', null, callback));
        }

        public handleStatus(data) {

            if (data) {
                // Found an archive
                if (data.publishing) {

                    if (this.polling) {
                        EventBus.dispatch(Debug.LOG, "Story Is Publishing");
                    }

                    // Is currently publishing
                    this.showAsPublishing(data.version, data.message);
                } else {
                    // Is published
                    if (this.polling) {
                        EventBus.dispatch(Debug.SUCCESS, "Story Published Successfully");
                        if (this.jobId) {
                            JobHandler.outputLogInConsole(this.jobId);
                            this.jobId = null;
                        }
                    }
                    this.showAsPublished(data.version, data.message);
                }
            } else {
                // No archive found
                this.showAsWorkingCopy();
            }

            this.polling = false;
        }

        public disableInput() {
            this.publishBtn.attr('disabled', 'disabled');
            this.messageInput.attr('disabled', 'disabled');
        }

        public enableInput() {
            this.publishBtn.removeAttr('disabled');
            this.messageInput.removeAttr('disabled');
        }

        public showAsPublishing(version, msg) {
            this.messageInput.val(msg);
            this.disableInput();
            this.loader.show();
            this.status.html('Publishing v' + version + '...');
        }

        public showAsPublished(version, msg) {
            this.messageInput.val('');
            this.enableInput();
            this.loader.hide();
            this.status.html('Version ' + version + ' published.');
        }

        public showAsWorkingCopy() {
            this.enableInput();
            this.loader.hide();
            this.status.html('Using working copy.');
        }
    }
}
