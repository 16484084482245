module Nickel {

    /**
     * Page for building, modifying and storing story JSON data.
     */
    export class StoriesView extends View {

        public initialized:boolean = false;

        /**
         * An object contaning all of the Story objects, stored as key value pairs with the story ID.
         */
        public children:any = {};

        /**
         * All of the raw story data, returned from the database.
         */
        public expData:any[];

        /**
         * The active (visible) story object.
         */
        private activeChild:Nickel.Story;

        /**
         * The ID of the story we want to remove.
         */
        private childToDelete:string;

        /**
         * The interface for navigating through the story Object. Can either be a NodeTree of ListInterface
         */
        public storyInterface:any;

        /**
         * The interface for displaying nodes data.
         */
        public rightInterface:Nickel.RightInterface;

        /**
         * The debugging interface.
         */
        public debug:Nickel.Debug;

        /**
         * The publishing interface.
         */
        private publisher:Nickel.StoryPublisher;

        /**
         * A jQuery object holding a reference to the title container.
         */
        private title:any;

        /**
         * An Array holding all of the items in the story dropdown.
         */
        private storyItems:any = [];

        private storyDropdown:any;

        /**
         * Static String used in the EventBus
         */
        public static UPDATE_NODES:string = "updatenodes";

        /**
         * Stores the global vars, and adds the state change listener
         * @param container    A jQuery object containing the parent div for this view.
         * @param id            The unique ID associated with this view, used to determine if this view should be
         *     visible or not by listening to the browser state.
         * @param displayText    The text to show in the header for this view.
         */
        constructor(container, id, displayText) {

            super(container, id, displayText);

            this.bindEvents();

            this.title = this.container.find('.storyTitle');
            this.publisher = new Nickel.StoryPublisher(this.container.find('.publish'), {}, this);
            this.debug = new Nickel.Debug(this.container.find('.nodeHolder'), {}, this);
            this.debug.bindGlobalEvents();
        }

        /**
         * Binds all of the event listeners for this view
         */
        private bindEvents():void {

            $("#btnAddStory").bind("click", $.proxy(this.addStoryClicked, this));
            $("#btnImportStory").bind('click', $.proxy(this.importStory, this));
            $("#storyImportFile").bind('change', $.proxy(this.getStoryData, this));
            $(".btnInterfaceType").bind('click', $.proxy(this.interfaceTypeChange, this));
            $(".btnSaveActiveStory").bind('click', $.proxy(this.saveActiveStory, this));
            $(".btnRefreshStory").bind('click', $.proxy(this.refreshStory, this));

            EventBus.addEventListener(StoriesView.UPDATE_NODES, $.proxy(this.updateNodes, this), this);
            EventBus.addEventListener(RightInterface.RESIZE_INTERFACE, $.proxy(this.resize, this), this);
            $(window).bind('resize', $.proxy(this.resize, this));
            this.resize();
        }

        /**
         * Hits the database to get the data for all of the user's stories.
         */
        public init():void {

            this.initialized = true;

            this.setDefaultInterfaceType();

            this.storyDropdown = new Nickel.StoryPicker(this.container.find('.dropdownContainer'), null, this);
            this.storyDropdown.on(StoryPicker.STORY_CLICKED, $.proxy(this.storyClicked, this));

            this.container.find('[data-toggle="tooltip"]').tooltip();

            let request = new JWTAjaxRequest('/stories', null, $.proxy(this.gotData, this));
            request.apiVersion = Ajax.latestApiVersion;
            Ajax.get(request);
        }

        /**
         * Adds the selected class to the active interface type in the story header (node or list interface).
         */
        private setDefaultInterfaceType():void {

            $(".btnInterfaceType." + Main.interfaceType).addClass('selected');
        }

        /**
         * Adds the selected class to the active interface type in the story header (node or list interface).
         * @param e    Event Passed in from the click.
         */
        private interfaceTypeChange(e:Event):void {

            $(".btnInterfaceType.selected").removeClass('selected');
            $(e.currentTarget).addClass('selected');
            Main.interfaceType = $(e.currentTarget).attr('data-type');
            Main.saveState();
            this.updateNodes();
        }

        /**
         * Saves the currently visible story.
         */
        private saveActiveStory():void {

            if (this.activeChild) {
                this.activeChild.saveData();
            }
        }

        /**
         * Updates the node (or list) interface to display the most recent data model.
         */
        private updateNodes():void {

            if (this.storyInterface) {
                this.storyInterface.killMe();
                this.storyInterface = null;
            }

            if (Main.interfaceType == "list") {
                this.storyInterface = new Nickel.ListInterface(this.container.find('.nodeHolder'), null, this);
            } else {
                if (Main.interfaceType == "nodes") {
                    this.storyInterface = new Nickel.NodeTree(this.container.find('.nodeHolder'), null, this);
                }
            }

            if (this.activeChild) {
                var data = this.getNodeData(this.activeChild.data);
                this.storyInterface.setData(data);
            }
        }

        /**
         * Shows the view, if it hasn't been initialized, calls init
         * @param subSection        The sub view to show.
         */
        public showMe(subSection:string) {

            super.showMe(subSection);

            if (!this.initialized) {
                this.init();
            }
        }

        /**
         * Makes a new story, saves it, and shows it.
         * @param e    Event passed in from the click.
         */
        public addStoryClicked(e:Event) {

            e.preventDefault();

            var story = this.addChild(null, 0);
            story.saveData();

            this.expData.push(story.data);

            this.showChild(story.data.id, true);
        }

        /**
         * Triggers the click event on a hidden form to import a JSON file.
         */
        public importStory():void {
            $("#storyImportFile").trigger('click');
        }

        /**
         * Refreshes active story data and assets
         */
        public refreshStory(e): void {
            if (this.activeChild) {
                let icon = $(e.currentTarget).find('i');
                icon.addClass('fa-spin');
                EventBus.dispatch(Debug.LOG, "Refresh Story and Assets");
                this.activeChild.reloadMe(function () {
                    Nickel.AssetProvider.refreshAssets(function () {
                        icon.removeClass('fa-spin');
                        EventBus.dispatch(StoriesView.UPDATE_NODES);
                        EventBus.dispatch(Debug.SUCCESS, "Story and Assets Refreshed Successfully");
                    });
                });
            }
        }

        /**
         * Reads the story data from an Imported JSON fiie.
         * @param e    Event passed in from the input
         */
        public getStoryData(e:any):void {
            if (e.target.files[0]) {
                var r = new FileReader();
                r.onload = $.proxy(this.saveImportedStory, this);
                r.readAsText(e.target.files[0]);
            } else {
                console.log("Failed to load file!");
            }
        }

        /**
         * Reads the story data from an Imported JSON fiie.
         * @param e    Event passed in from the input
         */
        public saveImportedStory(e:any):void {
            // Need to replace and re-bind the change event to allow multiple imports!
            $("#storyImportFile").replaceWith('<input id = "storyImportFile" type="file" style="display:none;">')
                .bind('change', $.proxy(this.getStoryData, this));

            // Once we've reset for the next import, save the actual story data
            var story = this.addChild(JSON.parse(e.target.result), 0);
            if (story.data.id !== this.activeChild.data.id) {
                story.firstSave = true;
            }
            story.saveData();
            this.showChild(story.data.id);
        }

        /**
         * Creates a new Story object, and adds it to the children array
         * @param data        The data passed in from the database for this story, if null, the story will create a new
         *     StoryVO.
         * @param index        The index of this story in the children array.
         * @returns        Returns the new Story object.
         */
        public addChild(data:any, index:number):Nickel.Story {

            var story = new Nickel.Story(this.container.find("#storyHolder"), data, index, this);
            this.children[story.data.id] = story;

            return story;
        }

        /**
         * Creates the right interface, and adds all of the stories with the data from the DB. Either shows the first
         * child, or the child saved in the cookie.
         * @param d        The data passed back from the ajax request.
         */
        public gotData(d:any):void {

            this.expData = d.stories;
            this.rightInterface = new Nickel.RightInterface(this.container, {"label": "", type: ""}, this);

            const sharedSession = Nickel.Auth.getSharedSession();

            var index;
            var found = false;
            for (var i = 0; i < this.expData.length; i++) {
                var data = this.expData[i];
                if (sharedSession && sharedSession.story_id && sharedSession.story_id == data.id) {
                    index = i;
                    found = true;
                }
            }
            if (!found) {
                index = 0;
            }

            this.addChild($.extend({}, this.expData[index]), index);

            if (sharedSession && sharedSession.story_id && this.children[sharedSession.story_id]) {
                this.showChild(sharedSession.story_id, false);
            } else {
                for (var first in this.children) break;
                this.showChild(first, false);
            }
        }

        /**
         * Loops through a story object and extracts the nessassary data for display on node or list interface.
         * @param data    The raw story JSON data.
         * @returns    Returns an object, containing multiple levels of child object, each with a label, ID, children,
         *     and type.
         */
        public getNodeData(data:any):any {

            //story
            var root:any = {};
            root.name = data.label || data.name;
            root.id = data.id;
            root.children = [];
            root.type = "story";

            //act
            for (var aid in data.acts) {
                var actData = data.acts[aid];
                if (actData) {

                    var act:any = {};
                    act.id = actData.id;
                    act.name = actData.label || actData.name;
                    act.children = [];
                    act.type = "act";

                    //scene
                    for (var sid in actData.scenes) {
                        var sceneData = actData.scenes[sid];
                        if (sceneData) {
                            var scene:any = {};
                            scene.name = sceneData.label || sceneData.name || 'Scene';
                            scene.id = sceneData.id;
                            scene.children = [];
                            scene.type = "scene";

                            //cut

                            if (sceneData.sceneData) {
                                if (sceneData.sceneData.cuts) {
                                    for (var c = 0; c < sceneData.sceneData.cuts.length; c++) {
                                        var cutData = sceneData.sceneData.cuts[c]
                                        if (cutData && cutData.name != "Generated") {
                                            var cut:any = {};
                                            cut.name = cutData.label || cutData.name;
                                            cut.id = cutData.id;
                                            cut.type = "cut";
                                            cut.children = [];

                                            //overlay
                                            for (var o = 0; o < cutData.overlays.length; o++) {

                                                var overlayData = cutData.overlays[o];
                                                if (overlayData) {
                                                    var overlay:any = {};
                                                    overlay.name = Utils.capitalize(overlayData.type);
                                                    overlay.id = overlayData.id;
                                                    overlay.type = "overlay";
                                                    overlay.enabled = overlayData.enabled;
                                                    cut.children.push(overlay);
                                                }
                                            }
                                            scene.children.push(cut);
                                        }
                                    }
                                }
                            }
                            act.children.push(scene);
                        }
                    }

                    //inventory
                    for (var id in actData.inventory) {
                        var inventoryData = actData.inventory[id];
                        var inventory:any = {};
                        inventory.id = inventoryData.id;
                        inventory.name = inventoryData.label || inventoryData.name;
                        inventory.type = "inventory";

                        act.children.push(inventory);
                    }

                    root.children.push(act);
                }
            }
            return root;
        }

        /**
         * Called when the active item in the story dropdown changes. Shows the new story.
         */
        public storyClicked(storyData):void {
            if ((this.activeChild && this.activeChild.data.id == storyData.id) || !this.expData) {
                return; // Skip if already displaying the specified story or data not loaded
            }

            var id = storyData.id;

            if (!this.children[id]) {
                for (var i = 0; i < this.expData.length; i++) {
                    var data = this.expData[i];
                    if (data.id == id) {
                        this.addChild($.extend({}, this.expData[i]), i);
                    }
                }
            }

            this.showChild(id, true);
        }

        /**
         * Removed a Story object, and calls the killMe function on it, which removes it from the database.
         */
        public deleteChild():void {

            //remove the TS controller for the act
            var child = this.children[this.childToDelete];

            //remove the data
            for (var i = 0; i < this.expData.length; i++) {
                var d = this.expData[i];
                if (d.id == this.childToDelete) {
                    Utils.removeFromArray(this.expData, i);
                    break;
                }
            }

            child.deleteMe();
            this.activeChild = null;
            child = null;

            this.updateNodes();
            this.rightInterface.clear();
        }

        /**
         * Shows a specific story, based off of the ID passed in. Updates the node interface to display this new story.
         * Saves the state of the editor.
         * @param id        The unique ID of the story you want to show.
         * @param force        Whether or not to set the active node as this story.
         */
        public showChild(id:string, force:boolean = false):void {
            if (this.activeChild) {
                var oldId = this.activeChild.data.id;
                this.activeChild.killMe();
                this.children[oldId] = null;
            }

            this.activeChild = this.children[id];

            if (this.activeChild) {

                if (force) {
                    Main.activeNode = this.activeChild.data.id;
                }

                Main.storyId = this.activeChild.data.id;
                Main.storyTitle = this.activeChild.data.name;
                Main.s3ResourcesBucket = this.activeChild.resourcesBucket;

                this.updateNodes();
                Main.saveState();

                this.publisher.updateStatus();
                this.title.html("Story: " + this.activeChild.data.name);
                Main.setHeaderStory(this.activeChild.data);
            }
        }

        /**
         * Resizes the story view to fill the entire screen.
         */
        public resize() {

            $("#storyView").css('height', window.innerHeight - 52);
            this.container.find('.nodeHolder').css('width', window.innerWidth - RightInterface.WIDTH);
            this.container.find('.storyHeader').css('width', window.innerWidth - RightInterface.WIDTH);

            if (this.storyInterface) {
                this.storyInterface.resize();
            }
        }
    }
}
