module Nickel {

    /**
     * Parent class for the 4 kinds of inventory item used on the moderation overlay.
     */
    export class Inventory extends Component {

        public delegate:ModerationOverlay;

        /**
         * Stores the global vars.
         * @param container        A jQuery object containing the parent div for this component.
         * @param data          The JSON data unique to this component.
         * @param delegate        The Class that created this instance.
         */
        constructor(container, data, delegate) {

            super(container, data, delegate);
        }

        /**
         * Binds the click event listener for this item.
         */
        public bindEvents() {
            this.content.bind('click', $.proxy(this.viewContent, this));
        }

        /**
         * Unbind the click event listener for this item.
         */
        public unbindEvents() {
            this.content.unbind('click', $.proxy(this.viewContent, this));
        }

        /**
         * Make this item active and make the previously active item inactive.
         */
        public viewContent() {

            this.container.find('.active').removeClass('active');
            this.content.addClass('active');
        }

        /**
         * Remove event listeners, remove this.content and set it to null.
         */
        public killMe() {

            this.unbindEvents();

            this.content.remove();
            this.content = null;
        }
    }

    export class VideoInventoryItem extends Inventory {

        constructor(container, data, delegate) {

            super(container, data, delegate);

            this.viewLoaded(Main.templates.find('.videoInventoryItem').clone());
            this.bindEvents();
        }

        public viewContent() {

            super.viewContent();

            var url = (this.data.mp4Url) ? this.data.mp4Url : this.data.url;
            var d = {'src': url};
            this.delegate.playVideo(d, this);
        }
    }

    export class TextInventoryItem extends Inventory {

        constructor(container, data, delegate) {

            super(container, data, delegate);

            this.viewLoaded(Main.templates.find('.textInventoryItem').clone());
            this.bindEvents();
        }

        public viewContent() {

            super.viewContent();

            this.delegate.showText(this.data.src + "", this);
        }
    }

    export class ImageInventoryItem extends Inventory {

        constructor(container, data, delegate) {

            super(container, data, delegate);

            this.viewLoaded(Main.templates.find('.imageInventoryItem').clone());
            this.bindEvents();
        }

        public viewContent() {

            super.viewContent();

            this.delegate.showImage(this.data.url, this);
        }
    }

    export class AudioInventoryItem extends Inventory {

        constructor(container, data, delegate) {

            super(container, data, delegate);

            this.viewLoaded(Main.templates.find('.audioInventoryItem').clone());
            this.bindEvents();
        }

        public viewContent() {

            super.viewContent();
            this.delegate.playAudio(this.data.url, this);
        }
    }
}
