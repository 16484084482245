module Nickel {

    export class SceneRecutter extends Component {

        private ladda:any;
        private polling:Boolean = false;

        constructor(container, data, delegate) {

            super(container, data, delegate);

            this.content = Main.templates.find('.btnRecutScene').clone();
            this.content.insertBefore(container.find('.btnGenerate').first());
            this.content.bind('click', $.proxy(this.recut, this));
            this.ladda = Ladda.create(this.content[0]);
        }

        public recut() {
            var scene = this.delegate;
            var act:Act = scene.delegate;
            var story:Story = act.delegate;

            if (!(scene.data.sceneData.cuts && scene.data.sceneData.cuts.length > 0)) {
                alert('Please add at least one cut to re-render');
                return;
            }

            this.ladda.start();

            JobHandler.runJob('recutScene', {
                'story_id': story.data.id,
                'act_id': act.data.id,
                'scene_id': scene.data.id
            }, $.proxy(function () {
                this.ladda.stop();
                this.delegate.reloadMe();
            }, this), $.proxy(function () {
                this.ladda.stop();
            }, this));
        }
    }
}
