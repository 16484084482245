module Nickel {

    export class ChatScene01VO extends SceneVO {

        public type: any = "ChatScene01";
        public sceneData: any = {
            conversation: "",
            delay: null,
            phoneNumber: ""
        };
    }

    export class ChatScene01 extends Scene {

        public conversationSelect: any;
        public label: string = "ChatBot Scene";

        constructor(container, data, index, delegate) {

            super(container, data, index, delegate);

            if (data) {
                this.data = data;
            } else {
                this.data = new ChatScene01VO();
            }

            this.viewLoaded(Main.templates.find('.chatscene01').clone());
        }

        public viewLoaded(v) {

            super.viewLoaded(v);

            //get the conversations from the chatbot
            $.getJSON('http://bot.jnickel.com/programo/get-bots.php', $.proxy(this.populateStories, this));
        }

        public populateStories(bots) {
            for (var i = 0; i < bots.length; i++) {
                this.content.find('.conversationSelect').append("<option value = '" + bots[i] + "'>" + bots[i] + "</option>");
            }

            this.conversationSelect = this.content.find(".conversationSelect").bind('change', $.proxy(this.conversationChanged, this));
            this.conversationSelect.val(this.data.sceneData.conversation);
        }

        public conversationChanged(e) {
            this.data.sceneData.conversation = $(e.target).val();
        }

        public killMe() {

            super.killMe();

            this.conversationSelect.unbind('change');
        }
    }
}
