module Nickel {

    /**
     * View for testing out a story full flow, using the same imposium.js lib that a front end site would be using.
     */
    export class PreviewView extends View {

        static AUTH_FACEBOOK = 1;
        static AUTH_TWITTER = 4;

        /**
         * Whether or not this class has been initialized.
         */
        public initialized:boolean = false;

        /**
         * Rivets instance for data binding.
         */
        private rivets:any;

        /**
         * Story name bound to the DOM through rivets.
         */
        public storyLabel:string = "";
        public actLabel:string = "";

        /**
         * The status updates from imposium.js
         */
        private status = "";

        /**
         * The video download src, set once a video is done rendering.
         */
        private src = "";

        /**
         * The social authentication data on the experience if any.
         */
        private socialData:any = null;

        /**
         * If the selected act is meant to have Facebook authentication.
         */
        private actUsesFacebook:boolean = false;

        /**
         * If the selected act is meant to have Twitter authentication.
         */
        private actUsesTwitter:boolean = false;

        /**
         * Dropdown for selecting which story we want to view the stats for.
         */
        private storyDropdown:Nickel.StoryPicker;

        /**
         * Dropdown for selecting which act we want to view the stats for.
         */
        private actDropdown:Nickel.ActPicker;

        /**
         * The current jobId, set by the trigger-event response.
         */
        private jobId:string;

        /**
         * The current storyId in the url
         */
        public static storyId:string = "";

        /**
         * The current actId in the url
         */
        public static actId:string = "";

        /**
         * The current experience ID in the url
         */
        private experienceId:any;

        /**
         * The story JSON for the story currently set in the URL
         */
        public currentStoryData:any;

        /**
         * The interfaces for displaying / uploading inventory items.
         */
        private inventoryInterfaces:any[] = [];

        /**
         * Imposium.js Client
         */
        private imposium:any;

        /**
         * Imposium.js Player
         */
        private imposiumPlayer:any;

        /**
         * Whether or not the Imposium.js client is initialized
         */
        private imposiumInitialized:boolean = false;

        /**
         * JQuery containers for the buttons, video player, and loader.
         */
        private invHolder:JQuery;
        private btnGeneratePreview:JQuery;
        private btnResetPreview:JQuery;
        private videoNode:JQuery;
        private playerHolder:JQuery;
        private topInteractables:JQuery;
        private topPlayerBtns:JQuery;
        private loader:JQuery;
        private btnCopyPreview:JQuery;
        private btnPrevPreview:JQuery;
        private btnNextPreview:JQuery;

        /**
         * Twitter login components.
         */
        private btnTwitter:JQuery;
        private twitterLogin:TwitterLogin;

        /**
         * ZeroClipboard global
         */
        private zero:ZeroClipboard;

        /**
         * Stores the dynamic cuts from the story
         */
        private dynamicCutArray:any[] = [];

        /**
         * Keeps track of the current dynamic section we are on
         */
        private currentDynamicSection:number = 0;

        /**
         * Debug console.
         */
        public debug:any;

        /**
         * Interval for checking if stomp subscription is established
         */
        private interval;

        /**
         * Basic component constructor.
         */
        constructor(container:string, id:string, displayText:string) {

            super(container, id, displayText);
        }

        /**
         * Bind all event listeners, create the date pickers, get the stories, and create the story dropdown.
         */
        public init() {

            this.storyDropdown = new Nickel.StoryPicker(this.container.find('.storyDropdownContainer'), null, this);
            this.storyDropdown.on(StoryPicker.STORY_CLICKED, $.proxy(this.storyClicked, this));

            this.actDropdown = new Nickel.ActPicker(this.container.find('.actDropdownContainer'), null, this);
            this.actDropdown.on(ActPicker.ACT_CLICKED, $.proxy(this.actClicked, this));

            this.container.find('.socialReset').bind('click', $.proxy(this.resetSocialConnections, this));

            this.container.find('.socialOpts .socialOptFacebook').bind('click', $.proxy(this.connectWithFacebook, this));

            this.btnTwitter = this.container.find('.socialOpts .socialOptTwitter').bind('click', $.proxy(this.connectWithTwitter, this));
            this.twitterLogin = new TwitterLogin(this.btnTwitter, $.proxy(this.connectedWithTwitter, this));

            this.btnGeneratePreview = this.container.find('.btnGeneratePreview').bind('click', $.proxy(this.createExperience, this));
            this.btnResetPreview = this.container.find('.btnResetPreview').bind('click', $.proxy(this.resetPreview, this));
            this.btnPrevPreview = this.container.find('.btnPrevPreview').bind('click', $.proxy(this.previousDynamicCut, this))
            this.btnNextPreview = this.container.find('.btnNextPreview').bind('click', $.proxy(this.nextDynamicCut, this))

            this.btnCopyPreview = this.container.find('.btnCopyPreview');
            this.playerHolder = this.container.find('.player .playerHolder');
            this.topPlayerBtns = this.container.find('.topBtns');
            this.topInteractables = this.container.find('.topInteractables');
            this.videoNode = this.playerHolder.find('video');
            this.loader = this.container.find('.loaderHolder');

            this.invHolder = this.container.find('.inventory');

            this.rivets = rivets.bind(this.container, {
                "controller": this
            });

            $(window).bind('resize', $.proxy(this.resize, this));
            this.resize();

            this.initialized = true;
        }

        /**
         * Configure the Imposium client for a given story.
         * @param storyId
         * @returns Imposium.Client
         */
        private configureImposiumClient(storyId) {
            if (!this.imposiumInitialized) {
                let apiUrl: string = AjaxUrlProvider.getRestApiBaseUrl();
                let clientConfig = {'accessToken': 'Bearer ' + JWTAjaxRequest.idToken, 'storyId': storyId};
                if (apiUrl.split('/').pop() === AjaxUrlProvider.localApiHost) {
                    clientConfig['environment'] = 'local';
                } else if (apiUrl.indexOf('.staging.') != -1) {
                    clientConfig['environment'] = 'staging';
                }
                this.imposium = new Imposium.Client(clientConfig);
                this.imposiumPlayer = new Imposium.Player(this.videoNode[0], this.imposium);
                this.imposium.on(Imposium.Events.STATUS_UPDATE, $.proxy(this.onStatusUpdate, this));
                this.imposium.on(Imposium.Events.EXPERIENCE_CREATED, $.proxy(this.experienceCreated, this));
                this.imposium.on(Imposium.Events.GOT_EXPERIENCE, $.proxy(this.gotScene, this));
                this.imposiumInitialized = true;
            } else {
                this.imposium.setup({'storyId': storyId});
            }
            return this.imposium;
        }

        /**
         * Remove any social auth data.
         * @param e The click event.
         */
        private resetSocialConnections(e = null) {
            this.socialData = null;
        }

        /**
         * Bring up Facebook login page.
         * @param e The click event.
         */
        private connectWithFacebook(e) {
            FB.login($.proxy(function (auth) {
                if (auth.authResponse && auth.authResponse.accessToken && auth.authResponse.userID) {
                    FB.api('/me', 'get', {}, $.proxy(function (response) {
                        this.connectedWithFacebook($.extend(auth.authResponse, response));
                    }, this));
                }
            }, this), {'scope': 'user_photos, user_location'});
        }

        /**
         * Called on successful Facebook login.
         * @param data Login response data from Facebook.
         */
        private connectedWithFacebook(data) {
            this.socialData = {
                'message': 'Connected to Facebook as ' + data['name'] + '.',
                'auth': PreviewView.AUTH_FACEBOOK,
                'id': data['id'],
                'credentials': {
                    'access_token': data['accessToken'],
                },
                'info': {
                    'name': data['name'],
                    'first_name': data['first_name'],
                    'last_name': data['last_name'],
                    'gender': data['gender'],
                }
            };
        }

        /**
         * Bring up Twitter login page.
         * @param e The click event.
         */
        private connectWithTwitter(e) {
            e.preventDefault();
            this.twitterLogin.login();
        }

        /**
         * Called on successful Twitter login.
         * @param data Login response data from server.
         */
        private connectedWithTwitter(data) {
            this.socialData = {
                'message': 'Connected to Twitter as @' + data['username'] + '.',
                'auth': PreviewView.AUTH_TWITTER,
                'id': data['uid'],
                'credentials': {
                    'access_token': data['access_token'],
                    'access_token_secret': data['access_token_secret'],
                },
                'info': {
                    'name': data['name'],
                    'username': data['username'],
                }
            };
        }

        /**
         * Clear which social auths are shown.
         */
        private clearShownSocial() {
            this.actUsesFacebook = false;
            this.actUsesTwitter = false;
        }

        /**
         * Set which social auths are shown based on the selected act.
         */
        private setShownSocial(act:ActVO) {
            this.actUsesFacebook = act.usesFacebook;
            this.actUsesTwitter = act.usesTwitter;
        }

        /**
         * Clear out the experienceID, forcing the preview and inventory items to reset.
         */
        private resetPreview() {

            Utils.pushState('/preview/' + PreviewView.storyId + "/" + PreviewView.actId);
        }

        /**
         * Force a new story ID into the url.
         */
        public storyClicked(d) {

            Utils.pushState('/preview/' + d.id);
        }

        /**
         * Force a new act ID into the url.
         */
        private actClicked(d) {

            Utils.pushState('/preview/' + PreviewView.storyId + '/' + d.id);
        }

        /**
         * Decide which story, act, and experience we have to load and display.
         */
        public stateChanged() {

            super.stateChanged();

            if (this.initialized) {
                var urlStrings = History['getState']().url.split("?")[0].split("/");

                var storyId = urlStrings[4];
                var actId = urlStrings [5];
                var expId = urlStrings [7];

                if (expId) {

                    //play an existing video
                    this.experienceId = expId;
                } else {
                    this.experienceId = null;
                    this.src = "";
                    this.videoNode.hide();
                    this.resetInventory();
                }

                if (storyId) {

                    if (storyId != PreviewView.storyId) {
                        PreviewView.storyId = storyId;
                        PreviewView.actId = actId;
                        this.getStoryData();
                    } else {
                        if (actId != PreviewView.actId) {
                            PreviewView.actId = actId;
                            this.setStory(this.currentStoryData);
                        } else {
                            if (this.experienceId) {
                                this.loadVideo();
                                this.getExperienceData();
                            }
                        }
                    }
                } else {
                    if (!Main.setDefaultHeaderStory()) {
                        this.clearStory();
                    }
                }
            }
        }

        /**
         * Get a single experiences data, so we can display the inventory.
         */
        private getExperienceData() {
            Ajax.get(new JWTAjaxRequest('/experience/' + this.experienceId, null, $.proxy(this.gotExperienceData, this), $.proxy(this.expDataError, this)));
        }

        /**
         * Set the inventory items for this specific experience.
         */
        private gotExperienceData(d) {

            let inventory = d.experience.inventory_items;

            for (let i = 0; i < this.inventoryInterfaces.length; i++) {

                var inter = this.inventoryInterfaces[i];
                var inv: any = _.find(inventory, function (item) {
                    return item["id"] == inter.data.id
                });

                if (inv) {
                    inter.setInventory(inv);
                }
            }
        }

        /**
         * Clear the UI elements from a previous story.
         */
        private clearStory() {

            this.storyLabel = "";
            this.actLabel = "";
            this.actDropdown.clearActDropdown();
            this.clearInventory();
            this.clearShownSocial();

            PreviewView.storyId = "";
            PreviewView.actId = "";
        }

        /**
         * Get the story data for a single story, so we can determine the acts, and the inventory required for each act.
         */
        public getStoryData() {

            if (PreviewView.storyId) {
                Ajax.get(new JWTAjaxRequest('/story/' + PreviewView.storyId, null, $.proxy(this.gotStory, this), $.proxy(this.getStoriesError, this)));
            }
        }


        /**
         * Set the story data to the DOM.
         */
        public gotStory(d) {
            if (d) {
                this.setStory(d);
            } else {
                this.getStoriesError(null);
            }
        }

        /**
         * Leaving this here so if we have to add validation we know where it should go.
         */
        private validate() {

            return true;
        }

        /**
         * Create a solo experience, with the inventory set through the inventory interfaces.
         */
        private createExperience() {

            this.loader.show();
            this.videoNode.hide();
            if (this.src) {
                (<HTMLVideoElement>this.videoNode[0]).src = ""
            }

            this.status = "Creating Experience";

            //if there is a caption in the input
            if (this.validate()) {

                //build a data object to pass into Imposium
                let inventory = this.getInventory();

                if (this.socialData) {
                    let data = {
                        'task': 'solo-experience',
                        'story_id': PreviewView.storyId,
                        'inventory': inventory,
                    };

                    data['auth'] = this.socialData['auth'];
                    data['id'] = this.socialData['id'];

                    for (let key in this.socialData['credentials']) {
                        if (this.socialData['credentials'].hasOwnProperty(key)) {
                            data[key] = this.socialData['credentials'][key];
                        }
                    }

                    for (let key in this.socialData['info']) {
                        if (this.socialData['info'].hasOwnProperty(key)) {
                            data[key] = this.socialData['info'][key];
                        }
                    }

                    Ajax.post(new AjaxRequest(data, $.proxy(this.legacyExperienceCreated, this), $.proxy(this.experienceError, this), null, '/api/1.0'));
                } else {
                    this.imposium.createExperience(inventory, false);
                }
            }
        }

        /**
         * Get the inventory values from the interfaces, put them in an object, and return it so we can send it to
         * Imposium.
         */
        private getInventory() {

            var inv = {};

            for (var i = 0; i < this.inventoryInterfaces.length; i++) {

                var invInt = this.inventoryInterfaces[i];
                var val = invInt.checkValue();
                if (val || val === false) {
                    inv[invInt.data.id] = val;
                }
            }

            return inv;
        }

        /**
         * Once the experience is created, push the new expID to the url to trigger the event processor.
         */
        private experienceCreated(expData) {
            if (expData.id) {

                this.status = "Experience Created";

                this.experienceId = expData.id;
                Utils.pushState('/preview/' + PreviewView.storyId + '/' + PreviewView.actId + '/v/' + this.experienceId);

            } else {

                this.status = "Error Creating Experience";
            }
        }

        /**
         * Once the experience is created, push the new expID to the url to trigger the event processor.
         */
        private legacyExperienceCreated(expData) {
            if (expData.bind.success == 1) {

                this.status = "Experience Created";

                this.experienceId = expData.bind.experience_id;
                Utils.pushState('/preview/' + PreviewView.storyId + '/' + PreviewView.actId + '/v/' + this.experienceId);

            } else {

                this.status = "Error Creating Experience";
            }
        }

        /**
         * Start the event processor to eventually return a video.
         */
        private loadVideo() {

            this.loader.show();
            this.videoNode.hide();

            this.status = 'Start Event Processor';

            //build the data object to pass into the event processor
            let data = {};

            if (PreviewView.actId && PreviewView.actId !== 'undefined') {
                data['act_id'] = PreviewView.actId;

                let sceneId = this.getActVideoSceneId(PreviewView.actId);
                if (sceneId) {
                    data['scene_id'] = sceneId;
                }
            }

            //begin the user's flow through the act
            this.imposium.renderExperience(this.experienceId, true);

            let attempts = 100;
            clearInterval(this.interval);
            this.interval = setInterval($.proxy(function () {
                // noinspection JSPotentiallyInvalidUsageOfClassThis
                if (this.imposium.consumer.stomp.client.connected) {
                    // noinspection JSPotentiallyInvalidUsageOfClassThis
                    clearInterval(this.interval);
                    // noinspection JSPotentiallyInvalidUsageOfClassThis
                    this.triggerEvent(data);
                } else if (attempts <= 0) {
                    // noinspection JSPotentiallyInvalidUsageOfClassThis
                    clearInterval(this.interval);
                    console.log('Failed to establish stomp subscription');
                } else {
                    attempts--;
                }
            }, this), 100);
        }

        /**
         * Hits the API to trigger the next render event.
         * @param data Object containing act/scene ID overrides.
         */
        private triggerEvent(data) {
            let request = new JWTAjaxRequest('/experience/' + this.experienceId + '/trigger-event', data, $.proxy(function (responseData) {
                if (responseData && responseData.job_id) {
                    // noinspection JSPotentiallyInvalidUsageOfClassThis
                    this.status = 'Added job to queue...';
                    // noinspection JSPotentiallyInvalidUsageOfClassThis
                    this.jobId = responseData.job_id;
                }
            }, this), $.proxy(this.experienceError, this));
            request.apiVersion = Ajax.latestApiVersion;
            Ajax.post(request);
        }

        /**
         * Gets the only video scene in an act. Returns false if no video scenes or null if more than 1 video scene.
         * @param actId The ID of the act to look for scenes in.
         */
        private getActVideoSceneId(actId) {
            let videoSceneIds = [];
            if (this.currentStoryData && this.currentStoryData.acts) {
                for (let actId in this.currentStoryData.acts) {
                    for (let sceneId in this.currentStoryData.acts[actId].scenes) {
                        if (this.currentStoryData.acts[actId].scenes[sceneId].type.substr(0, 10) === 'VideoScene') {
                            videoSceneIds.push(sceneId);
                        }
                    }
                }
            }
            if (videoSceneIds.length === 1) {
                return videoSceneIds[0];
            } else if (videoSceneIds.length > 1) {
                return null;
            }

            return false;
        }

        /**
         * Play the newly created scene.
         */
        private gotScene(data) {

            this.status = 'Got Scene';

            this.loader.hide();
            this.videoNode.show();
            if (data.output && data.output.videos) {
                this.src = PreviewView.findHighestQualitySrc(data.output.videos);
            }
            this.topPlayerBtns.css('display', 'inline-block');
            this.resize();

            var checkBoxIsCheck = this.container.find('.checkBox').is(":checked");
            if (checkBoxIsCheck) {
                (<HTMLVideoElement>this.videoNode[0]).currentTime = this.dynamicCutArray[0]
            }
            this.setCopyVideoPath();

            // Output log in console if jobId was set (ie. not "Found existing content.")
            if (this.jobId && this.debug) {

                this.debug.log('Retrieving log...');
                JobLogFinder.findLogHtml(this.jobId, $.proxy(function(html:string) {
                    this.debug.logHtml(html);
                }, this), 2000, true);

                this.jobId = null;
            }
        }

        /**
         * Find the format best suited for video download.
         */
        private static findHighestQualitySrc(outputVideos) {
            let videos = [];
            for (let formatKey in outputVideos) {
                if (outputVideos.hasOwnProperty(formatKey) && formatKey != 'm3u8') {
                    videos.push(outputVideos[formatKey])
                }
            }
            if (videos.length >= 1) {
                videos.sort(function (a, b) {return (b.width * b.height) - (a.width * a.height)});
                return videos[0].url;
            }
            return "";
        }

        /**
         * Update the status variable to display on front end.
         */
        private onStatusUpdate(event) {
            this.status = event.status;
        }

        /**
         * Set the storyData to the DOM, create the inventory interfaces, load the video if there is one.
         */
        public setStory(storyData) {

            this.currentStoryData = storyData;
            this.createDynamicCutsArray();
            this.storyLabel = this.currentStoryData.name;
            Main.setHeaderStory(this.currentStoryData);
            this.configureImposiumClient(this.currentStoryData.id);

            //populate the act dropdown 
            this.actDropdown.populateActDropdown(this.currentStoryData);
            this.actLabel = "";
            this.clearInventory();

            if (PreviewView.actId) {
                var act = StoryUtils.getAct(this.currentStoryData, PreviewView.actId);
                if (act) {
                    this.actLabel = act.name;
                    this.setShownSocial(act);
                    this.addInventory();
                }
            } else {
                for (var key in storyData.acts) {
                    if (storyData.acts.hasOwnProperty(key)) {
                        this.actClicked(storyData.acts[key]);
                        return;
                    }
                }
            }

            if (this.experienceId) {
                this.loadVideo();
                this.getExperienceData();
            } else {
                this.resetInventory();
            }
        }

        /**
         * Reset the user inventory items in the interfaces.
         */
        private resetInventory() {

            for (var i = 0; i < this.inventoryInterfaces.length; i++) {
                var inv = this.inventoryInterfaces[i];
                inv.clearDefault();
            }
        }

        /**
         * Remove the old inventory interfaces.
         */
        private clearInventory() {

            for (var i = 0; i < this.inventoryInterfaces.length; i++) {
                var inv = this.inventoryInterfaces[i];
                inv.killMe();
            }

            this.inventoryInterfaces = [];
        }

        /**
         * Create the inventory interfaces with the inventory data from the Act.
         */
        private addInventory() {

            var inv = this.currentStoryData.acts[PreviewView.actId].inventory;

            for (var key in inv) {
                if (inv.hasOwnProperty(key)) {
                    var invData = inv[key];
                    if (!invData['hidden']) {
                        var item = new Nickel.InventoryInterface(this.invHolder, invData, this);
                        this.inventoryInterfaces.push(item);
                    }
                }
            }
        }

        /**
         * Loop through the storyData and make an array of the dynamic cuts
         */
        private createDynamicCutsArray() {

            var acts = this.currentStoryData.acts;
            for (var act in acts) {
                var scenes = acts[act].scenes
                for (var scene in scenes) {
                    var cuts = scenes[scene].sceneData.cuts;
                    for (var cut in cuts) {
                        var currentCut = cuts[cut]
                        if (currentCut.overlays.length != 0) {
                            var time = currentCut.startFrame / currentCut.rate
                            this.dynamicCutArray.push(time)
                        }
                    }
                }
            }
        }

        /**
         * Move to the previous dynamic cut
         */
        private previousDynamicCut() {

            for (var i = 0; i <= this.dynamicCutArray.length; i++) {
                if ((<HTMLVideoElement>this.videoNode[0]).currentTime > this.dynamicCutArray[i]) {
                    this.currentDynamicSection = i;
                }
            }

            var diff = (<HTMLVideoElement>this.videoNode[0]).currentTime - this.dynamicCutArray[this.currentDynamicSection]
            if (diff < 1.5 && this.currentDynamicSection != 0) {
                this.currentDynamicSection--;
            }

            (<HTMLVideoElement>this.videoNode[0]).currentTime = this.dynamicCutArray[this.currentDynamicSection];
        }

        /**
         * Move to the next dynamic cut
         */
        private nextDynamicCut() {
            for (var i = 0; i < this.dynamicCutArray.length; i++) {
                if ((<HTMLVideoElement>this.videoNode[0]).currentTime < this.dynamicCutArray[i]) {
                    (<HTMLVideoElement>this.videoNode[0]).currentTime = this.dynamicCutArray[i];
                    this.currentDynamicSection = i;
                    break;
                }
            }
        }

        /**
         * Use ZeroClipboard to copy the path to the video
         */
        private setCopyVideoPath() {

            this.btnCopyPreview.attr('data-clipboard-text', this.src)
            this.zero = new ZeroClipboard(this.btnCopyPreview[0]);

            var toolTip = this.container.find('[data-toggle="tooltip"]')
            this.btnCopyPreview.on('mousedown', ()=> {
                toolTip.tooltip('show');
            });

            this.zero.on("aftercopy", ()=> {
                toolTip.find('[data-toggle="tooltip"]').tooltip('destroy');
            });
        }

        /**
         * Position the video player inside of the screen.
         */
        private resize() {

            // Position the video player
            var cWidth = <number>this.container.find('.player').width();
            var cHeight = <number>this.container.find('.player').height();
            var pad = 60;

            var pos = Utils.fitToContainer({
                'containerWidth': cWidth,
                'containerHeight': cHeight,
                'contentWidth': 1280,
                'contentHeight': 720,
                'scaleMode': 'proportionalInside',
                'hAlign': 'center',
                'vAlign': 'center',
                'maxWidth': cWidth - pad,
                'maxHeight': cHeight - pad,
            });

            this.playerHolder.css(pos);
            this.positionTopBtns(pos);

            // Shift up to make way for debug console
            var shiftUp:number = parseFloat(this.topInteractables.css('top')) - 5;
            this.topInteractables.css('top', '-=' + shiftUp + 'px');
            this.playerHolder.css('top', '-=' + shiftUp + 'px');
        }

        /**
         * Position the copy, download, next and previous btns
         */
        private positionTopBtns(pos) {

            var buffer = 5
            var buttonHeight = this.topInteractables.height();
            var nButtonHeight = pos.top - buttonHeight - buffer;
            var displayType = "block"

            if (nButtonHeight < 0) {
                nButtonHeight = 0;
            }
            if (pos.width < 165) {
                displayType = 'none'
            }

            this.topInteractables.css({'display': displayType, 'top': nButtonHeight, 'right': pos.left})
        }

        /**
         * Show this view, and if it's not initialized, call init.
         */
        public showMe(subView) {

            super.showMe(subView);

            if (!this.initialized) {
                this.init();
            }
        }

        private getStoriesError(e:Event):void {
            console.error("Error getting the stories");
            console.log(e);
        }

        private expDataError(e) {
            console.error("Error getting experience data");
            console.log(e);
        }

        private experienceError(e) {
            console.error("Experience Error");
            console.log(e);
        }
    }
}
