/**
 * Helper functions available to all other Classes.
 */
class Utils {

    /* --------------------------------------------------------*/
    /* --------------------- MISC HELPERS ---------------------*/
    /* --------------------------------------------------------*/

    /**
     * Resizes a rectangle to fit into another rectangle using different positioning and scale properties to crop /
     * position.
     * @data    The data object containig all of the paraeters for the calculation.
     */
    static fitToContainer(data:ResizeValues):FitValues {

        var newH, newW, top, left;
        var aspectRatio = data.contentWidth / data.contentHeight;

        //scale
        if (data.scaleMode == "proportionalInside") {

            newW = data.containerWidth;
            newH = newW / aspectRatio;

            if (newH > data.containerHeight) {
                newH = data.containerHeight;
                newW = newH * aspectRatio;
            }

        } else {
            if (data.scaleMode == "proportionalOutside") {

                newW = data.containerWidth;
                newH = newW / aspectRatio;

                if (newH < data.containerHeight) {
                    newH = data.containerHeight;
                    newW = newH * aspectRatio;
                }

            } else {
                if (data.scaleMode == "none" || !data.scaleMode) {

                    newW = data.contentWidth;
                    newH = data.contentHeight;
                }
            }
        }

        if (data.maxWidth) {
            if (newW > data.maxWidth) {
                newW = data.maxWidth;
                newH = newW / aspectRatio;
            }
        }

        if (data.maxHeight) {
            if (newH > data.maxHeight) {
                newH = data.maxHeight;
                newW = newH * aspectRatio;
            }
        }

        //fit
        left = (data.hAlign == "left") ? 0 : (data.hAlign == "right") ? -(newW - data.containerWidth) : (data.containerWidth - newW) / 2;
        top = (data.vAlign == "top") ? 0 : (data.vAlign == "bottom") ? -(newH - data.containerHeight) : (data.containerHeight - newH) / 2;

        return {
            'width': newW,
            'height': newH,
            'top': top,
            'left': left
        };
    }

    /**
     * Generates a UUID and returns it.
     */
    static generateUUID():string {
        var d = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
        });
        return uuid;
    }

    /**
     * Generates a unique id, based off of the langth passed in.
     * @numChars    How may characters we want the (psuedo) unique ID to contain.
     */
    static generateID(numChars:number):string {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < numChars; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }

    /**
     * Pushes a browser state
     * @state   The state we want to push.
     */
    static pushState(state) {
        History['pushState']({state: 1}, document.title, state + window.location.search);
    }

    /**
     * Opens a popup window centered in the screen
     */
    static openWindow(url:string, width:number, height:number) {
        var windowSize = {
            'width': width,
            'height': height,
            'left': (screen.width / 2) - (width / 2),
            'top': (screen.height / 2) - (height / 2 + 100)
        }
        var windowFeatures = "width=" + windowSize.width + ",height=" + windowSize.height + ",status,resizable,scrollbars,modal,alwaysRaised";
        windowFeatures += ",left=" + windowSize.left + ",top=" + windowSize.top + "screenX=" + windowSize.left + ",screenY=" + windowSize.top;
        return window.open(url, '' + new Date().getTime() + '', windowFeatures);
    }

    /**
     * Parse a query string variable from the current URL.
     * @param variable
     */
    static getQueryStringVariable(variable) {
        if (window.location.search) {
            const query = window.location.search.substring(1);
            const vars = query.split('&');
            for (let i = 0; i < vars.length; i++) {
                const pair = vars[i].split('=');
                if (decodeURIComponent(pair[0]) == variable) {
                    return decodeURIComponent(pair[1]);
                }
            }
        }

        return null;
    }

    /* --------------------------------------------------------*/
    /* ------------------------ STRING ------------------------*/
    /* --------------------------------------------------------*/

    /**
     * Capitalises the first letter of a string.
     * @str     The string you want to capitalise.
     */
    static capitalize(str:string):string {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    /**
     * Returns a formatted string for displaying the file size, from bytes.
     * @bytes       The filesize, in bytes.
     */
    static formatBytes(bytes:any):string {
        if (bytes >= 1000000000) {
            bytes = (bytes / 1000000000).toFixed(2) + ' GB';
        }
        else {
            if (bytes >= 1000000) {
                bytes = (bytes / 1000000).toFixed(2) + ' MB';
            }
            else {
                if (bytes >= 1000) {
                    bytes = (bytes / 1000).toFixed(2) + ' KB';
                }
                else {
                    if (bytes > 1) {
                        bytes = bytes + ' bytes';
                    }
                    else {
                        if (bytes == 1) {
                            bytes = bytes + ' byte';
                        }
                        else {
                            bytes = '0 byte';
                        }
                    }
                }
            }
        }
        return bytes;
    }

    /* --------------------------------------------------------*/
    /* ------------------------ OBJECT ------------------------*/
    /* --------------------------------------------------------*/

    /**
     * Counts the key/value pairs in an object.
     * @obj     The object you want to get a "length" from.
     */
    static objSize(obj:any):number {
        var size = 0, key;
        for (key in obj) {
            if (obj.hasOwnProperty(key)) {
                size++;
            }
        }
        return size;
    }

    /* --------------------------------------------------------*/
    /* ----------------------- NUMBERS ------------------------*/
    /* --------------------------------------------------------*/

    /**
     * Detects if a value is numberic or not.
     * @n   The value we want to check.
     */
    static isNumeric(n:any):boolean {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    /**
     * Detects if a number is odd or event
     * @num     The value we want to check.
     */
    static isOdd(num:number):boolean {
        return (num % 2) == 16
    }

    /**
     * Contain a number to a min and max.
     */
    static clamp(min:number, max:number, val:number):number {
        if (val < min) {
            return min;
        }
        if (val > max) {
            return max;
        }
        return val;
    }

    /* --------------------------------------------------------*/
    /* ------------------------- MATH -------------------------*/
    /* --------------------------------------------------------*/

    /**
     * Convert degrees to radians.
     */
    static degreesToRadians(degrees:number):number {

        return degrees * Math.PI / 180;
    }

    /**
     * Convert radians to degrees
     */
    static radiansToDegrees(radians:number):number {

        return radians * 180 / Math.PI;
    }

    /**
     * Calculates the distance between two points in 2D space.
     */
    static lineDistance(point1:Point, point2:Point):number {
        var xs = 0;
        var ys = 0;
        xs = point2.x - point1.x;
        xs = xs * xs;
        ys = point2.y - point1.y;
        ys = ys * ys;
        return Math.sqrt(xs + ys);
    }

    /**
     * Calculates the angle in degrees between two points
     */
    static calcAngle(p1:Point, p2:Point):number {

        var calcAngle = Math.atan2(p1.x - p2.x, p1.y - p2.y) * (180 / Math.PI);
        if (calcAngle < 0) {
            calcAngle = Math.abs(calcAngle);
        } else {
            calcAngle = 360 - calcAngle;
        }
        return calcAngle;
    }

    /**
     * Returns a random number between 2 numbers
     */
    static randomFromInterval(from:number, to:number) {
        return Math.floor(Math.random() * (to - from + 1) + from);
    }

    /* --------------------------------------------------------*/
    /* ------------------------- ARRAY ------------------------*/
    /* --------------------------------------------------------*/

    /**
     * Switches the position of two array elements.
     * @array       The array containing both elements.
     * @a           The index of the first element.
     * @b           The index of the second element.
     * @return      The array with the elements switched.
     */
    static swapArrayElements(array:any[], a:any, b:any):any[] {
        var temp = array[a];
        array[a] = array[b];
        array[b] = temp;
        return array;
    }

    /**
     * Removes one of more elements from an array.
     * @array       The array containing all of the elements.
     * @from        The first element we want to remove (and the last, if @to isn't set).
     * @to          The index of the last element we want to remove.
     * @return      The original array with the elements removed.
     */
    static removeFromArray(array:any[], from:number, to?:number):any[] {
        var rest = array.slice((to || from) + 1 || array.length);
        array.length = from < 0 ? array.length + from : from;
        return array.push.apply(array, rest);
    }

    /**
     * Randomly shuffles the contents of an array.
     * @array       The array containing all of the elements.
     */
    static shuffleArray(array:any[]):any[] {
        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    }

    /* --------------------------------------------------------*/
    /* -------------------------- JSON ------------------------*/
    /* --------------------------------------------------------*/

    /**
     * Checks if a string is valid json.
     * @str     The string you want to check.
     */
    static isValidJSON(str:string):boolean {
        try {
            if (str.charAt(0) == "{") {
                JSON.parse(str);
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
        return true;
    }

    /**
     * Formats a JSON sting with line breaks for displaying pretty JSON.
     * @str     The string you want to format.
     */
    static formatJSONString(str:string):string {

        var jsonObj = JSON.parse(str);
        var jsonPretty = JSON.stringify(jsonObj, null, '\t');
        return jsonPretty;
    }

    /* --------------------------------------------------------*/
    /* ----------------- BROWSER / OS DETECTION ---------------*/
    /* --------------------------------------------------------*/

    /**
     * Detects the operating system on a mobile device, returns and os and the version.
     */
    static detectMobileOS():MobileOS {

        var mobileOS;
        var mobileOSver;
        var ua = navigator.userAgent;
        var uaindex;

        // determine OS
        if (ua.match(/iPad/i) || ua.match(/iPhone/i)) {
            mobileOS = 'iOS';
            uaindex = ua.indexOf('OS ');
        } else {
            if (ua.match(/Android/i)) {
                mobileOS = 'Android';
                uaindex = ua.indexOf('Android ');
            } else {
                mobileOS = 'unknown';
            }
        }

        // determine version
        if (mobileOS === 'iOS' && uaindex > -1) {
            mobileOSver = ua.substr(uaindex + 3, 3).replace('_', '.');
        } else {
            if (mobileOS === 'Android' && uaindex > -1) {
                mobileOSver = ua.substr(uaindex + 8, 3);
            } else {
                mobileOSver = 'unknown';
            }
        }

        var num = Number(mobileOSver);
        return {"os": mobileOS, "ver": num};
    }

    /* --------------------------------------------------------*/
    /* -------------------- FEATURE DETECTION -----------------*/
    /* --------------------------------------------------------*/

    /**
     * Detects if the current browser can play the mp4 video format or now.
     */
    static canPlayMP4():boolean {

        var canPlay = false;
        var v = document.createElement('video');
        if (v.canPlayType && v.canPlayType('video/mp4').replace(/no/, '')) {
            canPlay = true;
        }
        return canPlay;
    }

    /* --------------------------------------------------------*/
    /* ----------------------- DATE / TIME --------------------*/
    /* --------------------------------------------------------*/

    static formatDate(date, format) {
        let dateStr = date.toString();
        let dateArr = dateStr.split(" ");
        let tz = dateArr[dateArr.length - 1];
        if (tz === "Time)") {
            let matches = /\(([^)]+)\)$/.exec(dateStr);
            if (matches) {
                tz = '(' + matches[1].match(/\b(\w)/g).join('') + ')';
            }
        }
        return date.toString(format).replace(' 0:', ' 12:') + " " + tz; // Fix date.js bug to show 12:00am instead of 0:00am
    }

    /* --------------------------------------------------------*/
    /* ------------------------ COOKIES -----------------------*/
    /* --------------------------------------------------------*/

    /**
     *Sets a cookie to the document object
     * @c_name      The name for the new cookie.
     * @value       The data to set on this cookie.
     * @exseconds   How many seconds before this cookie expires.
     * @domain      Optional domain to place the cookie on.
     */
    static setCookie(c_name:string, value:any, exseconds:number, domain?:string) {
        var exdate = new Date();
        exdate.setTime(exdate.getTime()+(exseconds*1000));
        var c_value = value +
            ((exseconds == null) ? "" : "; expires=" + exdate.toUTCString()) +
            ((!domain) ? "" : "; domain=" + domain) +
            ";path=/";

        document.cookie = c_name + "=" + c_value;
    }

    /**
     * Retrives a cookie from the document object using the cookies name
     * @c_name      Label of the cookie you want to retrieve.
     */
    static getCookie(c_name:string):any {
        var c_value = document.cookie;
        var c_start = c_value.indexOf(" " + c_name + "=");
        if (c_start == -1) {
            c_start = c_value.indexOf(c_name + "=");
        }
        if (c_start == -1) {
            c_value = null;
        } else {
            c_start = c_value.indexOf("=", c_start) + 1;
            var c_end = c_value.indexOf(";", c_start);
            if (c_end == -1) {
                c_end = c_value.length;
            }
            c_value = c_value.substring(c_start, c_end);
        }
        return c_value;
    }

    /* --------------------------------------------------------*/
    /* ---------------------- VALIDATION ----------------------*/
    /* --------------------------------------------------------*/

    /**
     * Validates an email address.
     */
    static realEmail(addressToTest):boolean {
        var regPattern = /^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
        return regPattern.test(addressToTest);
    }
}

interface ResizeValues {
    containerWidth:number;
    containerHeight:number;
    contentWidth:number;
    contentHeight:number;
    scaleMode:string;
    hAlign:string;
    vAlign:string;
    maxWidth?:number;
    maxHeight?:number;
}

interface FitValues {
    'top':number;
    'left':number;
    'width':number;
    'height':number;
}

interface MobileOS {
    "os":string;
    "ver":number;
}

interface Point {
    "x":number;
    "y":number;
}


class StoryUtils {

    static getFirstCutTime(story:any, sceneId:string):number {

        var time = 0;
        var scene = StoryUtils.getScene(story, sceneId);
        if (scene) {
            var cuts = scene.sceneData.cuts;
            var first = cuts[0];
            if (first) {
                time = first.startFrame / scene.sceneData.videoFile.rate;
            }
        }
        return time;
    }

    static getScene(story:any, sceneId:string, params?:string[]):any {

        var r = null;
        if (story.acts) {

            for (var key in story.acts) {

                if (story.acts.hasOwnProperty(key)) {

                    var act = story.acts[key];

                    if (act.scenes) {

                        for (var s in act.scenes) {

                            if (act.scenes.hasOwnProperty(s)) {

                                var scene = act.scenes[s];
                                if (scene.id == sceneId) {
                                    r = scene;
                                    return r;
                                }
                            }
                        }
                    }
                }
            }
        }
        return r;
    }

    static getFirstSceneOfType(story:any, type:string):string {

        var r = null;
        if (story.acts) {

            for (var key in story.acts) {

                if (story.acts.hasOwnProperty(key)) {

                    var act = story.acts[key];

                    if (act.scenes) {

                        for (var s in act.scenes) {

                            if (act.scenes.hasOwnProperty(s)) {

                                var scene = act.scenes[s];
                                if (scene.type === type) {
                                    r = scene.id;
                                    return r;
                                }
                            }
                        }
                    }
                }
            }
        }
        return r;
    }

    static getAct(story:any, actId:string, params?:string[]):any {

        var act = null;
        if (story.acts) {

            if (story.acts.hasOwnProperty(actId)) {

                act = story.acts[actId];
                return act;
            }
        }
        return act;
    }

    static getInventory(story:any, invId:string):any {

        var r = null;

        //run through all of the acts, and their scenes, and look for the label matching this ID
        if (story.acts) {

            for (var key in story.acts) {

                if (story.acts.hasOwnProperty(key)) {

                    var act = story.acts[key];

                    if (act.inventory) {

                        for (var s in act.inventory) {

                            if (act.inventory.hasOwnProperty(s)) {

                                var inv = act.inventory[s];
                                if (inv.id == invId) {
                                    r = inv;
                                    return r;
                                }
                            }
                        }
                    }
                }
            }
        }

        return r;
    }
}

class StringUtils {

    static between(p_string, p_start, p_end) {
        var str = '';
        if (p_string == null) {
            return str;
        }
        var startIdx = p_string.indexOf(p_start);
        if (startIdx != -1) {
            startIdx += p_start.length;
            var endIdx = p_string.indexOf(p_end, startIdx);
            if (endIdx != -1) {
                str = p_string.substr(startIdx, endIdx - startIdx);
            }
        }
        return str;
    }

    static contains(needle, haystack) {
        return haystack && (haystack.indexOf(needle) != -1)
    }

    static afterLast(p_string, p_char) {
        if (p_string == null) {
            return '';
        }
        ;
        var idx = p_string.lastIndexOf(p_char);
        if (idx == -1) {
            return '';
        }
        idx += p_char.length;
        return p_string.substr(idx);
    }

    static toHHMMSS(secondsInput) {
        var sec_num = parseInt(secondsInput, 10); // don't forget the second param
        var hours:any = Math.floor(sec_num / 3600);
        var minutes:any = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds:any = sec_num - (hours * 3600) - (minutes * 60);

        if (hours < 10) {
            hours = "0" + hours;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        if (seconds < 10) {
            seconds = "0" + seconds;
        }
        return hours + ':' + minutes + ':' + seconds;
    }
}
