module Nickel {

    export class CutPickerVO {

        public inFrame:number = 0;
        public outFrame:number = 0;
        public currentFrame:number = 0;
        public inTime:number = 0;
        public outTime:number = 0;
        public currentTime:number = 0.00001;
        public duration:number = 0;
    }

    export class CutPicker extends Component {

        public videoFrame:any = null;
        public videoFile:any;
        //public videoNode:any;

        public video:any = null;
        public image:any = null;
        public updateInt:any;
        public loader:any;

        public btnPlay:any;
        public btnPause:any;
        public btnIn:any;
        public btnOut:any;
        public btnCreate:any;
        public btnClose:any;
        public btnSave:any;

        public maxFrame:number;
        private timelineWidth:number;
        private playHeadWidth:number = 1;
        private playHead:any;
        private duration:any;
        private inPoint:any;
        private outPoint:any;
        private imageBased:any;
        static preparing:boolean = false;
        public editing:boolean = false;
        public open:boolean = false;
        public multipleVideos:boolean = false;

        constructor(container, data, id) {

            super(container, data, id);

            this.data = (data) ? data : new CutPickerVO();
            this.viewLoaded(Main.templates.find('.cutPicker').clone());
            $(document).on('keydown', $.proxy(this.checkKeys, this));
        }

        public useImageBasedSeek(shouldUse = true) {
            if (shouldUse) {
                this.image = this.content.find('#cut-picker-img');
                this.content.find('#cut-picker-video').addClass('inactive');
                this.content.find('#cut-picker-img').addClass('active');
                this.btnPlay.hide();
            } else {
                this.image = null;
                this.content.find('#cut-picker-video').removeClass('inactive');
                this.content.find('#cut-picker-img').removeClass('active');
                this.btnPlay.show();
            }
        }

        public loadFrameImage(index = 0) {
            this.image.attr('src', this.videoFile.frames.replace('%d', index + 1)); // +1 because frames are cut 1-based
            this.data.currentFrame = index;
        }

        public setData(data) {

            this.data = $.extend(this.data, data);
        }

        public viewLoaded(v) {

            super.viewLoaded(v);

            this.playHead = this.content.find('.playHead');
            this.playHead.draggable({
                "containment": "parent",
                "drag": $.proxy(this.onPlayheadDrag, this),
                "stop": $.proxy(this.onPlayheadStop, this)
            });

            this.inPoint = this.content.find('.in');
            this.outPoint = this.content.find('.out');

            this.content.find('.vidPicker select').bind('change', $.proxy(this.changeVideo, this));

            this.btnPlay = this.content.find('.btnPlay').bind('click', $.proxy(this.playVideo, this));
            this.btnPause = this.content.find('.btnPause').bind('click', $.proxy(this.pauseVideo, this));
            this.btnIn = this.content.find('.btnIn').bind('click', $.proxy(this.setInFrame, this));
            this.btnOut = this.content.find('.btnOut').bind('click', $.proxy(this.setOutFrame, this));
            this.btnCreate = this.content.find('.btnCreate').bind('click', $.proxy(this.createCut, this));
            this.btnClose = this.content.find('.btnClose').bind('click', $.proxy(this.killMe, this));
            this.btnSave = this.content.find('.btnSave').bind('click', $.proxy(this.saveCut, this));
            this.loader = new Loader(this.content.find('.cut-picker-loader'));
            $(window).bind('resize', $.proxy(this.resize, this));
        }

        public resize() {

            this.timelineWidth = this.content.find('.timeline').width() - this.playHeadWidth;

            var w = 800;
            var maxH = 440;
            var pad = 20;

            var r = this.videoFile.width / this.videoFile.height;
            var vWidth = w - (pad * 2);
            var vHeight = vWidth / r;

            if(vHeight > maxH){
                vHeight = maxH;
                vWidth = vHeight * r;
            }

            this.content.find('.cut-picker-element').css({
                'width': vWidth,
                'height': vHeight
            });
        }

        public onPlayheadDrag(e) {

            var perc = (e.target.offsetLeft + this.playHeadWidth) / this.timelineWidth;
            this.updateFrameBasedOnPercent(perc);
        }

        public onPlayheadStop(e) {

            var perc = e.target.offsetLeft / this.timelineWidth;
            this.updateFrameBasedOnPercent(perc);
        }

        public removeOutPoint() {

            this.data.outFrame = 0;
            this.data.outTime = 0;
            this.outPoint.hide();
        }

        public removeInPoint() {

            this.data.inFrame = 0;
            this.data.inTime = 0;
            this.inPoint.hide();
        }

        public checkPoints(justChanged) {

            if (justChanged == 'in' && this.data.inTime > this.data.outTime) {
                this.removeOutPoint();
            } else {
                if (justChanged == 'out' && this.data.outTime < this.data.inTime) {
                    this.removeInPoint();
                }
            }
        }

        public metaDataLoaded(video) {
            var dFallback = (video) ? video.duration : 0;
            this.data.duration = (this.videoFile.duration) ? this.videoFile.duration : dFallback;
            this.data.frames = (this.videoFile.totalFrames) ? this.videoFile.totalFrames : Math.ceil(this.videoFile.duration * this.videoFile.rate);
            this.maxFrame = this.data.frames - 1;
            if (video) {
                this.videoFrame = new VideoFrame({'id': this.video.id, 'frameRate': this.videoFile.rate});
            }
            this.showMe();
        }

        public createCut() {

            if (this.data.inFrame == this.data.outFrame) {
                alert("In frame and Out frame can't be the same");
            } else {
                if (this.data.inFrame > this.data.outFrame) {
                    alert("In frame can't be higher than Out frame");
                } else {
                    this.delegate.addCut(this.data, false);
                    this.delegate.updateChildTags();
                    this.killMe();
                }
            }
        }

        public saveCut() {
            this.delegate.saveCut(this.data);

            if (typeof this.delegate.updateChildTags === 'function') {
                this.delegate.updateChildTags();
            }
            this.killMe();
        }

        public resetData() {
            this.data.inTime = 0;
            this.data.outTime = 0;
            this.data.currentTime = 0;
            this.data.duration = 0;
            this.data.currentFrame = 0;
            this.data.inFrame = 0;
            this.data.outFrame = 0;
            this.updatePlayhead();
            this.updateInPoint();
            this.updateOutPoint();
        }

        public changeVideo(e) {
            var select = $(e.target);
            var videoOptions = this.delegate.data.sceneData.videoFiles;
            this.loadChosenVideo(videoOptions[select.val()]);

        }

        public loadChosenVideo(opt) {
            this.pauseVideo();
            this.loadVideo(opt);
            this.resetData();
        }

        public loadVideoOptions() {
            var optHtml = '';
            var select = this.content.find('.vidPicker select');
            this.content.find('.vidPicker').show();
            var selectedOptId = null;
            var videoOptions = this.delegate.data.sceneData.videoFiles;
            for (var id in videoOptions) {
                var opt = videoOptions[id];
                optHtml += '<option value="' + id + '">' + opt.name + '</option>';
                if (this.videoFile.name == opt.name) {
                    selectedOptId = id;
                }
            }
            select.html(optHtml);
            var sorted = select.find('option').sort(function (a:HTMLOptionElement, b:HTMLOptionElement) { return a.innerHTML.toUpperCase().localeCompare(b.innerHTML.toUpperCase()) });
            select.html(sorted);
            if (selectedOptId) {
                select.val(selectedOptId);
            } else {
                this.loadChosenVideo(videoOptions[select.val()]);
            }
        }

        public loadVideo(src, imageBased = null) {

            this.videoFile = src;
            Main.cutRate = src.rate;

            if (this.multipleVideos) {
                this.delegate.data.sceneData.videoFile = this.videoFile;
                this.loadVideoOptions();
            }

            if (imageBased) {
                this.image = this.content.find('#cut-picker-img');
            }

            this.updateVideo();
        }

        public showMe() {

            super.showMe();

            this.resize();

            this.updatePlayhead();
            this.updateInPoint();
            this.updateOutPoint();

            if (this.editing) {
                this.btnCreate.hide();
                this.btnSave.show();
            } else {
                this.btnCreate.show();
                this.btnSave.hide();
            }

            this.open = true;
        }

        public hideMe() {

            super.hideMe();

            this.pauseVideo();
            this.editing = false;

            this.open = false;
        }

        public checkKeys(e) {
            if (this.open) {
                switch (e.which) {

                    case 37: // left
                        var amt = (e.shiftKey) ? 10 : 1;
                        if (this.data.currentFrame > 0) {
                            this.seekBackward(amt);
                        }
                        break;

                    case 39: // right
                        var amt = (e.shiftKey) ? 10 : 1;
                        if (this.data.currentFrame < this.maxFrame) {
                            this.seekForward(amt);
                        }
                        break

                    case 73: // i
                        this.setInFrame();
                        break;

                    case 79: //o
                        this.setOutFrame();
                        break;

                    default:
                        return; // exit this handler for other keys
                }
                e.preventDefault();
            }
        }

        public removeKeyboardListeners() {

        }

        public setInFrame() {

            this.data.inFrame = this.data.currentFrame;
            this.data.inTime = this.data.currentTime;

            this.updateInPoint();
        }

        public updateInPoint() {
            var perc = this.data.inTime / this.data.duration;
            var l = perc * this.timelineWidth;

            this.inPoint.css('left', l);
            this.inPoint.show();
            this.checkPoints('in');
        }

        public updateOutPoint() {
            var perc = this.data.outTime / this.data.duration;
            var l = perc * this.timelineWidth;

            this.outPoint.css('left', l);
            this.outPoint.show();
            this.checkPoints('out');
        }

        public setOutFrame() {

            this.data.outFrame = this.data.currentFrame;
            this.data.outTime = this.data.currentTime;
            this.updateOutPoint();

        }

        public updateFrameBasedOnPercent(perc) {
            var frame = Math.round(this.maxFrame * perc);
            if (this.image) {
                this.loadFrameImage(frame);
            } else {
                this.videoFrame.seekTo({'frame': frame});
            }
            this.updateTime();
        }

        public updateTime() {
            if (this.image) {
                this.data.currentTime = this.data.currentFrame / this.videoFile.rate;
            } else {
                this.data.currentTime = this.video.currentTime;
                this.data.currentFrame = this.videoFrame.get();
            }
            this.updatePlayhead();
        }

        public seekForward(amt = 1) {
            if (this.image) {
                this.loadFrameImage(this.data.currentFrame + amt);
                this.updateTime();
            } else {
                this.videoFrame.seekForward(amt, $.proxy(this.updateTime, this));
            }
        }

        public seekBackward(amt = 1) {
            if (this.image) {
                this.loadFrameImage(this.data.currentFrame - amt);
                this.updateTime();
            } else {
                this.videoFrame.seekBackward(amt, $.proxy(this.updateTime, this));
            }
        }

        public updatePlayhead() {
            var perc = this.data.currentFrame / this.maxFrame;
            var x = this.timelineWidth * perc;
            this.playHead.css('left', x);
        }

        public playVideo() {

            this.btnPlay.hide();
            this.btnPause.show();
            this.video.play();

            clearInterval(this.updateInt);
            this.updateInt = setInterval($.proxy(this.updateTime, this), this.videoFile.rate);
        }

        public pauseVideo() {

            clearInterval(this.updateInt);

            this.btnPlay.show();
            this.btnPause.hide();
            this.video.pause();
        }

        public updateVideo() {
            if (this.image) {
                this.loadFrameImage();
                this.metaDataLoaded(null)
            } else {
                if (!this.video) {
                    var videoSelector = $('#cut-picker-video');
                    this.video = videoSelector[0];
                    videoSelector.bind('loadedmetadata', $.proxy(function () {
                        this.metaDataLoaded(this.video);
                    }, this));
                }

                this.video.src = this.videoFile.url;
                this.video.load();
            }
        }

        public killMe() {

            super.killMe();

            $(window).unbind('resize', $.proxy(this.resize, this));

            if (this.video) {
                this.video.src = "";
                this.video.load();
            }

            this.btnPlay.unbind('click', $.proxy(this.playVideo, this));
            this.btnPause.unbind('click', $.proxy(this.pauseVideo, this));
            this.btnIn.unbind('click', $.proxy(this.setInFrame, this));
            this.btnOut.unbind('click', $.proxy(this.setOutFrame, this));
            this.btnCreate.unbind('click', $.proxy(this.createCut, this));
            this.btnClose.unbind('click', $.proxy(this.killMe, this));

            $(document).off('keydown', $.proxy(this.checkKeys, this));

            if (this.content) {
                this.content.remove();
                this.content = null;
            }

            this.delegate.cutPicker = null;
            this.open = false;
        }
    }
}
