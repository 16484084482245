module Nickel {

    /**
     * Overlay for displaying detailed information about a specific ExperienceItem.
     * Includes UX elements and hotkeys to change buckets, view all inventory, and add / modify notes.
     */
    export class ModerationOverlay extends Component {

        /**
         * Max width of the modal popup window.
         */
        private modalMaxWidth:number = 900;

        /**
         * Max Height of the modal popup window.
         */
        private modalMaxHeight:number = 700;

        /**
         * The video plauyer for displaying video inventory items.
         */
        private videoPlayer:Nickel.ModVideoPlayer;

        /**
         * The audio player for displaying audio inventory items.
         */
        private audioPlayer:Nickel.AudioPlayer;

        /**
         * The div where we will display text inventory items.
         */
        private textViewer:JQuery;

        /**
         * The div where we will display image inventory items.
         */
        private imageViewer:JQuery;

        /**
         * The class that instantiated this compnent, specifically typed to prevent errors.
         */
        public delegate:ModerationView;

        /**
         * Whether or not this component has been initialized yet or not.
         */
        private initialized:boolean = false;

        /**
         * if we should jump to the first dynamic cut in a video inventory item.
         */
        public jumpToDynamic:boolean = true;

        /**
         * If we should autoplay audio and video inventory items.
         */
        public autoplay:boolean = true;

        /**
         * The popup modal holding all of the experience content.
         */
        private modal:JQuery;

        /**
         * The div to act as the contianer for all inventory items.
         */
        private inventoryHolder:JQuery;

        /**
         * The label for showing the story name on the modal.
         */
        private storyLabel:JQuery;

        /**
         * The div to contain all of the dynamically created buttons.
         */
        private buttonHolder:JQuery;

        /**
         * All of the inventory classes currently instantiated.
         */
        private inventory:any = [];

        /**
         * The ExperienceItem item we're currently displaying content for.
         */
        private activeItem:any;

        /**
         * The inventory item we're currently viewing.
         */
        private activeInventory:number = 0;

        /**
         * Initial values to bind rivets to for the inventory item data.
         */
        private invData:any = {
            "label": "",
            "type": "",
            "inventory_type": "",
            "width": "",
            "height": "",
            "filesize": "",
            "id": "",
            "url": "",
            "mp4Url": ""
        }

        /**
         * Stores the global vars, binds the experience item clicked event for initializing and displaying this
         * component for the first time.
         * @param container        A jQuery object containing the parent div for this component.
         * @param data          The JSON data unique to this component.
         * @param delegate        The Class that created this instance.
         */
        constructor(container:JQuery, data:any, delegate:ModerationView) {

            super(container, data, delegate);

            EventBus.addEventListener(ExperienceItem.CLICKED, $.proxy(this.showItem, this), this);
        }

        /**
         * Loads the view for this component, creates the video and audio player, and binds all events.
         */
        private init():void {

            this.initialized = true;
            this.viewLoaded(Main.templates.find('.moderationOverlay').clone());

            this.videoPlayer = new Nickel.ModVideoPlayer(this.modal.find('.player'), {}, this);
            this.audioPlayer = new Nickel.AudioPlayer(this.modal.find('.player'), {}, this);
            this.bindEvents();
            this.resize();
        }

        /**
         * Binds this.data, this.invData, and this to the view using rivets. Sets all of the JQuery object global
         * variables.
         * @param v        A jQuery object to act as this node's view and to bind it's data to.
         */
        public viewLoaded(v:JQuery):void {

            this.content = v;
            if (this.container) {
                this.container.append(this.content);
            }
            this.rivets = rivets.bind(this.content, {
                "data": this.data,
                "invData": this.invData,
                "controller": this
            })

            this.modal = this.content.find('.content');
            this.inventoryHolder = this.content.find('.inventoryItems');
            this.textViewer = this.content.find('.textViewer');
            this.imageViewer = this.content.find('.imageViewer');
            this.storyLabel = this.content.find('.storyLabel');
            this.buttonHolder = this.content.find('.buttonHolder');
        }

        /**
         * Binds all of the event listeners for this Class.
         */
        public bindEvents():void {

            $(window).bind('resize', $.proxy(this.resize, this));

            this.content.find('.btnClose').on('click', $.proxy(this.hideMe, this));
            this.content.find('.btnSaveNotes').on('click', $.proxy(this.saveNotes, this));

            $(document).on('keypress', null, 'a', $.proxy(this.bucketHotkeyPressed, this, "approved"));
            $(document).on('keypress', null, 'u', $.proxy(this.bucketHotkeyPressed, this, "used"));
            $(document).on('keypress', null, 'v', $.proxy(this.bucketHotkeyPressed, this, "vip"));
            $(document).on('keypress', null, 'p', $.proxy(this.bucketHotkeyPressed, this, "pending"));
            $(document).on('keydown', null, 'shift+up', $.proxy(this.prevInventory, this));
            $(document).on('keydown', null, 'shift+down', $.proxy(this.nextInventory, this));

            $('body').on('click', '.moderationOverlay .btnMoveToBucket', $.proxy(this.moveToBucketClicked, this));
            $('body').on('click', '.moderationOverlay .btnTriggerReject', $.proxy(this.rejectClicked, this));
            $('body').on('click', '.moderationOverlay .btnDelete', $.proxy(this.deleteClicked, this));
        }

        /**
         * Unbinds all of the event listeners for this Class.
         */
        public unbindEvents():void {

        }

        /**
         * Moves the active Item to a specific bucket.
         */
        private moveToBucketClicked(e:Event):void {

            this.delegate.activeItem.moveToBucket(e);
        }
            
        /**
         * Triggers the rejection overlay
         */
        private rejectClicked(e:Event):void{

            this.delegate.rejectionSelector.attach($(e.target).parent(), (r)=>this.gotRejectionReason(r));
            this.delegate.rejectionSelector.showMe();
        }

        /**
         * Handle the reason list being clicked
         */
        public gotRejectionReason(reason){
            this.delegate.activeItem.moveToBucket(null, 'rejected', reason);
        }

        /**
         * Deletes the active item.
         */
        private deleteClicked(e) {

            this.delegate.activeItem.deleteExperience(e);
        }

        /**
         * Moves the active Item to a specific bucket, from a hotkey press.
         */
        private bucketHotkeyPressed(bucket:string, e:Event):void {

            if (this.onStage) {
                if (this.data.buckets.indexOf(bucket) != -1) {
                    this.delegate.activeItem.moveToBucket(null, bucket);
                }
            }
        }

        /**
         * Shows the information for a specific ExperienceItem, popupates the dynamic buttons, the inventory, and shows
         * the first inventory item.
         * @item        The ExperienceItem class we want to display the details for.
         */
        private showItem(item:ExperienceItem):void {
            for (let key in this.data) {
                if (this.data.hasOwnProperty(key) && !item.data.hasOwnProperty(key)) {
                    this.data[key] = null;
                }
            }
            for (let key in item.data) {
                if (item.data.hasOwnProperty(key)) {
                    this.data[key] = item.data[key];
                }
            }
            
            this.activeItem = item;
            if (!this.onStage) {
                this.showMe();
            }

            this.clearOldData();
            this.populateButtons();
            this.populateEGC();
            this.populateUGC();

            //if there is at least one inventory item, show it
            if (this.inventory[0]) {
                this.activeInventory = 0;
                this.inventory[0].viewContent();
            } else {
                this.videoPlayer.hideMe();
                this.imageViewer.hide();
                this.audioPlayer.hideMe();
                this.textViewer.hide();
            }
        }

        /**
         * Merges the data passed in with the current invData.
         * @data        Inventory item data we want to merge in, to display.
         */
        public setInvData(data:any):void {

            for (let key in this.invData) {
                if (this.invData.hasOwnProperty(key) && !data.hasOwnProperty(key)) {
                    this.invData[key] = null;
                }
            }
            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    this.invData[key] = data[key];
                }
            }

        }

        /**
         * Plays an audio inventory item. hides the other inventory display classes.
         * @src        The url to the audio clip we want to play.
         * @item        The inventory item we're playing the clip for.
         */
        public playAudio(src:any, item:AudioInventoryItem):void {

            this.setInvData(item.data);
            this.activeInventory = this.inventory.indexOf(item);

            this.audioPlayer.autoplay = this.autoplay;
            this.audioPlayer.loadAudio(src);

            this.audioPlayer.showMe();
            this.videoPlayer.hideMe();
            this.textViewer.hide();
            this.imageViewer.hide();
        }

        /**
         * Plays a video inventory item. hides the other inventory display classes.
         * @url        The url to the video file we want to play.
         * @item        The inventory item we're playing the clip for.
         */
        public playVideo(url:any, item:VideoInventoryItem):void {

            this.setInvData(item.data);
            this.activeInventory = this.inventory.indexOf(item);
            //if it's an EGC video, use the scene id to find the first cut, and jump to that

            var time = 0;
            if (this.invData.inventory_type == "egc" && this.jumpToDynamic) {
                time = StoryUtils.getFirstCutTime(this.delegate.activeStoryData, this.invData.id) - 0.25;
            }

            this.videoPlayer.startTime = time;
            this.videoPlayer.autoplay = this.autoplay;
            this.videoPlayer.loadVideo(url);

            this.videoPlayer.showMe();
            this.textViewer.hide();
            this.imageViewer.hide();
            this.audioPlayer.hideMe();
        }

        /**
         * Displays a text inventory item. hides the other inventory display classes. Checks to see if the text is
         * JSON, and if so, displays it as JSON.
         * @text        The string we want to show.
         * @item        The inventory item we're showing the text for.
         */
        public showText(text:string, item:TextInventoryItem):void {

            this.setInvData(item.data);
            this.activeInventory = this.inventory.indexOf(item);

            if (Utils.isValidJSON(text)) {
                var str = '<pre>' + Utils.formatJSONString(text) + '</pre>';
                this.textViewer.html(str);
            } else {
                this.textViewer.html(text);
            }
            this.videoPlayer.hideMe();
            this.imageViewer.hide();
            this.audioPlayer.hideMe();
            this.textViewer.show();
        }

        /**
         * Shows an image inventory item. hides the other inventory display classes. Resizes the image to fit in the
         * container.
         * @src        The url to the image file we want to display.
         * @item        The inventory item we're loading the image for.
         */
        public showImage(src:string, item:ImageInventoryItem):void {

            this.setInvData(item.data);
            this.activeInventory = this.inventory.indexOf(item);

            var cont = this.content.find('.player');
            var img = new Image();
            img.onload = ()=> {
                this.imageViewer[0]["src"] = src;
                var posData = Utils.fitToContainer({
                    'containerWidth': <number>cont.width(),
                    'containerHeight': <number>cont.height(),
                    'contentWidth': img.naturalWidth,
                    'contentHeight': img.naturalHeight,
                    'scaleMode': 'proportionalInside',
                    'hAlign': 'center',
                    'vAlign': 'center'
                });
                this.imageViewer.css(posData);
                this.imageViewer.show();
            }

            img.src = src;

            this.videoPlayer.hideMe();
            this.textViewer.hide();
            this.audioPlayer.hideMe();
        }

        /**
         * Hides the overlay, hides all of the inventory display classes, clears the active experience.
         */
        public hideMe(e?:Event):void {

            if (e) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
            }

            super.hideMe();
            this.videoPlayer.hideMe();
            this.audioPlayer.hideMe();
            this.textViewer.hide();
            this.imageViewer.hide();

            this.delegate.clearActive();
        }

        /**
         * If there is a previous inventory item, show it.
         */
        private prevInventory():void {
            if (this.onStage) {
                if (this.inventory[this.activeInventory - 1]) {
                    this.activeInventory--;
                    this.inventory[this.activeInventory].viewContent();
                }
            }

        }

        /**
         * If there is a next inventory item, show it.
         */
        private nextInventory():void {
            if (this.onStage) {
                if (this.inventory[this.activeInventory + 1]) {
                    this.activeInventory++;
                    this.inventory[this.activeInventory].viewContent();
                }
            }
        }

        /**
         * Kills all of the existing inventory items, and dynamic bucket buttons.
         */
        private clearOldData():void {

            for (var i = 0; i < this.inventory.length; i++) {
                var item = this.inventory[i];
                item.killMe();
                item = null;
            }
            this.inventory = [];
            this.buttonHolder.empty();
        }

        /**
         * Creates an inventory item, based off of the "type" attribute in that inventory item's data.
         * @return        The inventory item just created.
         */
        private addInventoryItem(data):any {

            var item;

            switch (data.type) {

                case "video":
                    item = new Nickel.VideoInventoryItem(this.inventoryHolder, data, this);
                    break;

                case "text":
                case "enum":
                case "boolean":
                case "number":
                    item = new Nickel.TextInventoryItem(this.inventoryHolder, data, this);
                    break;

                case "image":
                    item = new Nickel.ImageInventoryItem(this.inventoryHolder, data, this);
                    break;

                case "audio":
                    item = new Nickel.AudioInventoryItem(this.inventoryHolder, data, this);
                    break;
            }

            if (item) {
                this.inventory.push(item);
                return item;
            }
        }

        /**
         * Loops through the Experience generated content inventory data, creates an Inventory Item for each one.
         */
        private populateEGC():void {
            if (!this.data.generated_inventory_items) {
                return;
            }

            var keys = Object.keys(this.data.generated_inventory_items).sort();
            for (var i = 0; i < keys.length; i++) {
                var key = keys[i];
                if (this.data.generated_inventory_items.hasOwnProperty(key)) {

                    var d = this.data.generated_inventory_items[key];
                    var scene = StoryUtils.getScene(this.delegate.activeStoryData, key);
                    if (scene) {
                        d.label = scene.label || scene.name || 'Scene';
                    }
                    d.inventory_type = "egc";

                    if (d.poster) {
                        this.addInventoryItem({
                            id: "poster",
                            type: "image",
                            inventory_type: "egc",
                            label: d.label + " poster",
                            width: d.width,
                            height: d.height,
                            url: d.poster,
                            filesize: 0
                        });
                    }

                    if (d.snapshot) {
                        this.addInventoryItem({
                            id : "snapshot",
                            type : "image",
                            inventory_type: "egc",
                            label: d.label + " snapshot",
                            width: d.width,
                            height: d.height,
                            url: d.snapshot,
                            filesize: 0
                        });
                    }

                    this.addInventoryItem(d);
                }
            }
        }

        /**
         * Loops through the User generated content inventory data, creates an Inventory Item for each one.
         */
        private populateUGC():void {
            if (!this.data.user_inventory_items) {
                return;
            }

            var keys = Object.keys(this.data.user_inventory_items).sort();
            for (var i = 0; i < keys.length; i++) {
                var key = keys[i];
                if (this.data.user_inventory_items.hasOwnProperty(key)) {

                    var d = this.data.user_inventory_items[key];
                    var inv = StoryUtils.getInventory(this.delegate.activeStoryData, key);
                    if (inv) {
                        d.label = inv.label || inv.name;
                    }
                    d.inventory_type = "ugc";

                    this.addInventoryItem(d);
                }
            }
        }

        /**
         * Positions the modal in the middle of the screen.
         */
        private resize(e?:Event):void {

            var sWidth = $(window).width();
            var sHeight = $(window).height();

            var w = Math.min(this.modalMaxWidth, sWidth);
            var h = Math.min(this.modalMaxHeight, sHeight);

            var size = Utils.fitToContainer({
                'containerWidth': sWidth,
                'containerHeight': sHeight,
                'contentWidth': w,
                'contentHeight': h,
                'scaleMode': 'none',
                'hAlign': 'center',
                'vAlign': 'center'
            });

            if (this.modal) {
                this.modal.css(size);
            }
        }

        /**
         * Pouplates the dynamic bucket buttons.
         */
        private populateButtons():void {

            for (var i = 0; i < this.data.buckets.length; i++) {

                var title = Utils.capitalize(this.data.buckets[i]);

                if(this.data.buckets[i] == "rejected"){
                    var btn = $('<div class = "rejectContainer"></div>');
                    var btnReject = $('<button type="button" data-bucket = "' + this.data.buckets[i] + '" class="btn btn-default btn-xs btnTriggerReject btnSend' + title + '"><div class = "flag"><i class = "fa fa-chevron-right"></i></div>' + title + '</button>')
                    btn.append(btnReject);
                }else{
                    var btn = $('<button type="button" data-bucket = "' + this.data.buckets[i] + '" class="btn btn-default btn-xs btnMoveToBucket btnSend' + title + '"><div class = "flag"><i class = "fa fa-chevron-right"></i></div>' + title + '</button>')
                }
                this.buttonHolder.append(btn)
            }

            //if this experience item is in the rejected bucket, add the delete button
            if (this.delegate.bucket == "rejected") {
                var del = $('<button type="button" class="btn btn-default btn-xs btnDelete"><div class = "flag"><i class = "fa fa-close"></i></div>Delete</button>');
                this.buttonHolder.append(del);
                del.bind('click', $.proxy(this.deleteClicked, this));
            }
        }

        /**
         * Saves the notes field to the database for the specific experience.
         */
        private saveNotes():void {

            Ajax.post(new AjaxRequest({
                'task': 'update-experience-notes',
                'notes': this.data.notes,
                'experience_id': this.data.id
            }, $.proxy(this.notesUpdated, this), $.proxy(this.notesUpdateError, this), null, '/api/1.1'));
        }

        /**
         * Shows the component, if it hasn't been initialized, call init.
         */
        public showMe() {

            if (!this.initialized) {
                this.init();
            }

            this.storyLabel.html(this.delegate.activeStoryData.name);

            super.showMe();
        }

        private notesUpdated() {

        }

        /**
         * Called if there's an error trying to update the notes on an experience.
         */
        private notesUpdateError(e:Event):void {
            console.error("Error updating experience notes");
            console.log(e);
        }
    }
}
