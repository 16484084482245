module Nickel {

    export class TweetScene01VO extends SceneVO {

        public type:any = "TweetScene01";
        public sceneData:any = {
            toItem: '', // Inv ID containing twitter handle to send to
            replyItem: '', // Inv ID containing the ID of an existing tweet to reply to
            videoItem: '', // Scene ID with the video to attach
            message: '',
            fromHandle: '',
            credentials: {
                oauthToken: '',
                oauthTokenSecret: ''
            }
        };
    }


    export class TweetScene01 extends Scene {

        public label:string = "Tweet Scene";
        public login:TwitterLogin;
        public btnLogin:any;

        constructor(container, data, index, delegate) {

            super(container, data, index, delegate);

            if (data) {
                this.data = data;
            } else {
                this.data = new TweetScene01VO();
            }

            this.viewLoaded(Main.templates.find('.tweetscene01').clone());
        }

        public loginClicked(e) {
            if (e) {
                e.preventDefault();
            }
            this.login.login();
        }

        public loggedIn(response) {
            this.data.sceneData.fromHandle = response.username;
            this.data.sceneData.credentials.oauthToken = response.access_token;
            this.data.sceneData.credentials.oauthTokenSecret = response.access_token_secret;
        }

        public viewLoaded(v) {

            super.viewLoaded(v);

            this.btnLogin = this.content.find('.twitterLogin');
            this.btnLogin.bind('click', $.proxy(this.loginClicked, this));

            this.login = new TwitterLogin(this.btnLogin, $.proxy(this.loggedIn, this));

            this.content.find('select.inventoryItems.to').bind('change', $.proxy(this.changeInventoryToSelect, this));
            this.content.find('select.inventoryItems.reply').bind('change', $.proxy(this.changeInventoryReplySelect, this));
            this.content.find('select.sceneItems.video').bind('change', $.proxy(this.changeSceneVideoSelect, this));

        }

        public changeInventoryToSelect(e) {
            this.data.sceneData.toItem = $(e.currentTarget).val();
        }

        public changeInventoryReplySelect(e) {
            this.data.sceneData.replyItem = $(e.currentTarget).val();
        }

        public changeSceneVideoSelect(e) {
            this.data.sceneData.videoItem = $(e.currentTarget).val();
        }

        public populateInventorySelect() {
            var optHtml = '<option value=""></option>';
            for (var invId in this.delegate.data.inventory) {
                var invItem = this.delegate.data.inventory[invId];
                if (!InventoryInterface.isMediaBasedItem(invItem['type'].toLowerCase())) {
                    optHtml += '<option value="' + invId + '">' + (invItem.label || invItem.name) + '</option>';
                }
            }
            this.content.find('select.inventoryItems').html(optHtml);
            this.content.find('select.inventoryItems.to').val(this.data.sceneData.toItem);
            this.content.find('select.inventoryItems.reply').val(this.data.sceneData.replyItem);
        }

        public populateSceneSelect() {
            var optHtml = '<option value=""></option>';
            for (var sceneId in this.delegate.data.scenes) {
                var scene = this.delegate.data.scenes[sceneId];
                if (scene.type.indexOf('Video') != -1) {
                    optHtml += '<option value="' + sceneId + '">' + sceneId + '</option>';
                }
            }
            this.content.find('select.sceneItems').html(optHtml);
            this.content.find('select.sceneItems.video').val(this.data.sceneData.videoItem);
        }

        public showMe() {

            super.showMe();
            this.populateInventorySelect();
            this.populateSceneSelect();
        }

        public killMe() {

            super.killMe();
        }
    }
}
