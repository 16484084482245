module Nickel {

    /**
     * Component for displaying a single user experience data, and providing a UI to manipulate it.
     */
    export class ExperienceItem extends Component {

        /**
         * JQuery object holding a reference for where to put the dynamically created bucket buttons
         */
        private buttonHolder:JQuery;

        /**
         * Set to true if this item is clicked, set to false once another item is clicked.
         */
        private active:boolean = false;

        /**
         * Dispatched when the user clicks on this specific experince item.
         */
        public static CLICKED:string = "expitemclicked";

        /**
         * Dispatched when the status on this item changes.
         */
        public static STATUS_CHANGED:string = "expstatuschanged";

        /**
         * Stores the global vars, loads the template, populates the dynamic buttons, and binds the event listeners.
         * @param container        A jQuery object containing the parent div for this view.
         * @param data          The JSON data unique to this component.
         * @param delegate        The Class that created this instance.
         */
        constructor(container:JQuery, data:any, delegate:ModerationView) {

            super(container, data, delegate);

            this.viewLoaded(Main.templates.find('.experienceItem').clone());
            this.buttonHolder = this.content.find('.buttonHolder');
            this.populateButtons();
            this.bindEvents();
        }

        /**
         * Binds all of the event listeners for this view.
         */
        public bindEvents():void {

            this.content.bind('click', $.proxy(this.selectItem, this));
            this.content.find('.btnMoveToBucket').bind('click', $.proxy(this.moveToBucket, this));
            this.content.find('.btnTriggerReject').bind('click', $.proxy(this.triggerReject, this));
        }

        /**
         * Unbinds all of the event listeners for this view.
         */
        public unbindEvents():void {

            this.content.unbind('click', $.proxy(this.selectItem, this));
            this.content.find('.btnMoveToBucket').unbind('click', $.proxy(this.moveToBucket, this));
            this.content.find('.btnTriggerReject').unbind('click', $.proxy(this.triggerReject, this));
        }    

        /**
         * Show the rejection Interface
         */
        public triggerReject(e?:Event):void{

            if (e) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
            }

            this.delegate.rejectionSelector.attach(this.content.find('.rejectContainer'), (r)=>this.gotRejectionReason(r));
            this.delegate.rejectionSelector.showMe();
        }

        /**
         * Handle the reason list being clicked
         */
        public gotRejectionReason(reason){
            this.moveToBucket(null, 'rejected', reason);
        }

        /**
         * Called if this is the active Experience item when another item is clicked. Removes the active class, and
         * sets active to false.
         */
        public unselectItem():void {
            this.active = false;
            this.content.removeClass('selected');
        }

        /**
         * Called if the user clicks on this item in the list.
         */
        private selectItem():void {

            this.active = true;
            EventBus.dispatch(ExperienceItem.CLICKED, this);
            this.content.addClass('selected');
        }

        /**
         * Changes the current status of this user experince in the DB.
         */
        public moveToBucket(e?:Event, status?:string, reason?:string):void {

            if (e) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
            }

            var params:Object = {'task': 'update-experience-status', 'experience_id': this.data.id};
            if (status) {
                // From hotkey
                params['status'] = status;
            } else {
                // From button
                params['status'] = $(e.currentTarget).attr('data-bucket');
            }

            if (params['status'] == 'rejected') {
                params['reason'] = reason;
            }

            Ajax.post(new AjaxRequest(params, $.proxy(this.bucketChanged, this), $.proxy(this.bucketChangeError, this), null, '/api/1.1'));
        }

        /**
         * Called if the bucket change is successful.
         */
        private bucketChanged(data:any):void {

            if (data.bind.success == 1) {
                EventBus.dispatch(ExperienceItem.STATUS_CHANGED, this);
            }
        }

        /**
         * Pouplates the dynamic bucket buttons.
         */
        private populateButtons():void {

            for (var i = 0; i < this.data.buckets.length; i++) {

                var title = Utils.capitalize(this.data.buckets[i]);

                if(this.data.buckets[i] == "rejected"){
                    var btn = $('<div class = "rejectContainer"></div>');
                    var btnReject = $('<button type="button" data-bucket = "' + this.data.buckets[i] + '" class="btn btn-default btn-xs btnTriggerReject btnSend' + title + '"><div class = "flag"><i class = "fa fa-chevron-right"></i></div>' + title + '</button>')
                    btn.append(btnReject);
                }else{
                    var btn = $('<button type="button" data-bucket = "' + this.data.buckets[i] + '" class="btn btn-default btn-xs btnMoveToBucket btnSend' + title + '"><div class = "flag"><i class = "fa fa-chevron-right"></i></div>' + title + '</button>')
                }
                this.buttonHolder.append(btn)
            }

            //if this experience item is in the rejected bucket, add the delete button
            if (this.delegate.bucket == "rejected") {
                var del = $('<button type="button"" class="btn btn-default btn-xs btnDelete"><div class = "flag"><i class = "fa fa-close"></i></div>Delete</button>');
                this.buttonHolder.append(del);
                del.bind('click', $.proxy(this.deleteExperience, this));
            }
        }

        /**
         * Hits the API to completely remove the experience and all of it's media.
         */
        public deleteExperience(e) {

            e.preventDefault();
            e.stopPropagation();

            Ajax.request('DELETE', new JWTAjaxRequest('/experience/' + this.data.id, {'erase_media': true},
                $.proxy(function () { EventBus.dispatch(ExperienceItem.STATUS_CHANGED, this); }, this),
                $.proxy(function (e) { console.error("ERROR", e); }, this))
            );
        }

        /**
         * Completely removes the view from the DOM, clears all event listeners and kills rivets.
         */
        public killMe():void {

            clearTimeout(this.displayTimeout);

            this.unbindEvents();
            this.rivets.unbind();
            this.content.remove();
            this.content = null;
        }

        /**
         * Called if there's an error updating this items status.
         */
        private bucketChangeError(e:Event):void {
            console.error("Error updating experience status");
            console.log(e);
        }
    }
}
