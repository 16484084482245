module Nickel {

    /**
     * Simple component for loading and playing an video file with a <video> tag.
     */
    export class ModVideoPlayer extends Component {

        /**
         * The video Node to play the video with.
         */
        private videoNode:any;

        /**
         * JQuery reference to the video node.
         */
        private $videoNode:any;

        /**
         * div holding the spinner for this video player.
         */
        private loader:any;

        /**
         * The url we're currently playing through the video node.
         */
        private activeSrc:string = "";

        /**
         * If we should play the video as soon as it's loaded.
         */
        public autoplay:boolean = false;

        /**
         * If the video is playing right now or not.
         */
        public isPlaying:boolean = false;

        /**
         * The interval to check the time on if we're checking progress.
         */
        private checkTimeInt:any;

        /**
         * Whether or not we should be checking the progress of the video as it plays. Disabled by default for
         * performace reasons.
         */
        public checkProgress:boolean = false;

        /**
         * What time we should start the video at.
         */
        public startTime:number = 0;

        /**
         * Event Dispatched once the video node begins to play.
         */
        public static PLAY:string = "playvideo";

        /**
         * Event Dispatched once the video node reaches the end.
         */
        public static VIDEO_END:string = "videoend";

        /**
         * Event Dispatched on an interval as the video plays.
         */
        public static PROGRESS:string = "progress";

        /**
         * Stores the global vars, sets the references to the video node, and loader.
         * @param container        A jQuery object containing the parent div for this component.
         * @param data          The JSON data unique to this component.
         * @param delegate        The Class that created this instance.
         */
        constructor(container:JQuery, data:any, delegate:ModerationOverlay) {

            super(container, data, delegate);

            this.viewLoaded(Main.templates.find('.moderationVideoPlayer').clone());

            this.$videoNode = this.content.find('.mainVideo');
            this.videoNode = this.$videoNode[0];
            this.$videoNode.on('ended', $.proxy(this.vidOver, this));
            this.loader = this.content.find('.loader');
        }

        /**
         * Called when the native ended event fires. Dispatches the VIDEO_END event.
         */
        private vidOver():void {

            clearInterval(this.checkTimeInt);
            this.dispatch(ModVideoPlayer.VIDEO_END);
        }

        /**
         * Loads a video through the video node, binds the canplaythrough event.
         * @data        The url and poster frame for the video we want to load.
         */
        public loadVideo(data:VideoParams) {

            if (!this.onStage) {
                this.showMe();
            }

            this.activeSrc = data.src;
            this.$videoNode.one('canplaythrough', $.proxy(this.videoReady, this));
            if (data.poster) {
                this.$videoNode.attr('poster', data.poster);
            } else {
                this.$videoNode.removeAttr('poster');
            }
            this.videoNode.src = data.src;
            this.videoNode.currentTime = this.startTime;
            this.videoNode.load();
        }

        /**
         * Called when "canplaythrough" fires on the video node, only once.
         */
        private videoReady():void {
            this.showVideo();
            if (this.autoplay) {
                this.playVideo();
            }
        }

        /**
         * Plays the video node, if we're checking progress, sets the checkTimeInt.
         */
        public playVideo():void {
            clearInterval(this.checkTimeInt);
            if (this.checkProgress) {
                this.checkTimeInt = setInterval($.proxy(this.checkTime, this), 43);
            }
            this.videoNode.play();
            this.dispatch(ModVideoPlayer.PLAY);
        }

        /**
         * Pauses the video node.
         */
        public pauseVideo() {
            clearInterval(this.checkTimeInt);
            this.videoNode.pause();
        }

        /**
         * Starts the video playback again.
         */
        public replayVideo():void {
            this.videoNode.currentTime = 0;
            this.videoNode.play();
            this.isPlaying = true;
        }

        /**
         * Checks the video node current time against it's duration to calculate the percentage complete, dispatches
         * the PROGRESS event.
         */
        public checkTime() {

            var current = this.videoNode.currentTime
            var perc = current / this.videoNode.duration;
            this.dispatch(ModVideoPlayer.PROGRESS, {'currentTime': current, 'perc': perc});
        }

        /**
         * Shows the video node and hides the loader.
         */
        private showVideo() {
            this.$videoNode.show();
            this.loader.hide();
        }

        /**
         * Hides the video node.
         */
        private hideVideo() {
            this.videoNode.pause();
            this.$videoNode.hide();
        }

        /**
         * Shows the loades and hides the video node.
         */
        public showLoader() {
            this.hideVideo();
            this.loader.show();
        }

        /**
         * Pauses the video and hides the component.
         */
        public hideMe() {

            super.hideMe();
            this.pauseVideo();
        }
    }
}

interface VideoParams {
    src:string;
    poster?:string;
}
