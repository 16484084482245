module Nickel {

    /**
     * Value Object for defining the structure of the top level Scene JSON data. All other Scene VO's extend SceneVO.
     */
    export class SceneVO extends VO {

        public label:string = "New Scene";
        public name:string = "New Scene";
        public decision:any = {
            "class": "",
            "options": {}
        };
        public metaData:any = {};
        public trigger:string = "doneProcessing";
    }

    /**
     * Parent class for all types of Scenes.
     */
    export class Scene extends Level {

        /**
         * Dropdown holding the Decision options.
         */
        private decisionDropdown:JQuery;

        /**
         * Array holding all of the list items for Decisions.
         */
        private decisionOptions:Nickel.DecisionOption[] = [];

        /**
         * Passes all of the Scenes params on to the parent Level Class.
         * @param container        A jQuery object containing the parent div for this view.
         * @param data          The JSON data unique to this node.
         * @param index        The index of this node in the delegate's child array (only applicable if stored in an
         *     array and not an object).
         * @param delegate        The Class that created this instance.
         */
        constructor(container, data, index, delegate) {

            super(container, data, index, delegate);
        }

        /**
         * Binds this.data to the view using rivets. Populates the Decision dropdown.
         * @param v        A jQuery object to act as this node's view and to bind it's data to.
         */
        public viewLoaded(v:JQuery):void {

            super.viewLoaded(v);

            this.content.find(".btnExportScene").bind('click', $.proxy(this.exportMe, this));

            if(this.data.decision){
                // Ajax.post(request);
                this.decisionDropdown = this.content.find('.decisionDropdown').val(this.data.decision.class);
                this.decisionDropdown.bind('change', $.proxy(this.getDecisionOptions, this));
                this.populateDecisionOptions();
            }
        }

        /**
         * Exports this classes this.data obeject to a .json file that the user downloads.
         */
        public exportMe():void {

            //save the story
            this.saveData();

            var sceneId: string = this.data.id;
            var actId: string = this.delegate.data.id;
            var filename: string = this.data.name + '.json';
            Ajax.get(new JWTAjaxRequest('/story/' + this.delegate.delegate.data.id, null, function (storyData) {
                var sceneData: Object = storyData['acts'][actId]['scenes'][sceneId];
                saveAs(new Blob([JSON.stringify(sceneData)], {type: "text/plain;charset=utf-8"}), filename);
            }));
        }

        /**
         * Hits the database to get all of the different options for decision scripts.
         */
        public getDecisionOptions():void {

            this.clearOptions();
            this.data.decision.options = {};

            if (this.data.decision.class) {
                var data = {'task': 'get-decision-options', "class": this.data.decision.class};
                var request:AjaxRequest = new AjaxRequest(data, $.proxy(this.gotDecisionOptions, this), function (e) {
                    console.log("error");
                    console.log(e);
                }, null, '/api/1.1');

                Ajax.post(request);
            }
        }

        /**
         * Dets the decision options in the data object, calls the populateDecisionOptions function to populate the
         * options.
         * @param d    The Data from the DB.
         */
        public gotDecisionOptions(d:any):void {

            this.data.decision.options = d.bind.options;
            this.populateDecisionOptions();
        }

        /**
         * Dets the decision options in the data object
         */
        public populateDecisionOptions():void {

            //remove this scene from the list
            var scenes = $.extend({}, this.delegate.data.scenes);
            delete scenes[this.data.id];

            if (this.data.decision.options) {
                for (var i = 0; i < this.data.decision.options.length; i++) {

                    var data = this.data.decision.options[i];
                    var option = new Nickel.DecisionOption(this.content.find('.decisionOptions'), data, i, this);
                    option.addScenes(scenes);
                    this.decisionOptions.push(option);
                }
            }
        }

        /**
         * Removes all of the options from the decision dropdown.
         */
        public clearOptions():void {

            for (var i = 0; i < this.decisionOptions.length; i++) {
                var option = this.decisionOptions[i];
                option.killMe();
            }

            this.decisionOptions = [];
        }

        /**
         * Saves the global story data.
         */
        public saveData():void {
            EventBus.dispatch(Story.SAVE_STORY);
        }

        /**
         * Shows the scene, sets the sceneID
         */
        public showMe():void {

            super.showMe();

            Main.sceneId = this.data.id;
        }

        /**
         * Tells the delegate to remove this child.
         */
        public removeMe() {
            this.delegate.childToDelete = this.data.id;
            this.delegate.deleteChild();
        }

        /**
         * Called by StoryPublisher to validate scene-specific fields before publishing
         * @param data
         * @returns {boolean} Whether or not to allow publishing to occur based on current parameters
         */
        public static validatePublishAction(data) {
            return true;
        }

        /**
         * Specifies where the story's data can be found on this level.
         * @param storyData An object containing the story JSON structure.
         */
        public onDataReload(storyData):void {
            $.extend(true, this.data, storyData['acts'][this.delegate.data.id]['scenes'][this.data.id]);
        }
    }
}
