module Nickel {

    export class VideoPlayer extends Component {

        private videoNode:any;
        private $videoNode:any;
        private vidWidth:number = 1920;
        private vidHeight:number = 1080;
        private aspectRatio:number;
        private activeSrc:string = "";

        public static LOAD_VIDEO:string = "loadvideo";
        public static CLEAR_VIDEO:string = "clearvideo";

        constructor(container, data, delegate) {

            super(container, data, delegate);

            this.viewLoaded(Main.templates.find('.videoPlayer').clone());

            this.$videoNode = this.content.find('.mainVideo');
            this.videoNode = this.$videoNode[0];
            this.$videoNode.on('ended', $.proxy(this.vidOver, this));

            EventBus.addEventListener(VideoPlayer.LOAD_VIDEO, $.proxy(this.loadVideo, this), this);
            EventBus.addEventListener(VideoPlayer.CLEAR_VIDEO, $.proxy(this.clearVideo, this), this);
        }

        private vidOver() {

        }

        private clearVideo() {
            this.activeSrc = null;
            this.hideVideo();
            this.videoNode.src = "";
            this.videoNode.load();
        }

        public loadVideo(data) {

            if (data.src != this.activeSrc) {
                this.activeSrc = data.src;
                this.$videoNode.one('canplaythrough', $.proxy(this.videoReady, this));
                this.videoNode.src = data.src;
                this.videoNode.load();
            }

            if (!this.onStage) {
                this.showMe();
            }
        }

        private videoReady() {
            this.showMe();
            this.showVideo();
            //this.playVideo();
        }

        private playVideo() {
            this.videoNode.play();
        }

        private showVideo() {
            this.$videoNode.show();
        }

        private hideVideo() {
            this.videoNode.pause();
            this.$videoNode.hide();
        }

        public showLoader() {
            this.hideVideo();
        }

        public hideMe() {
            super.hideMe();
            this.videoNode.pause();
        }

        public resize() {
            this.aspectRatio = this.vidWidth / this.vidHeight;
            var w = this.container.width() - 20;
            this.content.css({
                'width': w,
                'height': w / this.aspectRatio
            });
        }
    }
}
