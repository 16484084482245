module Nickel {

    export class YoutubeScene01VO extends SceneVO {

        public type: any = "YoutubeScene01";
        public sceneData: any = {
            videoItem: '', // Scene ID with the video to attach
            metadataScript: '',
            channelName: '',
            credentials: {
                id: null
            },
            onProcessed: {
                requestType: null,
                url: null
            }
        };
    }


    export class YoutubeScene01 extends Scene {

        public label: string = "Youtube Scene";
        public login: YoutubeLogin;
        public btnLogin: any;

        constructor(container, data, index, delegate) {

            super(container, data, index, delegate);

            if (data) {
                this.data = data;
            } else {
                this.data = new YoutubeScene01VO();
            }

            this.viewLoaded(Main.templates.find('.youtubescene01').clone());
        }

        public loginClicked(e) {
            if (e) {
                e.preventDefault();
            }
            this.login.login();
        }

        public loggedIn(response) {
            this.data.sceneData.channelName = response.user;
            this.data.sceneData.credentials.id = response.credentials_id;
        }

        public viewLoaded(v) {

            super.viewLoaded(v);

            this.btnLogin = this.content.find('.youtubeLogin');
            this.btnLogin.bind('click', $.proxy(this.loginClicked, this));

            this.login = new YoutubeLogin(this.btnLogin, $.proxy(this.loggedIn, this));

            this.content.find('.btnSelectMetadataScript').bind('click', $.proxy(this.selectMetadataScriptClicked, this));

            this.content.find('select.sceneItems.video').bind('change', $.proxy(this.changeSceneVideoSelect, this));
        }

        public changeSceneVideoSelect(e) {
            this.data.sceneData.videoItem = $(e.currentTarget).val();
        }

        public populateSceneSelect() {
            var optHtml = '<option value=""></option>';
            for (var sceneId in this.delegate.data.scenes) {
                var scene = this.delegate.data.scenes[sceneId];
                if (scene.type.indexOf('Video') != -1) {
                    optHtml += '<option value="' + sceneId + '">' + sceneId + '</option>';
                }
            }
            this.content.find('select.sceneItems').html(optHtml);
            this.content.find('select.sceneItems.video').val(this.data.sceneData.videoItem);
        }

        public showMe() {

            super.showMe();
            this.populateSceneSelect();
        }

        public selectMetadataScriptClicked(e) {
            S3FilePicker.getInstance().open($(e.target).parent().parent().find('input').val(), $.proxy(function (fileKey) {
                this.data.sceneData.metadataScript = fileKey.trim();
                this.saveData();
            }, this));
        }
    }
}
