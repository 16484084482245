module Nickel {

    export class InventorySourceVO {
        public from: string = "inventory_id";
        public inventory_id: string = "";
    }

    export class AssetSourceVO {
        public asset_type: string = ""; // can be any asset type except audio: video, image, image_sequence, template
        public asset_vars: any = []; // only used if type is template
    }

    export class AssetIdSourceVO extends AssetSourceVO {
        public from: string = "asset_id";
        public asset_id: string = "";
    }

    export class AssetTagsSourceVO extends AssetSourceVO {
        public from: string = "asset_tags";
        public asset_tags: any = [];
    }

    export class OverlaySource extends Level {

        constructor(container, data, index, delegate) {

            super(container, data, index, delegate);

            if (data) {
                let keys:Array<string> = Object.keys(this.getVO());
                for (let dataKey in data) {
                    if (data.hasOwnProperty(dataKey) && keys.indexOf(dataKey) == -1) {
                        delete data[dataKey];
                    }
                }
                this.data = data;
            } else {
                this.data = this.getVO();
            }

            this.showInPopup = false;

            let selector = this.getTemplateSelector();
            if (!selector) {
                throw new Error('Template selector not configured on an overlay source');
            }
            this.viewLoaded(Main.templates.find(selector).clone());
            this.container.append(this.content);
        }

        /**
         * Creates the rivets data binding and binds all Level generic events.
         * @param v A JQuery object containing this level's content selector.
         */
        public viewLoaded(v) {

            this.content = v;

            this.rivets = rivets.bind(this.content, {
                "data": this.data,
                "delegate": this.delegate.data,
                "controller": this
            });

            $(window).bind("resize", $.proxy(this.resize, this));
        }

        public overlayTypeChanged(type: string): void {
            // Override me
        }

        protected getTemplateSelector(): string {
            return null;
        }

        protected getVO(): any {
            return {};
        }
    }

    export class InventorySource extends OverlaySource {
        protected getTemplateSelector() {
            return '.inventoryIdSource';
        }

        protected getVO() {
            return new InventorySourceVO();
        }

        constructor(container, data, index, delegate) {

            super(container, data, index, delegate);

            this.populateInventorySelect();
        }

        public overlayTypeChanged(type: string): void {
            this.data.inventory_id = '';
            this.populateInventorySelect();
        }

        public showMe()
        {
            super.showMe();

            this.populateInventorySelect();
        }

        public populateInventorySelect(): void {
            let overlay:Overlay = this.delegate;
            let act:Act = overlay.delegate.delegate.delegate;

            let select = this.content.find('.inventoryItemIds');
            select.empty().append(new Option('', ''));

            for (let invId in act.data.inventory) {
                if (act.data.inventory.hasOwnProperty(invId)) {
                    let invItemType = act.data.inventory[invId].type.toLowerCase();
                    if (invItemType == overlay.data.type) {
                        select.append(new Option(act.data.inventory[invId].label || act.data.inventory[invId].name, invId));
                    }
                }
            }

            select.val(this.data.inventory_id);
        }
    }

    export class AssetIdSource extends OverlaySource {
        protected getTemplateSelector() {
            return '.assetIdSource';
        }

        protected getVO() {
            return new AssetIdSourceVO();
        }

        constructor(container, data, index, delegate) {

            super(container, data, index, delegate);

            this.delegate.populateAssetSelects();
        }

        public overlayTypeChanged(type: string): void {
            if (["template"].indexOf(this.data.asset_type) == -1) {
                this.data.asset_type = '';
                this.data.asset_vars = [];
                this.data.asset_id = '';
            }
        }

        public viewLoaded(v) {
            super.viewLoaded(v);

            // parameter setup
            this.content.find('.btnAddAssetParameter').bind('click', $.proxy(this.addParameter, this));
            this.updateParameters();
        }

        // parameter functionality
        public addParameter() {
            this.data.asset_vars.push({var_type: 'text', var_source: 'text', key: '', value: ''});
            this.updateParameters();
        }

        public removeParameter(e) {
            this.data.asset_vars.splice($(e.currentTarget).parent().parent().index(), 1);
            this.updateParameters();
        }

        private updateParameters() {

            // refresh delete click event so deleted entries are unbound and new entries are bound
            this.content.find('.btnRemoveAssetParameter').unbind('click').bind('click', $.proxy(this.removeParameter, this));
        }
    }

    export class AssetTagsSource extends OverlaySource {
        protected getTemplateSelector() {
            return '.assetTagsSource';
        }

        protected getVO() {
            return new AssetTagsSourceVO();
        }

        constructor(container, data, index, delegate) {

            super(container, data, index, delegate);

            this.populateInventorySelect();
        }

        public overlayTypeChanged(type: string): void {
            if (["template"].indexOf(this.data.asset_type) == -1) {
                this.data.asset_type = '';
                this.data.asset_vars = [];
                this.data.asset_tags = [];
            }
        }

        public showMe() {
            super.showMe();

            this.populateInventorySelect();
        }

        public viewLoaded(v) {
            super.viewLoaded(v);

            // parameter setup
            this.content.find('.btnAddAssetParameter').bind('click', $.proxy(this.addParameter, this));
            this.updateParameters();

            // tag setup
            this.content.find('select.tagSourceType').bind('change', $.proxy(this.changeTagSourceType, this));
            this.content.find('.btnAddTag').bind('click', $.proxy(this.addTag, this));
            this.content.find('.btnRemoveTag').unbind('click').bind('click', $.proxy(this.removeTag, this));
            this.content.find('.tags .tag').unbind('click').bind('click', $.proxy(this.toggleRequiredTag, this));
            this.content.find('.tagInput').bind('keypress', $.proxy(function (e) {
                var code = e.keyCode || e.which;
                if (code == 13) {
                    this.addTag();
                    e.preventDefault();
                }
            }, this));
        }

        // parameter functionality
        public addParameter() {
            this.data.asset_vars.push({var_type: 'text', var_source: 'text', key: '', value: ''});
            this.updateParameters();
        }

        public removeParameter(e) {
            this.data.asset_vars.splice($(e.currentTarget).parent().parent().index(), 1);
            this.updateParameters();
        }

        private updateParameters() {

            // refresh delete click event so deleted entries are unbound and new entries are bound
            this.content.find('.btnRemoveAssetParameter').unbind('click').bind('click', $.proxy(this.removeParameter, this));
        }

        // tag functionality
        public populateInventorySelect(): void {
            let act:Act = this.delegate.delegate.delegate.delegate;

            let select = this.content.find('.inventoryItemIds');
            let currentValue: string = select.val();
            select.empty();

            for (let invId in act.data.inventory) {
                if (act.data.inventory.hasOwnProperty(invId)) {
                    let invItemType = act.data.inventory[invId].type.toLowerCase();
                    if (invItemType != 'image' && invItemType != 'video' && invItemType != 'audio') {
                        select.append(new Option(act.data.inventory[invId].label || act.data.inventory[invId].name, invId));
                    }
                }
            }

            if (currentValue != null) {
                select.val(currentValue);
            }
        }

        public changeTagSourceType(e): void {
            var select: JQuery = $(e.currentTarget);
            this.content.find('.tagInput').addClass('hidden');
            this.content.find('.tagInput[data-source-type=' + select.val() + ']').removeClass('hidden');
        }

        public addTag(): void {
            var tagInput: JQuery = this.content.find(".tagInput").not('.hidden');
            var type: string = this.content.find("select.tagSourceType").val();
            var value: string = tagInput.val();
            if (value != null) {
                value = value.trim();
            }
            if (value != null && value != "" && this.getTagIndex(value, type) < 0) {
                this.data.asset_tags.push({type: type, value: value, required: false});
                this.content.find('.btnRemoveTag').last().bind('click', $.proxy(this.removeTag, this));
                this.content.find('.tags .tag').last().bind('click', $.proxy(this.toggleRequiredTag, this));
            }
            if (tagInput.get(0).tagName.toLowerCase() == 'input') {
                tagInput.val("");
            }
        }

        public removeTag(e): void {
            var tagValue: JQuery = $(e.currentTarget).parent().find('.tagValue');
            var tagIndex: number = this.getTagIndex(tagValue.text(), tagValue.attr('data-type'));
            if (tagIndex >= 0) {
                this.data.asset_tags.splice(tagIndex, 1);
                e.stopPropagation();
            }
        }

        public toggleRequiredTag(e): void {
            var tagValue: JQuery = $(e.currentTarget).find('.tagValue');
            var tagIndex: number = this.getTagIndex(tagValue.text(), tagValue.attr('data-type'));
            if (tagIndex >= 0) {
                this.data.asset_tags[tagIndex].required = !this.data.asset_tags[tagIndex].required;
                e.stopPropagation();
            }
        }

        public getTagIndex(tagValue, tagType) {
            if (!this.data.asset_tags) {
                this.data.asset_tags = [];
            }
            for (var i = 0; i < this.data.asset_tags.length; i++) {
                if (this.data.asset_tags[i].value == tagValue && this.data.asset_tags[i].type == tagType) {
                    return i;
                }
            }
            return -1;
        }
    }
}
