/**
 * Global event listener class. Used for passing global between views and components.
 */ 
class EventBus{
    
    /**
     * Object containing all event listeners, with the key being the event string, and the value being an array of listener functions.
     */ 
    static listeners:any = {};

    /**
     * Adds an event listener for a global event.
     * @evt         Event string we're listening for
     * @callback    The callback function to call if the event is dispatched. 
     */ 
    static addEventListener(evt:string, callback:any, caller:any):void{

        if(!EventBus.listeners[evt]){
            EventBus.listeners[evt] = {};
        }

        EventBus.listeners[evt][caller.guid] = callback;
    }

    /**
     * Removes a specific event listener for a global event.
     * @evt         Event string we're removing
     * @callback    The callback function we want to remove.
     */
    static removeEventListener(evt, callback, caller):void{

        var listeners = EventBus.listeners[evt];
        var callerListener = listeners[caller.guid];

        delete listeners[caller.guid];
    }   

    /**
     * Adds an event listener for a global event.
     * @evt         The event we want to dispatch.
     * @callback    The data we want to pass into the callback function for that event.
     */ 
    static dispatchEvent(evt:string, data:any=null):void{

        var listeners = EventBus.listeners[evt];

        for(var key in listeners){
            if(listeners.hasOwnProperty(key)){
                listeners[key](data);
            }
        }
    }

    /**
     * Adds an event listener for a global event.
     * @evt         The event we want to dispatch.
     * @callback    The data we want to pass into the callback function for that event.
     */ 
    static dispatch(evt:string, data:any=null):void{

        var listeners = EventBus.listeners[evt];

        for(var key in listeners){
            if(listeners.hasOwnProperty(key)){
                listeners[key](data);
            }
        }
    }
}