///<reference path='../def/jquery.d.ts'/>
///<reference path='../def/modernizr.d.ts'/>
///<reference path='../def/facebook.d.ts'/>
///<reference path='../def/jquery.placeholder.d.ts'/>
///<reference path='../def/jquery-ui-1.10.3.custom.min.d.ts'/>
///<reference path='../def/history.d.ts'/>
///<reference path='../def/custom.d.ts'/>
///<reference path='../def/underscore.d.ts'/>
///<reference path='../def/zeroclipboard.d.ts'/>
///<reference path='../def/crypto-js.d.ts'/>
///<reference path='../def/imposium.d.ts'/>
///<reference path='../def/filesaver.d.ts'/>
///<reference path='../def/bootbox.d.ts'/>
///<reference path='../def/auth0.d.ts'/>

///<reference path='./scaffolding/Auth.ts'/>
///<reference path='./scaffolding/Utils.ts'/>
///<reference path='./scaffolding/View.ts'/>
///<reference path='./scaffolding/PaginatedView.ts'/>
///<reference path='./scaffolding/Component.ts'/>
///<reference path='./scaffolding/Ajax.ts'/>
///<reference path='./scaffolding/Loader.ts'/>
///<reference path='./scaffolding/EventBus.ts'/>
///<reference path='./scaffolding/Level.ts'/>
///<reference path='./scaffolding/StopWatch.ts'/>
///<reference path='./scaffolding/StoryPicker.ts'/>
///<reference path='./scaffolding/ActPicker.ts'/>
///<reference path='./scaffolding/BatchPicker.ts'/>
///<reference path='./scaffolding/DynamicPanel.ts'/>
///<reference path='./scaffolding/CloudRegionProvider.ts'/>
///<reference path='./scaffolding/AssetProvider.ts'/>

///<reference path='./stories/StoriesView.ts'/>
///<reference path='./stories/Story.ts'/>
///<reference path='./stories/ui/NodeTree.ts'/>
///<reference path='./stories/ui/RightInterface.ts'/>
///<reference path='./stories/ui/VideoPlayer.ts'/>
///<reference path='./stories/ui/StoryPublisher.ts'/>
///<reference path='./stories/ui/CutPicker.ts'/>
///<reference path='./stories/ui/InfoBox.ts'/>
///<reference path='./stories/ui/JobHandler.ts'/>
///<reference path='./stories/ui/JobLogFinder.ts'/>
///<reference path='./stories/ui/SceneRecutter.ts'/>
///<reference path='./stories/ui/Debug.ts'/>
///<reference path='./stories/ui/ListInterface.ts'/>
///<reference path='./stories/ui/S3FilePicker.ts'/>

///<reference path='./stories/act/Act.ts'/>
///<reference path='./stories/act/inventory/InventoryItem.ts'/>

///<reference path='./stories/act/scenes/Scene.ts'/>
///<reference path='./stories/act/scenes/decision/DecisionOption.ts'/>
///<reference path='./stories/act/scenes/video_scene_01/VideoScene01.ts'/>
///<reference path='./stories/act/scenes/chat_scene_01/ChatScene01.ts'/>
///<reference path='./stories/act/scenes/text_scene_01/TextScene01.ts'/>
///<reference path='./stories/act/scenes/youtube_message_scene_01/YoutubeMessageScene01.ts'/>
///<reference path='./stories/act/scenes/tweet_scene_01/TwitterLogin/TwitterLogin.ts'/>
///<reference path='./stories/act/scenes/tweet_scene_01/TweetScene01.ts'/>
///<reference path='./stories/act/scenes/youtube_scene_01/YoutubeLogin/YoutubeLogin.ts'/>
///<reference path='./stories/act/scenes/youtube_scene_01/YoutubeScene01.ts'/>
///<reference path='./stories/act/scenes/api_scene_01/ApiScene01.ts'/>
///<reference path='./stories/act/scenes/cut/Cut.ts'/>
///<reference path='./stories/act/scenes/cut/overlay/Overlay.ts'/>
///<reference path='./stories/act/scenes/cut/sources/Sources.ts'/>
///<reference path='./stories/act/scenes/cut/inputs/Inputs.ts'/>

///<reference path='./moderation/ModerationView.ts'/>
///<reference path='./moderation/ExperienceItem.ts'/>
///<reference path='./moderation/ModerationOverlay.ts'/>
///<reference path='./moderation/ModVideoPlayer.ts'/>
///<reference path='./moderation/AudioPlayer.ts'/>
///<reference path='./moderation/Inventory.ts'/>
///<reference path='./moderation/RejectionSelector.ts'/>

///<reference path='./data/DataView.ts'/>
///<reference path='./data/BatchRowItem.ts'/>
///<reference path='./data/BatchColumn.ts'/>

///<reference path='./preview/PreviewView.ts'/>
///<reference path='./preview/InventoryInterface.ts'/>

class Main {

    static storyTitle:string;
    static storyId:string;
    static actId:string;
    static actTitle:string;
    static activeNode:string;
    static sceneId:string;
    static templates:any;
    static stories:any = [];
    static cutRate:number = 0;
    static s3ResourcesBucket:string;
    static interfaceType:string = "nodes";
    static email:string;

    /**
     * Creates a new Main instance and authenticates.
     */
    constructor() {
        let hash = window.location.hash;

        // Remove hash immediately if one is set
        let requestPath = window.location.pathname + window.location.search;
        if (window.location.href.replace(window.location.protocol + "//" + window.location.host, '') != requestPath) {
            window['History']['replaceState']({id: Utils.generateUUID()}, document.title, requestPath);
        }

        Nickel.Auth.login(hash, $.proxy(this.authenticated, this));
    }

    /**
     * Sets the templates for all views and components to use and gets the saved state from a cookie.
     * @param token
     * @param accessData
     */
    private authenticated(token, accessData)
    {
        JWTAjaxRequest.idToken = token;

        const tokenData = jwt_decode(token);
        Main.email = (tokenData && tokenData['https://imposium.com/email']) ? tokenData['https://imposium.com/email'] : '';

        const services = (accessData && accessData['services']) ? accessData['services'] : [];
        const hasStoriesAccess = Nickel.Auth.canAccessByName('Story Editor', services);
        const hasModerationAccess = Nickel.Auth.canAccess('/moderation', services);
        const hasDataAccess = Nickel.Auth.canAccess('/data', services);
        const hasPreviewAccess = Nickel.Auth.canAccessByName('Preview', services);

        let defaultRoute = '';
        if (hasStoriesAccess) {
            defaultRoute = '/stories';
        } else if (hasPreviewAccess) {
            defaultRoute = '/preview';
        }
        let serviceId = Main.determineActiveServiceId(window.location.pathname);
        if (serviceId <= 0 && defaultRoute) {
            serviceId = Main.determineActiveServiceId(defaultRoute);
        }
        const organizationData = Main.determineActiveOrganization(serviceId, accessData);
        const organizationId = organizationData[0];
        const restrictAccess = organizationData[1];
        AjaxRequest.organizationId = organizationId;
        if (accessData['organizations']) {
            for (let organizationIndex in accessData['organizations']) {
                if (accessData['organizations'].hasOwnProperty(organizationIndex) &&
                    accessData['organizations'][organizationIndex]['id'] == organizationId) {
                    Main.stories = accessData['organizations'][organizationIndex]['stories'];
                    break;
                }
            }
        }

        Main.templates = $("#templates");
        this.rivets();

        const state = JSON.parse(Utils.getCookie("editor_state"));

        if (state) {
            Main.activeNode = state.activeNode;
            if (state.interfaceType) {
                Main.interfaceType = state.interfaceType;
            }
        }

        if (organizationId && hasStoriesAccess) {
            // Cache list of assets on account
            Nickel.AssetProvider.getAssets(() => {});
        }

        // Configure views for role
        if (organizationId && hasStoriesAccess) {
            window['stories'] = new Nickel.StoriesView("storyView", "stories", "Stories");
        }
        if (organizationId && hasModerationAccess) {
            window['moderation'] = new Nickel.ModerationView("moderationView", "moderation", "Moderation");
        }
        if (organizationId && hasDataAccess) {
            window['dataTab'] = new Nickel.DataView("dataView", "data", "Data");
        }
        if (organizationId && hasPreviewAccess) {
            window['preview'] = new Nickel.PreviewView("previewView", "preview", "Preview");
        }
        if (organizationId && hasStoriesAccess && hasModerationAccess && hasDataAccess && hasPreviewAccess) {
            window['preview'].debug = new Nickel.Debug(window['preview'].container.find('.player'), {}, window['preview']);
            if (!window['preview'].debug.isHidden()) {
                window['preview'].debug.toggleConsole();
            }
        }

        const queryParams = (organizationId) ? '?organization_id=' + organizationId : '';
        const requestPath = window.location.pathname;
        if (requestPath === '' || requestPath === '/' || requestPath === '/redirect') {
            let prevRequestPath = Nickel.Auth.getPreviousRequestPath();
            if (prevRequestPath && prevRequestPath !== '/' && prevRequestPath !== '/redirect') {
                window['History']['replaceState']({id: Utils.generateUUID()}, document.title, prevRequestPath + queryParams);
            } else if (defaultRoute) {
                window['History']['replaceState']({id: Utils.generateUUID()}, document.title, defaultRoute + queryParams);
            } else {
                window['History']['replaceState']({id: Utils.generateUUID()}, document.title, requestPath + queryParams);
            }
        } else {
            window['History']['replaceState']({id: Utils.generateUUID()}, document.title, requestPath + queryParams);
        }

        Main.setHeader(accessData, organizationId, '');

        if (restrictAccess) {
            ReactDOM.render(React.createElement(NoAccess, {type: "restrict"}), $('#main-container').get(0));
        }
    }

    private static determineActiveServiceId(requestPath) {
        let serviceId = 0;
        if (requestPath.substr(0, 8) == '/stories') {
            serviceId = 1;
        } else if (requestPath.substr(0, 11) == '/moderation') {
            serviceId = 4;
        } else if (requestPath.substr(0, 5) == '/data') {
            serviceId = 5;
        } else if (requestPath.substr(0, 8) == '/preview') {
            serviceId = 6;
        }

        return serviceId;
    }

    private static determineActiveOrganizationIn(accessData, sharedSession, providedOrganizationId, organizationIds) {
        if (organizationIds.length > 0) {
            if (providedOrganizationId && organizationIds.indexOf(providedOrganizationId) != -1) {
                return Main.setActiveOrganization(accessData, providedOrganizationId, sharedSession);
            }
            if (sharedSession && sharedSession.organization_id && organizationIds.indexOf(sharedSession.organization_id) != -1) {
                return sharedSession.organization_id;
            }
            return Main.setActiveOrganization(accessData, organizationIds[0], sharedSession);
        }

        return "";
    }

    private static determineActiveOrganization(serviceId, accessData) {
        const sharedSession = Nickel.Auth.getSharedSession();
        const providedOrganizationId = Utils.getQueryStringVariable('organization_id');

        let allOrgs = [];
        let validOrgs = [];
        if (accessData['organizations']) {
            for (let i = 0; i < accessData['organizations'].length; i++) {
                allOrgs.push(accessData['organizations'][i]['id']);
                if (accessData['organizations'][i]['services'].indexOf(serviceId) != -1) {
                    validOrgs.push(accessData['organizations'][i]['id']);
                }
            }
        }
        let activeOrgId = Main.determineActiveOrganizationIn(accessData, sharedSession, providedOrganizationId, validOrgs);
        const restrictAccess = !activeOrgId;
        if (restrictAccess) {
            activeOrgId = Main.determineActiveOrganizationIn(accessData, sharedSession, providedOrganizationId, allOrgs);
        }

        return [activeOrgId, restrictAccess];
    }

    private static setActiveOrganization(accessData, organizationId, sharedSession) {
        if (!sharedSession || !sharedSession.organization_id || sharedSession.organization_id != organizationId) {
            let storyId = '';
            if (accessData['organizations']) {
                for (let organizationIndex in accessData['organizations']) {
                    if (accessData['organizations'].hasOwnProperty(organizationIndex) &&
                        accessData['organizations'][organizationIndex]['id'] == organizationId) {

                        if (accessData['organizations'][organizationIndex]['stories'] && accessData['organizations'][organizationIndex]['stories'][0] &&
                            accessData['organizations'][organizationIndex]['stories'][0].id) {
                            storyId = accessData['organizations'][organizationIndex]['stories'][0].id;
                        }

                        break;
                    }
                }
            }
            Nickel.Auth.updateSharedSession({organization_id: organizationId, story_id: storyId});
        }
        return organizationId;
    }

    public static setHeaderStory(story) {
        const storyId = (story && story['id']) ? story['id'] : '';
        const organizationId = AjaxRequest.organizationId;
        const session = Nickel.Auth.getSession();
        if (organizationId && session && session.access_data) {
            Main.setHeader(session.access_data, organizationId, storyId);
            if (storyId) {
                Nickel.Auth.updateSharedSession({story_id: storyId});
            }
        }
    }

    public static setDefaultHeaderStory() {
        const organizationId = AjaxRequest.organizationId;
        const session = Nickel.Auth.getSession();
        const sharedSession = Nickel.Auth.getSharedSession();
        if (organizationId && session && session.access_data) {
            let story;
            if (sharedSession && sharedSession.story_id && session.access_data['organizations']) {
                for (let organizationIndex in session.access_data['organizations']) {
                    if (session.access_data['organizations'].hasOwnProperty(organizationIndex) &&
                        session.access_data['organizations'][organizationIndex]['id'] == organizationId) {

                        if (session.access_data['organizations'][organizationIndex]['stories']) {
                            for (let i = 0; i < session.access_data['organizations'][organizationIndex]['stories'].length; i++) {
                                if (sharedSession.story_id === session.access_data['organizations'][organizationIndex]['stories'][i]['id']) {
                                    story = session.access_data['organizations'][organizationIndex]['stories'][i];
                                    break;
                                }
                            }
                        }

                        break;
                    }
                }
            }
            if (story) {
                Main.onStoryChange(story);
                return true;
            }
        }

        return false;
    }

    private static setHeader(accessData, organizationId: string, storyId: string) {
        ReactDOM.render(React.createElement(ImposiumHeader, {
            accessData: accessData,
            activeOrganization: organizationId,
            activeStory: storyId,
            email: Main.email,
            onOrganizationChange: Main.onOrganizationChange,
            onStoryChange: Main.onStoryChange,
            logout: Nickel.Auth.logout,
        }), $('.mainNav').get(0));
    }

    private static onOrganizationChange(orgId) {
        Nickel.Auth.updateSharedSession({story_id: ''});
        if (window.location.hostname === 'localhost') {
            window.location.replace('http://localhost:3036?organization_id=' + orgId);
        } else if (window.location.host.indexOf('.staging.') != -1) {
            window.location.replace('https://hub.staging.imposium.com?organization_id=' + orgId);
        } else {
            window.location.replace('https://hub.imposium.com?organization_id=' + orgId);
        }
    }

    private static onStoryChange(story) {
        let activeView;
        if (window['stories'] && window['stories']['onStage']) {
            activeView = window['stories'];
        } else if (window['moderation'] && window['moderation']['onStage']) {
            activeView = window['moderation'];
        } else if (window['dataTab'] && window['dataTab']['onStage']) {
            activeView = window['dataTab'];
        } else if (window['preview'] && window['preview']['onStage']) {
            activeView = window['preview'];
        }
        if (activeView) {
            activeView.storyClicked(story);
        }
    }

    /**
     * Sets our custom formatters and binders for rivets.js.
     */
    public rivets():void {

        rivets.formatters.num = {
            read: function(value) {
                return value
            },
            publish: function(value) {
                return Number(value)
            }
        };

        rivets.formatters.nullNum = {
            read: function(value) {
                return value
            },
            publish: function(value) {
                let num = Number(value);
                if(num === 0){
                    return null;
                }else{
                    return num;
                }
            }
        };

        //global rivets formatters
        rivets.formatters.date = function (value) {
            var myDate = new Date(value * 1000);
            return myDate;
        };

        rivets.formatters.bytes = function (value) {

            return Utils.formatBytes(value);
        };

        rivets.formatters.renderTime = function (value) {

            if (value) {
                return value;
            } else {
                return "0.00";
            }
        };

        //converts seconds to hh:mm:ss:ff
        rivets.formatters.aeTime = function (value) {

            var h:any = Math.floor(value / 3600); //Get whole hours
            value -= h * 3600;
            var m:any = Math.floor(value / 60); //Get remaining minutes
            value -= m * 60;
            var s:any = Math.floor(value / 1);
            value -= s;
            var f:any = Math.floor(Main.cutRate * value);

            var hStr = (h < 10) ? "0" + h : h;
            var mStr = (m < 10) ? "0" + m : m;
            var sStr = (s < 10) ? "0" + s : s;
            var fStr = (f < 10) ? "0" + f : f;

            var str = hStr + ":" + mStr + ":" + sStr + ":" + fStr;
            return str;
        };

        rivets.formatters.properCase = function (value) {
            return value.toLowerCase().replace(/_/g, ' ').replace(/\b([a-z\u00C0-\u00ff])/g, function (_, initial) {
                return initial.toUpperCase();
            });
        };

        rivets.formatters.length = function (value) {
            return value.length;
        };

        rivets.formatters.ceil = function (value) {
            return Math.ceil(value);
        };

        rivets.formatters.toFixed = function (value) {
            if (value) {
                return value.toFixed(2);
            } else {
                return 0;
            }
        };

        rivets.formatters.commaNumber = function (value) {
            if (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
                return 0;
            }
        }

        rivets.formatters.dateTime = function (value) {
            if (value) {
                var multiplier = (value.toString().length > 10) ? 1 : 1000;
                return Utils.formatDate(new Date(value * multiplier), 'MM/dd/yyyy, h:mm tt');
            }
            return "";
        };

        rivets.formatters.dateTimeShort = function (value) {
            if (value) {
                var multiplier = (value.toString().length > 10) ? 1 : 1000;
                return Utils.formatDate(new Date(value * multiplier), 'MMM d h:mm tt');
            }
            return value;
        };

        rivets.formatters.dollarValue = function (value) {
            return "$" + parseFloat(value).toFixed(6);
        };

        rivets.formatters.booleanDropdown = {
            read: String, // read in html as string
            publish: function (value) {
                return (value === "true"); // store in data as boolean
            }
        };

        var numberFormat = function (value) {
            var result:number = Number(value);
            return (isNaN(result)) ? 0 : result;
        };
        rivets.formatters.number = {
            read: numberFormat,
            publish: numberFormat
        };

        rivets.formatters.in = function (value, arg) {
            var list = JSON.parse(arg);
            return list.indexOf(value) > -1;
        };

        rivets.formatters.eq = function (value, arg) {
            return value == arg;
        };

        rivets.formatters.gt = function (value, arg) {
            return value > arg;
        };

        rivets.formatters.lt = function (value, arg) {
            return value < arg;
        };

        rivets.formatters.abs = function (value) {
            return Math.abs(value);
        }

        rivets.formatters.number = function (value) {
            if (value) {
                return parseFloat(value);
            } else {
                return 0;
            }
        }

        rivets.formatters.tag = function (value) {
            if(value == "text"){
                return "static";
            }else{
                return value;
            }
        }

        rivets.binders.addclass = function (el, value) {

            if (el.addedClass) {
                $(el).removeClass(el.addedClass)
                delete el.addedClass
            }

            if (value) {
                $(el).addClass(value)
                el.addedClass = value
            }
        };

        rivets.binders.addposter = function (el, value) {

            var src = value.replace('.mp4', '.jpg');
            src = Main.decache(src);
            $(el).attr('poster', src);
        };

        rivets.binders.sameprotocolsrc = function (el, value) {
            if (value) {
                var src = value.replace('//', '').replace('http:', '').replace('https:', '');
                $(el).attr('src', '//' + src);
            }
        };

        rivets.binders.fpo = function (el, value) {
            if (value) {
                var src = value.replace('//', '').replace('http:', '').replace('https:', '');
                $(el).attr('src', '//' + src);
            } else {
                $(el).attr('src', '/img/image_fpo.jpg');
            }
        };
    }

    /**
     * Kills the cache on a url passed in by adding a custom query string to the end.
     * @param url    The url that we want to clear out of the browser cache.
     * @returns    The URL passed in with a custom query string, example: http://url.com?123473584
     */
    public static decache(url) {
        var time = Math.ceil(new Date().getTime() / 1000);
        var pos = url.lastIndexOf('?');
        if (pos != -1) {
            // ? found
            return url.substr(0, pos + 1) + time;
        } else {
            return url + '?' + time;
        }
    }

    /**
     * Saves the current state of the CMS, including the active Story, Act, Scene, Node, and interface type.
     */
    public static saveState() {

        //store the state of the editor to bring back after page refresh
        var val = JSON.stringify({
            "story": Main.storyId,
            "act": Main.actId,
            "scene": Main.sceneId,
            "activeNode": Main.activeNode,
            "interfaceType": Main.interfaceType
        });

        Utils.setCookie("editor_state", val, 2592000); // 30 days
    }
}
