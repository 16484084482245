module Nickel {

    export class YoutubeMessageScene01VO extends SceneVO {

        public type: any = "YoutubeMessageScene01";
        public sceneData: any = {
            message: "",
            delay: null,
            phoneNumber: ""
        };
    }

    export class YoutubeMessageScene01 extends Scene {

        public label: string = "Youtube Message Scene";
        public nodeStyle: any = {
            "bg": {
                "fill": 0xe5bb62,
                "stroke": 0x929292
            },
            "hover": {
                "fill": 0xde9146,
                "stroke": 0x929292
            },
            "highlight": {
                "fill": 0xb56722,
                "stroke": 0x7c5030
            }
        };

        constructor(container, data, index, delegate) {

            super(container, data, index, delegate);

            if (data) {
                this.data = data;
            } else {
                this.data = new YoutubeMessageScene01VO();
            }

            this.viewLoaded(Main.templates.find('.youtubemessagescene01').clone());
        }
    }
}
