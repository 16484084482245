module Nickel {

    export class InventoryItemDefaultVO {

        public type:string;
        public src:any;
    }

    export class InventoryItemDefaultEnumVO extends InventoryItemDefaultVO {

        public options: Array<string> = [];
    }

    export class InventoryItemVO extends VO {

        public label:string = "New Inventory";
        public name:string = "New Inventory";
        public type:string = "";
        public description:string = "";
        public optional:boolean = false;
        public hidden:boolean = false;
        public createThumbnail:boolean = false;
        public includeInExperienceData:boolean = false;
        public defaultItem:InventoryItemDefaultVO = new InventoryItemDefaultVO();
    }

    export class InventoryItem extends Level {

        public label:string = "Inventory";

        constructor(container, data, index, delegate) {

            super(container, data, index, delegate);

            if (!data) {
                this.data = new InventoryItemVO();
            } else {
                this.data = data;
            }
            this.viewLoaded(Main.templates.find(".inventoryItem").clone());
        }

        /**
         * Binds this.data to the view using rivets and the event listeners for this Class.
         * @param v A jQuery object to act as this node's view and to bind it's data to.
         */
        public viewLoaded(v) {

            super.viewLoaded(v);

            this.content.find('.uploadInventoryDisplayImage').bind('change', $.proxy(this.uploadDisplayImage, this));
            this.content.find('.btnUploadInventoryDisplayImage').bind('click', $.proxy(this.uploadDisplayImageClicked, this));
            this.content.find('.uploadInventoryDefault').bind('change', $.proxy(this.uploadDefaultFile, this));
            this.content.find('.btnUploadInventoryDefaultFile').bind('click', $.proxy(this.uploadDefaultFileClicked, this));
            this.content.find('.typeSelect').bind('change', $.proxy(this.resetDefaultValue, this));
            this.content.find('.btnEditId').bind('click', $.proxy(this.editId, this));
            this.content.find('.btnChangeId').bind('click', $.proxy(this.saveId, this));
            this.content.find('.btnCancelIdChange').bind('click', $.proxy(this.resetId, this));
            this.content.find('.enumControls .btnAddEnumOption').bind('click', $.proxy(this.addEnumOption, this));
            this.content.find('.enumControls .btnRemoveEnumOption').bind('click', $.proxy(this.removeEnumOption, this));
            this.resetId();
        }

        /**
         * Opens the ID text field for editing and displays save/cancel buttons.
         */
        private editId() {
            this.content.find('.btnEditId').hide().parent().find('input').prop('disabled', false).focus();
            this.content.find('.btnChangeId').show();
            this.content.find('.btnCancelIdChange').show();
        }

        /**
         * Validates an ID change and changes this item's ID if successful.
         */
        private saveId() {
            let id:string = this.content.find('.btnEditId').parent().find('input').val().trim();

            if (id !== this.data.id) {
                for (var inventoryId in this.delegate.inventory) {
                    if (this.delegate.inventory.hasOwnProperty(inventoryId)) {
                        let inventoryItem:InventoryItem = this.delegate.inventory[inventoryId];
                        if (inventoryItem !== this && inventoryItem.data.id === id) {
                            alert(`There is already an inventory item on this act with an ID of "${id}". Please choose another ID.`);
                            return;
                        }
                    }
                }

                // Remove data stored with old ID
                delete this.delegate.data.inventory[this.data.id];

                // Set new ID
                this.data.id = id;

                // Store this object's data referenced by new ID
                this.delegate.data.inventory[id] = this.data;

                // Save new data with ID change
                this.delegate.saveData();
            }

            this.resetId();
        }

        /**
         * Resets the ID text field with the current ID stored in this item's data.
         */
        private resetId() {
            this.content.find('.btnEditId').show().parent().find('input').prop('disabled', true).val(this.data.id);
            this.content.find('.btnChangeId').hide();
            this.content.find('.btnCancelIdChange').hide();
        }

        /**
         * Reset the default item so that type-specific fields are erased.
         */
        public resetDefaultValue() {
            this.content.find('video,audio').removeAttr('src');

            var dataTypeIdentifier:string = this.data.type.toLowerCase();
            var defaultItem: any = (dataTypeIdentifier === 'enum') ? new InventoryItemDefaultEnumVO() : new InventoryItemDefaultVO();
            defaultItem.type = this.data.type;

            switch (dataTypeIdentifier) {
                case "number":
                    defaultItem.src = 0;
                    break;
                case "boolean":
                    defaultItem.src = false;
                    break;
                default:
                    defaultItem.src = "";
                    break;
            }

            this.data.defaultItem = defaultItem;
        }

        /**
         * Adds an Enum option to the defaultItem dropdown.
         */
        public addEnumOption() {
            var option: string = prompt('Please provide a name for the new Enum option');
            if (option && this.data.defaultItem.options.indexOf(option) === -1) {
                this.data.defaultItem.options.push(option);
                this.data.defaultItem.src = option;
            }
        }

        /**
         * Removes an Enum option from the defaultItem dropdown.
         */
        public removeEnumOption() {
            var enumDropdown: JQuery = this.content.find('.enumDropdown');
            this.data.defaultItem.options.splice(this.data.defaultItem.options.indexOf(enumDropdown.val()), 1);
            if (this.data.defaultItem.options.length === 0) {
                this.data.defaultItem.src = "";

                // Reloads the dropdown to reflect having no options
                // (otherwise, the last option hangs around until you click on the dropdown and THEN it disappears)
                enumDropdown.parent().prepend(enumDropdown.detach());
            }
        }

        /**
         * Removes the item from the story JSON.
         */
        public removeMe() {

            this.delegate.removeInventoryItem(this);
            this.delegate.saveData();
        }

        /**
         * Performs the default item file upload (image/video/audio).
         * @param e The click event.
         */
        public uploadDefaultFile(e) {
            var form = $(e.currentTarget).parent();
            form.attr('action', AjaxUrlProvider.getLegacyApiBaseUrl() + '/api');

            form.find('input:not([type=file])').remove();
            $('<input>', {name: 'task', value: 'upload-file', type: 'hidden'}).appendTo(form);
            $('<input>', {name: 'story_id', value: this.delegate.delegate.data.id, type: 'hidden'}).appendTo(form);

            var fileInput:HTMLInputElement = <HTMLInputElement>$(e.currentTarget)[0];
            var fileData = fileInput.files[0];
            if (fileData) {

                EventBus.dispatch(Debug.LOG, "Uploading Default File");

                Ajax.formSubmit(form, $.proxy(function (response) {

                    EventBus.dispatch(Debug.SUCCESS, "Default File Uploaded");

                    this.data.defaultItem = response.bind.info;
                    this.delegate.saveData();
                }, this));
            }
        }

        /**
         * Passes the click event from the button to the file input.
         * @param e The click event.
         */
        public uploadDefaultFileClicked(e) {
            e.preventDefault();
            switch (this.data.type.toLowerCase()) {
                case "image":
                    this.content.find('.imagePreview .uploadInventoryDefault').trigger('click');
                    break;
                case "video":
                    this.content.find('.videoPreview .uploadInventoryDefault').trigger('click');
                    break;
                case "audio":
                    this.content.find('.audioPreview .uploadInventoryDefault').trigger('click');
                    break;
            }
        }

        /**
         * Passes the click event from the button to the file input.
         * @param e The click event.
         */
        public uploadDisplayImageClicked(e) {
            e.preventDefault();
            this.content.find('.uploadInventoryDisplayImage').trigger('click');
        }

        /**
         * Performs the display image file upload.
         * @param e The click event.
         */
        private uploadDisplayImage(e) {

            var form = $(e.currentTarget).parent();

            // Get story ID so upload is sent to the right place
            form.find('input[name=story_id]').val(this.delegate.delegate.data.id);

            Ajax.formSubmit(form, $.proxy(function (response) {
                this.data.displayImage = response.bind.info;
                this.delegate.saveData();
            }, this));
        }
    }
}
