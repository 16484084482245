module Nickel {
    export class JobHandler {

        private static pollInterval: number = 2000;
        private static jobsRunning: any = {};

        private static handleError(error: string, onFailCallback: Function = null) {
            EventBus.dispatch(Nickel.Debug.ERROR, {
                text: 'CMS Job Error',
                error: {status: "Failure: ", statusText: error}
            });

            if (onFailCallback) {
                onFailCallback();
            }
        }

        public static pollFor(jobId: string, jobName: string, onFinish: Function, onFail: Function) {
            Ajax.get(new JWTAjaxRequest('/job/' + jobId, null, function (response) {
                if (response && response.processing) {

                    console.log('Polled status for job ' + jobId + ' (' + jobName + ')');

                    JobHandler.checkIfRunning(jobId, jobName, response.status == "running");

                    // Job still processing
                    setTimeout(function () {
                        JobHandler.pollFor(jobId, jobName, onFinish, onFail);
                    }, JobHandler.pollInterval);

                } else {

                    JobHandler.clearRunningJobEntry(jobId);

                    // Job complete
                    if (response && response.error) {
                        JobHandler.handleError(response.error, onFail);
                    } else {
                        if (onFinish) {
                            EventBus.dispatch(Nickel.Debug.SUCCESS, 'Job ' + jobId + ' (' + jobName + ') completed successfully');
                            if (response && response.output) {
                                onFinish(jobId, jobName, response.output);
                            } else {
                                onFinish(jobId, jobName);
                            }
                        }
                    }

                    JobHandler.outputLogInConsole(jobId);
                }
            }, function (xhr) {

                // Keep polling, likely a gateway timeout or blip
                setTimeout(function () {
                    JobHandler.pollFor(jobId, jobName, onFinish, onFail);
                }, JobHandler.pollInterval);

            }));
        }

        public static outputLogInConsole(jobId: string) {
            EventBus.dispatch(Nickel.Debug.LOG, 'Retrieving log...');
            JobLogFinder.findLogHtml(jobId, function (html: string) {
                EventBus.dispatch(Nickel.Debug.HTML, html);
            });
        }

        private static checkIfRunning(jobId: string, jobName: string, isRunning: boolean) {
            if (isRunning && !JobHandler.jobsRunning[jobId]) {
                EventBus.dispatch(Debug.LOG, 'Processing started on job ' + jobId + ' (' + jobName + ')');
                JobHandler.jobsRunning[jobId] = true;
            }
        }

        private static clearRunningJobEntry(jobId: string) {
            if (JobHandler.jobsRunning[jobId]) {
                delete JobHandler.jobsRunning[jobId];
            }
        }

        public static runJob(jobName: string, workload: any = {}, onFinishCallback: Function = null, onFailCallback: Function = null, defaultError: string = 'Could not process job') {
            let route = '/job/' + jobName.replace(/([A-Z])/g, (l) => `-${l[0].toLowerCase()}`);

            Ajax.post(new JWTAjaxRequest(route, workload, function (response) {
                if (response && response.id) {

                    EventBus.dispatch(Debug.LOG, 'Queued ' + response.id + ' (' + jobName + ')');

                    // Job created successfully
                    JobHandler.pollFor(response.id, jobName, onFinishCallback, onFailCallback);
                } else {
                    JobHandler.handleError(defaultError, onFailCallback);
                }
            }, function (xhr) {
                let response = JSON.parse(xhr.jqXHR.responseText);

                // Server response error
                JobHandler.handleError((response && response.error) ? response.error : defaultError, onFailCallback);
            }));
        }
    }
}
