module Nickel {

    /**
     * Simple component for loading and playing an audio file with a <audio> tag.
     */
    export class AudioPlayer extends Component {

        /**
         * Whether we should play the file as soon as it's loaded.
         */
        public autoplay:boolean = false;

        /**
         * The Audio node.
         */
        private audioNode:HTMLAudioElement;

        /**
         * A JQuery object for the audio Node.
         */
        private $audioNode:JQuery;

        /**
         * Stores the global vars, Loads the view, and sets the audio node vars.
         * @param container        A jQuery object containing the parent div for this component.
         * @param data          The JSON data unique to this component.
         * @param delegate        The Class that created this instance.
         */
        constructor(container:JQuery, data:any, del:any) {

            super(container, data, del);

            this.viewLoaded(Main.templates.find('.audioPlayer').clone());

            this.$audioNode = this.content.find('.audioNode');
            this.audioNode = <HTMLAudioElement>this.$audioNode[0];
        }

        /**
         * Loads an audio file and adds the canplaythrough event listener.
         * @param src        the url to the audio clip we want to load.
         */
        public loadAudio(src:string):void {

            this.$audioNode.one('canplaythrough', $.proxy(this.audioLoaded, this));
            this.audioNode.src = src;
        }

        /**
         * If we're autoplaying, play the audio.
         */
        public audioLoaded():void {

            if (this.autoplay) {
                this.audioNode.play();
            }
        }

        /**
         * Hide the node, and pause the audio.
         */
        public hideMe() {

            super.hideMe();

            this.audioNode.pause();
        }
    }
}
