module Nickel {

    /**
     * View for moderating and viewing user experiences associated with a specific story.
     * Moderatiors can view all user experience data including UGC, EGC, and account information.
     * Moderators can move stories into 5 different buckets (pending, approved, used, vip, rejected)
     */
    export class ModerationView extends View {

        static DEFAULT_BUCKETS = ["pending", "approved", "used", "vip", "rejected"];

        /**
         * The current story we're moderating videos for
         */
        private storyId:string;

        /**
         * The label of the current story we're viewing
         */
        private storyLabel:string = "";

        /**
         * The current page we're on
         */
        public page:number = 1;

        /**
         * How many experiences we want per page
         */
        public itemsPerPage:number = 50;

        /**
         * The current status we're viewing
         */
        public bucket:string = "pending";

        /**
         * The different bucket names, used to create the bucket toggles
         */
        private buckets:string[] = [];

        /**
         * The bucket button options for each experience item
         */
        private bucketOptions:any = {
            "pending": ["approved", "used", "vip", "rejected"],
            "approved": ["pending", "used", "vip", "rejected"],
            "used": ["pending", "approved", "vip", "rejected"],
            "vip": ["pending", "approved", "used", "rejected"],
            "rejected": ["pending", "approved"]
        };

        /**
         * The total number of pages in this bucket
         */
        private pages:number = 1;

        /**
         * The total number of experiences in this bucket
         */
        private total:number = 1;

        /**
         * If this view has been initilized yet
         */
        private initialized:boolean = false;

        /**
         * An array holding all ExperienceItem classes
         */
        private experiences:any = [];

        /**
         * The current item that is selected
         */
        public activeItem:Nickel.ExperienceItem;

        /**
         * The overlay for displaying specific experience inventory data.
         */
        private overlay:Nickel.ModerationOverlay;

        /**
         * Rivets.js instance binding this classes Global variables to the view
         */
        public rivets:any;

        /**
         * Reference to the JQuery ajax request sent to get experiences. Used to abort a currently waiting request if
         * we send in another.
         */
        private experiencesRequest:any;

        /**
         * Reference to the JQuery ajax request sent to get the experiences count. Used to abort a currently waiting
         * request if we send in another.
         */
        private experiencesCountRequest:any;

        /**
         * Container for all of the ExperienceItems.
         */
        private experienceHolder:JQuery;

        /**
         * Holder for the dynamically generated bucket buttons.
         */
        private bucketButtonHolder:JQuery;

        /**
         * Dropdown containing a link for each story.
         */
        private storyDropdown:Nickel.StoryPicker;

        /**
         * Contains the labels for the ExperienceItem columns.
         */
        private subHeader:JQuery;

        /**
         * All of the story data sent in from the DB.
         */
        private storyData:any;

        /**
         * The story data for the story we're currently moderating.
         */
        public activeStoryData:any;

        /**
         * The story data for the story we're currently moderating.
         */
        public rejectionSelector:Nickel.RejectionSelector;

        /**
         * Filters for passing into the getExperiences call.
         */
        private expFilter:string = "";

        /**
         * JQuery objects for the main buttons on the view
         */
        private btnNextPage:JQuery;
        private btnPrevPage:JQuery;
        private btnFirstPage:JQuery;
        private btnLastPage:JQuery;
        private btnRefresh:JQuery;
        private shortcuts:JQuery;
        private loader:JQuery;
        private countLoader:JQuery;

        /**
         * Refresh interval for fetching data from the DB periodically.
         */
        public autoRefreshInterval:any;

        /**
         * Stores the global vars, and adds the state change listener
         * @param container    A jQuery object containing the parent div for this view.
         * @param id            The unique ID associated with this view, used to determine if this view should be
         *     visible or not by listening to the browser state.
         * @param displayText    The text to show in the header for this view.
         */
        constructor(container, id, displayText) {

            super(container, id, displayText);

            this.experienceHolder = this.container.find('.experienceHolder');
            this.subHeader = this.container.find('.subHeader');
            this.bucketButtonHolder = this.container.find('.bucketButtons');
            this.storyDropdown = new Nickel.StoryPicker(this.container.find('.storyDropdownContainer'), null, this);
            this.loader = this.container.find('.expLoader');
            this.countLoader = this.container.find('.total .totalLoader');
            this.shortcuts = this.container.find('.shortcuts');

            this.overlay = new Nickel.ModerationOverlay(this.container, {}, this);
            this.rejectionSelector = new Nickel.RejectionSelector(this.container, {}, this);
        }

        /**
         * Binds this view to the DOM, populates the dynamic header buttons, binds all of the event listeners, and
         * get's the first batch of experiences.
         */
        private init():void {

            this.initialized = true;

            this.rivets = rivets.bind(this.container, {
                "controller": this
            });

            this.bindEvents();
        }

        /**
         * Binds all of the event listeners for this view.
         */
        private bindEvents():void {

            //buttons
            this.btnNextPage = this.container.find('.btnNextPage').bind('click', $.proxy(this.nextPageClicked, this));
            this.btnPrevPage = this.container.find('.btnPrevPage').bind('click', $.proxy(this.prevPageClicked, this));
            this.btnFirstPage = this.container.find('.btnFirstPage').bind('click', $.proxy(this.firstPageClicked, this));
            this.btnLastPage = this.container.find('.btnLastPage').bind('click', $.proxy(this.lastPageClicked, this));
            this.btnRefresh = this.container.find('.btnRefresh').bind('click', $.proxy(this.getExperiences, this, true));

            this.container.find('.btnFilter').bind('click', $.proxy(this.filterClicked, this));
            this.container.find('.btnClear').bind('click', $.proxy(this.clearClicked, this));
            this.container.find('.btnShortcuts').bind('click', $.proxy(this.toggleShortcuts, this));
            this.container.find('.btnCloseShortcuts').bind('click', $.proxy(this.toggleShortcuts, this));
            this.container.find('.searchable').keyup($.proxy(this.searchKeyup, this));
            this.container.find('.autoRefreshToggle input[type=checkbox]').bind('click', $.proxy(this.toggleAutoRefresh, this));

            this.storyDropdown.on(StoryPicker.STORY_CLICKED, $.proxy(this.storyClicked, this));

            //hotkeys
            $(document).on('keydown', null, 'shift+left', $.proxy(this.prevBucket, this));
            $(document).on('keydown', null, 'shift+right', $.proxy(this.nextBucket, this));
            $(document).on('keypress', null, 'shift+r', $.proxy(this.getExperiences, this, true));
            $(document).on('keydown', null, 'right', $.proxy(this.rightPressed, this));
            $(document).on('keydown', null, 'left', $.proxy(this.leftPressed, this));
            $(document).on('keydown', null, 'up', $.proxy(this.upPressed, this));
            $(document).on('keydown', null, 'down', $.proxy(this.downPressed, this));

            //events
            EventBus.addEventListener(ExperienceItem.CLICKED, $.proxy(this.itemClicked, this), this);
            EventBus.addEventListener(ExperienceItem.STATUS_CHANGED, $.proxy(this.itemStatusChanged, this), this);
        }

        /**
         * Removes all of the event listeners for this view.
         */
        private unbindEvents():void {

            this.btnNextPage.unbind('click', $.proxy(this.nextPageClicked, this));
            this.btnPrevPage.unbind('click', $.proxy(this.prevPageClicked, this));
            this.btnFirstPage.unbind('click', $.proxy(this.firstPageClicked, this));
            this.btnLastPage.unbind('click', $.proxy(this.lastPageClicked, this));
            this.btnRefresh.unbind('click', $.proxy(this.getExperiences, this, true));

            EventBus.removeEventListener(ExperienceItem.CLICKED, $.proxy(this.itemClicked, this), this);
            EventBus.removeEventListener(ExperienceItem.STATUS_CHANGED, $.proxy(this.itemStatusChanged, this), this);
        }

        /**
         * Decide which story to display.
         */
        public stateChanged() {

            super.stateChanged();

            if (this.initialized) {
                const urlStrings = History['getState']().url.split("?")[0].split("/");
                const storyId = urlStrings[4];
                const status = urlStrings[5];

                if (storyId) {
                    if (storyId !== this.storyId) {
                        Ajax.get(new JWTAjaxRequest('/story/' + storyId, null, $.proxy(this.setStory, this, status), $.proxy(this.clickDefaultStory, this)));
                    } else if (status && status !== this.bucket) {
                        this.applyBucket(status);
                    } else {
                        this.resetPagination();
                        this.getExperiences();
                    }
                } else {
                    if (!Main.setDefaultHeaderStory()) {
                        this.clickDefaultStory();
                    }
                }
            }
        }

        /**
         * Called if an invalid story is provided.
         */
        public clickDefaultStory()
        {
            if (Main.stories && Main.stories[0]) {
                this.storyClicked(Main.stories[0]);
            }
        }

        /**
         * Called when a story is selected.
         */
        public storyClicked(story) {
            if (story.id !== this.storyId) {
                Utils.pushState('/moderation/' + story.id);
            }
        }

        /**
         * Called if a user clicks on the filter icon on a filterable field.
         */
        private filterClicked(evt:Event):void {

            var targ = $(evt.currentTarget);
            targ.parent().addClass('search').find('input[type=text]').focus();
        }

        /**
         * Called if the user clicks on the close filter button. Clears the filters and gets the new experiences.
         */
        private clearClicked(evt:Event):void {

            var targ = $(evt.currentTarget);
            var field = targ.attr('data-filter');
            if (this[field] != "") {
                this[field] = "";
                this.getExperiences();
            }

            targ.parent().removeClass('search');
        }

        /**
         * Toggles the visibility of the keyboard shortcuts popup.
         */
        private toggleShortcuts(evt:Event):void {

            if (this.shortcuts.hasClass('active')) {
                this.shortcuts.removeClass('active');
            } else {
                this.shortcuts.addClass('active');
            }
        }

        /**
         * Toggles the auto refresh.
         */
        private toggleAutoRefresh(evt:Event):void {
            if (this.autoRefreshInterval) {
                clearInterval(this.autoRefreshInterval);
            }
            if ($(evt.currentTarget).is(':checked')) {
                this.autoRefreshInterval = setInterval($.proxy(this.handleAutoRefresh, this), 15000);
            }
        }

        /**
         * Refreshes if modal is not open.
         */
        private handleAutoRefresh()
        {
            if (!this.overlay.onStage) {
                this.getExperiences();
            }
        }

        /**
         * Hide this view, clearing the auto-refresh interval if set.
         */
        public hideMe() {
            super.hideMe();
            if (this.autoRefreshInterval) {
                clearInterval(this.autoRefreshInterval);
                this.container.find('.autoRefreshToggle input[type=checkbox]').attr('checked', false);
            }
        }

        /**
         * Changes the story we're currently viewing in this view.
         */
        private setStory(status, storyData) {
            this.activeStoryData = storyData;
            this.storyId = storyData.id;
            this.storyLabel = storyData.name;
            Main.setHeaderStory(storyData);
            this.page = 1;

            if (storyData['moderationBuckets']) {
                this.buckets = storyData['moderationBuckets'];
            } else {
                this.buckets = ModerationView.DEFAULT_BUCKETS;
            }

            this.populateHeader();
            if (status && this.buckets.indexOf(status) > -1) {
                this.setBucket(status);
            } else {
                this.setBucket(this.bucket);
            }
        }

        /**
         * Sets the current bucket we're viewing
         * @param bucket    The bucket we want to display.
         */
        private applyBucket(bucket: string): void {
            this.subHeader.removeClass(this.bucket);
            this.bucketButtonHolder.find('.active').removeClass('active');
            this.bucketButtonHolder.find('.btn' + Utils.capitalize(bucket)).addClass('active');
            this.bucket = bucket;
            this.subHeader.addClass(this.bucket);

            this.resetPagination();
            this.getExperiences();
        }

        /**
         * Hides the total value and shows a loader in it's place.
         */
        private showCountLoader() {
            this.countLoader.parent().find('.totalNumber').addClass('hidden');
            this.countLoader.removeClass('hidden');
        }

        /**
         * Shows the total value and hides the loader.
         */
        private hideCountLoader() {
            this.countLoader.addClass('hidden');
            this.countLoader.parent().find('.totalNumber').removeClass('hidden');
        }

        /**
         * Hits the backend for a new page of experiences. Filters it by page, story, and searches like user id and
         * experience id.
         */
        private getExperiences(refreshCount:boolean = true):void {

            this.checkPaginationButtons();
            this.clearActive();
            this.clearExperiences();
            this.loader.show();

            if (this.experiencesRequest) {
                this.experiencesRequest.abort();
            }

            let route = '/experiences/by-story/' + this.storyId;
            let queryData = {
                'page': this.page,
                'items_per_page': this.itemsPerPage,
                'status': this.bucket,
                'count': false,
                'entries': true,
            };

            //add the filters
            if (this.expFilter != "") {
                queryData['experience_id'] = this.expFilter;
            }

            let request = new JWTAjaxRequest(route, queryData, $.proxy(this.gotExperiences, this), $.proxy(this.experienceError, this));
            request.apiVersion = Ajax.oldestApiVersion;
            this.experiencesRequest = Ajax.get(request);

            if (refreshCount) {
                this.showCountLoader();
                if (this.experiencesCountRequest) {
                    this.experiencesCountRequest.abort();
                }
                queryData['count'] = true;
                queryData['entries'] = false;
                this.experiencesCountRequest = Ajax.get(new JWTAjaxRequest(route, queryData, $.proxy(this.gotExperiencesCount, this), $.proxy(this.experienceCountError, this)));
            }
        }

        /**
         * Called once we get data back from the DB. Clears the old experiences and adds the new experiences.
         * @param d    The data passed back from the DB
         */
        private gotExperiences(d:any):void {

            this.experiencesRequest = null;
            this.loader.hide();

            this.addExperiences(d.experiences);
        }

        /**
         * Called once we get a count back from the DB. Enables pagination by setting pages and total values.
         * @param d    The data passed back from the DB
         */
        private gotExperiencesCount(d:any): void {

            this.experiencesCountRequest = null;
            this.hideCountLoader();

            this.pages = d.total_pages;
            this.total = d.experience_count;

            this.checkPaginationButtons();
        }

        /**
         * Creats new ExperienceItem classes and adds them to the this.experiences object.
         * @param expData        Array of JSON objects for each experience
         */
        private addExperiences(expData:any[]):void {
            var buckets:string[] = [];
            var bucketOptions:string[] = this.bucketOptions[this.bucket];
            for (var i = 0; i < bucketOptions.length; i++) {
                if (this.buckets.indexOf(bucketOptions[i]) != -1) {
                    buckets.push(bucketOptions[i]);
                }
            }

            for (var i = 0; i < expData.length; i++) {

                var data = expData[i];
                data.buckets = buckets;
                var exp = new Nickel.ExperienceItem(this.experienceHolder, data, this);
                this.experiences.push(exp);
            }
        }

        /**
         * Removes and kills all of the existing ExperienceItem classes.
         */
        private clearExperiences():void {

            for (var i = 0; i < this.experiences.length; i++) {
                var exp = this.experiences[i];
                exp.killMe();
                exp = null;
            }
            this.activeItem = null;
            this.experiences = [];
        }

        /**
         * Called when an ExperienceItem is clicked, sets that item as active, and deselects the previously active item.
         */
        private itemClicked(item):void {

            if (this.activeItem) {
                this.activeItem.unselectItem();
            }
            this.activeItem = item;
        }

        public clearActive():void {

            if (this.activeItem) {
                this.activeItem.unselectItem();
            }
            if (this.overlay.onStage) {
                this.overlay.hideMe();
            }
            this.activeItem = null;
        }

        /**
         * Called when the status on an item changes. Removes that ExperienceItem completely and if there's none left,
         * gets more.
         */
        private itemStatusChanged(item):void {

            //if the item that changed was the activeItem, clear it
            if (item == this.activeItem) {
                this.activeItem = null;
            }

            //completely remove all references to this item
            this.total--;
            var id = item.data.id;
            var index = this.experiences.indexOf(item);
            item.killMe();
            this.experiences.splice(index, 1);

            //select the next experince, if no next, select the previous
            var next = this.experiences[index];
            if (!next) {
                next = this.experiences[index - 1];
            }
            if (next && this.overlay.onStage) {
                next.selectItem();
            } else {
                if (this.overlay.onStage) {
                    this.overlay.hideMe();
                }
            }
            //if there's no experiences left from the last batch. get more
            if (this.experiences.length == 0) {
                this.getExperiences();
            }
        }

        /**
         * Makes sure that the pagination buttons are enabled / disabled appropriately.
         */
        private checkPaginationButtons():void {

            if (this.page <= 1) {
                this.btnPrevPage.addClass('disabled');
                this.btnFirstPage.addClass('disabled');
            } else {
                this.btnPrevPage.removeClass('disabled');
                this.btnFirstPage.removeClass('disabled');
            }

            if (this.page == this.pages) {
                this.btnNextPage.addClass('disabled');
                this.btnLastPage.addClass('disabled');
            } else {
                this.btnNextPage.removeClass('disabled');
                this.btnLastPage.removeClass('disabled');
            }

            if (this.pages == 0) {
                this.container.find('.paginationPiece').hide();
            } else {
                this.container.find('.paginationPiece').show();
            }
        }

        /**
         * Creates the bucket buttons for navigating through the different bucket types.
         */
        private populateHeader(): void {
            var currentBucket:string = this.bucketButtonHolder.find('.active').attr('data-bucket');

            //add a button for each
            this.bucketButtonHolder.empty();
            for (var i = 0; i < this.buckets.length; i++) {

                var title = Utils.capitalize(this.buckets[i]);
                this.bucketButtonHolder.append(
                    '<button type="button" data-bucket = "' + this.buckets[i] + '" class="btnBucket btn' + title + '">' + title + '</button>'
                );
            }

            var buttons:JQuery = this.bucketButtonHolder.find('.btnBucket');
            buttons.unbind('click', $.proxy(this.changeBucket, this)).bind('click', $.proxy(this.changeBucket, this));
            if (currentBucket) {
                var currentBucketElement:JQuery = buttons.filter('.btnBucket[data-bucket=' + currentBucket + ']');
                if (currentBucketElement.length) {
                    currentBucketElement.addClass('active');
                } else {
                    this.setBucket(buttons.first().attr('data-bucket'));
                }
            } else {
                buttons.first().addClass('active');
            }
        }

        /**
         * Changes the currently active bucket.
         * @param e    Event passed in from the button click.
         */
        private changeBucket(e:Event):void {

            var targ = $(e.currentTarget);
            var bucket = targ.attr('data-bucket');
            this.setBucket(bucket);
        }

        /**
         * Sets the current bucket we're viewing
         * @param bucket    The bucket we want to display.
         */
        private setBucket(bucket:string):void {
            Utils.pushState('/moderation/' + this.storyId + '/' + bucket);
        }

        /**
         * Resets the pagination info back to default values.
         */
        private resetPagination() {
            this.page = 1;
            this.pages = 1;
            this.total = 1;
        }

        /**
         * Called if the user hits enter after entering a serch filter.
         * e    The event passed in from the keypress.
         */
        private searchKeyup(e:KeyboardEvent) {

            if (e.keyCode === 13) {
                this.resetPagination();
                this.getExperiences();
            }
        }

        /**
         * Moves this.page variable ahead and gets some new experiences.
         * @param e    Event passed in from the button click.
         */
        private nextPageClicked(e:Event):void {

            this.page++;
            this.getExperiences(false);
        }

        /**
         * Moves this.page variable back and gets some new experiences.
         * @param e    Event passed in from the button click.
         */
        private prevPageClicked(e:Event):void {

            this.page--;
            this.getExperiences(false);
        }

        /**
         * Sets this.page to 1 and gets the experiences.
         * @param e    Event passed in from the button click.
         */
        private firstPageClicked(e:Event):void {

            this.page = 1;
            this.getExperiences(false);
        }

        /**
         * Sets this.page to the last page and gets the experiences.
         * @param e    Event passed in from the button click.
         */
        private lastPageClicked(e:Event):void {

            this.page = this.pages;
            this.getExperiences(false);
        }

        /**
         * Shows the previous bucket.
         */
        private prevBucket(e:Event):void {

            var index = this.buckets.indexOf(this.bucket);
            if (index > 0) {
                this.setBucket(this.buckets[index - 1]);
            }
        }

        /**
         * Shows the next bucket.
         */
        private nextBucket(e:Event):void {

            var index = this.buckets.indexOf(this.bucket);
            if (index < this.buckets.length - 1) {
                this.setBucket(this.buckets[index + 1]);
            }
        }

        /**
         * If there is a previous page, show it.
         */
        private leftPressed() {
            if (this.page > 1) {
                this.page--;
                this.getExperiences(false);
            }
        }

        /**
         * If there is a next page, show it.
         */
        private rightPressed() {
            if (this.page < this.pages) {
                this.page++;
                this.getExperiences(false);
            }
        }

        /**
         * If there isn't an active item, show the first one, if there is, show the previous one.
         */
        private upPressed(e:Event) {

            e.preventDefault();
            e.stopPropagation();

            if (this.activeItem) {
                var index = this.experiences.indexOf(this.activeItem);
                if (index > 0) {
                    this.experiences[index - 1].selectItem();
                }
            } else {
                var item = this.experiences[0];
                if (item) {
                    item.selectItem();
                }
            }
        }

        /**
         * If there isn't an active item, show the last one, if there is, show the next one.
         */
        private downPressed(e:Event) {

            e.preventDefault();
            e.stopPropagation();

            if (this.activeItem) {
                var index = this.experiences.indexOf(this.activeItem);
                if (index < this.experiences.length - 1) {
                    this.experiences[index + 1].selectItem();
                }
            } else {
                var item = this.experiences[this.experiences.length - 1];
                if (item) {
                    item.selectItem();
                }
            }
        }

        /**
         * Sets this.page to the last page and gets the experiences.
         * @param subSection    The current subSection to display
         */
        public showMe(subSection) {

            super.showMe(subSection);

            if (!this.initialized) {
                this.init();
            }
        }

        /**
         * Called if there's an error trying to get new experiences.
         */
        private experienceError(e:any):void {
            if (e.textStatus != "abort") {
                console.error("Error getting user experiences");
                console.log(e);
            }
        }

        /**
         * Called if there's an error trying to get the experiences count.
         */
        private experienceCountError(e:any):void {
            if (e.textStatus != "abort") {
                console.error("Error getting user experiences count");
                console.log(e);
            }
        }
    }
}
