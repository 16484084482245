module Nickel {

    export class BatchPicker extends Component {

        public static BATCH_CLICKED:string = "batchpicked";

        private btnDropdown:any;
        private batchDropdown:any;
        private batchData:any;

        constructor(content, data, delegate) {

            super(content, data, delegate);

            this.viewLoaded(Main.templates.find('.batchPicker').clone());

            this.btnDropdown = this.content.find('.btnBatchDropdown').bind('click', $.proxy(this.dropdownClicked, this));
            this.batchDropdown = this.content.find('.dropdown');

            this.content.on('click', '.dropdown .btnBatch', $.proxy(this.batchButtonClick, this));
        }

        /**
         * Toggles the visibility of the batch dropdown.
         */
        private dropdownClicked():void {

            if (this.btnDropdown.hasClass('active')) {
                this.btnDropdown.removeClass('active');
                this.batchDropdown.hide();
            } else {
                this.btnDropdown.addClass('active');
                this.batchDropdown.show();
            }
        }

        /**
         * Populates the batch dropdown for this view.
         */
        public populateBatchDropdown(batches, storyId):void {
            this.batchData = {};
            this.batchDropdown.empty();

            batches.sort(function(a, b){
                if(a.name < b.name) return -1;
                if(a.name > b.name) return 1;
                return 0;
            });

            for (var key in batches) {

                if (batches.hasOwnProperty(key)) {

                    var batch = batches[key];
                    batch['story_id'] = storyId;
                    this.batchData[batch.id] = batch;

                    var btn = $('<a class = "btnBatch" data-id = "' + batch.id + '">' + batch.name + '</a>');
                    this.batchDropdown.append(btn);
                }
            }
        }

        /**
         * Sets the batch based off of whatever batch button the user clicked in the batch dropdown.
         */
        private batchButtonClick(evt:Event):void {

            this.dropdownClicked();

            var id = $(evt.target).attr('data-id');
            var result = this.batchData[id];
            if (result) {
                this.dispatch(BatchPicker.BATCH_CLICKED, result);
            }

        }
    }
}
