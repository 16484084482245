module Nickel {

    /**
     * Component for selecting why an experience was rejected
     */
    export class RejectionSelector extends Component {
        
        /**
         * Config
         */
        private rejectionReasons:any = [    
            {    
                "category":"Violence and Threats",
                "content":[
                    "Contains Threats to harm others, or organize acts of real-world violence.",
                    "Contains Threats of self-harm or promotion or encouragement of self-mutilation.",
                    "Suggests or encourages illegal activity.",
                    "Contains content suggesting political affiliations.",
                    "Contains Racial comments/affiliations including flags or symbols."
                ]
            },
            {
                "category":"Adult/Obscene",
                "content":[
                    "Contains Obscene, profane, lewd, graphic or suggestive content.",
                    "Exploits children or minors in any form.",
                    "Contains sex toys in the frame."
                ]
            },
            {
                "category":"Bullying and Harassment",
                "content":[
                    "Bullying or stalking, including abusive behavior directed at individuals.",
                    "Harassment of others including but not limited to name-calling, taunting, teasing or threats.",
                    "Contains Personal identifying information (phone #, address, SSN, etc.)."
                ]
            },
            {
                "category":"Hate Speech",
                "content":[
                    "Contains Racially motivated or otherwise offensive language.",
                    "Attacks a person based on their race, ethnicity, national origin, religion, sex, gender, sexual orientation, disability or medical condition."
                ]
            },
            {
                "category":"Illegal Drug Use",
                "content":[
                    "Describes or encourages illegal drug use.",
                    "Describes underage drinking.",
                    "Contains alcoholic beverages.",
                    "Contains drug paraphernalia."
                ]
            },
            {
                "category":"Graphic Content",
                "content":[
                    "Encourages or depicts cruelty to animals",
                    "Contains gang signs.",
                    "Contains racist content."
                ]
            },
            {
                "category":"Other",
                "content":[
                    "Technical difficulties"
                ]
            }
        ];

        /**
         * The function to call once a reason is selected
         */
        private callback:any;

        /**
         * Container object for the categories and reasons
         */
        private rejectionList:JQuery;

        /**
         * The max width and height of the component
         */
        private width:number = 300;
        private height:number = 250;
        private btnWidth:number = 80;
        private btnHeight:number = 20;

        /**
         * Stores the global vars, Loads the view, and sets the audio node vars.
         * @param container        A jQuery object containing the parent div for this component.
         * @param data          The JSON data unique to this component.
         * @param delegate        The Class that created this instance.
         */
        constructor(container:JQuery, data:any, del:any) {

            super(container, data, del);

            this.viewLoaded(Main.templates.find('.rejectionSelector').clone());

            this.rejectionList = this.content.find('.rejectionList');

            this.populateList();

            this.content.find('.btnCloseRejection').on('click', ()=>this.hideMe());
            this.content.find('.reason').on('click', (e)=>this.reasonSelected(e));
        }

        /**
         * On click handler for rejection reasons
         */
        private reasonSelected(e){

            this.callback($(e.target).text());
            this.hideMe();
        }

        /**
         * Toggle the visibility of each category
         */
        private toggleCategory(e){

            var p = $(e.target).parent();
            if(p.hasClass('open')){
                p.removeClass('open');
            }else{
                p.addClass('open');
            }
        }

        /**
         * Create all of the categories and reasons for the list
         */
        private populateList(){

            for(var i = 0; i<this.rejectionReasons.length; i++){

                var cat = this.rejectionReasons[i];

                var catContainer = $('<div class = "categoryContainer"><ul class = "reasons"></ul></div>');
                var title = $('<span class = "title">' + cat.category + '<i class = "icon fa fa-caret-right"/></span>');

                title.on('click', (e)=>this.toggleCategory(e));

                catContainer.prepend(title);

                for(var j = 0; j<cat.content.length; j++){
                    var item = cat.content[j];
                    catContainer.find('.reasons').append('<li class = "reason">' + item + '</li>');
                }

                this.rejectionList.append(catContainer);
            }
        }

        /**
         * Move the rejection Selector to be on top of the button that triggered it, set the callback
         */
        public attach(target:JQuery, callback:any):void{

            this.callback = callback;

            //top left default
            var x = target.offset().left;
            var y = target.offset().top;
            var pos = {};

            var xKey = 'left';
            var yKey = 'top';

            //if too close to the right, change to right pos
            if(x + this.width > window.innerWidth){
                x = x - this.width + this.btnWidth;
            }

            //if too close to the bottom, change to bottom pos
            if(y + this.height > window.innerHeight){
                y = y - this.height + this.btnHeight;
            }

            pos[xKey] = x;
            pos[yKey] = y;

            this.content.css(pos);
        }

        public hideMe(){

            super.hideMe();

            this.content.find('.open').removeClass('open');
        }
    }
}
