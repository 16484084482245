/*
 * Copyright © 2018. Nickel Media Inc.
 *
 */
module Nickel {

    export class CloudRegionProvider {

        private static regions: Array<string> = [
            'us-east-1',
            'us-east-2',
            'us-west-1',
            'us-west-2',
            'ca-central-1',
            'eu-west-1',
            'eu-west-2',
            'eu-west-3',
            'eu-central-1',
            'ap-south-1',
            'ap-northeast-1',
            'ap-northeast-2',
            'ap-southeast-1',
            'ap-southeast-2',
            'sa-east-1'
        ];

        public static getRegions(callback) {
            callback(CloudRegionProvider.regions);
        }
    }
}
