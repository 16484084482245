module Nickel {

    export class OverlayVO {
        public id:string = "";
        public name:string = "New Overlay";
        public label:string = "New Overlay";
        public description:string = "";
        public type:string = "";
        public enabled:boolean = true;
        public position:string = "";
        public position_inputs:any = null;
        public layers:any = [];
        public width:number = null;
        public height:number = null;
        public background_color:string = null;
        public phantom_version:number = 2;
        public effects:any = null;

        constructor() {
            this.id = Utils.generateUUID();
        }
    }

    export class AnimationVO{
        ease:string = null;
        type:string = null;
        duration:number = null;
    }

    export class Overlay extends Level {

        //cms config
        public label:string = "Overlay";
        public videoPlayer:boolean = true;

        private typeSelect:any;

        private positionInput:any;
        private positionSelect:any;
        private inputContainer:JQuery;

        private source:any;
        private sourceSelect:any;
        private sourceContainer:JQuery;

        constructor(container, data, index, delegate) {

            super(container, data, index, delegate);

            if (data) {
                if (!data.hasOwnProperty("phantom_version")) {
                    data.phantom_version = 1;
                }
                this.data = data;
            } else {
                this.data = new OverlayVO();
                this.firstSave = true;
            }   

            //convert [] to {}
            if (this.data.effects instanceof Array && this.data.effects.length == 0) {
                this.data.effects = {};
            }

            this.viewLoaded(Main.templates.find('.overlay').clone());

            this.sourceContainer = this.content.find('.sourceInput');
            this.inputContainer = this.content.find('.positionInput');
            this.content.find('.groupHeader').bind('click', (e)=>this.toggleGroup(e));
            this.content.find('.effect').bind('change', (e)=>this.effectUpdated(e));

            this.content.find('.animationInput').bind('change', (e)=>this.updateAnimationInput(e));
            this.setEffects();
            this.setPositionInput();
            this.setSource(this.sourceSelect.val());
            this.setAnimation('animate_on');
            this.setAnimation('animate_off');
        }

        public viewLoaded(v) {

            super.viewLoaded(v);

            this.sourceSelect = this.content.find('.sourceTypes').bind('change', (e)=>this.sourceChanged(e));
            if (this.data.source && this.data.source.from) {
                this.sourceSelect.val(this.data.source.from);
            } else if (this.data.generating_script) {
                this.sourceSelect.val('script');
            }

            this.positionSelect = this.content.find('.positionTypes').bind('change', (e)=>this.positionChanged(e));
            this.positionSelect.val(this.data.position);

            this.typeSelect = this.content.find('.overlayTypes').bind('change', (e)=>this.typeChanged(e));
            this.typeSelect.val(this.data.type);

            this.content.find('.btnUpdateOverlay').bind('click', $.proxy(this.updateClicked, this));
        }

        public updateAnimationInput(e){

            let targ = $(e.target);
            let key = targ.attr('data-key');
            let subKey = targ.attr('data-val');
            let val = this.transformVal(targ.val());

            if(val !== ""){

                let obj = this.data.effects[key];
                if(!obj){
                    this.data.effects[key] = new AnimationVO();
                }
                this.data.effects[key][subKey] = val;
            }else{

                if(!this.checkIfSet(key)){
                    delete this.data.effects[key];
                }
            }
        }

        public checkIfSet(key){

            let set = true;
            let cont = this.content.find('.animationGroup[data-key="' + key + '"]');
            cont.find('.animationInput').each(function(){
                let input = $(this);
                if(!input.val()){
                    set = false;
                }
            });
            return set;
        }

        public setAnimation(key){

            if(!this.data.effects){
                this.data.effects = {};
            }

            if(this.data.effects[key]){

                let data = this.data.effects[key];
                let cont = this.content.find('.animationGroup[data-key="' + key + '"]');
                for(var k in data){
                    if(data.hasOwnProperty(k)){
                        let c = cont.find('.animationInput[data-val="' + k + '"]');
                        c.val(data[k]);
                    }
                }
            }
        }

        public sourceChanged(e) {
            this.setSource($(e.target).val());
        }

        public positionChanged(e) {

            this.data.position = $(e.target).val();
            this.setPositionInput();
        }

        public typeChanged(e){

            this.data.type = $(e.target).val();

            if (this.data.type == 'swap' || this.data.type == 'audio') {
                // Prevent source from getting saved on unsupported types
                if (this.source) {
                    this.source.killMe();
                    this.data.source = null;
                    this.source = null;
                }
            } else if (this.sourceSelect.val() === 'script') {
                // Handle conflicting rivets bindings to show/hide MediaScript field
                this.content.find('.generatingScript').show();
            } else if (this.source) {
                // Proxy type change event
                this.source.overlayTypeChanged(this.data.type);
            }
        }

        private setPositionInput(){

            if(this.positionInput){
                this.positionInput.killMe();
                this.data.position_inputs = null;
                this.positionInput = null;
            }

            if(this.data.position){

                switch(this.data.position){
                    case "MotionTrackQuad":
                        this.positionInput = new MTQuadInput(this.inputContainer, this.data.position_inputs, null, this);
                    break;
                    case "StaticRect":
                        this.positionInput = new StaticRectInput(this.inputContainer, this.data.position_inputs, null, this);
                    break;
                    case "StaticQuad":
                        this.positionInput = new StaticQuadInput(this.inputContainer, this.data.position_inputs, null, this);
                    break;
                    case "MotionTrackRect":
                        this.positionInput = new MTRectInput(this.inputContainer, this.data.position_inputs, null, this);
                    break;
                }

                if(this.positionInput){
                    this.data.position_inputs = this.positionInput.data;
                }
            }
        }

        private setSource(from) {

            if (this.source) {
                this.source.killMe();
                this.data.source = null;
                this.source = null;
            }

            if (from) {
                switch (from) {
                    case "script":
                        this.source = null;
                        break;
                    case "inventory_id":
                        this.source = new InventorySource(this.sourceContainer, this.data.source, null, this);
                        break;
                    case "asset_id":
                        this.source = new AssetIdSource(this.sourceContainer, this.data.source, null, this);
                        break;
                    case "asset_tags":
                        this.source = new AssetTagsSource(this.sourceContainer, this.data.source, null, this);
                        break;
                }

                if (this.source) {
                    this.data.source = this.source.data;
                    this.data.generating_script = null;
                }
            }
        }

        private effectUpdated(e){

            let targ = $(e.target);
            let key = targ.attr('data-effect');
            let val = targ.val();

            if(val !== "" && key){
                val = this.transformVal(val);

                if(!this.data.effects){
                    this.data.effects = {};
                }

                this.data.effects[key] = val;
            }else{
                delete this.data.effects[key];
            }
        }

        private setEffects(){

            if(this.data.effects){

                for(var key in this.data.effects){
                    if(this.data.effects.hasOwnProperty(key)){

                        let value = this.data.effects[key];
                        let targ = this.content.find('.effect[data-effect="' + key + '"]');
                        targ.val(value);
                    }
                }
            }
        }

        private transformVal(val){

            let str = isNaN(val);
            if(!str){
                val = Number(val);
            }
            return val;
        }

        private toggleGroup(e){

            let targ = $(e.target).parent();
            if(targ.hasClass('closed')){
                targ.removeClass('closed');
                targ.find('.groupIcon').removeClass('fa-caret-right').addClass('fa-caret-down');
            }else{
                targ.addClass('closed');
                targ.find('.groupIcon').addClass('fa-caret-right').removeClass('fa-caret-down');
            }
        }

        public updateClicked() {

            this.delegate.updateCut();
        }

        public showMe() {

            super.showMe();
            if (this.source) {
                this.source.showMe();
            }

            this.delegate.loadVideo();

            this.populateAssetSelects();
        }

        public populateAssetSelects() {
            AssetProvider.getAssets($.proxy(function (assets) {
                let select = this.content.find('.assetIds');
                select.empty().append(new Option('', ''));
                select.each((index, element) => {
                    let selectElement = $(element);
                    let assetType = selectElement.data('asset-type');
                    if (assets[assetType]) {
                        for (let id in assets[assetType]) {
                            if (assets[assetType].hasOwnProperty(id)) {
                                selectElement.append(new Option(assets[assetType][id], id));
                            }
                        }
                    }
                    let effect = selectElement.data('effect');
                    if (effect) {
                        if (this.data.effects && this.data.effects[effect]) {
                            selectElement.val(this.data.effects[effect]);
                        }
                    } else if (selectElement.hasClass('fromSourceData')) {
                        let dataPoint = selectElement.attr('rv-value').split('.').pop();
                        if (this.data.source && this.data.source[dataPoint]) {
                            selectElement.val(this.data.source[dataPoint]);
                        }
                    }
                });
            }, this));
        }

        public hideMe() {
            super.hideMe();
            if (this.source) {
                this.hideMe();
            }
        }

        public saveData() {
            EventBus.dispatch(Story.SAVE_STORY);
        }
    }

}
