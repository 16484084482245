module Nickel {

    export class ActPicker extends Component {

        public static ACT_CLICKED:string = "actpicked";

        private btnDropdown:any;
        private actDropdown:any;
        private actData:any;

        constructor(content, data, delegate) {

            super(content, data, delegate);

            this.viewLoaded(Main.templates.find('.actPicker').clone());

            this.btnDropdown = this.content.find('.btnActDropdown').bind('click', $.proxy(this.dropdownClicked, this));
            this.actDropdown = this.content.find('.dropdown');

            this.content.on('click', '.dropdown .btnAct', $.proxy(this.actButtonClick, this));

        }

        /**
         * Toggles the visibility of the story dropdown.
         */
        private dropdownClicked():void {

            if (this.btnDropdown.hasClass('active')) {
                this.btnDropdown.removeClass('active');
                this.actDropdown.hide();
            } else {
                this.btnDropdown.addClass('active');
                this.actDropdown.show();
            }
        }

        /**
         * Populates the story dropdown for this view.
         */
        public populateActDropdown(storyData):void {

            this.actData = storyData.acts;
            this.clearActDropdown();

            var acts = storyData.acts;
            for (var key in acts) {

                if (acts.hasOwnProperty(key)) {

                    var act = acts[key];
                    var btn = $('<a class = "btnAct" data-id = "' + act.id + '">' + act.name + '</a>');
                    this.actDropdown.append(btn);
                }
            }
        }

        /**
         * Removes all of the items from the dropdown
         */
        public clearActDropdown():void {

            this.actDropdown.empty();
        }

        /**
         * Sets the story based off of whatever story button the user clicked in the story dropdown.
         */
        private actButtonClick(evt:Event):void {

            this.dropdownClicked();

            var id = $(evt.target).attr('data-id');
            var result = this.actData[id];
            if (result) {
                this.dispatch(ActPicker.ACT_CLICKED, result);
            }

        }
    }
}
