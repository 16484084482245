module Nickel {

    export class NodeTree extends Component {

        private treeData:any;
        private width:number;
        private height:number;
        private cluster:any;
        private diagonal:any;
        private svg:any;
        private svgGroup:any;
        private infoBox:any;
        private nodeData:any;
        private selected:boolean = false;
        private zoomListener:any;
        private duration:number = 750;
        private resizeTimeout:any;

        public static SELECT_NODE:string = "selectnode";

        private activeId:string;

        constructor(container, data, delegate) {

            super(container, data, delegate);

            this.treeData = data;

            EventBus.addEventListener(NodeTree.SELECT_NODE, $.proxy(this.selectNode, this), this);

            $(window).bind('resize', $.proxy(this.resize, this));

            this.init();
        }

        private resize() {

            clearTimeout(this.resizeTimeout);
            this.resizeTimeout = setTimeout(()=> {
                this.killMe();
                this.init();
                this.update(this.treeData);
            }, 100);
        }

        private selectNode(data) {

            this.activeId = data.id;

            var node = this.svg.selectAll(".node");
            var scope = this;
            //reset all nodes
            node.attr('class', 'node');

            this.svg.selectAll('.node')
                .each(function (d) {
                    //add the selected class to the one node with the correct ID
                    if (d.id == data.id) {
                        var selectedNode = d3.select(this);

                        // scope.centerNode(d);
                        var g = $(selectedNode[0][0]).context;
                        $(g).attr('class', 'node selected');
                    }
                });
        }


        public setData(data) {
            this.treeData = data;
            this.update(this.treeData);

            if (Main.activeNode) {
                EventBus.dispatch(Level.SHOW_DETAILS, {id: Main.activeNode});
            }
        }

        private init() {

            var pad = 200;
            this.width = this.container.width();
            this.height = this.container.height() - 100;
            // this.infoBox = new Nickel.InfoBox($('#storyView'), {}, this);
            this.zoomListener = d3.behavior.zoom().scaleExtent([0.5, 2])
            // this.zoomListener = d3.behavior.zoom().scaleExtent([0.5, 2]).on("zoom", $.proxy(this.zoom, this));

            this.cluster = d3.layout.tree()
                .size([this.height - pad, this.width - pad]);

            this.diagonal = d3.svg.diagonal()
                .projection(function (d) { return [d.y, d.x]; });

            this.svg = d3.select(this.container[0]).append("svg")
                .attr("width", this.width)
                .attr("height", this.height)
                .call(this.zoomListener)
            //.attr("transform", "translate(40,0)");

            this.svgGroup = this.svg.append("g")
            this.svgGroup.attr('transform', 'translate(100, 100)');
        }

        public killMe() {

            super.killMe();

            d3.select(".nodeHolder svg")
                .remove();
            // this.container.empty();
        }

        private getColorFromType(type) {
            var color;
            switch (type) {
                case "story":
                    color = "#b35d2a";
                    break;
                case "act":
                    color = "#e4c038";
                    break;
                case "scene":
                    color = "#6f0808";
                    break;
                case "cut":
                    color = "#54846a";
                    break;
                case "overlay":
                    color = "#2ab3ab";
                    break;
                case "inventory":
                    color = "#75369c";
                    break;
            }
            return color;
        }

        private update(source) {

            var scope = this;
            var duration = 750;
            var nodes = this.cluster.nodes(this.treeData),
                links = this.cluster.links(nodes);

            var link = this.svgGroup.selectAll(".link")
                .data(links)
                .enter().append("path")
                .attr("class", "link")
                .attr("d", this.diagonal);

            var node = this.svgGroup.selectAll(".node")
                .data(nodes)
                .enter().append("g")
                .attr("class", "node")
                .attr("transform", function (d) { return "translate(" + d.y + "," + d.x + ")"; })
                .each(function (d) {
                    var currentNode = d3.select(this)

                    d.cls = 'item-' + d.type;

                    var template = new Nickel.Component(null, d, this);
                    template.viewLoaded(Main.templates.find('.node_item').clone());
                    if ("enabled" in d) {
                        if (d.enabled == false) {
                            template.content.addClass('disabled');
                        }
                    }
                    var markup = template.content[0].outerHTML;
                    currentNode.append("foreignObject")
                        .attr("x", -60)
                        .attr("y", -20)
                        .attr("width", 120)
                        .attr("height", 40)
                        .append("xhtml:body")
                        .html(markup)
                    // .style("font", "14px 'Helvetica Neue'")

                });

            node.on('click', function (d, i) {
                EventBus.dispatch(Level.SHOW_DETAILS, {id: d.id});

            });

            // On node hover, examine the links to see if their
            // source or target properties match the hovered node.
            node.on('mouseover', function (d) {
                link.style('stroke-width', function (l) {
                    if (d === l.source || d === l.target) {
                        return 4;
                    } else {
                        return 2;
                    }
                });

                var selectedNode = d3.select(this);
                scope.nodeData = d;
                //scope.findEmptySpace(selectedNode);

            });

            // Set the stroke width back to normal when mouse leaves the node.
            node.on('mouseout', function () {
                // scope.infoBox.hideMe();
                link.style('stroke-width', 2);

            });

            this.svg.selectAll(".node")
                .each(function (d) {
                    var currentNode = d3.select(this);
                    //var offset = -40;
                    //if(currentNode.data()[0].type == 'overlay' || currentNode.data()[0].type == 'inventory'){
                    //    offset = -28;
                    //}
                    var offset = -28;

                    // currentNode

                    // currentNode.append('text')
                    //     .attr("dy", offset)
                    //     .attr('fill', "#ccc")
                    //     .attr('font-family', "Arial, helvetica, sans-serif")
                    //     .attr('font-size', "14px")
                    //     .text(function(d) { return d.name; });

                    // var textWidth = $(currentNode[0][0]).find('text').width();
                    // $(currentNode[0][0]).find('text').attr('dx', -(textWidth/2));
                })

            this.selectNode({"id": this.activeId});

        }

        private zoom() {
            this.svgGroup.attr("transform", "translate(" + d3.event.translate + ")scale(" + d3.event.scale + ")");
        }

        private centerNode(source) {

            var scale = this.zoomListener.scale();
            var x = -source.y;
            var y = -source.x;
            x = x * scale + this.width / 2;
            y = y * scale + this.height / 2;

            this.svgGroup.attr("transform", "translate(" + x + "," + y + ")scale(" + scale + ")");

            //TODO: animate this fucker! GW            
            //this.svgGroup.transition()
            //.duration(this.duration)
            //.attr("transform", "translate(" + x + "," + y + ")scale(" + scale + ")");
            this.zoomListener.scale(scale);
            this.zoomListener.translate([x, y]);
        }

        //Look to see if there is space free around the node
        public findEmptySpace(selectedNode) {
            var offset = 155;
            var emptySpaces = {
                'top': true,
                'bottom': true,
                'left': true,
                'right': true
            }
            var selectedNodePosition = $(selectedNode[0][0]).position();
            var node = this.svg.selectAll(".node");

            for (var i = 0; i < node[0].length; i++) {
                var compareNode = $(node[0][i]).position();
                if (compareNode.top == selectedNodePosition.top) {
                    if (compareNode.left > selectedNodePosition.left && selectedNodePosition.left + offset > compareNode.left) {
                        emptySpaces.right = false;
                    }
                    if (compareNode.left < selectedNodePosition.left && selectedNodePosition.left - offset < compareNode.left) {
                        emptySpaces.left = false;
                    }
                }

                if (compareNode.left == selectedNodePosition.left) {
                    if (compareNode.top > selectedNodePosition.top && selectedNodePosition.top + offset > compareNode.top) {
                        emptySpaces.bottom = false;
                    }
                    if (compareNode.top < selectedNodePosition.top && selectedNodePosition.top - offset < compareNode.top) {
                        emptySpaces.top = false;
                    }
                }

                if (!emptySpaces.right && !emptySpaces.left && !emptySpaces.bottom && !emptySpaces.top) {
                    emptySpaces.top = true;
                    break;
                }
            }

            this.setPositionOfInfoBox(emptySpaces, selectedNodePosition);
        }

        //Set the position of the Infobox off the spaces available
        public setPositionOfInfoBox(emptySpaces, selectedNodePosition) {
            var offset = {
                'right': 105,
                'left': 215,
                'top': 105,
                'bottom': 65
            };
            var nodeHolder = $('.nodeHolder');
            var popUpBox = $('.infoBox');
            var nPosition = {'top': selectedNodePosition.top, 'left': selectedNodePosition.left};

            if (emptySpaces.top && nPosition.top - popUpBox.height() > nodeHolder.position().top) {
                nPosition.top = nPosition.top - offset.top;
            } else {
                if (emptySpaces.right) {
                    nPosition.left = nPosition.left + offset.right;
                } else {
                    if (emptySpaces.left) {
                        nPosition.left = nPosition.left - offset.left;
                    } else {
                        if (emptySpaces.bottom) {
                            nPosition.top = nPosition.top + offset.bottom;
                        }
                    }
                }
            }

            this.showInfoBox(nPosition)
        }

        public toggleChildren(d) {
            if (d.children) {
                d._children = d.children;
                d.children = null;
            } else {
                if (d._children) {
                    d.children = d._children;
                    d._children = null;
                }
            }
            return d;
        }

        // Toggle children on click.

        public click(d) {
            if (d3.event.defaultPrevented) {
                return;
            } // click suppressed
            d = this.toggleChildren(d);
            this.update(d);
        }


        public collapse(d) {
            if (d.children) {
                d._children = d.children;
                d._children.forEach(this.collapse);
                d.children = null;
            }
        }


        public showInfoBox(position) {
            this.infoBox.updateMe(this.nodeData);
            this.infoBox.setPosition(position);
            this.infoBox.showMe();
        }
    }
}
