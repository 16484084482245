class S3FilePicker {

    public callback:any = null;
    public bucketUrl = null;
    public s3Excludes = /(\.DS_STORE)$/i;
    public container:any;
    public currentFolder:any;
    private static instance:S3FilePicker = null;

    constructor(container, bucketUrl = null) {
        this.container = container;
        this.container.on('click', '.s3Link', $.proxy(this.handleS3LinkClick, this));
        this.container.on('click', '.backBtn', $.proxy(this.handleBackClick, this));
        this.container.on('click', '.okBtn', $.proxy(this.handleOkClick, this));

        if (bucketUrl != null) {
            this.bucketUrl = bucketUrl;
        } else {
            this.bucketUrl = "https://" + Main.s3ResourcesBucket + ".s3.amazonaws.com/";
        }
    }

    public open(currentValue = "", callback = null) {
        this.getS3Data(false, null, null);
        this.container.modal('show');
        this.container.find('textarea.s3Preview').val(currentValue);

        if (callback) {
            this.callback = callback;
        } else {
            this.callback = null;
        }
    }

    public handleOkClick(e) {
        this.container.modal('hide');
        if (this.callback) {
            this.callback(this.container.find('textarea.s3Preview').val());
        }
    }

    public handleBackClick(e) {
        this.container.find('.s3Link').each($.proxy(function (i, elem) {
            if ($(elem).hasClass('undefined')) {
                $(elem).click();
                return false;
            }
            return true;
        }, this));
    }

    public handleS3LinkClick(e) {
        e.preventDefault();
        var elem = $(e.target);
        var href = elem.attr('href');
        if (elem.hasClass('directory')) {
            this.getS3Data(null, href, null);
        } else {
            if (href.indexOf('https') > -1) {
                var fileKey:string = decodeURIComponent(href.replace(this.bucketUrl, ""));
                if (fileKey.indexOf('.jpg') > -1 || fileKey.indexOf('.png') > -1) {
                    fileKey = fileKey.replace(/(.*)([^0-9])([0-9]*\.)(jpg|png)/i, "$1$2%d.$4");
                }
                this.container.find('textarea.s3Preview').val(fileKey);
            } else {
                this.getS3Data(null, href, null);
            }
        }
    }

    public static getInstance() {
        if (!S3FilePicker.instance) {
            S3FilePicker.instance = new S3FilePicker(Main.templates.find('.s3-file-picker').clone());
        }
        return S3FilePicker.instance;
    }

    public getS3Data(marker, prefix, html) {
        var s3_rest_url = this.createS3QueryUrl(marker, prefix);
        // set loading notice
        $('.s3-list').html('<img src="/img/ajax-loader-circle.gif" />');
        $.get(s3_rest_url)
            .done($.proxy(function (data) {
                // clear loading notice
                $('.s3-list').html('');
                var xml = $(data);
                var info = this.getInfoFromS3Data(xml);
                this.currentFolder = info.prefix.replace('&prefix=', '');
                html = (typeof html !== 'undefined' && html != null) ? html + this.prepareTable(info) : this.prepareTable(info);
                if (info.nextMarker != "null") {
                    this.getS3Data(info.nextMarker, html);
                } else {
                    //this.container.innerHTML = '<pre>' + html + '</pre>';
                    this.container.find('.s3-list')[0].innerHTML = '<pre>' + html + '</pre>';
                }
            }, this))
            .fail(function (error) {
                console.error(error);
                $('.s3-list').html('<strong>Error: ' + error + '</strong>');
            });
    }

    public createS3QueryUrl(marker, prefix:any = null) {
        var s3_rest_url:any;
        if (typeof this.bucketUrl != 'undefined') {
            s3_rest_url = this.bucketUrl;
        } else {
            s3_rest_url = location.protocol + '//' + location.hostname;
        }

        s3_rest_url += '?delimiter=/';

        if (prefix) {
            // make sure we end in /
            prefix = prefix.replace(/\/$/, '') + '/';
            prefix = (prefix.indexOf("?prefix=") > -1 ? prefix.substr(8) : this.currentFolder + prefix);
            prefix = (prefix == "/") ? "" : prefix;
            s3_rest_url += '&prefix=' + prefix;
        }
        if (marker) {
            s3_rest_url += '&marker=' + marker;
        }
        return s3_rest_url;
    }

    public getInfoFromS3Data(xml) {
        var files = $.map(xml.find('Contents'), function (item) {
            item = $(item);
            return {
                Key: item.find('Key').text(),
                LastModified: item.find('LastModified').text(),
                Size: item.find('Size').text(),
                Type: 'file'
            }
        });
        var directories = $.map(xml.find('CommonPrefixes'), function (item) {
            item = $(item);
            return {
                Key: item.find('Prefix').text(),
                LastModified: '',
                Size: '0',
                Type: 'directory'
            }
        });
        var nextMarker:any;
        if ($(xml.find('IsTruncated')[0]).text() == 'true') {
            nextMarker = $(xml.find('NextMarker')[0]).text();
        } else {
            nextMarker = null;
        }
        return {
            files: files,
            directories: directories,
            prefix: /*(this.currentFolder) ? this.currentFolder + $(xml.find('Prefix')[0]).text() :*/ $(xml.find('Prefix')[0]).text(),
            nextMarker: encodeURIComponent(nextMarker)
        }
    }

    // info is object like:
    // {
    //    files: ..
    //    directories: ..
    //    prefix: ...
    // }
    public prepareTable(info) {
        var files = info.files.concat(info.directories)
            , prefix = info.prefix;
        var cols = [25, 25, 15];
        var content = [];
        content.push(this.padRight('Last Modified', cols[1]) + '  ' + this.padRight('Size', cols[2]) + 'Key \n');
        content.push(new Array(cols[0] + cols[1] + cols[2] + 4).join('-') + '\n');

        // add the ../ at the start of the directory listing
        if (prefix) {
            var up = prefix.replace(/\/$/, '').split('/').slice(0, -1).concat('').join('/'), // one directory up
                item = {
                    Key: (up == null) ? "" : up,
                    LastModified: '',
                    Size: '',
                    keyText: '../',
                    href: '?prefix=' + up
                },
                row = this.renderRow(item, cols);
            content.push(row + '\n');
        }

        jQuery.each(files, $.proxy(function (idx, item) {
            // strip off the prefix
            item.keyText = item.Key.substring(prefix.length);

            // some tools create directory keys like dir/ which show as empty names
            if (item.keyText == "") {
                return;
            }
            // Skip any excluded paths
            if (typeof this.s3Excludes != 'undefined' && this.s3Excludes != null && this.s3Excludes.test(item.keyText)) {
                return;
            }

            if (item.Type === 'directory') {
                item.href = item.keyText;
            } else {
                item.href = this.bucketUrl + encodeURIComponent(item.Key);
            }
            var row = this.renderRow(item, cols);
            content.push(row + '\n');
        }, this));

        return content.join('');
    }

    public renderRow(item, cols) {
        var row = '';
        row += this.padRight(item.LastModified, cols[1]) + '  ';
        row += this.padRight(item.Size, cols[2]);
        row += '<a href="' + item.href + '" class="s3Link ' + item.Type + '">' + item.keyText + '</a>';
        return row;
    }

    public padRight(padString, length) {
        var str = padString.slice(0, length - 3);
        if (padString.length > str.length) {
            str += '...';
        }
        while (str.length < length) {
            str = str + ' ';
        }
        return str;
    }

    /**
     * Creates an S3 bucket if it does not already exist.
     * @param bucket The name of the bucket.
     * @param region The region of the bucket.
     * @param createDistribution Whether to associate a CloudFront distribution with the new bucket.
     * @param readOnly Whether to allow writing/deleting to the bucket.
     * @param callback Function to call when request is complete (such as hiding a loader)
     */
    public static createS3Bucket(bucket, region, createDistribution, readOnly, callback): void {

        EventBus.dispatch(Nickel.Debug.LOG, 'Create Bucket');

        Ajax.post(new JWTAjaxRequest('/bucket/' + encodeURIComponent(bucket), {
            'region': region,
            'create_distribution': (createDistribution) ? 1 : 0,
            'read_only': (readOnly) ? 1 : 0,
        }, function (response) {
            if (callback) {
                callback(response);
            }
            if (response.created) {
                // Created
                EventBus.dispatch(Nickel.Debug.SUCCESS, 'New S3 Bucket "' + response.bucket + '" Created Successfully');
            } else {
                // Already existed
                EventBus.dispatch(Nickel.Debug.SUCCESS, 'S3 Bucket "' + response.bucket + '" Already Exists');
            }
            if (response.created_distribution) {
                EventBus.dispatch(Nickel.Debug.SUCCESS, 'New CloudFront Distribution "' + response.distribution + '" For Bucket "' + response.bucket + '" Created Successfully');
            }
        }, function (xhr) {
            let response = JSON.parse(xhr.jqXHR.responseText);
            if (callback) {
                callback(response);
            }
            let error = (response && response.error) ? response.error : 'Could not create S3 bucket';
            EventBus.dispatch(Nickel.Debug.ERROR, {
                text: 'S3 Bucket Create Error',
                error: {status: "Failure: ", statusText: error}
            });
        }));
    }

    public static bucketExists(bucket, callback, errorCallback) {
        EventBus.dispatch(Nickel.Debug.LOG, 'Check Bucket');

        Ajax.get(new JWTAjaxRequest('/bucket/' + encodeURIComponent(bucket), null, function (response) {
            if (callback) {
                callback(response.exists);
            }
        }, function (xhr) {
            let response = JSON.parse(xhr.jqXHR.responseText);
            let error = (response && response.error) ? response.error : 'Could not check if S3 bucket exists';
            EventBus.dispatch(Nickel.Debug.ERROR, {
                text: 'S3 Bucket Check Error',
                error: {status: "Message: ", statusText: error}
            });
            if (errorCallback) {
                errorCallback();
            }
        }));
    }
}
