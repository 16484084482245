module Nickel {

    export class ApiScene01VO extends SceneVO {

        public type:any = "ApiScene01";
        public sceneData:any = {
            url: '', // The URL to make a request to
            requestType: 'get', // How to send the request (example: get, post, json)
            setupScript: '', // Optional pre-processing script
            videoItem: '', // Scene ID with the video to attach
            headers: [],
            additionalParams: [], // A list of additional parameters to send
        };
    }

    export class ApiScene01 extends Scene {

        public label:string = "API Request Scene";

        constructor(container, data, index, delegate) {

            super(container, data, index, delegate);

            if (data) {
                this.data = data;
            } else {
                this.data = new ApiScene01VO();
            }

            this.viewLoaded(Main.templates.find('.apiscene01').clone());
        }

        public viewLoaded(v) {

            super.viewLoaded(v);

            this.content.find('.btnSelectSetupScript').bind('click', $.proxy(this.selectSetupScriptClicked, this));
            this.content.find('.btnAddParam').bind('click', $.proxy(this.addParameter, this));
            this.content.find('.btnAddHeader').bind('click', $.proxy(this.addHeader, this));
            this.updateParameters();
            this.updateHeaders();
        }

        /**
         * Adds an empty / blank parameter to the parameter list.
         */
        public addParameter() {
            this.data.sceneData.additionalParams.push({key: '', value: ''});
            this.populateInventorySelects();
            this.updateParameters();
        }

        /**
         * Adds an empty / blank header to the header list.
         */
        public addHeader() {
            this.data.sceneData.headers.push({key: '', value: ''});
            this.updateHeaders();
        }

        /**
         * Removes a parameter from the parameter list.
         * @param e The click event.
         */
        public removeParameter(e) {
            this.data.sceneData.additionalParams.splice($(e.currentTarget).parent().parent().index(), 1);
            this.updateParameters();
        }

        /**
         * Removes a header from the header list.
         * @param e The click event.
         */
        public removeHeader(e) {
            this.data.sceneData.headers.splice($(e.currentTarget).parent().parent().index(), 1);
            this.updateHeaders();
        }

        /**
         * Called every time the parameter list is updated.
         */
        private updateParameters() {

            // refresh delete click event so deleted entries are unbound and new entries are bound
            this.content.find('.btnRemoveParam').unbind('click').bind('click', $.proxy(this.removeParameter, this));
        }

        /**
         * Called every time the header list is updated.
         */
        private updateHeaders() {

            // refresh delete click event so deleted entries are unbound and new entries are bound
            this.content.find('.btnRemoveHeader').unbind('click').bind('click', $.proxy(this.removeHeader, this));
        }

        /**
         * Called when the Select Script button is pressed.
         * @param e The click event.
         */
        public selectSetupScriptClicked(e) {
            S3FilePicker.getInstance().open($(e.target).parent().parent().find('input').val(), $.proxy(function (fileKey) {
                this.data.sceneData.setupScript = fileKey.trim();
                this.saveData();
            }, this));
        }

        /**
         * Populate the "Attach Video" select field with the latest list of video scenes on the act.
         */
        public populateSceneSelect() {
            var select = this.content.find('select.sceneItems');
            select.empty().append(new Option('', ''));

            for (var sceneId in this.delegate.data.scenes) {
                if (this.delegate.data.scenes.hasOwnProperty(sceneId)) {
                    var scene = this.delegate.data.scenes[sceneId];
                    if (scene.type.indexOf('Video') != -1) {
                        select.append(new Option(sceneId, sceneId));
                    }
                }
            }

            this.content.find('select.sceneItems.video').val(this.data.sceneData.videoItem);
        }

        /**
         * Populate the "Parameters" select fields with the latest list of text-based inventory items on the act.
         */
        public populateInventorySelects() {
            var select = this.content.find('.params .param select');
            select.empty().append(new Option('', ''));

            select.append(new Option('Experience ID', 'experience_id'));
            select.append(new Option('Story ID', 'story_id'));
            select.append(new Option('Story Image URL', 'story_image_url'));
            select.append(new Option('Act ID', 'act_id'));
            select.append(new Option('Scene ID', 'scene_id'));
            select.append(new Option('Scene Duration', 'scene_duration'));

            for (var sceneId in this.delegate.data.scenes) {
                if (this.delegate.data.scenes.hasOwnProperty(sceneId)) {
                    var scene = this.delegate.data.scenes[sceneId];
                    if (scene.id === this.data.sceneData.videoItem) {
                        var increments = [];
                        var formatNames = [];
                        var formatKeys = [];
                        for (var j = 0; j < scene.sceneData.encodingSettings.length; j++) {
                            var encodingSettings = scene.sceneData.encodingSettings[j];
                            if (encodingSettings.extension && encodingSettings.height) {
                                var formatName = encodingSettings.name;
                                var formatKey = encodingSettings.extension + '_' + encodingSettings.height;

                                if (typeof increments[formatKey] === 'undefined') {
                                    increments[formatKey] = 0;
                                }
                                increments[formatKey]++;

                                formatNames.push(formatName);

                                if (formatKeys.indexOf(formatKey) <= -1) {
                                    formatKeys.push(formatKey);
                                } else {
                                    formatKeys.push(formatKey + '_' + increments[formatKey]);
                                }
                            }
                        }

                        for(var k = 0; k < formatKeys.length; k++) {
                            select.append(new Option(formatNames[k], formatKeys[k]));
                        }

                        select.append(new Option('M3U8', 'm3u8'));
                    }
                }
            }

            for (var invId in this.delegate.data.inventory) {
                if (this.delegate.data.inventory.hasOwnProperty(invId)) {
                    var invItem = this.delegate.data.inventory[invId];
                    select.append(new Option(invItem.label || invItem.name, invId));
                }
            }

            select.each((index, element)=> {
                $(element).val(this.data.sceneData.additionalParams[index].value);
            });
        }

        public showMe() {
            super.showMe();

            this.populateSceneSelect();
            this.populateInventorySelects();
        }
    }
}
