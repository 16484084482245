module Nickel {

    export class BatchColumn extends Component {

        private columnIndex: number;

        /**
         * Stores the global vars, loads the template, populates the dynamic buttons, and binds the event listeners.
         * @param container A jQuery object containing the parent div for this view.
         * @param index     The column index (used for identification).
         * @param data      The JSON data unique to this component.
         * @param delegate  The Class that created this instance.
         */
        constructor(container: JQuery, index: number, data: any, delegate: DataView) {

            super(container, data, delegate);

            this.viewLoaded(Main.templates.find('.batchColumn').clone());
            this.bindEvents();

            this.content.attr('name', this.data.name);
            this.columnIndex = index;
        }

        /**
         * Gets column index (read only property)
         */
        public getColumnIndex(): number {
            return this.columnIndex;
        }

        /**
         * Binds all of the event listeners for this view.
         */
        public bindEvents(): void {

            this.content.find('.btnFilter').bind('click', $.proxy(this.filterClicked, this));
            this.content.find('.btnClear').bind('click', $.proxy(this.clearClicked, this));
            this.content.find('.searchInput').bind('keyup', $.proxy(this.searchKeyup, this));
        }

        /**
         * Unbinds all of the event listeners for this view.
         */
        public unbindEvents(): void {

            this.content.find('.btnFilter').unbind('click', $.proxy(this.filterClicked, this));
            this.content.find('.btnClear').unbind('click', $.proxy(this.clearClicked, this));
            this.content.find('.searchInput').unbind('keyup', $.proxy(this.searchKeyup, this));
        }

        /**
         * Called if a user clicks on the filter icon on a filterable field.
         */
        private filterClicked(): void {
            this.content.addClass('search').find('input[type=text]').focus();
        }

        /**
         * Called if the user clicks on the close filter button. Clears the filters and gets the new experiences.
         */
        private clearClicked(): void {
            this.content.find('.searchInput').val('');
            this.content.removeClass('search');
            this.reloadResults();
        }

        /**
         * Called if the user hits enter after entering a serch filter.
         * e    The event passed in from the keypress.
         */
        private searchKeyup(e: KeyboardEvent) {
            if (e.keyCode === 13) {
                this.reloadResults();
            }
        }

        /**
         * Reloads the results given new column filter configuration.
         */
        private reloadResults() {
            this.delegate.page = 1;
            this.delegate.reloadPaginatedData();
        }

        /**
         * Get an object representing this column's filter. Key is column index, value is the search string.
         */
        public getFilter() {
            return {
                'column_index': this.columnIndex,
                'value': this.content.find('.searchInput').val().trim()
            };
        }

        /**
         * Whether or not we should search by this column.
         */
        public hasFilter() {
            return this.content.hasClass('search');
        }

        /**
         * Completely removes the view from the DOM, clears all event listeners and kills rivets.
         */
        public killMe(): void {

            clearTimeout(this.displayTimeout);

            this.unbindEvents();
            this.rivets.unbind();
            this.content.remove();
            this.content = null;
        }
    }
}
