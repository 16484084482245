module Nickel {

    /**
     * Value Object for defining the structure of the top level Story JSON data.
     */
    export class StoryVO extends VO {

        public label:any = "New Story";
        public name:any = "New Story";
        public status:any = "active";
        public moderationType:string = "post";
        public moderationApprovedScene:string = "";
        public moderationRejectedScene:string = "";
        public metadata:any = {};
        public description:any = "";
        public acts:any = {};
        public displayImage:any = null;
        public displayVideoUrls:any = {};
        public disabled:boolean = false;
        public discardUgc:boolean = false;
        public useAsTemplate:boolean = false;
        public gaTrackingId:string = "";
        public resourcesDataImage:string = "";
        public jobAllocationType:string = "";
    }

    /**
     * Top Level Node, containing all global information for the story.
     */
    export class Story extends Level {

        /**
         * The max character length of a story's JSON data that can be saved to the database.
         * @type {number}
         */
        private maxJsonLength:number = 16777215;

        /**
         * An object containing all of the Act child classes. Stored as key/value pairs indexed by the Acts ID.
         */
        public children:any = {};

        /**
         * The JSON data from the DB associated with this Story.
         */
        public data:StoryVO;

        /**
         * The bucket on the s3 that this story will push to.
         */
        public uploadsBucket:string;

        /**
         * The bucket on the s3 that the user's rendered videos will push to.
         */
        public renderedBucket:string;

        /**
         * The bucket on the s3 that the user's resources are on.
         */
        public resourcesBucket:string;

        /**
         * The whitelisted URL's for this story
         */
        public whitelistedUrls:string = "*";

        /**
         * If true, will add the story to the Database on save, if false, will just update an existing story in the
         * Database.
         */
        public firstSave:boolean;

        /**
         * The Label for this kind of node to display in the CMS.
         */
        public label:string = "Story";

        /**
         * If true, show the video player in the right interface when this view is visible.
         */
        public videoPlayer:boolean = false;

        /**
         * A jQuery sortable(); list, that the user can interact with to re-order the acts.
         */
        private actOrderlist:any;

        /**
         * Event for telling this story to send its data to the Database.
         */
        public static SAVE_STORY:string = "savestory";

        /**
         * Event for telling this story that its data has been successfully written to the Database.
         */
        public static SAVED_STORY:string = "savedstory";

        /**
         * The Uploads s3 bucket to use if none is set.
         */
        public static DEFAULT_UPLOADS_BUCKET = "imposium-dev-uploads";

        /**
         * The Rendered s3 bucket to use if none is set.
         */
        public static DEFAULT_RENDERED_BUCKET = "imposium-dev-renders";

        /**
         * The Resources s3 bucket to use if none is set.
         */
        public static DEFAULT_RESOURCES_BUCKET = "imposium-resources";

        /**
         * Stores the global vars, loads this Node's DOM view, and creates an new Value Object if needed.
         * @param container        A jQuery object containing the parent div for this view.
         * @param data          The JSON data unique to this node.
         * @param index        The index of this node in the delegate's child array (only applicable if stored in an
         *     array and not an object).
         * @param delegate        The Class that created this instance.
         */
        constructor(container, data, index, delegate) {

            super(container, data, index, delegate);

            if (data) {
                this.data = data;
            } else {
                this.data = new StoryVO();
                this.firstSave = true;
            }

            //if there is no moderationType set, set as "post"
            if (!this.data.moderationType) {
                this.data.moderationType = "post";
            }

            //set the uploads and resources as globals and remove them from the data to prevent it being saved back
            // into the db.
            if (this.data["resourcesBucket"]) {
                this.resourcesBucket = this.data["resourcesBucket"];
            } else {
                this.resourcesBucket = Story.DEFAULT_RESOURCES_BUCKET;
            }
            if (this.data["uploadsBucket"]) {
                this.uploadsBucket = this.data["uploadsBucket"];
            } else {
                this.uploadsBucket = Story.DEFAULT_UPLOADS_BUCKET;
            }
            if (this.data["renderedBucket"]) {
                this.renderedBucket = this.data["renderedBucket"];
            } else {
                this.renderedBucket = Story.DEFAULT_RENDERED_BUCKET;
            }
            if (this.data["whitelistedUrls"]) {
                this.whitelistedUrls = this.data["whitelistedUrls"];
            }
            delete this.data["whitelistedUrls"];
            delete this.data["resourcesBucket"];
            delete this.data["uploadsBucket"];
            delete this.data["renderedBucket"];

            this.viewLoaded(Main.templates.find(".story").clone());
        }

        /**
         * Binds this.data to the view using rivets. Creates all of the child Acts.
         * @param v        A jQuery object to act as this node's view and to bind its data to.
         */
        public viewLoaded(v:JQuery):void {

            super.viewLoaded(v);

            //loop through and create the acts
            var holder = [];
            for (var key in this.data.acts) {
                holder.push($.extend({}, this.data.acts[key]));
            }

            //order the data objects based off of order
            holder.sort(function (a, b) {
                return a.order - b.order;
            });

            //add the children
            for (var i = 0; i < holder.length; i++) {
                this.addChild(this.data.acts[holder[i].id], i);
            }

            let targ = (this.data.moderationType == 'post') ? 'middle' : (this.data.moderationType == 'bypass') ? 'right' : 'left';
            this.content.find('.toggle .' + targ).addClass('selected');
            this.handleModerationSceneDisplay();

            //Bind all of the event listeners for this view
            this.bindEvents();
        }

        /**
         * Binds all of the event listeners for this Class.
         */
        private bindEvents():void {

            this.content.find(".btnCreateUploadsBucket").bind('click', $.proxy(this.createUploadsBucket, this));
            this.content.find(".btnCreateRenderedBucket").bind('click', $.proxy(this.createRenderedBucket, this));
            this.content.find(".btnCreateResourcesBucket").bind('click', $.proxy(this.createResourcesBucket, this));
            this.content.find(".btnAdd").bind('click', $.proxy(this.addActBtnClicked, this));
            this.content.find(".btnExportStory").bind('click', $.proxy(this.exportMe, this));
            this.content.find('.upload-story-image').bind('change', $.proxy(this.uploadImage, this));
            this.content.find('.btnUploadStoryImage').bind('click', $.proxy(this.uploadImageClicked, this));
            this.content.find('.toggle .option').bind('click', $.proxy(this.moderationToggleClicked, this));

            this.actOrderlist = this.content.find(".actOrderList").sortable({
                "stop": $.proxy(this.actOrderListChange, this)
            });

            EventBus.addEventListener(Story.SAVE_STORY, $.proxy(this.saveData, this), this);
        }

        /**
         * Binds all of the event listeners for this Class.
         */
        private unbindEvents():void {

            this.content.find(".btnCreateUploadsBucket").unbind('click', $.proxy(this.createUploadsBucket, this));
            this.content.find(".btnCreateRenderedBucket").unbind('click', $.proxy(this.createRenderedBucket, this));
            this.content.find(".btnCreateResourcesBucket").unbind('click', $.proxy(this.createResourcesBucket, this));
            this.content.find(".btnAdd").unbind('click', $.proxy(this.addActBtnClicked, this));
            this.content.find(".btnExportStory").unbind('click', $.proxy(this.exportMe, this));
            this.content.find('.upload-story-image').unbind('change', $.proxy(this.uploadImage, this));
            this.content.find('.btnUploadStoryImage').unbind('click', $.proxy(this.uploadImageClicked, this));
            this.content.find('.toggle .option').unbind('click', $.proxy(this.moderationToggleClicked, this));

            EventBus.removeEventListener(Story.SAVE_STORY, $.proxy(this.saveData, this), this);
        }

        /**
         * Create an S3 bucket for use on the story if it doesn't already exist.
         * @param e    Event passed in from the click.
         * @param createDistribution    Whether or not to create a CloudFront distribution.
         * @param readOnly    Whether or not to grant write permissions on the bucket.
         */
        private createBucket(e, createDistribution, readOnly) {
            var button = $(e.currentTarget);
            var bucketInput = button.parent().find('input');
            var bucketName = bucketInput.val().toLowerCase().trim();
            bucketInput.val(bucketName); // force lowercase or else bucket won't be created successfully

            if (bucketName == '') {
                EventBus.dispatch(Nickel.Debug.ERROR, {
                    text: 'S3 Bucket Check Error',
                    error: {status: "Message: ", statusText: 'Bucket Name Cannot Be Empty'}
                });
                return;
            }

            var spinner = Ladda.create(button[0]);
            spinner.start();

            S3FilePicker.bucketExists(bucketName, function (exists) {
                if (!exists) {
                    CloudRegionProvider.getRegions(function (regions) {
                        let inputOptions = [];
                        for (let i = 0; i < regions.length; i++) {
                            inputOptions.push({'text': regions[i], 'value': regions[i]});
                        }
                        bootbox.prompt({
                            title: 'S3 Bucket "' + bucketName + '" does not exist. Create it?',
                            inputType: 'select',
                            inputOptions: inputOptions,
                            container: '#main-container',
                            callback: function (region) {
                                if (region) {
                                    S3FilePicker.createS3Bucket(bucketName, region, createDistribution, readOnly, function () {
                                        spinner.stop();
                                    });
                                } else {
                                    EventBus.dispatch(Nickel.Debug.SUCCESS, 'S3 Bucket "' + bucketName + '" Creation Cancelled');
                                    spinner.stop();
                                }
                            }
                        });
                    });
                } else {
                    EventBus.dispatch(Nickel.Debug.SUCCESS, 'S3 Bucket "' + bucketName + '" Exists');
                    spinner.stop();
                }
            }, function () {
                spinner.stop();
            });
        }

        /**
         * Create an S3 bucket to use for the "uploads" bucket.
         * @param e    Event passed in from the click.
         */
        private createUploadsBucket(e) {
            this.createBucket(e, false, false);
        }

        /**
         * Create an S3 bucket to use for the "rendered" bucket.
         * @param e    Event passed in from the click.
         */
        private createRenderedBucket(e) {
            this.createBucket(e, true, false);
        }

        /**
         * Create an S3 bucket to use for the "resources" bucket.
         * @param e    Event passed in from the click.
         */
        private createResourcesBucket(e) {
            this.createBucket(e, false, true);
        }

        /**
         * Toggles the active moderation type
         * @param e    Event passed in from the click.
         */
        private moderationToggleClicked(e) {

            var targ = $(e.currentTarget);
            targ.parent().find('.selected').removeClass('selected');
            targ.addClass('selected');
            this.data.moderationType = targ.attr('data-type');
            this.handleModerationSceneDisplay();
        }

        private handleModerationSceneDisplay() {
            if (this.data.moderationType == 'post' || this.data.moderationType == 'bypass') {
                this.content.find('.mod-callback-pre').hide();
            } else {
                this.content.find('.mod-callback-pre').show();
            }
        }

        /**
         * Exports this classes this.data obeject to a .json file that the user downloads.
         */
        public exportMe():void {

            //save the story
            this.saveData();

            var filename: string = this.data.name + '.json';
            Ajax.get(new JWTAjaxRequest('/story/' + this.data.id, null, function (storyData) {
                saveAs(new Blob([JSON.stringify(storyData)], {type: "text/plain;charset=utf-8"}), filename);
            }));
        }

        /**
         * Creates a FileReader instance to parse the incoming JSON file.
         * @param e    Event passed in from the click.
         */
        private getActData(e:any):void {
            if (e.target.files[0]) {
                var r = new FileReader();
                r.onload = $.proxy(this.saveImportedAct, this);
                r.readAsText(e.target.files[0]);
            } else {
                console.error("Failed to load file!");
            }
        }

        /**
         * Saves the act that has just been imported. Re-binds the import event listener.
         * @param e    Event passed in from the click.
         */
        private saveImportedAct(e:any) {

            // Need to replace and re-bind the change event to allow multiple imports!
            $("#actImportFile").replaceWith('<input id = "actImportFile" type="file" style="display:none;">');
            $("#actImportFile").bind('change', $.proxy(this.getActData, this));

            // Once we've reset for the next import, save the actual act data
            var act = this.addChild(JSON.parse(e.target.result), 0);
            act.saveData();
            this.showChild(act.data.id);
        }

        /**
         * Uploads an image to the backend using a hidden form.
         * @param e    Event passed in from the click.
         */
        private uploadImage(e:Event) {

            var form = $(e.target).parent().parent();
            form.attr('action', AjaxUrlProvider.getLegacyApiBaseUrl() + '/api');

            // Get story ID so upload is sent to the right place
            var storyIdInput = form.find('input[name=story_id]');
            storyIdInput.val(this.data.id);

            Ajax.formSubmit(form, $.proxy(function (response) {

                this.data.displayImage = response.bind.info;
                this.saveData();

            }, this));
        }

        /**
         * Triggers a click on a hidden button to upload an image.
         * @param e    Event passed in from the click.
         */
        private uploadImageClicked(e:Event):void {
            e.preventDefault();
            this.content.find('.upload-story-image').trigger('click');
        }

        /**
         * Called when a user re-orders the act order list. Saves the new order to the Database.
         * @param e    Event passed in from the click.
         */
        private actOrderListChange(e:Event):void {

            //update the acts order and save the info to the database
            var _this = this;
            this.content.find('.actOrderList li').each(function (i) {

                var id = $(this).data('id');
                var act = _this.children[id];
                act.data.order = i + 1;
                act.saveData();
            });
        }

        /**
         * Saves this instance data to the Database.
         */
        public saveData():void {
            let params:any = this.data;
            params['resourcesBucket'] = this.resourcesBucket;
            params['renderedBucket'] = this.renderedBucket;
            params['uploadsBucket'] = this.uploadsBucket;
            params['whitelistedUrls'] = this.whitelistedUrls;

            let maxCharMsg:string = 'Story JSON character count exceeds max length of ' + this.maxJsonLength;
            if (JSON.stringify(params).length <= this.maxJsonLength) {
                let route: string = '/story';
                let method: string = 'POST';
                if (!this.firstSave) {
                    route = '/story/' + this.data.id;
                    method = 'PATCH';
                }
                Ajax.request(method, new JWTAjaxRequest(route, params, $.proxy(this.dataSaved, this), $.proxy(this.dataSaveFailed, this)));

                EventBus.dispatch(StoriesView.UPDATE_NODES);
                EventBus.dispatch(Debug.LOG, "Save Story");

                Main.saveState();
            } else {
                alert(maxCharMsg);
                console.error(maxCharMsg);
            }
        }

        /**
         * Called if this story's data saves successfully.
         */
        public dataSaved(response): void {
            if (response.id) {

                if (this.firstSave) {
                    this.firstSave = false;
                }
                EventBus.dispatch(Debug.SUCCESS, "Story Saved Successfully");
                EventBus.dispatch(StoryPicker.UPDATE_STORIES);
                EventBus.dispatch(Story.SAVED_STORY);

            } else {
                EventBus.dispatch(Debug.ERROR, {
                    text: 'Story Could Not Be Saved',
                    error: {status: "Message: ", statusText: 'Could Not Parse Response'}
                });
            }
        }

        /**
         * Called if this story's data does not save successfully.
         */
        public dataSaveFailed(e): void {
            let response: any = JSON.parse(e.jqXHR.responseText);
            if (response && response.error) {
                let error: string = response.error;
                let consoleError: string = 'An Error Occurred';

                // Provide additional info in console if there is talk about the S3 bucket being invalid.
                if (error.toLowerCase().indexOf('s3') != -1 && error.toLowerCase().indexOf('invalid') != -1) {
                    consoleError = 'S3 Bucket Was Invalid';
                    error = 'Please update the Uploads / Rendered Buckets on the story and hit the "Check" button to make sure the bucket specified is reachable by the system.';
                }

                alert(error);
                EventBus.dispatch(Debug.ERROR, {
                    text: 'Story Could Not Be Saved',
                    error: {status: "Message: ", statusText: consoleError}
                });
            } else {
                EventBus.dispatch(Debug.ERROR, {
                    text: 'Story Could Not Be Saved',
                    error: {status: "Message: ", statusText: 'Could Not Parse Error From Response'}
                });
            }
        }

        /**
         * Creates and returns a new Act class.
         * @param data        The data object to build the Act off of.
         * @param index    The index of the Act in the children array.
         * @returns        The new Act class it just created.
         */
        public addChild(data:any, index:number):Nickel.Act {

            var i = (index) ? index : this.children.length;
            var act = new Nickel.Act(this.content.find(".actHolder"), data, i, this);
            this.children[act.data.id] = act;

            return act;
        }

        /**
         * Receives click event from Add Act button.
         */
        public addActBtnClicked():void {
            this.addAct();
        }

        /**
         * Adds a new act to this Story's children object. Saves to the database. Shows the new Act.
         * @param data Initial data to initialize the act with. Set to <null> to use default value object.
         */
        private addAct(data:Act = null):void {
            var act = this.addChild(data, this.children.length);

            //TODO figure out why this is nessassary. Empty object from VO is being converted to []. GW
            if (this.data.acts instanceof Array) {
                this.data.acts = {};
            }

            this.data.acts[act.data.id] = act.data;

            this.saveData();
            this.showChild(act.data.id);
        }

        /**
         * Duplicates an existing act, changes all scene, cut and overlay IDs, shows that new act. Saves to the
         * Database.
         * @param act
         */
        public duplicateChildItem(act:Act):void {
            var data = $.extend(true, {}, act.data);
            data.id = Utils.generateUUID();

            var sceneIds = Object.keys(data.scenes);
            for (var i = 0; i < sceneIds.length; i++) {

                // change out scene ids
                var newId = Utils.generateUUID();
                data.scenes[newId] = data.scenes[sceneIds[i]];
                data.scenes[newId].id = newId;
                delete data.scenes[sceneIds[i]];

                // change out cut ids
                if (data.scenes[newId].sceneData.cuts && data.scenes[newId].sceneData.cuts.length > 0) {
                    for (var cutIndex in data.scenes[newId].sceneData.cuts) {
                        data.scenes[newId].sceneData.cuts[cutIndex].id = Utils.generateUUID();

                        // change out overlay ids
                        if (data.scenes[newId].sceneData.cuts[cutIndex].overlays && data.scenes[newId].sceneData.cuts[cutIndex].overlays.length > 0) {
                            for (var overlayIndex in data.scenes[newId].sceneData.cuts[cutIndex].overlays) {
                                data.scenes[newId].sceneData.cuts[cutIndex].overlays[overlayIndex].id = Utils.generateUUID();
                            }
                        }
                    }
                }
            }

            this.addAct(data);
        }

        /**
         * Shows this story, sets the storyID and storyTitle.
         */
        public showMe():void {

            super.showMe();

            Main.storyId = this.data.id;
            Main.storyTitle = this.data.name;

            this.populateModerationSceneSelects();
        }

        /**
         * Populates the moderation scene select fields with the latest list of api scenes on the story.
         */
        public populateModerationSceneSelects() {
            var select = this.content.find('select.sceneItems.api');
            select.empty().append(new Option('', ''));

            for (var actId in this.data.acts) {
                if (this.data.acts.hasOwnProperty(actId)) {
                    for (var sceneId in this.data.acts[actId].scenes) {
                        if (this.data.acts[actId].scenes.hasOwnProperty(sceneId)) {
                            var scene = this.data.acts[actId].scenes[sceneId];
                            if (scene.type.indexOf('Api') != -1) {
                                select.append(new Option(sceneId, sceneId));
                            }
                        }
                    }
                }
            }

            select.filter('.approved').val(this.data.moderationApprovedScene);
            select.filter('.rejected').val(this.data.moderationRejectedScene);
        }

        /**
         * Removes and Kills the selected Act.
         */
        public deleteChild():void {

            //kill the view
            var child = this.children[this.childToDelete];
            child.killMe();
            child = null;
            this.activeChild = null;

            //remove the data
            delete this.children[this.childToDelete];
            delete this.data.acts[this.childToDelete];

            //save
            this.showMe();
            this.saveData();
        }

        /**
         * Removes this story and tells the StoriesView to clear it from the database.
         */
        public removeMe():void {
            this.delegate.childToDelete = this.data.id;
            this.delegate.deleteChild();
        }

        /**
         * Removes this story from interface, but leaves it in the database
         */
        public killMe():void {

            this.unbindEvents();

            super.killMe();
        }

        /**
         * Removes this story from the database.
         */
        public deleteMe():void {

            Ajax.request('DELETE', new JWTAjaxRequest('/story/' + this.data.id, null, $.proxy(this.storyRemoved, this)));

            this.killMe();
        }

        /**
         * Specifies where the story's data can be found on this level.
         * @param storyData An object containing the story JSON structure.
         */
        public onDataReload(storyData):void {
            $.extend(true, this.data, storyData);
        }

        /**
         * Update the story dropdown once the story is removed.
         */
        private storyRemoved() {

            EventBus.dispatch(StoryPicker.UPDATE_STORIES);
        }
    }
}
