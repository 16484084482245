/*
 * Copyright © 2018. Nickel Media Inc.
 *
 */
module Nickel {

    export class Auth {
        private static sharedSessionCookie = 'auth_state';

        private static factory() {
            return new auth0.WebAuth({
                domain: 'imposium.auth0.com',
                clientID: 'FoP5tjbiy76e8XGK7NDAGagIEC07bfPX',
                responseType: 'id_token',
                scope: 'openid',
                redirectUri: window.location.protocol + '//' + window.location.host + '/redirect'
            });
        }

        public static login(hash, callback) {
            const auth = Auth.factory();

            if (!Auth.isAuthenticated()) {
                if (hash) {
                    auth.parseHash({hash: hash}, (err, authResult) => {
                        if (authResult && authResult.idToken) {
                            Auth.setSession(authResult, callback);
                        } else if (err) {
                            console.error(err);
                        }
                    });
                } else {
                    auth.checkSession({}, (err, authResult) => {
                        if (authResult && authResult.idToken) {
                            Auth.setSession(authResult, callback);
                        } else {
                            localStorage.setItem('request_path', window.location.pathname);
                            auth.authorize();
                        }
                    });
                }
            } else {
                const session = Auth.getSession();
                const sharedSession = Auth.getSharedSession();
                if (sharedSession && sharedSession.sub && sharedSession.sub === session.sub &&
                    sharedSession.stories_hash === Auth.generateHash(session.access_data)) {
                    callback(session.token, session.access_data);
                } else {
                    auth.checkSession({}, (err, authResult) => {
                        if (authResult && authResult.idToken) {
                            Auth.setSession(authResult, callback);
                        } else {
                            Auth.logout();
                        }
                    });
                }
            }
        }

        public static logout() {
            const auth = Auth.factory();

            // Remove tokens and expiry time from localStorage
            localStorage.removeItem('id_token');
            localStorage.removeItem('sub');
            localStorage.removeItem('access_data');
            localStorage.removeItem('expires_at');
            localStorage.removeItem('request_path');

            const sharedSession = Auth.getSharedSession();
            if (sharedSession && sharedSession.sub) {
                // Remove shared session cookie
                Auth.updateSharedSessionCookie('', -1);

                // noinspection TypeScriptValidateJSTypes
                auth.logout({"returnTo": window.location.protocol + '//' + window.location.host + '/redirect'});
            } else {
                auth.authorize();
            }

            return false;
        }

        public static setSession(authResult, callback) {
            if (!authResult.idToken || !authResult.idTokenPayload['sub'] || !authResult.idTokenPayload['exp']) {
                console.error('Unable to configure session from auth result');
                callback(null, null);
            }

            JWTAjaxRequest.idToken = authResult.idToken;
            Ajax.get(new JWTAjaxRequest('/access', null, function (accessData) {
                const idToken = authResult.idToken;
                const sub = authResult.idTokenPayload['sub'];

                localStorage.setItem('id_token', idToken);
                localStorage.setItem('sub', sub);
                localStorage.setItem('access_data', JSON.stringify(accessData));
                localStorage.setItem('expires_at', JSON.stringify(authResult.idTokenPayload['exp'] * 1000));

                Auth.updateSharedSession({sub: sub, stories_hash: Auth.generateHash(accessData)});

                callback(idToken, accessData);
            }, function (xhr) {
                console.error('The request to retrieve service access failed with a status of ' + xhr.jqXHR.status + ' ' + xhr.jqXHR.statusText);
                callback(null, null);
            }));
        }

        public static getSession() {
            return {"token": localStorage.getItem('id_token'), "sub": localStorage.getItem('sub'), "access_data": JSON.parse(localStorage.getItem('access_data'))}
        }

        public static getSharedSession() {
            const value = Utils.getCookie(Auth.sharedSessionCookie);
            if (value) {
                return JSON.parse(decodeURIComponent(value));
            }
            return null;
        }

        public static updateSharedSession(data) {
            let currentData = Auth.getSharedSession();
            if (!currentData) {
                currentData = {};
            }
            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    currentData[key] = data[key];
                }
            }
            const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
            const expirySeconds = (expiresAt) ? (expiresAt - new Date().getTime()) / 1000 : -1;

            Auth.updateSharedSessionCookie(encodeURIComponent(JSON.stringify(currentData)), expirySeconds);
        }

        private static updateSharedSessionCookie(value, expirySeconds) {
            const domainParts = window.location.host.split('.').reverse();
            const domain = (domainParts.length > 2) ? '.' + domainParts[1] + '.' + domainParts[0].split(':')[0] : null;

            Utils.setCookie(Auth.sharedSessionCookie, value, expirySeconds, domain);
        }

        public static isAuthenticated() {
            // Check whether the current time is past the expiry time
            const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
            return new Date().getTime() < expiresAt;
        }

        public static generateHash(accessData: any): number {
            const s: string = accessData.organizations.reduce((p, c) => ([...p, ...c.stories.reduce((p, c) => (p += `${c.id}${c.name}`), '')]), '');
            let hash: number = 0;

            if (s.length === 0) {
                return hash;
            }

            for (let i = 0; i < s.length; i++) {
                hash = ((hash << 5) - hash) + s.charCodeAt(i);
                hash |= 0;
            }

            return hash;
        }

        public static getPreviousRequestPath() {
            const prevRequestPath = localStorage.getItem('request_path');
            localStorage.removeItem('request_path');
            return prevRequestPath;
        }

        public static canAccess(route, services) {
            const accessUrl = window.location.host + route;
            if (services) {
                for (let i = 0; i < services.length; i++) {
                    if (services[i]['url']) {
                        if (accessUrl == services[i]['url'].replace('http://', '').replace('https://', '').replace('//', '')) {
                            return true;
                        }
                    }
                }
            }

            return false
        }

        public static canAccessByName(name, services) {
            if (services) {
                for (let i = 0; i < services.length; i++) {
                    if (services[i]['name'] && services[i]['name'] === name) {
                        return true;
                    }
                }
            }

            return false
        }
    }
}
