module Nickel {

    export class Debug extends Component {

        private textArea:any;

        static LOG:string = "debuglog";
        static ERROR:string = "debugerror";
        static SUCCESS:string = "debugsuccess";
        static HTML:string = "debughtml";

        private debugString:any = "";
        private btnClear:any;
        private btnToggle:any;
        private panel:any;
        private hidden:boolean = false;
        private defaultHeight:number = 150;
        private headerHeight:number = 20;

        constructor(container, data, id) {

            super(container, data, id);

            this.viewLoaded(Main.templates.find('.debug_console').clone());

            $(window).bind('resize', $.proxy(this.resize, this));

            this.btnClear = this.content.find('.btnClear').bind('click', $.proxy(this.clearConsole, this));
            this.btnToggle = this.content.find('.btnToggle').bind('click', $.proxy(this.toggleConsole, this));
            this.resize();

            this.panel = new Nickel.DynamicPanel(this.content, {
                'handleX': 'center',
                'handleY': 'top',
                'minHeight': 30,
                'handleStyle': 'horizontal'
            });
            this.panel.on(DynamicPanel.RESIZE, $.proxy(this.resize, this));
            this.panel.on(DynamicPanel.RESIZE, $.proxy(this.setMaxHeight, this));
        }

        public bindGlobalEvents() {
            EventBus.addEventListener(Debug.LOG, $.proxy(this.log, this), this);
            EventBus.addEventListener(Debug.ERROR, $.proxy(this.error, this), this);
            EventBus.addEventListener(Debug.SUCCESS, $.proxy(this.success, this), this);
            EventBus.addEventListener(Debug.HTML, $.proxy(this.logHtml, this), this);
        }

        public isHidden() {
            return this.hidden;
        }

        public toggleConsole() {

            if (this.hidden) {
                this.panel.initialHeight = this.defaultHeight;
                this.panel.currentHeight = this.defaultHeight;
                this.panel.setSize();
                this.btnToggle.addClass('down');
                this.hidden = false;
                this.resize();
            } else {
                this.panel.initialHeight = this.headerHeight;
                this.panel.currentHeight = this.headerHeight;
                this.panel.setSize();
                this.btnToggle.removeClass('down');
                this.hidden = true;
            }
        }

        private clearConsole() {
            this.textArea.empty();
        }

        public logHtml(log) {
            this.textArea.append(log);
            this.scrollToBottom();
        }

        public log(log) {
            this.logHtml("<span class = 'log'>" + log + "</span>")
        }

        public error(data) {
            this.logHtml("<span class = 'error'>" + data.text + "<br>" + data.error.status + " " + data.error.statusText + "</span>")
        }

        public success(log) {
            this.logHtml("<span class = 'success'>" + log + "</span>");
        }

        private scrollToBottom() {
            this.textArea[0].scrollTop = this.textArea[0].scrollHeight;
        }

        private resize() {

            this.hidden = false;

            var h:number = <number>this.content.height();
            this.textArea.css('height', (h - 25) + 'px');
        }

        private setMaxHeight() {

            var maxH:number = <number>this.container.height();
            this.content.css('max-height', maxH + 'px');
        }

        public viewLoaded(v) {
            super.viewLoaded(v);

            this.textArea = this.content.find('.debug');
        }
    }
}
