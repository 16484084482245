/**
 * Created by webbergreg on 15-03-21.
 */
module Nickel {

    export class RightInterface extends Component {

        private activeInterface:any;
        private title:any;
        private videoPlayer:any;
        private panel:any;
        private btnDelete:any;
        private btnDuplicate:any;
        private btnExport:any;
        private viewHolder:any;

        public static SHOW_IN_INTERFACE:string = "showininterface";
        public static RESIZE_INTERFACE:string = "resizerightinterface";
        public static WIDTH:number = 500;

        constructor(container, data, delegate) {

            super(container, data, delegate);

            this.viewLoaded(Main.templates.find('.rightInterface').clone());

            EventBus.addEventListener(RightInterface.SHOW_IN_INTERFACE, $.proxy(this.showView, this), this);

            this.title = this.content.find('.title');
            this.videoPlayer = new Nickel.VideoPlayer(this.content.find('.video'), {}, this);
            this.videoPlayer.showMe();

            this.btnDuplicate = this.content.find('.btnDuplicate').bind('click', $.proxy(this.duplicateView, this));
            this.btnDelete = this.content.find('.btnRemove').bind('click', $.proxy(this.removeView, this));
            this.btnExport = this.content.find('.btnExport').bind('click', $.proxy(this.exportView, this));
            this.viewHolder = this.content.find('.viewHolder');

            this.panel = new Nickel.DynamicPanel(this.content, {
                'handleX': 'left',
                'handleY': 'center',
                'minWidth': 450,
                'handleStyle': 'vertical'
            });
            this.panel.on(DynamicPanel.RESIZE, $.proxy(this.resize, this));

            this.resize();
        }

        private exportView() {
            if (this.activeInterface) {
                this.activeInterface.exportMe();
            }
        }

        private duplicateView() {
            if (this.activeInterface) {
                this.activeInterface.duplicateMe();
            }
        }

        private resize() {

            RightInterface.WIDTH = this.panel.currentWidth;
            EventBus.dispatchEvent(RightInterface.RESIZE_INTERFACE);

            this.videoPlayer.resize();

            if (this.activeInterface) {
                //if the interface can resize, resize it
                if (typeof this.activeInterface.resize == 'function') {

                    setTimeout(()=> {
                        this.activeInterface.resize();
                    }, 50);
                }
            }
        }

        private removeView() {

            var r = confirm(`Are you sure you want to remove ${this.data.label}?\nThis action cannot be undone.`);
            if (r == true) {
                if (this.activeInterface) {
                    this.activeInterface.removeMe();
                }
            } else {

            }
        }

        private showView(data) {

            //remove the old active interface
            if (this.activeInterface) {
                if (this.activeInterface.content) {
                    this.activeInterface.content.detach();
                }
            }

            //set the new active interface
            this.activeInterface = data.view;

            //if this class doesn't need a video player, hide it
            if (!this.activeInterface.videoPlayer) {
                this.videoPlayer.hideMe();
                this.videoPlayer.clearVideo();
            }

            //add the active interface
            this.viewHolder.append(data.view.content);

            //set the labels
            this.data.label = data.view.data.name;
            this.data.type = data.type;

            //update the dropdown to show releavent function calls
            this.updateDropdown();
            this.resize();
        }

        public clear() {

            if (this.activeInterface) {
                if (this.activeInterface.content) {
                    this.activeInterface.content.detach();
                }
            }
            this.activeInterface = null;
            this.videoPlayer.hideMe();
            this.videoPlayer.clearVideo();
            this.data.label = "";
            this.data.type = "";

            this.updateDropdown();
        }

        private updateDropdown() {

            if (this.activeInterface) {
                if (typeof this.activeInterface.exportMe == 'function') {
                    this.btnExport.show();
                } else {
                    this.btnExport.hide();
                }

                if (this.activeInterface.delegate && typeof this.activeInterface.delegate.duplicateChildItem == 'function' && typeof this.activeInterface.duplicateMe == 'function') {
                    this.btnDuplicate.show();
                } else {
                    this.btnDuplicate.hide();
                }
            } else {
                this.btnDuplicate.hide();
                this.btnExport.hide();
            }
        }

    }
}
